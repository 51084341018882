import { Injectable, EventEmitter } from "@angular/core";
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from "oidc-client-ts";
import { ConfigService } from "../config/config.service";
import { Observable, BehaviorSubject, from, of, combineLatest } from "rxjs";
import { Router } from "@angular/router";
import intersection from "lodash/intersection";
import { map, shareReplay, take, tap, filter, catchError, throttleTime, withLatestFrom, switchMap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiService } from "../api/api.service";
import { Claim } from "src/app/models/claims.model";
import { AppState } from "src/app/@store/reducers";
import { select, Store } from "@ngrx/store";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { ResultActions } from "src/app/@store/actions/results.actions";
import { StsScopes } from "../data/auth.model";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { EStorageKeys, localStorageKeys } from "src/app/shared/model/storage.model";
import { isInAvailableLocale } from "src/app/transloco-root.module";
import { TranslocoService } from "@ngneat/transloco";
import { getLangCodeFromLocalStorage, getLangCodeFromQueryString, showCookieBot } from "src/app/shared/component-modules/core/util/common.utils";
import { LoggerService } from "../logger.service";
import { claimTypes } from "src/app/appsettings";

/** value to check if user is of patient user_type in claims */
export const patientUserType = "patient";
/** SSN claim type constant */
export const ssnClaimType = "ssn";
/** model for user info after being picked and decoded */
export interface UserInfo {
  name: string;
  username?: string;
  country: string;
  client_id: string;
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  phone: string;
  preferred_username: string;
  sub: string;
  user_type: string | string[];
  ssn?: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private jwt = new JwtHelperService();
  userInfo = new BehaviorSubject(null);
  profileInfo: UserInfo = null;
  public userInfo$: Observable<UserInfo> = this.userInfo.asObservable().pipe(shareReplay(1));

  public country$ = this.userInfo$.pipe(filter(i=>!!i), map((u) => u?.country));
  public isPatient$ = of(true); /** all users can access the site */
  public sub$ = this.userInfo$.pipe(filter(i=>!!i), map((claims) => claims.sub));
  public patientSsn$ = this.userInfo$.pipe(filter(i=>!!i), map((claims) => claims?.ssn));

  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  _manager: UserManager = null;
  _user: User = null;
  _clientSettings: UserManagerSettings;
  loggedIn: boolean;

  signinRedirectTrigger$ = new BehaviorSubject<any>(null);
  signinRedirectCallbackTrigger$ = new BehaviorSubject<any>(null);
  public signinRedirectDone$ = new BehaviorSubject(null);
  claimsIsLoaded: boolean = false;

  constructor(
    private config: ConfigService,
    private apiService: ApiService,
    private router: Router,
    private store: Store<AppState>,
    private _transloco: TranslocoService,
    private logr: LoggerService
  ) {
    this._manager = new UserManager(this.getClientSettings());
    this._manager
      .getUser()
      .then((user) => {
        this.logr.log("getUser() resolve!");
        if (user) {
          this._user = user;
          this.userLoadededEvent.emit(user);
          this.checkSavedUserInfo(user);
        }
        else { this.loggedIn = false; }
      })
      .catch((err) => {
        this.loggedIn = false;
        console.log(err);
      });

    this._manager.events.addUserLoaded((user) => {
      this.logr.log("addUserLoaded event called!");
      this._user = user;

      this.checkSavedUserInfo(user);
    });

    this._manager.events.addSilentRenewError((err) => {
      this.logr.log("addSilentRenewError event called!", err);
      let hasInvalidGrant = err?.message?.indexOf('{"error":"invalid_grant"}');
      if (!hasInvalidGrant) {
        localStorage.clear();
        sessionStorage.clear();
        this.store.dispatch( STSActions.loadStssSuccess({ data: { token: "" } }) );
        this.store.dispatch( ResultActions.loadCheckResults({ params: {}, isEmailUsed: true }) );
      }
    });

    this._manager.events.addUserSessionChanged(() => {
      this.logr.log("addUserSessionChanged event called!");

      // will check for updated token,
      // if token expired, will be redirected to sts-login
      this.signinRedirectTrigger$.next(Date.now());
    });

    // this._manager.events.addUserSignedOut(() => {
    //   this.logr.log("addUserSignedOut event called!");

    //   this.startSignoutMainWindow();
    // });


    this.addSigninEvents();
  }


  isLoggedInObs(): Observable<boolean> {
    return from(this._manager.getUser()).pipe(
      map<User, boolean>((user) => {
        if (user) { return true; }
        return false;
      })
    );
  }

  getClientSettings(): UserManagerSettings {
    const { OIDC } = this.config.envJson;
    const config = this.config._envConfig;

    let clientSettings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      authority: config.baseAuthUrl,
      client_id: OIDC?.ClientId ? OIDC.ClientId : config.IdSrvClientClientId,
      redirect_uri: `${config.basePlasmaUrl}/signin-callback`,
      post_logout_redirect_uri: config.basePlasmaUrl,
      response_type: OIDC?.ResponseType ? OIDC.ResponseType : config.IdSrvClientResponseType,
      scope: OIDC?.Scope ? OIDC.Scope : StsScopes.join(" "),
      filterProtocolClaims: true,
      loadUserInfo: false,
      silent_redirect_uri: `${config.basePlasmaUrl}/renewtoken`,
      automaticSilentRenew: true,
      revokeTokensOnSignout: true,
      monitorSession: true,
    };

    console.log(JSON.stringify(clientSettings))
    return clientSettings;
  }

  startSignoutMainWindow() {
    this._manager.getUser().then((user) => {
      if (user) {
        return this._manager
          .signoutRedirect({
            id_token_hint: user.id_token,
            extraQueryParams: {
              refresh_token: user.refresh_token,
            },
          })
          .then((resp) => {
            this.logr.log("startSignoutMainWindow -> signoutRedirect[user] success!", resp);
            this.clearAppLanguageFromStorage();
          })
          .catch((err) => this.logr.error(err));
      } else {
        return this._manager
          .signoutRedirect()
          .then((resp) => {
            this.logr.log("startSignoutMainWindow -> signoutRedirect[null] success!", resp);
            this.clearAppLanguageFromStorage();
          })
          .catch((err) => this.logr.error(err));
      }
    });
  }

  checkUserAccess(types = []): Observable<boolean> {

    return this.userInfo$.pipe(filter(i=>!!i), map(claims=>{
      const userTypes = claims.user_type as string[] || [];
      const allowedUsers = types.map((c) => c.toLowerCase());

      if (types.some((n) => n == "all")) return true;
      return intersection(userTypes.map((c) => c.toLowerCase()), allowedUsers).length ? true : false;
    }))

  }

  private getUserClaims(sub: string): Observable<Claim[]> {
    return this.apiService.get<Claim[]>(`/manage/accounts/${sub}`);
  }

  checkSavedUserInfo(user) {
    this.store.pipe(select(StsSelectors.getUserBasicInfo))
      .pipe(
        filter((info) => {
          const { synlab_id } = this.jwt.decodeToken(user.access_token);
          const newUser = info.synlabId && info.synlabId != synlab_id;
          if (newUser) {
            this.claimsIsLoaded = false;
          }
          return !info.synlabId || newUser
        }),
        take(1),
        tap(n=>{ this.saveToState(user) })
    ).subscribe();
  }

  saveToState(user) {
    const { langCode, idp, sub } = this.jwt.decodeToken(user.access_token);
    this.store.dispatch( STSActions.loadStssSuccess({ data: { token: user.access_token, idp} as any }) );

    this.getClaims(sub)
    showCookieBot(true, 500);

    //  set langgauge from Jwt
    if (langCode) { this.setAppLanguageFromJwtToken(langCode); }
  }

  setAppLanguageFromJwtToken(lang: string) {
    const langCodeFromStorage: string = getLangCodeFromLocalStorage();
    const langCodeFromQueryString: string = getLangCodeFromQueryString();

    this.config
      .getEnvironmentConfig()
      .pipe(
        filter((x) => Boolean(x)),
        take(1),
        tap((config) => {
          const configLanguages = config.AvailableLanguage?.split(",");
          let availableLanguages = [
            this._transloco.getDefaultLang(),
            ...(Array.isArray(configLanguages) ? configLanguages : []),
          ];

          const isJwtLanguageAvailable = lang && isInAvailableLocale(lang, availableLanguages) != "";
          this.logr.log({ isJwtLanguageAvailable, lang })

          if (
            !langCodeFromQueryString &&
            !langCodeFromStorage &&
            isJwtLanguageAvailable
          ) {
            const langToSet: string = isInAvailableLocale(lang, availableLanguages);
            this.logr.log('Setting from JWT', langToSet);
            localStorage.setItem(localStorageKeys.langCode, langToSet);
            this._transloco.setActiveLang(langToSet);
          }
        })
      )
      .subscribe();
  }

  clearAppLanguageFromStorage() {
    localStorage.removeItem(localStorageKeys.langCode);
    localStorage.removeItem(EStorageKeys.REDIRECT_URL);
  }

  addSigninEvents() {
    this.signinRedirectTrigger$.pipe(filter((v) => !!v),
        throttleTime(1000), tap((evt) => {
          from(this._manager.signinRedirect().catch(e => e)).pipe(
            take(1),
            tap(()=>{
              this.logr.log("startSigninMainWindow -> signinRedirect success!");
            })
          ).subscribe();
        }),
        catchError(error => {
          this.logr.log(error);
          throw new Error("signinRedirect() has encountered an error!");
        })
      ).subscribe();

    this.signinRedirectCallbackTrigger$.pipe(filter((v) => !!v),
        tap(() => this.signinRedirectDone$.next(false)),
        throttleTime(1000), tap((evt) => {
          from(this._manager.signinRedirectCallback().catch(e => e)).pipe(
            withLatestFrom(
              this.store.pipe(select(StsSelectors.isHuTenant)),
              this.store.pipe(select(StsSelectors.getRedirectPath)),
              ),
            take(1),
            tap(([user, isHuTenant, path])=>{
              this.logr.log("endSigninMainWindow -> signinRedirectCallback success!", user);
              this.signinRedirectDone$.next(true);
              document.querySelector("body").classList.remove("signin-redirecting");

              this.checkRedirect();
            }),
            catchError(error => {
              this.logr.log(error);
              throw new Error("signinRedirectCallback() has encountered an error!");
            })
          ).subscribe();
        })
      ).subscribe();
  }

  private checkRedirect(): void {
    combineLatest([
      this.signinRedirectDone$.pipe(filter(user=>!!user)),
      this.store.pipe(select(StsSelectors.getRole)).pipe(filter(roles=>!!roles.length))
    ]).pipe(take(1),
    switchMap(() => this.store.pipe(select(StsSelectors.getRedirectPath))),
    tap((redirectPath) => {
        this.logr.log({ redirectPath });

        const redirectURL = localStorage.getItem(EStorageKeys.REDIRECT_URL);
        let initialRoute: string | string[] = (redirectURL?.includes('signin-callback') ? "/" : redirectURL) || "/";
        if (initialRoute === "/") { initialRoute = redirectPath; }
        else if (initialRoute === "/results/") { initialRoute = redirectPath; }

        this.logr.log({ initialRoute });
        this.logr.log("Redirecting to initial route:\n", initialRoute);
        localStorage.removeItem(EStorageKeys.REDIRECT_URL);

        if(typeof initialRoute === 'string') {
          this.router.navigateByUrl(initialRoute as string).then(navigated => this.logr.log("Redirect to initial route success"));
        } else {
          this.router.navigate(initialRoute as string[]).then(navigated => this.logr.log("Redirect to initial route success"));
        }
      })).subscribe();
  }

  getClaims(sub: string) {
    if(!this.claimsIsLoaded) {
      this.getUserClaims(sub).pipe(
        map((claims) => {
          const roles = claims.filter((z) => z.claimType == claimTypes.USER_TYPE).map((a) => a.claimValue);
          const userId: string = claims.find( (z) => z.claimType == claimTypes.USERID ).claimValue;

          this.store.dispatch(STSActions.loadProfileSuccess({ claims }));

          return {
            ...claims.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {}),
            user_type: roles,
            sub: userId
          };
        }), shareReplay(1)).subscribe((claims: any) => {
          this.claimsIsLoaded = true;

          const { idp } = this.jwt.decodeToken( this._user.access_token );
          const userData = {
            idp,
            role: claims.user_type,
            token: this._user,
            name: `${claims.given_name} ${claims.family_name}`,
            synlabId: claims.synlab_id,
            country: claims.country,
            email: claims.email,
            given_name: claims.given_name,
            family_name: claims.family_name,
            sub: claims.sub,
            synlab_id: claims.synlab_id,
          };
          this.store.dispatch( STSActions.loadStssSuccess({ data: userData }) );
          this.userInfo.next({ ...claims, ...userData } as any);
          this.profileInfo = { ...claims, ...userData };
        });
    }
  }

}
