import dayjs from "dayjs"
import memoize from "lodash/memoize";
import { timezone } from "src/app/@store/models/timezone";

/** parse `YYYY-MM-DDThh:mm:ss` into `YYYY-MM-DDThh:mm:ssZ` (with timezone utc indicator) */
export const toProperIsoDate = memoize((date: string) => {
  const d = dayjs(date);
  return d.isValid() ? d.toISOString() : "";
});

export const dateToddmmyyy = memoize((date: string) => {
  const d = dayjs(date, 'YYYY-MM-DD', true);
  return d.isValid() ? d.format("DD.MM.YYYY") : "";
});
/** parse `YYYY-MM-DDThh:mm:ssZ` to `DD.MM.YYYY hh:mm A (CET)` */
export const properToDateTimeCet = memoize((date: string) => {
  const d = dayjs(date).tz(timezone);
  return d.isValid() ? d.format("DD.MM.YYYY hh:mm A (CET)") : "";
})
