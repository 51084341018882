import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { ApiService } from "src/app/service/api/api.service";
import { HeaderActions } from "../actions/header.actions";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { of } from "rxjs";


@Injectable()
export class HeaderEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
  ) {}

  loadRelatedUsers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeaderActions.getRelatedUsers),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ synlabId }, endpoint]) => {
          return this.api.getMethod(`${endpoint}/api/user/getRelatedUsers/${synlabId}`);
        }),
        tap((results) => {
          this.store.dispatch(
            HeaderActions.getRelatedUsersSuccessful({ results })
          );
        }),
      ),
    { dispatch: false }
  );

}
