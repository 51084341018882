import { Injectable } from "@angular/core";
import { CoreModule } from "../core.module";

@Injectable({
  providedIn: CoreModule,
})
export class LoggerService {
  public get log(): Function {
    return () => {};
  }

  constructor() {}
}
