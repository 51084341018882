import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-info-message",
  templateUrl: "./info-message.component.html",
  styleUrls: ["./info-message.component.scss"],
})
export class InfoMessageComponent {
  constructor(private _snackBar: MatSnackBar) {}

  close() {
    this._snackBar.dismiss();
  }
}
