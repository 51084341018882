import { createSelector } from '@ngrx/store';
import dayjs from 'dayjs';
import { AppState } from '../reducers';
import { parentChildViewResultFeatureKey } from '../reducers/parent-child-view-result.reducer';

const expiryInDays = 7;

const State = (state: AppState) => state[parentChildViewResultFeatureKey];

const securityError = createSelector(State, s => s.securityInfoError)
const loading = createSelector(State, s => s.securityInfoLoading);
const submitLoading = createSelector(State, s => s.securityInfoSubmitLoading);
const refreshLoading = createSelector(State, s => s.securityInfoRefreshLoading);
const securityInfo = createSelector(State, s => s.securityInfo);
const resultInfo = createSelector(State, s => s.wardResult);
const submitError = createSelector(State, s => s.wardResultError);
const expired = createSelector(
  loading,
  submitLoading,
  refreshLoading,
  securityInfo,
  resultInfo,
  securityError,
  (_loading, _submitLoading, refreshing, _securityInfo, _resultInfo, _securityError) => {
    const notLoading = !_loading && !_submitLoading && !refreshing;
    const noResultYet = !_resultInfo;
    const noAttemptsLeft = _securityInfo && _securityInfo.requestCount < 1;
    const expiredByDate = _securityInfo && isExpired(_securityInfo.dateCreated);
    const responseSaysExpired = 'ERROR__PARENTCHILDVIEW__NOTACCESSIBLE' === _securityError;

    return notLoading && noResultYet && (
      (noAttemptsLeft || expiredByDate) ||
      responseSaysExpired
    );
  }
);

const otpGenerating = createSelector(State, s => s.otpGenerating);
const otpGeneratedDate = createSelector(State, s => s.otpGeneratedDate);
const otpTypeSelected = createSelector(State, s => s.otpTypeSelected);
const otpGenerateError = createSelector(State, s => s.otpGenerateError);

const certLoading = createSelector(State, s => s.getCertLoading);
const certError = createSelector(State, s => s.getCertError);
export const ParentChildViewResultSelectors = {
  loading,
  submitLoading,
  refreshLoading,
  securityInfo,
  resultInfo,
  submitError,
  expired,
  otpGenerating,
  otpGeneratedDate,
  otpTypeSelected,
  otpGenerateError,
  certLoading,
  certError,
}


function isExpired(dateCreated: string) {
  const created = dayjs.utc(dateCreated);
  const expiryDate = created.add(expiryInDays, 'day');
  const now = dayjs.utc();
  const pastExpiry = now.isAfter(expiryDate);
  return pastExpiry
}
