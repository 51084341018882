import { isAntigen, Results } from "src/app/@store/models/results.model";
import { Claim, findClaimAndGetValue, passportNumberClaimType } from "src/app/models/claims.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";
import dayjs from "dayjs";

export interface OthersCertData extends Results, BaseCertData {
  isOthers: true;
  lastName: string;
  firstName: string;
  dateIssued: string;
  dateOfBirth: string;
  samplingDate: string;
  samplingTime: string;
  result: string;
  resultComment: string;
  resultCommentTranslated: string;
  antigen: boolean;
  schoolUser: boolean;
  forceCertificate: number;
  islolliIndividualResult: boolean;
  showIssuedOn: boolean;
  showOtherSchoolFields: boolean;
  companyBackground: boolean;
  showCompanyPoolFields: boolean;
  companyName: string;
  isCompanyEmployee: boolean;
  showDateOfBirth: boolean; // if the user is of is company admin (mass_company && school_admin)  role, then DoB of the employee must be displayed
  patientFname: string;
  patientLname: string;
  patientDOB: string;
}
export const resultAsOthersCertData: ResultMapper<Results, OthersCertData> = (r, o) => {
  const baseCertData = baseResultAsCertData(r, o);
  const antigen = isAntigen(r);
  const isCompany = o.companyCheckRecord[r?.poolSynlabId]?.isPoolCompany;
  const companyName = o.companyCheckRecord[r?.poolSynlabId]?.companyName;
  const resultComment = getResultComment(r.synlabValue, antigen, o.enTranslations);
  const resultCommentTranslated = !o.currentLanguageIsEn
    ? getResultComment(r.synlabValue, antigen, o.translations)
    : "";
  return {
    ...r,
    ...baseCertData,
    isOthers: true,
    lastName: customUppercase(r?.lastName?.trim?.()),
    firstName: customUppercase(r?.firstName?.trim?.()),
    dateIssued: r.issuedOn?.slice?.(0, 10),
    dateOfBirth: r.dateOfBirth?.slice?.(0, 10),
    samplingDate: r.samplingTime?.slice?.(0, 10),
    samplingTime: r.samplingTime?.slice?.(11),
    resultComment,
    resultCommentTranslated,
    antigen,
    schoolUser: o.userIsLolliUser,
    forceCertificate: o.forceCertificate,
    islolliIndividualResult: r.isLolliIndividualResult,
    showIssuedOn: !r.isLolliIndividualResult,
    showOtherSchoolFields: o.userIsLolliUser && !r.isLolliIndividualResult && !isCompany,
    companyBackground: isCompany && !r.isLolliIndividualResult,
    showCompanyPoolFields: isCompany && !r.isLolliIndividualResult,
    companyName,
    isCompanyEmployee: o.userIsCompanyEmployee,
    showDateOfBirth: o.userIsCompanyAdmin,
    patientFname: o.userPatientInfo?.firstName || '',
    patientLname: o.userPatientInfo?.lastName || '',
    patientDOB: dayjs.tz(o.userPatientInfo?.dateOfBirth, "Europe/Berlin").format("DD.MM.YYYY"),
    isLolliIndividualResult: o.isPoolRecord[r.synlabId],
    passportNumber: getPassportNumber(r, o.claimsRecord),
  };
}

function getResultComment(
  synlabValue: string,
  antigen: boolean,
  translation: Object,
) {
  const resultKey = synlabValueTranslationKeyRecord[synlabValue] ||
    synlabValueTranslationKeyRecord[0];
  let msgKey: string;
  if (antigen) { msgKey = "antigenMessage"; }
  else { msgKey = "message"; }
  const key = [resultKey, msgKey];
  return key.reduce((acc, k) => acc[k], translation);
}
function getPassportNumber<T extends Results>(result: T, claimRecord: Record<string, Claim[]>) {
  const hasSynlabNoPassport = result.synlabId && !result.passportNumber;
  const claim = claimRecord[result.synlabId];
  const passport = claim
    ? findClaimAndGetValue(
      passportNumberClaimType,
      claim
    )
    : undefined;
  return hasSynlabNoPassport && passport
    ? passport
    : result.passportNumber;
}
