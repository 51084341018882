import { createReducer, on } from "@ngrx/store";
import * as SingleTestActions from "src/app/@store/actions/single-test-actions";
export const singleTestFeatureKey = 'singleTest';
export interface State {
  singleTestRequestLoading: boolean;
  singleTestRequestError: string;
  singleTestRequestSuccess: boolean;
}
export const initialState: State = {
  singleTestRequestLoading: false,
  singleTestRequestError: undefined,
  singleTestRequestSuccess: false,
}
export const reducer = createReducer<State>(
  initialState,
  on(SingleTestActions.sendSingleTest, s => ({
    ...s,
    singleTestRequestLoading: true,
    singleTestRequestError: undefined,
    singleTestRequestSuccess: false
  })),
  on(SingleTestActions.sendSingleTestSuccess, s => ({
    ...s,
    singleTestRequestLoading: false,
    singleTestRequestSuccess: true
  })),
  on(SingleTestActions.sendSingleTestFailure, (s, a) => ({
    ...s,
    singleTestRequestLoading: false,
    singleTestRequestError: a.error
  })),
  on(SingleTestActions.reset, () => ({
    ...initialState
  })),
)
