import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import * as LabOrderReducer from "../reducers/lab-order.reducer";

const State = (state: AppState) => state[LabOrderReducer.labOrderFeatureKey];

const orderExist = createSelector(State, (data) => data.orderExist);
const getSchool = createSelector(State, (data) => data.schoolName);
const getAccountHolder = createSelector(State, (data) => data.accountHolder);
const getSchoolLoading = createSelector(State, (data) => data.schoolLoading);
const getBarcodeLoading = createSelector(State, (data) => data.barcodeLoading);
const getOrderLoading = createSelector(State, (data) => data.orderLoading);

export const LabOrderSelector = {
  orderExist,
  getSchool,
  getSchoolLoading,
  getBarcodeLoading,
  getOrderLoading,
  getAccountHolder
};
