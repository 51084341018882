import { createAction, props } from '@ngrx/store';
import { EmployeeData, GetTempEmployeeListData, GetTempStudentListData, GetTempUserListPagination, StudentData, TempUserGetParams, UploadHistoryItem, UserStatusResponse, UserUploadFileType } from 'src/app/models/user-create.model';
const key = '[UserCreate]';
/** reset user create store data to initial */
export const resetUserCreateState = createAction(
  `${key} Reset user create state`
)
/** request user upload status */
export const getUserUploadStatus = createAction(
  `${key} Get user upload status`,
  props<{ id?: string }>()
);
/** request user upload status, successful */
export const getUserUploadStatusSuccess = createAction(
  `${key} Get user upload status success`,
  props<{ status: UserStatusResponse, id?: string }>()
);
/** request user upload status, failed */
export const getUserUploadStatusFailure = createAction(
  `${key} Get user upload status failure`,
  props<{ error: string, id?: string }>()
);
/** start polling user upload status */
export const startUserUploadStatusPoll = createAction(
  `${key} Start user upload status poll`
);
/** stop polling user upload status */
export const stopUserUploadStatusPoll = createAction(
  `${key} Stop user upload status poll`
);
/** request user upload status and reset poll timer */
export const requestEarlyUserUploadStatus = createAction(
  `${key} Request early user upload Status`
);
/** upload csv */
export const uploadTempUserCsv = createAction(
  `${key} Upload temp user csv`,
  props<{ file: File, uploadFileType: UserUploadFileType }>()
);
/** upload csv success */
export const uploadTempUserCsvSuccess = createAction(
  `${key} Upload temp user csv success`
);
/** upload csv failed */
export const uploadTempUserCsvFailure = createAction(
  `${key} Upload temp user csv failure`,
  props<{ error: string }>()
);
// user list
export const getTempUserList = createAction(
  `${key} Get temp user list`,
  props<TempUserGetParams>()
);
export const getTempUserListFailure = createAction(
  `${key} Get temp user list failure`,
  props<{ error: string }>()
);
export const getTempStudentList = createAction(
  `${key} Get temp Student list`,
  props<TempUserGetParams>()
);
export const getTempStudentListSuccess = createAction(
  `${key} Get temp Student list success`,
  props<GetTempStudentListData>()
);
export const getTempStudentListFailure = createAction(
  `${key} Get temp Student list failure`,
  props<{ error: string }>()
);
export const getTempEmployeeList = createAction(
  `${key} Get temp Employee list`,
  props<TempUserGetParams>()
);
export const getTempEmployeeListSuccess = createAction(
  `${key} Get temp Employee list success`,
  props<GetTempEmployeeListData>()
);
export const getTempEmployeeListFailure = createAction(
  `${key} Get temp Employee list failure`,
  props<{ error: string }>()
);
// user list pagination
export const setPageSize = createAction(
  `${key} Set page size`,
  props<{ size: number }>()
);
export const nextPage = createAction(
  `${key} Next page`
);
export const previousPage = createAction(
  `${key} Previous page`
);
export const firstPage = createAction(
  `${key} First page`
);
export const lastPage = createAction(
  `${key} Last page`
);
export const changePage = createAction(
  `${key} Change page`,
  props<{ page: number }>()
)
export const changePageSuccess = createAction(
  `${key} Change page success`,
  props<{
    studentList: StudentData[],
    employeeList: EmployeeData[],
    newPagination: GetTempUserListPagination,
  }>()
)
export const changePageFailure = createAction(
  `${key} Change page failure`,
  props<{ error: string }>()
)


// cancel user creation
export const cancelUserCreation = createAction(
  `${key} Cancel user creation`
);
export const cancelUserCreationSuccess = createAction(
  `${key} Cancel user creation success`
);
export const cancelUserCreationFailure = createAction(
  `${key} Cancel user creation failure`,
  props<{ error: string }>()
);
// export current list
export const exportCurrentList = createAction(
  `${key} Export current list`
);
export const exportCurrentListSuccess = createAction(
  `${key} Export current list success`,
  props<{ blob: Blob, name: string }>()
);
export const exportCurrentListFailure = createAction(
  `${key} Export current list failure`,
  props<{ error: string }>()
);
// process user creation
export const processUserCreation = createAction(
  `${key} Process user creation`
);
export const processUserCreationSuccess = createAction(
  `${key} Process user creation success`,
  props<{ successful: boolean }>()
);
export const processUserCreationFailure = createAction(
  `${key} Process user creation failure`,
  props<{ error: string }>()
);
// get user
export const getTempUserInfo = createAction(
  `${key} Get temp user info`,
  props<{ id: string }>()
)
export const getTempUserInfoFailure = createAction(
  `${key} Get temp user info failure`,
  props<{ id: string, error: string }>()
)
export const getTempStudentInfo = createAction(
  `${key} Get temp student info`,
  props<{ id: string }>()
)
export const getTempStudentInfoSuccess = createAction(
  `${key} Get temp student info success`,
  props<{ id: string, data: StudentData }>()
)
export const getTempStudentInfoFailure = createAction(
  `${key} Get temp student info failure`,
  props<{ id: string, error: string }>()
)
export const getTempEmployeeInfo = createAction(
  `${key} Get temp employee info`,
  props<{ id: string }>()
)
export const getTempEmployeeInfoSuccess = createAction(
  `${key} Get temp employee info success`,
  props<{ id: string, data: EmployeeData }>()
)
export const getTempEmployeeInfoFailure = createAction(
  `${key} Get temp employee info failure`,
  props<{ id: string, error: string }>()
)
// update temp user
export const updateTempUserRecord = createAction(
  `${key} Update temp user record`,
  props<{ data: StudentData | EmployeeData }>()
);
export const updateTempUserRecordFailure = createAction(
  `${key} Update temp user record failure`,
  props<{ data: StudentData | EmployeeData, error: string }>()
);
export const updateStudentRecord = createAction(
  `${key} Update student record`,
  props<{ data: StudentData }>()
);
export const updateStudentRecordSuccess = createAction(
  `${key} Update student record success`,
  props<{ data: StudentData }>()
);
export const updateStudentRecordFailure = createAction(
  `${key} Update student record failure`,
  props<{ data: StudentData, error: string }>()
);
export const updateEmployeeRecord = createAction(
  `${key} Update employee record`,
  props<{ data: EmployeeData }>()
);
export const updateEmployeeRecordSuccess = createAction(
  `${key} Update employee record success`,
  props<{ data: EmployeeData }>()
);
export const updateEmployeeRecordFailure = createAction(
  `${key} Update employee record failure`,
  props<{ data: EmployeeData, error: string }>()
);
// delete temp user
export const deleteTempUserRecord = createAction(
  `${key} Delete temp user record`,
  props<{ id: string }>()
);
export const deleteTempUserRecordSuccess = createAction(
  `${key} Delete temp user record success`,
  props<{ id: string }>()
);
export const deleteTempUserRecordFailure = createAction(
  `${key} Delete temp user record failure`,
  props<{ id: string, error: string }>()
);

// get history
export const getUserCreateHistory = createAction(
  `${key} Get user create history`
);
export const getUserCreateHistorySuccess = createAction(
  `${key} Get user create history success`,
  props<{ data: UploadHistoryItem[] }>()
);
export const getUserCreateHistoryFailure = createAction(
  `${key} Get user create history failure`,
  props<{ error: string }>()
);
// download upload csv
export const downloadUploadCsv = createAction(
  `${key} Download upload csv`,
  props<{ fileId: number, fileName: string }>()
);
export const downloadUploadCsvSuccess = createAction(
  `${key} Download upload csv success`,
  props<{ fileId: number, blob: Blob, name: string }>()
);
export const downloadUploadCsvFailure = createAction(
  `${key} Download upload csv failure`,
  props<{ fileId: number, error: string }>()
);
// download result csv
export const downloadResultCsv = createAction(
  `${key} Download result csv`,
  props<{ fileId: number, fileType: UserUploadFileType, fileName: string }>()
);
export const downloadResultCsvSuccess = createAction(
  `${key} Download result csv success`,
  props<{ fileId: number, blob: Blob, name: string }>()
);
export const downloadResultCsvFailure = createAction(
  `${key} Download result csv failure`,
  props<{ fileId: number, error: string }>()
);
export const resetUploadSuccessFlag = createAction(
  `${key} Reset upload success flag`
);
