import { Component } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";

@Component({
  selector: "app-unauthorize",
  templateUrl: "./unauthorize.component.html",
})
export class UnauthorizeComponent {
  constructor(private auth: AuthService) {}

  logout() {
    this.auth.startSignoutMainWindow();
  }
}
