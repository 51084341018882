import { createReducer, on } from "@ngrx/store";
import { RegTestActions } from "../actions/regtest.actions";
import { TestPools } from "../models/regtest.model";

export const regtestFeatureKey = "regtest";

export interface State {
  testPoolsIsLoading: boolean;
  testPools: TestPools[];
  tubeIdLookupValue: boolean;
  tubeIdLookupInProgress: boolean;
  sendTestInProgress: boolean;
}

export const initialState: State = {
  testPoolsIsLoading: false,
  testPools: [],
  tubeIdLookupValue: null,
  tubeIdLookupInProgress: null,
  sendTestInProgress: false,
};

export const reducer = createReducer(
  initialState,

  // list of test's pool
  on(RegTestActions.loadTestPools, (state) => ({
    ...state,
    testPoolsIsLoading: true,
    testPools: [],
  })),
  on(
    RegTestActions.loadTestPoolsSuccess,
    RegTestActions.loadTestPoolsFailure,
    (state) => ({
      ...state,
      testPoolsIsLoading: false,
    })
  ),
  on(RegTestActions.loadTestPoolsSuccess, (state, { data }) => ({
    ...state,
    testPools: data,
  })),

  // testtube id lookup
  on(RegTestActions.testTubeIdLookup, (state) => ({
    ...state,
    tubeIdLookupValue: null,
    tubeIdLookupInProgress: true,
  })),
  on(RegTestActions.testTubeIdLookupSuccess, (state, { value }) => ({
    ...state,
    tubeIdLookupValue: value,
  })),
  on(RegTestActions.testTubeIdLookupSuccess, (state) => ({
    ...state,
    tubeIdLookupInProgress: false,
  })),
  on(RegTestActions.loadTestPoolsFailure, (state) => ({
    ...state,
    tubeIdLookupInProgress: false,
  })),
  on(RegTestActions.startSendTest, (state) => ({
    ...state,
    sendTestInProgress: true,
  })),
  on(
    RegTestActions.startSendTestFailure,
    RegTestActions.startSendTestSuccess,
    (state) => ({
      ...state,
      sendTestInProgress: false,
    })
  )
);
