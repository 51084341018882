import curry from "lodash/curry";
import flow from "lodash/flow";
import { ELoginMethod } from "../appsettings";

/** Model for claims */
export interface Claim {
    claimType: string;
    claimValue: string;
}
/** get claim value of `Claim` */
export function getClaimValue(claim: Claim): string {
    return claim && claim.claimValue
        ? claim.claimValue
        : '';
}
/** get claim type of `Claim` */
export function getClaimType(claim: Claim): string {
    return claim && claim.claimType
        ? claim.claimType
        : '';
}
/** `claim` is of type `claimType`
 * @param claimType type of claim to check
 * @param claim claim whose type to check
 */
export const isClaimType = curry((claimType: string, claim: Claim) =>
    getClaimType(claim) === claimType);

/** find claim with with specified claim type. */
export const findClaimAndGetValue = curry((claimType: string, claims: Claim[]) =>
    getClaimValue(claims?.find?.(isClaimType(claimType))));
export const findClaimAndGetBool = curry((claimType: string, claims: Claim[]) => flow(
  findClaimAndGetValue(claimType),
  x => x?.trim()?.toLowerCase() === "true"
)(claims));
export const passportNumberClaimType = 'passport_number';
export const synlabIdClaimType = 'synlab_id';
export const dateOfBirthClaimType = 'date_of_birth';

/** user data in accountinfo model */
export interface UserAccountInfo {
  active: string;
  additionalInfo: {}
  createdBy: string;
  createdDate: string;
  email: string;
  emailConfirmed: string;
  firstName: string;
  id: string;
  lastName: string;
  modifiedBy: string
  modifiedDate: string
  name: string;
  patientIndex: string;
  ssn: string
  twoFactorEnabled: string;
  twoFactorProvider: string
  uefaId: string;
  userType: string;
}

export interface IDecodedToken {
  synlab_id: string;
  role: string[];
  name: string;
  country: string;
  email: string;
  token: string;
  idp: ELoginMethod;
  given_name: string;
  family_name: string;
  sub: string;
}
