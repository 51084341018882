import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ExtendedResultV2FilterModel, ResultV2FilterModel } from "../model/filter.mode";

@Injectable({
  providedIn: 'root'
})
export class ResultsV2FilterNotifierService {

  private sender = new BehaviorSubject<ResultV2FilterModel>(null)

  private receiver = new BehaviorSubject<ExtendedResultV2FilterModel>(null);

  getReceiverAsObservable(): Observable<ExtendedResultV2FilterModel> {
    return this.receiver.asObservable();
  }

  getSenderAsObservable(): Observable<ResultV2FilterModel> {
    return this.sender.asObservable();
  }

  broadcastSelectedFilters(filters: ResultV2FilterModel): void {
    this.sender.next(filters);
  }

  setFilterOptions(filters: ExtendedResultV2FilterModel): void {
    this.receiver.next(filters);
  }
}
