import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { AppState } from "src/app/@store/reducers";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this will set the redirect url in redux state before going to normal path
    this.activatedRoute.queryParams.subscribe((params) => {
      const path = params["url"];
      this.store.dispatch(STSActions.setRedirectUrl({ path }));

      // continue with normal route
      this.router.navigate(["/"]);
    });
  }
}
