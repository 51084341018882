import { Claim } from "./claims.model";

export interface PoolBarcodeExists {
  requestAttempted: boolean;
  exists: boolean;
  error: string;
  loading: boolean;
}
export interface PoolBarcodeExistRecord extends Record<string,PoolBarcodeExists> {}

export const newPoolBarcodeExistsState = (): PoolBarcodeExists => ({
  requestAttempted: false,
  exists: false,
  error: undefined,
  loading: false
});

export const updatePoolBarcodeExistsRecord = (
  barcode: string,
  newValue: Partial<PoolBarcodeExists>,
  rec: PoolBarcodeExistRecord
): PoolBarcodeExistRecord => ({
  ...rec,
  [barcode]: {
    ...newPoolBarcodeExistsState(),
    ...rec[barcode],
    ...newValue
  }
})



export interface PoolBarcodeInformationResponse {
  orderId: string;
  samplingDate: string;
  synlabId: string;
  organizationSynlabId: string;
}

export interface PoolBarcodeInformationState {
  orderId: string;
  samplingDate: string;
  synlabId: string;
  organizationSynlabId: string;
  error: string;
  loading: boolean;
}
export interface PoolBarcodeInformationRecord extends Record<string,PoolBarcodeInformationState> {}

export const newPoolBarcodeInformationState =
  (): PoolBarcodeInformationState => ({
    orderId: undefined,
    samplingDate: undefined,
    synlabId: undefined,
    organizationSynlabId: undefined,
    error: undefined,
    loading: false
  });

export interface PoolClaimsState {
  claims: Claim[];
  loading: boolean;
  error: string;
}
export interface PoolClaimsRecord extends Record<string, PoolClaimsState> {}

/** used in pool fields comp */
export interface PoolFields {
  poolName: string,
  date: string;
  time: string;
}



export interface PoolIndividualOrder {
  barcode: string;
  organizationSynlabId: string;
  synlabId: string;
  testKitBarcode: string;
}
export interface PoolIndividualOrdersState {
  poolIndividualOrders: PoolIndividualOrder[];
  loading: boolean;
  error: string;
}
export interface PoolIndividualOrdersStateRecord extends Record<string, PoolIndividualOrdersState> {}


export interface HasNegative {
  hasNegative: boolean;
}
export interface HasNegativeState {
  hasNegative: boolean;
  loading: boolean;
  error: string;
}
/** key is pool barcode */
export interface HasNegativeStateRecord extends Record<string, HasNegativeState> { }


export interface CreatePoolTestRequest {
  poolName: string;
  testTubeIdentifier: string;
  samplingTime: string;
}

export interface CreatePoolBarcodeState {
  loading: boolean;
  error: string;
  success: boolean;
}

export interface CreatePoolBarcodeStateRecord extends Record<string, CreatePoolBarcodeState> { }
/** participant name model used in participant field comp */
export interface ParticpantNameInfo {
  given_name: string;
  family_name: string;
}
/** particpant info state */
export interface ParticipantClaimState {
  claims: Claim[];
  loading: boolean;
  exists: boolean;
  error: string;
}
export interface ParticipantClaimStateRecord extends Record<string, ParticipantClaimState> { }

/** Individual test create state */
export interface ParticipantFormValue {
  synlabId: string;
  testkitBarcode: string
}
export interface CreateIndividualTestRequest {
  schoolName: string;
  schoolid: string;
  testTubeIdentifier: string;
  samplingTime: string;
  poolName: string;
}
export interface CreateIndividualTestState {
  loading: boolean;
  error: string;
  success: boolean;
}
export interface CreateIndividualTestStateRecord extends Record<string, CreateIndividualTestState> {}

/** temp order request */
export interface TempOrderRequest {
  synlabId: string;
  barcode: string;
  testKitBarcode: string;
  // OrganizationSynlabId: string;
}
export interface TempOrderRequestState {
  loading: boolean;
  error: string;
  success: boolean;
}
export interface TempOrderRequestRecord extends Record<string, TempOrderRequestState> {}
