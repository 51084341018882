import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { resultsV2FeatureKey } from '../reducers/resultsV2.reducer';
import { flatMap } from 'lodash';

const State = (state: AppState) => state[resultsV2FeatureKey];

const getResults = createSelector(State, (data) => data.results);

const getSelectedWardSynlabId = createSelector(State, (data) => data.selectedWardSynlabId);

const getIndicators = createSelector(State, (data) => {
  return flatMap(data.results, panel => panel.indicators);
});

const getSupportViewSelectedUser = createSelector(State, (data) => data.selectedUserSynlabId);

const getSupportViewPatientHaspassport = createSelector(State, (data) => data.selectedUserHasPassport);

const showFilters = createSelector(getSupportViewSelectedUser, (selected) => !!selected);

const isLoading = createSelector(State, (data) => data.loading);

const getSrollPositionTop = createSelector(State, (data) => data.scrollTop);

const getFilterPanelDetached = createSelector(State, (data) => data.detachedFilterPanel);

const resultsTransferDialogShown = createSelector(State, data => data.resultTransfer?.showDialog || false);

const resultsTransferInProgress = createSelector(State, data => data.resultTransfer?.inProgress);

export const ResultV2Selectors = {
  getResults,
  getIndicators,
  getSupportViewSelectedUser,
  getSupportViewPatientHaspassport,
  showFilters,
  isLoading,
  getSelectedWardSynlabId,
  getSrollPositionTop,
  getFilterPanelDetached,
  resultsTransferInProgress,
  resultsTransferDialogShown,
}
