import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { UnauthorizePromptService } from 'src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare let $: any;

@Component({
  selector: 'app-popup-unauthorized',
  templateUrl: './popup-unauthorized.component.html',
  styleUrls: ['./popup-unauthorized.component.scss']
})
export class PopupUnauthorizedComponent implements AfterViewInit, OnDestroy {
  onDestroy$ = new Subject();
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.onDestroy$.unsubscribe();
  }

  constructor(private unauthorizedPromptService: UnauthorizePromptService, private auth: AuthService) { }


  ngAfterViewInit(){
    this.unauthorizedPromptService.isPopupShown$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(shouldPopupShow => {
      shouldPopupShow
        ? $('#notAuthModal').modal('show')
        : $('#notAuthModal').modal('hide');
    });
  }
  logout() {
    this.auth.startSignoutMainWindow();
  }

}
