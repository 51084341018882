import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { ApiService } from "src/app/service/api/api.service";
import { RegTestActions } from "../actions/regtest.actions";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";

@Injectable()
export class RegtestEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService
  ) {}

  loadTestPools$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegTestActions.loadTestPools),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([actions, path]) => this.api.getMethod(`${path}/api/pools`)),
        tap((pools) => {
          this.store.dispatch(
            RegTestActions.loadTestPoolsSuccess({
              data: pools,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            RegTestActions.loadTestPoolsFailure({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  testTubeIdLookup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegTestActions.testTubeIdLookup),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ id }, path]) =>
          id
            ? this.api.getMethod(
                `${path}/api/tests/testTubeIdentifiers/${id}/exists`
              )
            : of(null)
        ),
        tap(({ doesExist }) => {
          this.store.dispatch(
            RegTestActions.testTubeIdLookupSuccess({ value: doesExist })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            RegTestActions.testTubeIdLookupFailure({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  startSendTest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RegTestActions.startSendTest),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ params }, path]) =>
          this.api.postMethod(`${path}/api/tests`, params)
        ),
        tap(({ success }) => {
          this.store.dispatch(
            RegTestActions.startSendTestSuccess({ data: success })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            RegTestActions.startSendTestFailure({
              error: error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );
}
