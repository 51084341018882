import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { auditTime, filter, map, startWith } from "rxjs/operators";
import { AuthService } from "src/app/service/auth/auth.service";
import { ConfigService } from "src/app/service/config/config.service";
import { localStorageKeys } from "../../model/storage.model";
import { Claim } from "src/app/models/claims.model";

@Injectable({
  providedIn: "root",
})
export class SuomiWardSelectorService {
  synlabId$ = new BehaviorSubject("");
  public wardInfo$: BehaviorSubject<IClaimsAsObject> = new BehaviorSubject(null);

  public fullName$ = this.wardInfo$.pipe(map(claims => {
    if (!claims) return "";

    return `${claims.given_name} ${claims.family_name}`
  }))

  constructor(
    private readonly config: ConfigService,
    private readonly auth: AuthService,
    private readonly http: HttpClient
  ) {
    this.synlabId$
      .pipe(filter((v) => !!v), auditTime(100))
      .subscribe((synlabId) => synlabId && this.fetchUserInfo(synlabId));

    const selectedWard = localStorage.getItem(
      localStorageKeys.SUOMI_SELECTED_WARD_SYNLAB_ID
    );
    if (selectedWard) {
      this.synlabId$.next(selectedWard);
    }
  }

  public redirectToSuomiSelectWardURL() {
    const stsUrl: string = this.config.baseAuthUrl;
    const resultsUrl: string = this.config.basePlasmatUrl;
    const userToken: string = this.auth._user.access_token;

    const redirectUrl: string = `${stsUrl}/suomi/select-suomi-ward?returnUrl=${resultsUrl}/select-suomi-ward&token=${userToken}`;

    window.location.href = redirectUrl;
  }

  private fetchUserInfo(synlabId: string) {
    const url: string = this.config.baseAuthUrl;

    this.http
      .get<Claim[]>(`${url}/manage/accounts/GetBySynlabid/${synlabId}`)
      .pipe(map((result) => result.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue}), {}) ))
      .subscribe((claimsAsObject: any) => {
        this.wardInfo$.next(claimsAsObject);
      });
  }
}

export interface IClaimsAsObject {
  IsMigratedToProfile: string
  active: string
  country: string
  country_residence: string
  created_by: string
  date_of_birth: string
  ee_message_type: string
  ee_notification_language: string
  ee_notification_when_ready: string
  email: string
  email_turned_off: string
  email_verified: string
  family_name: string
  gender: string
  given_name: string
  is_self_registered: string
  langCode: string
  locale: string
  nationality: string
  passport_number: string
  phone: string
  synlab_id: string
  userId: string
  user_type: string
}
