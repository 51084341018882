import { createSelector } from '@ngrx/store';
import { AppState } from "../reducers";
import * as UserCreateReducer from 'src/app/@store/reducers/user-create.reducer';
import { UserUploadFileType, UserUploadStatus } from 'src/app/models/user-create.model';
import memoize from 'lodash/memoize';
const State = (state: AppState) => state[UserCreateReducer.userCreateFeatureKey];

export const statusPollOn = createSelector(State, s => s.statusPollOn);
export const statusLoading = createSelector(State, s => s.statusLoading);
export const statusError = createSelector(State, s => s.statusGetError);
export const status = createSelector(State, s => s.userStatus);
export const isStudentList = createSelector(status, s => s?.fileType === UserUploadFileType.LolliStudent);
export const isEmployeeList = createSelector(status, s => s?.fileType === UserUploadFileType.LolliEmployee);
export const fileId = createSelector(status, s => s?.fileId);
export const fileName = createSelector(status, s => s?.fileName);

export const showUploadPage = createSelector(State, s =>
  s.userStatus?.status === UserUploadStatus.FreeToUpload
);
export const showValidatingPage = createSelector(State, s =>
  s.userStatus?.status === UserUploadStatus.Validating
);
export const showVerifyingPage = createSelector(State, s =>
  s.userStatus?.status === UserUploadStatus.Verifying
);
export const showProcessingPage = createSelector(State, s =>
  s.userStatus?.status === UserUploadStatus.Processing
);

export const uploadLoading = createSelector(State, s => s.uploading);
export const uploadingError = createSelector(State, s => s.uploadingError);
export const uploadingSuccess = createSelector(State, s => !s.uploading && s.uploadingSuccess && !s.uploadingError);
export const uploadingHasError = createSelector(State, s => s.uploadingError && !s.uploading);

export const studentFields = createSelector(State, s => s.studentFields);
export const employeeFields = createSelector(State, s => s.employeeFields);
export const currentFields = createSelector(status, studentFields, employeeFields, (status, student, employee) => {
  if (status) {
    return status.fileType === UserUploadFileType.LolliStudent
      ? student
      : employee;
  } else {
    return [];
  }
});

export const changePageLoading = createSelector(State, s => s.changePageLoading);
export const tempUserListLoading = createSelector(State, s => s.tempUserListLoading);
export const tempUserListError = createSelector(State, s => s.tempUserListError);
export const tempStudentList = createSelector(State, s => s.tempStudentList);
export const tempEmployeeList = createSelector(State, s => s.tempEmployeeList);
export const tempUserList = createSelector(
  status,
  tempStudentList,
  tempEmployeeList,
  (statusState, student, employee) => {
    if (statusState?.status === UserUploadStatus.Verifying) {
      if (statusState?.fileType === UserUploadFileType.LolliStudent) {
        return student;
      } else {
        return employee;
      }
    }
  }
);

export const uploadHistoryList = createSelector(State, s => s.historyList);
export const loadingUploadHistoryList = createSelector(State, s => s.historyLoading);
export const historyUploadDownloadingRecord = createSelector(State, s => s.historyUploadDownloadingRecord);
export const historyUploadDownloadErrorRecord = createSelector(State, s => s.historyUploadDownloadErrorRecord);
export const historyDownloadingRecord = createSelector(State, s => s.historyDownloadingRecord);
export const historyDownloadErrorRecord = createSelector(State, s => s.historyDownloadErrorRecord);
export const historyUploadDownloading = memoize((id: string) => createSelector(historyUploadDownloadingRecord, s => s[id]));
export const historyDownloading = memoize((id: string) => createSelector(historyDownloadingRecord, s => s[id]));

// pagination, query
export const tempUserParams = createSelector(State, s => s.tempUserRequestParams);
export const tempUserPage = createSelector(State, s => s.tempUserPage);
export const tempUserPageSize = createSelector(State, s => s.tempUserPageSize);
export const tempUserPagination = createSelector(State, s => s.tempUserRequestPagination);
export const tempUserTotalUnknown = createSelector(tempUserPagination, s => !s);
export const tempUserTotal = createSelector(tempUserPagination, s => s?.totalCount);
export const tempUserHasNext = createSelector(tempUserPagination, s => s && s?.hasNext);
export const tempUserHasPrevious = createSelector(tempUserPagination, s => s && s?.hasPrevious);
export const tempUserHasFirst = createSelector(tempUserPagination, s => s && s?.currentPage > 1);
export const tempUserHasLast = createSelector(tempUserPagination, s => s && s?.currentPage < s?.totalPages);

export const cancelLoading = createSelector(State, s => s.cancelLoading);
export const cancelError = createSelector(State, s => s.cancelError);

export const currentListExportLoading = createSelector(State, s => s.currentListExportLoading);
export const currentListExportError = createSelector(State, s => s.currentListExportError);

export const processAllLoading = createSelector(State, s => s.processAllLoading);
export const processAllError = createSelector(State, s => s.processAllError);

export const tempStudentGetRecord = createSelector(State, s => s.tempStudentGetRecord);
export const tempEmployeeGetRecord = createSelector(State, s => s.tempEmployeeGetRecord);
export const tempUserGetLoadingRecord = createSelector(State, s => s.tempUserGetLoadingRecord);
export const tempUserGetErrorRecord = createSelector(State, s => s.tempUserGetErrorRecord);
export const tempUserUpdateLoadingRecord = createSelector(State, s => s.tempUserUpdateLoadingRecord);
export const tempUserUpdateErrorRecord = createSelector(State, s => s.tempUserUpdateErrorRecord);
export const tempUserDeleteLoadingRecord = createSelector(State, s => s.tempUserDeleteLoadingRecord);
export const tempUserDeleteErrorRecord = createSelector(State, s => s.tempUserDeleteErrorRecord);


export const tempStudent = memoize((id: string) => createSelector(tempStudentGetRecord, s => s[id]));
export const tempEmployee = memoize((id: string) => createSelector(tempEmployeeGetRecord, s => s[id]));
/** pass id and get get loading selector for a user with the passed id */
export const tempUserGetLoading = memoize((id: string) => createSelector(tempUserGetLoadingRecord, s => s[id]));
/** pass id and get get error selector for a user with the passed id */
export const tempUserGetError = memoize((id: string) => createSelector(tempUserGetErrorRecord, s => s[id]));
/** pass id and get update loading selector for a user with the passed id */
export const tempUserUpdateLoading = memoize((id: string) => createSelector(tempUserUpdateLoadingRecord, s => s[id]));
/** pass id and get update error selector for a user with the passed id */
export const tempUserUpdateError = memoize((id: string) => createSelector(tempUserUpdateErrorRecord, s => s[id]));
/** pass id and get update loading selector for a user with the passed id */
export const tempUserDeleteLoading = memoize((id: string) => createSelector(tempUserDeleteLoadingRecord, s => s[id]));
/** pass id and get Delete error selector for a user with the passed id */
export const tempUserDeleteError = memoize((id: string) => createSelector(tempUserDeleteErrorRecord, s => s[id]));
