import { createSelector } from '@ngrx/store';
import { AppState } from "../reducers";
import moment from 'moment';

import * as userEditRecucer from '../reducers/user-edit.reducer';
import { rolesToEdit} from 'src/app/models/user-edit.model';

const State = (state: AppState) => state[userEditRecucer.userEditFeatureKey];

export const fields = createSelector(State, state => state.tableFields);
export const activeRowIndex = createSelector(State, state => state.tableActiveRowIndex);
export const userActiveId = createSelector(State, state => state.userActiveId);
export const isLoading = createSelector(State, state => state.tableLoading);

export const paging = createSelector(State, state => state.paging);
export const pageSize = createSelector(paging, state => state.PageSize);
export const pageCurrent = createSelector(paging, state => state.CurrentPage);

export const search = createSelector(State, state => state.search);
export const searchTerm = createSelector(search, state => state.term);
export const searchFields = createSelector(search, state => state.fields);

export const users = createSelector(State, state => state.users.map(user => ({
  ...user,
  dateOfBirth: moment(user.dateOfBirth).format('DD.MM.yyyy'),
  editingNotAllowed: rolesToEdit.indexOf(user.role) == -1
})));

export const noUserFound = createSelector(State, state => state.users.length == 0 && state.searchBarLoading === false && state.tableLoading === false )

export const organization = createSelector(State, state => state?.organization);
export const user = createSelector(State, state => state?.user);
export const isOrgCompany = createSelector(State, state => state.organization?.isCompany);

export const sideFilterShown = createSelector(State, state => state.sideFilterShow);
const getSelectedUserDetails = createSelector(State, state => state.selectedUser);
const isStudent = createSelector(State, state => {
  return state.selectedUser?.student_child_account?.toLowerCase() === 'true'
});
const isEmployee  = createSelector(State, state => {
  return state.selectedUser?.employee_account?.toLowerCase() === 'true'
});
const prevSelectedUserData = createSelector(State, state => {
  return state.prevSelectedUserData
});

export const UserEditSelectors = {
  getSelectedUserDetails,
  isStudent,
  isEmployee,
  prevSelectedUserData,
  isOrgCompany
}
export const searchBarLoading = createSelector(State, state => state.searchBarLoading);
export const exportLoading = createSelector(State, state => state.exportLoading);
