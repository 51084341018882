import { createAction, props } from "@ngrx/store"
import { SingleTest } from "src/app/models/single-test.model"

const key = '[Single Test]';
export const sendSingleTest = createAction(
  `${key} Send single test`,
  props<{ data: SingleTest }>()
);
export const sendSingleTestSuccess = createAction(
  `${key} Send single test success`,
);
export const sendSingleTestFailure = createAction(
  `${key} Send single test failure`,
  props<{ error: string }>()
);
export const reset = createAction(
  `${key} Reset`
);
