import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, NgModule, Output } from '@angular/core';
import { HeaderSwitchProfileModule} from '../header-switch-profile/header-switch-profile.component';
import { SynlabHeaderService } from 'src/app/plasma-ui-common/synlab-access-ui-components/synlab-header/synlab-header.service';
import {  tap } from 'rxjs/operators';


@Component({
  selector: 'syn-synlab-header-switch-profile',
  templateUrl: './synlab-header.component.html',
  styleUrls: ['./synlab-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SynlabHeaderService]
})
export class SynlabHeaderSwitchProfile {

  links$ = this.headerService.links$;
  user$ = this.headerService.user$;
  myAccountLink$ = this.headerService.myAccountLink$;
  brandLogoLink$ = this.headerService.brandLogoLink$;

  @Output()
  onLogout = new EventEmitter();
  @Input()
  disableLinks: boolean = false;

  constructor(private headerService: SynlabHeaderService) { }

  logout() {
    this.onLogout.emit();
  }


}
@NgModule({
  imports: [CommonModule, HeaderSwitchProfileModule],
  declarations: [SynlabHeaderSwitchProfile],
  exports: [SynlabHeaderSwitchProfile]
})
export class SynlabHeaderSwitchProfileModule {}
