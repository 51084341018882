import { createAction, props } from "@ngrx/store";
import { Claim } from "src/app/models/claims.model";
import { LogTextbox } from "src/app/models/log-textbox.model";
import {
  CreatePoolTestRequest,
  PoolBarcodeInformationResponse,
  PoolIndividualOrder,
  TempOrderRequest,
} from "src/app/models/pool-order.model";

const key = "[PoolOrder]";

const checkPoolBarcodeExists = createAction(
  `${key} Check Pool Barcode Exists`,
  props<{ barcode: string }>()
);

const checkPoolBarcodeExistsSuccess = createAction(
  `${key} Check Pool Barcode Exists Success`,
  props<{ barcode: string; exists: boolean }>()
);

const checkPoolBarcodeExistsFailure = createAction(
  `${key} Check Pool Barcode Exists Failure`,
  props<{ barcode: string; error: string | any }>()
);

const loadPoolBarcodeInfo = createAction(
  `${key} Load Pool Barcode Info`,
  props<{ barcode: string }>()
);
const loadPoolBarcodeInfoSuccess = createAction(
  `${key} Load Pool Barcode Info Success`,
  props<{ barcode: string; barcodeInfo: PoolBarcodeInformationResponse }>()
);
const loadPoolBarcodeInfoFailure = createAction(
  `${key} Load Pool Barcode Info Failure`,
  props<{ barcode: string; error: string | any }>()
);

const loadSchoolClaims = createAction(
  `${key} Load School Claims`,
  props<{ schoolSynlabId: string }>()
);
const loadSchoolClaimsSuccess = createAction(
  `${key} Load School Claims Success`,
  props<{ claims: Claim[] }>()
);
const loadSchoolClaimsFailure = createAction(
  `${key} Load School Claims Failure`,
  props<{ error: string | any }>()
);

const loadPoolClaims = createAction(
  `${key} Load Pool Claims`,
  props<{ poolSynlabId: string }>()
);
const loadPoolClaimsSuccess = createAction(
  `${key} Load Pool Claims Success`,
  props<{ claims: Claim[]; poolSynlabId: string }>()
);
const loadPoolClaimsFailure = createAction(
  `${key} Load Pool Claims Failure`,
  props<{ error: string | any; poolSynlabId: string }>()
);

const loadPoolIndividualOrders = createAction(
  `${key} Load Pool Individual Orders`,
  props<{ poolBarcode: string }>()
);
const loadPoolIndividualOrdersSuccess = createAction(
  `${key} Load Pool Individual Orders Success`,
  props<{ poolBarcode: string; poolIndividualOrders: PoolIndividualOrder[] }>()
);
const loadPoolIndividualOrdersFailure = createAction(
  `${key} Load Pool Individual Orders Failure`,
  props<{ poolBarcode; error: string | any }>()
);

const loadHasNegativeResults = createAction(
  `${key} Load Has Negative Results`,
  props<{ barcode: string }>()
);
const loadHasNegativeResultsSuccess = createAction(
  `${key} Load Has Negative Results Success`,
  props<{ barcode: string; hasNegative: boolean }>()
);
const loadHasNegativeResultsFailure = createAction(
  `${key} Load Has Negative Results Failure`,
  props<{ barcode: string; error: string | any }>()
);

const createPoolBarcode = createAction(
  `${key} Create Pool Barcode`,
  props<{ request: CreatePoolTestRequest; barcode: string }>()
);
const createPoolBarcodeSuccess = createAction(
  `${key} Create Pool Barcode Success`,
  props<{ barcode: string }>()
);
const createPoolBarcodeFailure = createAction(
  `${key} Create Pool Barcode Failure`,
  props<{ error: string | any; barcode: string }>()
);

const viewPoolBarcode = createAction(
  `${key} View Pool Barcode`,
  props<{ barcode: string }>()
);

const setActiveParticipantSynlabId = createAction(
  `${key} Set Active Participant SYNLAB Id`,
  props<{ synlabId: string }>()
);

const loadParticipantClaims = createAction(
  `${key} Load Participant Claims`,
  props<{ synlabId: string }>()
);
const loadParticipantClaimsSuccess = createAction(
  `${key} Load Participant Claims Success`,
  props<{ synlabId: string; claims: Claim[] }>()
);
const loadParticipantClaimsFailure = createAction(
  `${key} Load Participant Claims Failuire`,
  props<{ synlabId: string; error: string | any }>()
);

const setActiveParticipantBarcode = createAction(
  `${key} Set Active Participant Barcode`,
  props<{ barcode: string }>()
);

const tempOrderRequest = createAction(
  `${key} Temp Order Request`,
  props<{
    poolBarcode: string;
    participantBarcode: string;
    request: TempOrderRequest;
  }>()
);
const tempOrderRequestSuccess = createAction(
  `${key} Temp Order Request Success`,
  props<{
    participantBarcode: string;
  }>()
);
const tempOrderRequestFailure = createAction(
  `${key} Temp Order Request Failure`,
  props<{
    participantBarcode: string;
    error: string | any;
  }>()
);

const log = createAction(`${key} Log`, props<{ action: LogTextbox }>());
const reset = createAction(`${key} Reset`);

export const PoolOrderActions = {
  checkPoolBarcodeExists,
  checkPoolBarcodeExistsSuccess,
  checkPoolBarcodeExistsFailure,
  loadPoolBarcodeInfo,
  loadPoolBarcodeInfoSuccess,
  loadPoolBarcodeInfoFailure,
  loadSchoolClaims,
  loadPoolClaims,
  loadPoolClaimsSuccess,
  loadPoolClaimsFailure,
  loadSchoolClaimsSuccess,
  loadSchoolClaimsFailure,
  loadPoolIndividualOrders,
  loadPoolIndividualOrdersSuccess,
  loadPoolIndividualOrdersFailure,
  loadHasNegativeResults,
  loadHasNegativeResultsSuccess,
  loadHasNegativeResultsFailure,
  createPoolBarcode,
  createPoolBarcodeSuccess,
  createPoolBarcodeFailure,
  viewPoolBarcode,
  setActiveParticipantSynlabId,
  loadParticipantClaims,
  loadParticipantClaimsSuccess,
  loadParticipantClaimsFailure,
  setActiveParticipantBarcode,
  tempOrderRequest,
  tempOrderRequestSuccess,
  tempOrderRequestFailure,
  log,
  reset,
};
