import { Routes } from "@angular/router";
import { PATIENT_WEB_LOCALE_STORAGE_KEY } from "src/app/plasma-ui-common/synlab-access-ui-components/core/constants";
import { formatLocale } from "src/app/plasma-ui-common/utils/format-locale";
import { AuthGuard } from "src/app/service/authGuard/auth.guard";
import { LoggerService } from "src/app/service/logger.service";
import { localStorageKeys } from "src/app/shared/model/storage.model";

const logr = new LoggerService(null);

/**
 * Get the "langCode" value from URL query string
 * @returns String. Returns "langCode" value from query string. If not exists, it will return null.
 */
export const getLangCodeFromQueryString = (): string => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let locale: string;
  urlSearchParams.forEach((v, k) => {
    if (k.toLowerCase() == "langcode") {
      locale = v.toLowerCase();
    }
  });
  return locale ?? null;
};


/**
 * Get the saved language code from local storage.
 * @returns String. Returns the saved language code in the local storage.
 */
export const getLangCodeFromLocalStorage = (): string => {
  return formatLocale(localStorage.getItem(PATIENT_WEB_LOCALE_STORAGE_KEY), null, true);
};

/**
 * Reload the current page
 */
export const pageReload = (): void => {
  const currentPage = location.href;
  location.href = currentPage;
}


export const showCookieBot = (value: boolean, delay?: number): void => {
  const element = document.getElementById("CybotCookiebotDialog");
  if (element) {
    if (value) {
      setTimeout(() => element.classList.add("show"), delay || 1000);
    } else {
      element.classList.remove("show");
    }
  }
}


/**
 * Returns the host from a url
 * @param url
 * @returns
 */
 export const trimUrl = (url: string = ""):string => {
  const link = document.createElement('a');
        link.href = url;
  return link.host;
}

/**
 * @returns String. returns the redirect url
 */
 export const getRedirectUrl = (): string => {
  return location.pathname + location.search;
};

/**
 * Boolean. Returns "true" if path is a private route, otherwise, returns "false"
 * @param path String. The URL that needs to be checked.
 * @param routes Array. Routes from Router.config provided by Angular
 * @returns Boolean
 */
 export const isPrivateRoute = (path: string, routes: Routes): boolean => {
  // get the first path from the redirect url
  const link = document.createElement("a");
  link.href = path;
  const tempPath: string = link.pathname?.charAt(0) === "/" ? link.pathname.substring(1) : link.pathname;
  const firstPath: string = tempPath?.split("/")?.filter((link) => link)[0] ?? undefined;

  logr.log({ redirectURL: path });
  logr.log({ tempPath, firstPath });
  // if URL has no specific firstPath, consider the URL as private route
  if (firstPath === undefined) {
    return true;
  }

  // check if firstPath is private or public based from Router.config settings
  const route = routes.find(
    (route) => route.path?.toLowerCase() == firstPath?.toLowerCase()
  );
  const canActivates: any[] = route?.canActivate;
  return canActivates?.some((canActivate: any) => canActivate?.toString() == AuthGuard.toString());
};


export const getFileName = (rawFilename: string) =>  {

  if (!rawFilename) return null;

  const regexForZip = /(filename=)(.*)(.zip)/;
  const regexForPdf = /(filename=)(.*)(.pdf)/;

  let fileNamesInArray: RegExpMatchArray;
  const regex = rawFilename.includes(".zip") ? regexForZip : regexForPdf;
  fileNamesInArray = rawFilename.match(regex);

  if (fileNamesInArray?.length > 3) {
    const specialCharsRegex = /[&\/\\#,+()$~%'":*?<>{}]/g;
    const filename =  fileNamesInArray.slice(2, 4).join("").replace(specialCharsRegex, "");

    if (filename.includes(';')) { return filename.split(';')[0]; }
    return filename;
  }
  return null;
}

