import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { RegTestActions } from "src/app/@store/actions/regtest.actions";
import { RegTestSelectors } from "src/app/@store/selectors/regtest.selectors";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { auditTime, take } from "rxjs/operators";
import startCase from "lodash/startCase";
import { SendTest } from "src/app/@store/models/regtest.model";
import { AuthService } from "src/app/service/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Actions, ofType } from "@ngrx/effects";
import { ReplaySubject } from "rxjs";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-register-and-test",
  templateUrl: "./register-and-test.component.html",
  styleUrls: ["./register-and-test.component.scss"],
})
export class RegisterAndTestComponent implements OnInit {
  registerAndTestForm = new UntypedFormGroup({
    teacherName: new UntypedFormControl(null),
    testPool: new UntypedFormControl(null, { validators: [Validators.required] }),
    testNumber: new UntypedFormControl(null, {
      validators: [Validators.pattern(/^97\d{8}(01|00)$/), Validators.required], //971111111101
    }),
  });

  pools$ = this.store.pipe(select(RegTestSelectors.getTestPools));
  teacherName$ = this.store.pipe(select(StsSelectors.getName));

  getLookupValue$ = this.store.pipe(select(RegTestSelectors.getLookupValue));
  getLookupInProgress$ = this.store.pipe(
    select(RegTestSelectors.getLookupInProgress)
  );

  sendTestInProgress$ = this.store.pipe(
    select(RegTestSelectors.getSendTestInProgress)
  );

  sendTestSucceeded$: ReplaySubject<any> = new ReplaySubject(1);
  selectedPoolName$: ReplaySubject<string> = new ReplaySubject(1);

  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    private store: Store<AppState>,
    private auth: AuthService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private _snackBar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    this.auth
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.router.navigate(["/results/list"]); }
      });
  }

  ngOnInit(): void {
    // load test pools
    this.store.dispatch(RegTestActions.loadTestPools());

    // patch value of teacher name
    this.teacherName$.pipe(take(1)).subscribe((name) => {
      this.registerAndTestForm.get("teacherName").patchValue(startCase(name));
      this.registerAndTestForm.get("teacherName").disable();
    });

    // lookup barcode if valid
    this.registerAndTestForm
      .get("testNumber")
      .valueChanges.pipe(auditTime(100))
      .subscribe((n) => {
        const e = this.registerAndTestForm.get("testNumber").errors?.pattern;
        if (!e && n) {
          this.store.dispatch(RegTestActions.testTubeIdLookup({ id: n }));
        } else {
          // reset testLookup to null to disable submit button
          this.store.dispatch(
            RegTestActions.testTubeIdLookupSuccess({ value: null })
          );
        }
      });

    // listen to submitted test
    this.actions$
      .pipe(ofType(RegTestActions.startSendTestSuccess))
      .subscribe((n) => {
        this.sendTestSucceeded$.next(new Date());
        this.openSnackBar();
      });
  }

  get isSubmitDisabled(): boolean {
    return (
      this.registerAndTestForm.get("testNumber").errors?.pattern ||
      this.registerAndTestForm.pristine
    );
  }

  sendTest(evt: Event) {
    evt.preventDefault();
    const { testPool, testNumber } = this.registerAndTestForm.value;

    const params: SendTest = {
      synlabId: testPool,
      samplingDateTime: "",
      testTubeIdentifier: testNumber,
    };

    this.store.dispatch(RegTestActions.startSendTest({ params }));
  }

  reset() {
    this.registerAndTestForm.markAsPristine();
    this.registerAndTestForm.reset();

    // patch value of teacher name
    this.teacherName$.pipe(take(1)).subscribe((name) => {
      this.registerAndTestForm.get("teacherName").patchValue(startCase(name));
    });

    this.sendTestSucceeded$.next(null);
    this.selectedPoolName$.next(null);

    // clear lookup validation
    this.store.dispatch(
      RegTestActions.testTubeIdLookupSuccess({ value: null })
    );
  }

  poolNameOnChanges(evt) {
    const selectedIndex = evt.selectedIndex;
    const name = evt.options[selectedIndex].text;

    this.selectedPoolName$.next(name);
  }

  openSnackBar() {
    this._snackBar.open(
      this.transloco.translate('registerAndTest.testPerformedMessage'),
      null,
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 2000, //971111111101
      }
    );
  }
}
