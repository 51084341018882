<footer data-test-id="footer-footer"  class="white-labeled-footer new-footer">
  <div data-test-id="footer-outer-wrapper"  class="outer-wrapper">
    <p data-test-id="footer-outer-p" class="ltext">Covidresults.dk is made in collaboration between</p>
    <div data-test-id="footer-logo"  class="logo-partners">
      <img data-test-id="footer-logo1"  src="/assets/images/new/partner-logo-1.png" alt="logo1" class="logo1">
      <img data-test-id="footer-logo2"  src="/assets/images/new/partner-logo-2.png" alt="logo2" class="logo2">
      <img data-test-id="footer-logo3"  src="/assets/images/new/partner-logo-3.png" alt="logo3" class="logo3">
    </div>
    <div data-test-id="footer-copyright"  class="copyright d-flex w-100">
      <div data-test-id="footer-links"  class="links">
        <a data-test-id="footer-covidresults" href="https://faq.covidresults.dk" target="_blank">Instructions</a>
        <a data-test-id="footer-digitaloceanspaces" href="https://faq-pdf.ams3.cdn.digitaloceanspaces.com/pdfs/information-about-processing-of-your-personal-data.pdf" target="_blank">Information about the processing of your personal data</a>
        <a data-test-id="footer-covidresults-dk" href="https://www.was.digst.dk/covidresults-dk" target="_self">Accessibility statement</a>
        <span data-test-id="footer-copyright" >Copyright 2021 - SYNLAB Medical Digital Services - 7100 Vejle |
          All rights reserved</span>
      </div>
      <div data-test-id="footer-logo-right"  class="ml-auto logo-right">
        <img data-test-id="footer-img"  src="/assets/images/new/small-synlab-logo.svg" height="60" alt="">
      </div>
    </div>
  </div>
</footer>
