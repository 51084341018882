import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-transfer-confirm-dialog',
  templateUrl: './transfer-confirm-dialog.component.html',
  styleUrls: ['./transfer-confirm-dialog.component.scss']
})

export class TransferConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<TransferConfirmDialogComponent>
  ) { }

  confirm(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }
}
