import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { EmployeeData, GetTempEmployeeListData, GetTempStudentListData, GetTempUserFileRequest, GetTempUserListPagination, GetTempUserListRequest, StudentData, UploadCsvRequest, UploadHistoryItem, UserStatusResponse } from 'src/app/models/user-create.model';
import isNil from 'lodash/isNil';
import { toProperIsoDate } from 'src/app/shared/utils/date.util';
@Injectable({
  providedIn: 'root'
})
export class UserCreateService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig), take(1));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl), take(1));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  getUserCreateStatus() {
    return this.createUrl$('/api/lolliusermanagement/status').pipe(
      mergeMap(url => this.http.get<UserStatusResponse>(url))
    )
  }

  uploadCsv(request: UploadCsvRequest) {
    const form = new FormData();
    let fileType = request.uploadFileType?.toString()
    form.append("file", request.file);
    return this.createUrl$(`/api/lolliusermanagement/upload/${fileType}`).pipe(
    mergeMap(url => this.http.post(url, form))
    );
  }

  getTempStudentList = (request: GetTempUserListRequest) => {
    const { fileId, ...rest } = request;
    let query: Record<string, string> = Object.keys(rest).filter(k => !isNil(rest[k])).reduce((acc, curr) => ({ ...acc, [curr]: encodeURIComponent(rest[curr]) }), { });
    return this.createUrl$(`api/lolliusermanagement/student/${fileId}/search`).pipe(
      mergeMap(url => this.http.get<StudentData[]>(url, {
        params: query,
        observe: 'response'
      })),
      map((res): GetTempStudentListData => {
        const paginationTxt = res.headers.get('X-Pagination');
        const pagination: {} = JSON.parse(paginationTxt);
        const camelPagination: GetTempUserListPagination = Object.keys(pagination).reduce((acc, curr) => {
          const camelKey = curr?.[0]?.toUpperCase?.() === curr?.[0]
            ? curr?.[0]?.toLowerCase?.() + curr.slice(1)
            : curr;
          return {
            ...acc,
          [camelKey]: pagination[curr]
          }
        }, {} as GetTempUserListPagination)
        return {
          data: res.body,
          pagination: camelPagination
        };
      })
    )
  }

  getTempEmployeeList = (request: GetTempUserListRequest) => {
    const { fileId, ...rest } = request;
    let query: Record<string, string> = Object.keys(rest).filter(k => !isNil(rest[k])).reduce((acc, curr) => ({ ...acc, [curr]: encodeURIComponent(rest[curr]) }), { });
    return this.createUrl$(`api/lolliusermanagement/employee/${request.fileId}/search`).pipe(
      mergeMap(url => this.http.get<EmployeeData[]>(url, {
        params: query,
        observe: 'response'
      })),
      map((res): GetTempEmployeeListData => {
        const paginationTxt = res.headers.get('X-Pagination');
        const pagination: {} = JSON.parse(paginationTxt);
        const camelPagination: GetTempUserListPagination = Object.keys(pagination).reduce((acc, curr) => {
          const camelKey = curr?.[0]?.toUpperCase?.() === curr?.[0]
            ? curr?.[0]?.toLowerCase?.() + curr.slice(1)
            : curr;
          return {
            ...acc,
          [camelKey]: pagination[curr]
          }
        }, {} as GetTempUserListPagination)
        return {
          data: res.body,
          pagination: camelPagination
        };
      })
    )
  }

  cancelUserCreation(fileId) {
    return this.createUrl$(`api/lolliusermanagement/cancel/${fileId}`).pipe(
      mergeMap(url => this.http.post(url, undefined))
    );
  }

  getTempStudentFile(request: GetTempUserFileRequest) {
    const { fileId, ...rest } = request;
    const query: Record<string, string> = Object.keys(rest).reduce((acc, curr) => ({ ...acc, [curr]: rest[curr] }), { });
    return this.createUrl$(`api/lolliusermanagement/student/${fileId}/export`).pipe(
      mergeMap(url => this.http.get(url, {
        params: query,
        observe: 'response',
        responseType: 'blob'
      })),
      this.getFileAndFileName
    )
  }

  getTempEmployeeFile(request: GetTempUserFileRequest) {
    const { fileId, ...rest } = request;
    const query: Record<string, string> = Object.keys(rest).reduce((acc, curr) => ({ ...acc, [curr]: rest[curr] }), { });
    return this.createUrl$(`api/lolliusermanagement/employee/${fileId}/export`).pipe(
      mergeMap(url => this.http.get(url, {
        params: query,
        observe: 'response',
        responseType: 'blob'
      })),
      this.getFileAndFileName
    )
  }

  getTempStudent(id: string) {
    return this.createUrl$(`api/lolliusermanagement/student/${id}`).pipe(
      mergeMap(url => this.http.get<StudentData>(url))
    );
  }
  getTempEmployee(id: string) {
    return this.createUrl$(`api/lolliusermanagement/employee/${id}`).pipe(
      mergeMap(url => this.http.get<EmployeeData>(url))
    );
  }
  updateTempStudent(id: string, data: StudentData) {
    return this.createUrl$(`api/lolliusermanagement/student/${id}`).pipe(
      mergeMap(url => this.http.put<StudentData>(url, data))
    );
  }
  updateTempEmployee(id: string, data: EmployeeData) {
    return this.createUrl$(`api/lolliusermanagement/employee/${id}`).pipe(
      mergeMap(url => this.http.put<EmployeeData>(url, data))
    );
  }
  deleteTempStudent(id: string) {
    return this.createUrl$(`api/lolliusermanagement/student/${id}`).pipe(
      mergeMap(url => this.http.delete(url))
    );
  }
  deleteTempEmployee(id: string) {
    return this.createUrl$(`api/lolliusermanagement/employee/${id}`).pipe(
      mergeMap(url => this.http.delete(url))
    );
  }

  processUserCreation(fileId: number) {
    return this.createUrl$(`api/lolliusermanagement/process/${fileId}`).pipe(
      mergeMap(url => this.http.post<boolean>(url, undefined))
    );
  }

  /** get list of previous uploads of current user */
  getHistoryList() {
    return this.createUrl$(`api/lolliusermanagement/upload/history`).pipe(
      mergeMap(url => this.http.get<UploadHistoryItem[]>(url)),
      map(res => res.map(x => ({ ...x, dateUploaded: toProperIsoDate(x?.dateUploaded) })))
    );
  }

  /** get file used for upload */
  getUploadFile(fileId: number, fileName: string) {
    return this.createUrl$(`api/lolliusermanagement/upload/download/${fileId}`).pipe(
      mergeMap(url => this.http.get(url, {
        params: { filename: fileName },
        observe: 'response',
        responseType: 'blob'
      })),
      this.getFileAndFileName
    )
  }
  getEmployeeResultFile = (fileId: number, fileName: string) => {
    return this.createUrl$(`api/lolliusermanagement/employee/upload/download/result/${fileId}`).pipe(
      mergeMap(url => this.http.get(url, {
        params: { filename: fileName },
        observe: 'response',
        responseType: 'blob'
      })),
      this.getFileAndFileName
    );
  }
  getStudentResultFile = (fileId: number, fileName: string) => {
    return this.createUrl$(`api/lolliusermanagement/student/upload/download/result/${fileId}`).pipe(
      mergeMap(url => this.http.get(url, {
        params: { filename: fileName },
        observe: 'response',
        responseType: 'blob'
      })),
      this.getFileAndFileName
    );
  }

  private createUrl$(endpoint: string) {
    const addSlash = endpoint[0] !== '/' ? '/' : '';
    return this.api$.pipe(
      map(api => api + addSlash + endpoint)
    );
  }

  private getFileAndFileName = pipe(
    map((response: HttpResponse<Blob>) => {
      let fileName: string = response.headers
        .get("content-disposition")
        .split("; ")[1];
      fileName = fileName.replace('filename=', "").replace(/"/ig, "");
      const file = response.body;
      return {
        fileName,
        file,
      };
    })
  )
}
