import { createAction, props } from "@ngrx/store";
import { ResultV2 } from "../../pages/results-v2/model/resultv2";

const loadResultssV2 = createAction(
  "[Results] Load Resultss V2"
);

const loadResultsForWard = createAction(
  "[Results] Load Results For Ward By SynlabId",
  props<{ synlabId: string }>()
);

const loadResultssSuccessfulV2 = createAction(
  "[Results] Load Results Successful V2",
  props<{results: ResultV2[]}>(),
);
const loadResultssFailureV2 = createAction(
  "[Results] Load Results Failure V2"
);
const loadResultsV2Admin = createAction(
  "[Results] Load Results V2 Admin",
  props<{ synlabId: string }>()
);

const loadResultsV2AdminClear = createAction(
  "[Results] Clear Results V2 Admin Clear"
);

const setSupportViewSelectedSynlabId = createAction(
  "[Results] Support View Set Selected SynlabId",
  props<{ synlabId: string }>()
);

const wardChanged = createAction(
  "[Results] Selected Ward Is Changed",
  props<{ ward: { synlabId: string; firstName: string; lastName: string } }>()
);

const fetchSupportViewSelectedSynlabIdClaims = createAction(
  "[Results] Support View Featch Selected SynlabId Claims",
  props<{ synlabId: string, results: ResultV2[] }>()
)

const setSupportViewSelectedPatientHasPassport = createAction(
  "[Results] Support View Mark current Patient Has Passport",
  props<{ hasPassport: boolean }>()
)

const setScrollTop = createAction(
  "[Results] Set scroll top position",
  props<{ top: number }>()
)

const setFilterPanelDetached = createAction(
  "set filter detaced",
  props<{ detached: boolean }>()
)

const resultsTransferStart = createAction(
  "[Results Transfer] Start Transfer",
  props<{origin: string, destination: string, reason: string }>()
)

const resultsTransferShowDialog = createAction(
  "[Results Transfer] Show Dialog",
  props<{ show: boolean }>()
)

const resultsTransferSuccessful = createAction(
  "[Results Transfer] Transfer Success"
)

const resultsTransferFailure = createAction(
  "[Results Transfer] Transfer Faliure"
)

const resultsTransferComplete = createAction(
  "[Results Transfer] Transfer Complete"
)

const resultsTransferCancel = createAction(
  "[Results Transfer] Transfer Cancel"
)

export const ResultActionsV2 = {
  loadResultssV2,
  loadResultssSuccessfulV2,
  loadResultssFailureV2,
  loadResultsV2Admin,
  loadResultsV2AdminClear,
  setSupportViewSelectedSynlabId,
  fetchSupportViewSelectedSynlabIdClaims,
  setSupportViewSelectedPatientHasPassport,
  loadResultsForWard,
  wardChanged,
  setScrollTop,
  setFilterPanelDetached,
  resultsTransferStart,
  resultsTransferShowDialog,
  resultsTransferSuccessful,
  resultsTransferFailure,
  resultsTransferComplete,
  resultsTransferCancel,
};
