import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../filter/results-v2-people-filter/results-v2-people-filter.component'

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.scss']
})
export class UserInfoCardComponent  {

  constructor(
    public dialogRef: MatDialogRef<UserInfoCardComponent>,
    @Inject(MAT_DIALOG_DATA) public user: User
  ) { }

  close() {
    this.dialogRef.close();
  }
}
