import { createReducer, on } from '@ngrx/store';
import { Individual, PoolDetails } from 'src/app/pages/generate-order/g-order/data-model';
import { AbTestingActions } from '../actions/ab-testing.actions';


export const abTestingFeatureKey = 'abTesting';

export interface State {
	individual: Individual[];
	individualInfoProgress: boolean;
	poolInvalid: boolean;
	sendOrderInProgress: boolean;
  sendOrderDisabled: boolean;
	setNextPersonButtonAsDisabled: boolean;
	poolIsNegative: boolean;
  allOrdersSent: boolean;
  poolStatus: any;
  poolDetails: PoolDetails
}

export const initialState: State = {
  individual: null,
  individualInfoProgress: false,
	poolInvalid: false,
	sendOrderInProgress: false,
  sendOrderDisabled: true,
	setNextPersonButtonAsDisabled: true,
	poolIsNegative: false,
  allOrdersSent: true,
  poolStatus: null,
  poolDetails: null
};


export const reducer = createReducer(
  initialState,
  on(AbTestingActions.clearPool, (state) => ({
    ...state,
    individual: null,
    individualInfoProgress: false,
    sendOrderInProgress: false,
    poolInvalid: false,
    sendOrderDisabled: true,
    allOrdersSent: true,
    poolStatus: null,
    poolDetails: null
  })),
  on(AbTestingActions.checkBarcodeSuccess, (state, {data}) => ({
    ...state,
    poolStatus: data,
    individualInfoProgress: data ? true : false,
  })),
  on(AbTestingActions.getDetails, (state) => ({
    ...state,
    individual: null,
    individualInfoProgress: true,
    sendOrderDisabled: true,
    allOrdersSent: true,
  })),
  on(AbTestingActions.getDetailsSuccess, (state, {data, allOrderSent}) => ({
    ...state,
    individual: data,
    individualInfoProgress: false,
    sendOrderDisabled: false,
    allOrdersSent: allOrderSent
  })),
  on(AbTestingActions.getDetailsError, (state) => ({
    ...state,
    individualInfoProgress: false,
    sendOrderDisabled: true,
  })),

  on(AbTestingActions.hasNegativeResults, (state) => ({
    ...state,
    individual: null,
    poolIsNegative: null,
    //poolInfoInProgress: true,
    individualInfoProgress: true,
    poolInfoStatus: null,
    sendOrderDisabled: true
  })),

  // check pool if hasNegative value
  on(AbTestingActions.hasNegativeResultsSuccess, (state, { value }) => ({
    ...state,
    poolIsNegative: value,
    //poolInfoInProgress: false,
    poolInfoStatus: value ? "hasNegative" : null,
    individualInfoProgress: !value ,
    sendOrderDisabled: !value
  })),

  on(AbTestingActions.hasNegativeResultsFailed, (state) => ({
    ...state,
    poolIsNegative: null,
    //poolInfoInProgress: false,
    individualInfoProgress: false,
  })),
  on(AbTestingActions.sendOrder, (state) => ({
    ...state,
    individualInfoProgress: true,
    sendOrderDisabled: true,
    allOrdersSent: true,
  })),
  on(AbTestingActions.sendOrderSuccess, (state, {data, allOrderSent}) => ({
    ...state,
    individual: data,
    individualInfoProgress: false,
    sendOrderDisabled: false,
    allOrdersSent: allOrderSent
  })),
  on(AbTestingActions.sendOrderFailed, (state) => ({
    ...state,
    individualInfoProgress: false,
    sendOrderDisabled: false,
  })),
  on(AbTestingActions.getPoolDetails, (state) => ({
    ...state,
    individualLoading: true
  })),
  on(AbTestingActions.getPoolDetailsSuccess, (state, {data}) => ({
    ...state,
    individualLoading: false,
    poolDetails: data
  })),
  on(AbTestingActions.getPoolDetailsFailed, (state) => ({
    ...state,
    individualLoading: false
  }))

);

