import { createReducer, on } from '@ngrx/store';
import { Club } from 'src/app/models/club.model';
import { hungaryFootballActions } from '../actions/hungary-football.actions';


export const hungaryFootballFeatureKey = 'hungaryFootball';

export interface State {
  clubList: Club[]
  clubListLoading: boolean;
}

export const initialState: State = {
  clubList: [],
  clubListLoading: false
};


export const reducer = createReducer<State>(
  initialState,
  on(
    hungaryFootballActions.loadClubList,
    (s) => ({ ...s, clubListLoading: true })),
  on(
    hungaryFootballActions.loadClubListSuccess,
    hungaryFootballActions.loadClubListFailure,
    s => ({ ...s, clubListLoading: false })
  ),
  on(
    hungaryFootballActions.loadClubListSuccess,
    (s, { data }) => ({ ...s, clubList: data })
  )
);

