import { createReducer, on } from '@ngrx/store';
import { Individual, PoolDetails } from 'src/app/pages/generate-order/g-order/data-model';
import { PoolIndividualInfoActions } from '../actions/pool-individual-info.actions';

export const poolIndividualInfoFeatureKey = 'poolIndividualInfo';

export interface State {
  poolInformation: PoolDetails;
  poolParticipants: Individual[];
  loadingPoolInformation: boolean;
  loadingParticipants: boolean;
  isIndividual: boolean;
  hasSearched: boolean;
}

export const initialState: State = {
  poolInformation: null,
  poolParticipants: null,
  loadingPoolInformation: true,
  loadingParticipants: true,
  isIndividual: false,
  hasSearched: false
}


export const reducer = createReducer(
  initialState,
  on(PoolIndividualInfoActions.clearPoolAndParticipantData, (state)=>({
    ...state,
    poolInformation: null,
    poolParticipants: null,
    loadingPoolInformation: true,
    loadingParticipants: true,
    isIndividual: true,
    hasSearched: false
  })),
  on(PoolIndividualInfoActions.getPoolDetails,(state)=>({
    ...state,
    loadingPoolInformation: true,
    hasSearched: true
  })),
  on(PoolIndividualInfoActions.getPoolDetailsSuccess,(state, {data})=>({
    ...state,
    loadingPoolInformation: false,
    poolInformation: data,
    isIndividual: data.poolBarcode == null ? false : true
  })),
  on(PoolIndividualInfoActions.getPoolDetailsFailed,(state)=>({
    ...state,
    poolInformation: null,
    hasSearched: false,
    loadingPoolInformation: false
  })),
  on(PoolIndividualInfoActions.getPoolParticipants,(state)=>({
    ...state,
    loadingParticipants: true
  })),
  on(PoolIndividualInfoActions.getPoolParticipantsSuccess,(state, {data})=>({
    ...state,
    loadingParticipants: false,
    poolParticipants: data
  })),
  on(PoolIndividualInfoActions.getPoolParticipantsFailed,(state)=>({
    ...state,
    loadingParticipants: false
  }))
);

