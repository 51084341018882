import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UnauthorizePromptService {
  /** store for popup state */
  private isPopupShown = new BehaviorSubject<boolean>(false);
  /** public isPopup state. */
  public readonly isPopupShown$ = this.isPopupShown.asObservable();

  constructor(private actRoute: ActivatedRoute) {}

  /** shows popup. pass `false` to hide. */
  showPopup(isShown: boolean = true) {
    this.isPopupShown.next(isShown);
  }
  get routeData() {
    return this.actRoute.snapshot.data;
  }
}
