import { createReducer, on } from "@ngrx/store";
import { LabOrderActions } from "../actions/lab-order.actions";

export const labOrderFeatureKey = "labOrder";

export interface State {
  schoolLoading: boolean;
  orderExist: boolean;
  schoolName: string;
  accountHolder: string;
  barcodeLoading: boolean;
  orderLoading: boolean;
}

export const initialState: State = {
  schoolLoading: false,
  orderExist: true,
  schoolName: "",
  accountHolder: "",
  barcodeLoading: false,
  orderLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(LabOrderActions.checkExistingOrder, (state) => ({
    ...state,
    barcodeLoading: true,
    orderExist: true,
  })),
  on(LabOrderActions.checkExistingOrderSucess, (state, { orderExist }) => ({
    ...state,
    orderExist: orderExist,
    barcodeLoading: false,
  })),
  on(LabOrderActions.checkExistingError, (state, { error }) => ({
    ...state,
    error: error,
    barcodeLoading: false,
  })),
  on(LabOrderActions.createNewOrder, (state) => ({
    ...state,
    orderLoading: true,
  })),
  on(LabOrderActions.createNewOrderSuccess, (state) => ({
    ...state,
    orderLoading: false,
  })),
  on(LabOrderActions.createNewOrderError, (state, { error }) => ({
    ...state,
    error: error,
    orderLoading: false,
  })),
  on(LabOrderActions.inputChanges, (state) => ({
    ...state,
    orderExist: true,
    barcodeLoading: false,
  })),
  on(LabOrderActions.getSchool, (state, { synlabId }) => ({
    ...state,
    schoolLoading: true,
    schoolName: null,
    accountHolder: "",
  })),
  on(LabOrderActions.resetSchool, (state) => ({
    ...state,
    schoolLoading: false,
    schoolName: null,
    accountHolder: "",
  })),
  on(LabOrderActions.getSchoolSuccess, (state, { data }) => ({
    ...state,
    schoolLoading: false,
    schoolName: data ? data.schoolName : null,
    accountHolder: data ? data.accountHolder : null,
  }))
);
