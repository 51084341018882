import { createSelector } from '@ngrx/store';
import { ParticpantNameInfo } from 'src/app/models/pool-order.model';
import { AppState } from '../reducers';
import * as PoolOrderReducer from '../reducers/pool-order.reducer'

const poolIndividualLimit = 25;

const State = (state: AppState) => state[PoolOrderReducer.poolOrderFeatureKey];

/** school claims */
const schoolClaims = createSelector(State, s => s.schoolClaims);
const schoolClaimsError = createSelector(State, s => s.schoolClaimsError);
const schoolClaimsLoading = createSelector(State, s => s.schoolClaimsLoading);
const schoolName = createSelector(schoolClaims, s => s?.find?.(x => x.claimType === 'given_name')?.claimValue)

/** pool barcode */
const activePoolBarcode = createSelector(State, s => s.activePoolBarcode);
const hasActivePoolBarcode = createSelector(activePoolBarcode, s => Boolean(s));

/** pool barcode exists */
const poolOrderExistsRecord = createSelector(State, s => s.poolBarcodeExists);
const activePoolBarcodeExistsRecord = createSelector(
  activePoolBarcode,
  poolOrderExistsRecord,
  (activeBarcode, poolBarcodeExist) => poolBarcodeExist[activeBarcode]
);
const activePoolBarcodeExistsLoading = createSelector(
  activePoolBarcodeExistsRecord,
  (poolBarcodeExist) => poolBarcodeExist?.loading
);
const activePoolBarcodeExistsError = createSelector(
  activePoolBarcodeExistsRecord,
  (poolBarcodeExist) => poolBarcodeExist?.error
);
const activePoolBarcodeExists = createSelector(activePoolBarcodeExistsRecord, s => s?.exists);

/** pool barcode info */
const poolBarcodeInfo = createSelector(State, s => s.poolBarcodeInfo);
const activePoolBarcodeInfo = createSelector(
  activePoolBarcode,
  poolBarcodeInfo,
  (activeBarcode, poolBarcodeInfo) => poolBarcodeInfo[activeBarcode]
);
const activePoolBarcodeInfoLoading = createSelector(
  activePoolBarcodeInfo,
  (poolBarcodeInfo) => poolBarcodeInfo?.loading
);
const activePoolBarcodeInfoError = createSelector(
  activePoolBarcodeInfo,
  (poolBarcodeInfo) => poolBarcodeInfo?.error
);
const activePoolBarcodeInfoSamplingDate = createSelector(
  activePoolBarcodeInfo,
  s => s?.samplingDate
);
const activePoolSynlabId = createSelector(activePoolBarcodeInfo, s => s?.synlabId);

/** pool has negative */
const poolBarcodeHasNegative = createSelector(State, s => s.poolBarcodeHasNegative);
const hasNegativeState = createSelector(
  activePoolBarcode,
  poolBarcodeHasNegative,
  (activeBarcode, hasNegative) => hasNegative[activeBarcode]
)
const hasNegativeLoading = createSelector(
  hasNegativeState,
  (hasNegative) => hasNegative?.loading
)
const hasNegativeError = createSelector(
  hasNegativeState,
  (hasNegative) => hasNegative?.error
)
const hasNegative = createSelector(
  hasNegativeState,
  (hasNegative) => hasNegative?.hasNegative
)

/** pool claims */
const poolClaimsRecord = createSelector(State, s => s.poolClaims);
const activePoolClaim = createSelector(
  activePoolBarcodeInfo,
  poolClaimsRecord,
  (barcodeInfo, poolClaimsRecord) => barcodeInfo && poolClaimsRecord
    ? poolClaimsRecord[barcodeInfo?.synlabId]
    : undefined
)
const activePoolClaimLoading = createSelector(
  activePoolClaim,
  activePoolClaim => activePoolClaim?.loading
)
const activePoolClaimError = createSelector(
  activePoolClaim,
  activePoolClaim => activePoolClaim?.error
)
const activePoolName = createSelector(
  activePoolClaim, s => s?.claims?.find?.(c => c.claimType === 'given_name')?.claimValue
)

/** create pool barcode state */
const poolBarcodeCreateStateRecord = createSelector(State, s => s.createPoolBarcodeState);
const activePoolBarcodeCreateState = createSelector(
  activePoolBarcode,
  poolBarcodeCreateStateRecord,
  (barcode, record) => record[barcode]
);
const activePoolCreateLoading = createSelector(
  activePoolBarcodeCreateState,
  s => s?.loading
);
const activePoolCreateError = createSelector(
  activePoolBarcodeCreateState,
  s => s?.error
);

/** pool individual orders */
const poolIndividualOrdersRecord = createSelector(State, s => s.poolIndividualOrders);
const activeIndividualOrders = createSelector(
  activePoolBarcode,
  poolIndividualOrdersRecord,
  (barcode, record) => record[barcode]
);
const activeIndividualOrderList = createSelector(
  activeIndividualOrders,
  s => s.poolIndividualOrders
)
const activeOrderParticipantSynlabIds = createSelector(
  activeIndividualOrderList,
  s => s?.map?.(x => x.synlabId)
);
const activeIndividualOrderLoading = createSelector(
  activeIndividualOrders,
  s => s?.loading
);
const activeIndividualOrderError = createSelector(
  activeIndividualOrders,
  s => s?.error
);
const activeIndividualOrderCount = createSelector(
  activeIndividualOrders,
  s => s?.poolIndividualOrders?.length ?? 0
);
const activeIndividualOrderCountReachedLimit = createSelector(
  activeIndividualOrderCount,
  s => s >= poolIndividualLimit
)
/** Active participant SynlabId */
const activeParticipantSynlabId = createSelector(State, s => s.activeParticipantSynlabId);
const hasActiveParticipant = createSelector(activeParticipantSynlabId, Boolean);
/** participant claims */
const participantClaimsRecord = createSelector(State, s => s.participantClaimRecord);
const activeParticipantClaimsState = createSelector(
  activeParticipantSynlabId,
  participantClaimsRecord,
  (id, record) => record[id]
);
const activeParticipantClaimsLoading = createSelector(
  activeParticipantClaimsState,
  s => s?.loading
)
const activeParticipantClaimsExists = createSelector(
  activeParticipantClaimsState,
  s => s?.exists
)
const activeParticipantClaimsError = createSelector(
  activeParticipantClaimsState,
  s => s?.error
)
const activeParticipantClaims = createSelector(
  activeParticipantClaimsState,
  s => s?.claims
);
const activeParticipantClaimsName = createSelector(
  activeParticipantClaims,
  (s): ParticpantNameInfo => s
    ? ({
      given_name: s?.find(c => c.claimType === 'given_name')?.claimValue,
      family_name: s?.find(c => c.claimType === 'family_name')?.claimValue,
    })
    : undefined
);
/** active participant barcode */
const activeParticipantBarcode = createSelector(State, s => s.activeParticipantBarcode);
const hasActiveParticipantBarcode = createSelector(activeParticipantBarcode, Boolean);

/** temp order states */
const tempOrderState = createSelector(State, s => s.tempOrderRequestRecord);
const activeTempOrder = createSelector(
  activeParticipantBarcode,
  tempOrderState,
  (id, record) => record[id]
);
const activeTempOrderLoading = createSelector(activeTempOrder, s => s?.loading);
const activeTempOrderSuccess = createSelector(
  activeTempOrder,
  s => s ? s.success && !s.loading && !s.error : false
);
const activeTempOrderError = createSelector(activeTempOrder, s => s?.error);

export const PoolOrderSelectors = {
  poolBarcodeInfo,
  poolClaimsRecord,
  schoolClaims,
  schoolClaimsError,
  schoolClaimsLoading,
  schoolName,
  activePoolBarcode,
  hasActivePoolBarcode,
  activePoolBarcodeExistsLoading,
  activePoolBarcodeExistsError,
  activePoolBarcodeExists,
  activePoolBarcodeInfoLoading,
  activePoolBarcodeInfoError,
  activePoolBarcodeInfoSamplingDate,
  activePoolSynlabId,
  hasNegativeLoading,
  hasNegativeError,
  hasNegative,
  activePoolClaimLoading,
  activePoolClaimError,
  activePoolName,
  activePoolBarcodeCreateState,
  activePoolCreateLoading,
  activePoolCreateError,
  activeOrderParticipantSynlabIds,
  activeIndividualOrderLoading,
  activeIndividualOrderError,
  activeIndividualOrderCount,
  activeIndividualOrderCountReachedLimit,
  hasActiveParticipant,
  activeParticipantClaimsLoading,
  activeParticipantClaimsExists,
  activeParticipantClaimsError,
  activeParticipantClaimsName,
  activeParticipantBarcode,
  hasActiveParticipantBarcode,
  tempOrderState,
  activeTempOrderLoading,
  activeTempOrderSuccess,
  activeTempOrderError,
};
