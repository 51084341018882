import { createAction, props } from "@ngrx/store";

export const loadLabOrders = createAction("[LabOrder] Load LabOrders");

export const loadLabOrdersSuccess = createAction(
  "[LabOrder] Load LabOrders Success",
  props<{ data: any }>()
);

export const loadLabOrdersFailure = createAction(
  "[LabOrder] Load LabOrders Failure",
  props<{ error: any }>()
);

export const checkExistingOrder = createAction(
  "[LabOrder] Load Check Existing Order",
  props<{ params: any; testTubeNumber: string }>()
);

export const checkExistingOrderSucess = createAction(
  "[LabOrder] Load Check Existing Order Success",
  props<{ orderExist: boolean }>()
);

export const checkExistingError = createAction(
  "[LabOrder] Load Check Existing Order Error",
  props<{ error: any }>()
);

export const createNewOrder = createAction(
  "[LabOrder] Load Create New Order",
  props<{ payload: any }>()
);

export const createNewOrderSuccess = createAction(
  "[LabOrder] Load Create New Order Success"
);

export const createNewOrderError = createAction(
  "[LabOrder] Load Create New Order Error",
  props<{ error: any }>()
);

export const inputChanges = createAction("[LabOrder] Load Input Change");

export const getSchool = createAction(
  "[LabOrder] Load Get School",
  props<{ synlabId: string }>()
);

export const resetSchool = createAction("[LabOrder] Reset School");

export const getSchoolSuccess = createAction(
  "[LabOrder] Load Get School Success",
  props<{ data: any }>()
);
export const getSchoolError = createAction(
  "[LabOrder] Load Get School Error",
  props<{ data: string }>()
);
export const clearSchool = createAction("[LabOrder] Load Clear School");

export const submitScannedSynlabId = createAction(
  "[LabOrder] Submit Scanned SynlabId",
  props<{ synlabId: string }>()
);

export const LabOrderActions = {
  submitScannedSynlabId,
  resetSchool,
  checkExistingOrder,
  checkExistingOrderSucess,
  checkExistingError,
  createNewOrder,
  createNewOrderSuccess,
  createNewOrderError,
  inputChanges,
  getSchool,
  getSchoolSuccess,
  getSchoolError,
  clearSchool,
};
