export const InputIds = {
  spoSynlabId: "SPOSynlabId",
  spoBarcode: "SPOBarcode",
  spoPoolName: "SPOPoolName",
  pioPoolBarcode: "PIOPoolBarcode",
  pioSynlabId: "PIOSynlabId",
  pioTestKitBarcode: "PIOTestKitBarcode",
  gioPoolBarcode: "GIOPoolBarcode",
};

export const ActionTypes = {
  auto: "Automatic",
  enter: "OnEnter",
  click: "OnSearchClick",
};
