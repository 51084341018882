import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import * as IndividualOrderReducer from "./individual-order.reducer";

const State = (state: AppState) => state[IndividualOrderReducer.individualOrderFeatureKey];

const getPoolInProgress = createSelector( State, (data) => data.poolInfoInProgress );
const getPoolInfo = createSelector(State, (data) => data.poolInfo);
const getPoolInvalid = createSelector(State, (data) => data.poolInvalid);
const getPoolInfoStatus = createSelector(State, (data) => data.poolInfoStatus);
const childInfo = createSelector(State, (data) => data.childInfo);
const childInfoInProgress = createSelector( State, (data) => data.childInfoInProgress );
const childInfoStatus = createSelector(State, (data) => data.childInfoStatus);
const sendOrderInProgress = createSelector( State, (data) => data.sendOrderInProgress );
const checkDuplicateInProgress = createSelector( State, (data) => data.checkTestTubeInprogress );
const isNextPersonDisabled = createSelector( State, (data) => data.setNextPersonButtonAsDisabled );
const poolHasNegative = createSelector(State, (data) => data.poolIsNegative);
const getIndividualsCount = createSelector( State, (data) => data.individuals.individuals.length || 0 );
const getTempOrdersCount = createSelector( State, (data) => data.tempOrders.length );
const getSchoolName = createSelector( State, (data) => data?.childInfo?.school_name );
const getTempOrdersList = createSelector(State, (data) => data.tempOrders.map((n) => n.synlabId) );
const getIndividualOrdersList = createSelector( State, (data) => data.individuals.individuals );

export const IndividualOrderSelector = {
  getSchoolName,
  getTempOrdersCount,
  getIndividualsCount,
  poolHasNegative,
  isNextPersonDisabled,
  checkDuplicateInProgress,
  sendOrderInProgress,
  getPoolInProgress,
  childInfoStatus,
  childInfo,
  childInfoInProgress,
  getPoolInvalid,
  getPoolInfo,
  getPoolInfoStatus,
  getTempOrdersList,
  getIndividualOrdersList,
};
