import { createAction, props } from '@ngrx/store';
import { Pagination } from 'src/app/models/user-edit.model';

export const loadUsers = createAction(
  '[UserEdit] Load Users'
);

export const loadUsersSuccess = createAction(
  '[UserEdit] Load Users Success',
  props<{
    users: any[],
    paging: Pagination }>()
);

export const loadUsersFailure = createAction(
  '[UserEdit] Load Users Failure',
  props<{ error: string}>()
);

export const setActiveUserId = createAction(
  '[UserEdit] Set Active User ID',
  props<{ userId: string }>()
)

export const loadUser = createAction(
  '[UserEdit] Load User',
  props<{ userId: string }>()
);

export const loadUserSuccess = createAction(
  '[UserEdit] Load Use Success',
  props<{ user: string }>()
);

export const setActiveRowIndex = createAction(
  '[UserEdit] Table Set Active Row Index',
  props<{ rowIndex: number }>()
);

export const setPageSize = createAction(
  '[UserEdit] Page Set Page Size',
  props<{ pageSize: number }>()
);

export const nextPage = createAction(
  '[UserEdit] Page Next Page'
);

export const previousPage = createAction(
  '[UserEdit] Page Previous Page'
);

export const firstPage = createAction(
  '[UserEdit] Page First Page'
);

export const lastPage = createAction(
  '[UserEdit] Page Last Page'
);

export const setOrganization = createAction(
  '[UserEdit] Set Organization',
  props<{ organization: any }>()
);
export const setUser = createAction(
  '[UserEdit] Set User',
  props<{ user: string }>()
);

export const sideFilterToggle = createAction(
  '[UserEdit] SideFilter Toggle'
);

const getSelectedUserDetails = createAction(
  '[UserEdit] Get Selected User Details'
);

const getSelectedUserDetailsSuccess = createAction(
  '[UserEdit] Get Selected User Details Success',
  props<{ userDetails: any }>()
);
const getSelectedUserDetailsFailure = createAction(
  '[UserEdit] Get Selected User Details Failure',
  props <{ error: string }>()
);

const updateUser = createAction(
  '[UserEdit] Update User',
  props<{ data: any, comments: string, userId: string }>()
);

const updateUserSuccess = createAction(
  '[UserEdit] Update User Success'
);
const updateUserFailure = createAction(
  '[UserEdit] Update User Failure',
  props<{ error: string }>()
);

const storePreviousValue = createAction(
  '[UserEdit] Store Previous User Claim',
  props<{ old_data: any }>()
);
export const reset = createAction('[UserEdit] Reset');

export const UserEditActions = {
  getSelectedUserDetails,
  getSelectedUserDetailsSuccess,
  getSelectedUserDetailsFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  storePreviousValue,
  setActiveUserId,
  loadUsers,
  reset
}
export const searchBarSearch = createAction(
  '[UserEdit] SearchBar Search',
  props<{ term: string, fields: string}>()
);

export const searchBarSearchSuccess = createAction(
  '[UserEdit] SearchBar Search Success'
);

export const exportUsers = createAction(
  '[UserEdit] Export Users'
);

export const exportUsersSuccess = createAction(
  '[UserEdit] Export Users Success'
);

export const exportUsersFailure = createAction(
  '[UserEdit] Export Users Failure',
  props<{ error: string }>()
);
