import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { CoreModule } from '../core.module';

@Injectable({
  providedIn: CoreModule
})
export class HeaderVisibilityService {
  visible$ = of(true);
}
