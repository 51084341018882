import { ConfigService } from "src/app/service/config/config.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  public version: any;
  constructor(private _config: ConfigService) {}

  ngOnInit() {
    this.version = this._config.version;
  }
}
