import { ActionReducer, ActionReducerMap, MetaReducer, } from "@ngrx/store";
import * as STSReducer from "./sts.reducer";
import * as ResultReducer from "./results.reducer";
import * as ResultReducerV2 from "./resultsV2.reducer";
import * as RegTestReducer from "./regtest.reducer";
import * as LabOrderReducer from "./lab-order.reducer";
import * as individualOrderReducer from "../individual-orders/individual-order.reducer";
import * as HungaryFootballReducer from "./hungary-football.reducer";
import * as AbTestingReducer from "./ab-testing.reducer";
import * as PoolOrderReducer from "./pool-order.reducer";
import * as PoolIndividualInfoReducer from "./pool-individual-info.reducer";
import * as ParentChildViewResultReducer from "./parent-child-view-result.reducer";
import * as OtpReducer from "./otp.reducer";
import * as UserEditReducer from "./user-edit.reducer";
import * as UserCreateReducer from "./user-create.reducer";
import * as PreventionWellnessReducer from "./prevention-wellness.reducer";
import * as HeaderReducer from './header.reducer';
import * as SingleTestReducer from './single-test.reducer';
import * as ProfileReducer from './profile.reducer';

export interface AppState {
  [individualOrderReducer.individualOrderFeatureKey]: individualOrderReducer.State;
  [STSReducer.stsFeatureKey]: STSReducer.State;
  [ResultReducer.resultsFeatureKey]: ResultReducer.State;
  [ResultReducerV2.resultsV2FeatureKey]: ResultReducerV2.State;
  [RegTestReducer.regtestFeatureKey]: RegTestReducer.State;
  [LabOrderReducer.labOrderFeatureKey]: LabOrderReducer.State;
  [HungaryFootballReducer.hungaryFootballFeatureKey]: HungaryFootballReducer.State;
  [AbTestingReducer.abTestingFeatureKey]: AbTestingReducer.State;
  [PoolOrderReducer.poolOrderFeatureKey]: PoolOrderReducer.State;
  [PoolIndividualInfoReducer.poolIndividualInfoFeatureKey]: PoolIndividualInfoReducer.State;
  [ParentChildViewResultReducer.parentChildViewResultFeatureKey]: ParentChildViewResultReducer.State;
  [OtpReducer.otpFeatureKey]: OtpReducer.State;
  [UserEditReducer.userEditFeatureKey]: UserEditReducer.State;
  [UserCreateReducer.userCreateFeatureKey]: UserCreateReducer.State;
  [PreventionWellnessReducer.preventionWellnessFeatureKey]: PreventionWellnessReducer.State;
  [HeaderReducer.headerFeatureKey]: HeaderReducer.State;
  [SingleTestReducer.singleTestFeatureKey]: SingleTestReducer.State
  [ProfileReducer.profileFeatureKey]: ProfileReducer.State
}

export const reducers: ActionReducerMap<AppState> = {
  individualOrder: individualOrderReducer.reducer,
  sts: STSReducer.reducer,
  results: ResultReducer.reducer,
  resultsV2: ResultReducerV2.reducer,
  regtest: RegTestReducer.reducer,
  labOrder: LabOrderReducer.reducer,
  hungaryFootball: HungaryFootballReducer.reducer,
  abTesting: AbTestingReducer.reducer,
  poolOrder: PoolOrderReducer.reducer,
  poolIndividualInfo: PoolIndividualInfoReducer.reducer,
  parentChildViewResult: ParentChildViewResultReducer.reducer,
  otp: OtpReducer.reducer,
  userEdit: UserEditReducer.reducer,
  userCreate: UserCreateReducer.reducer,
  preventionWellness: PreventionWellnessReducer.reducer,
  appHeader: HeaderReducer.reducer,
  singleTest: SingleTestReducer.reducer,
  profile: ProfileReducer.reducer,
};

/** log all actions */
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [debug];
