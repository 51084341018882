import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, Observable, of, throwError, timer } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom, switchMap, tap, takeUntil, filter, take, pairwise } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import cond from 'lodash/fp/cond';
import equals from 'lodash/fp/equals';
import { AppState } from '../reducers';
import { UserCreateService } from 'src/app/service/user-create/user-create.service';
import * as UserCreateActions from '../actions/user-create.actions';
import * as UserCreateSelectors from '../selectors/user-create.selectors';
import { getErrorMessage } from 'src/app/shared/utils/error.util';
import { EmployeeData, GetTempEmployeeListData, GetTempStudentListData, GetTempUserFileRequest, GetTempUserListRequest, StudentData, UserUploadFileType } from 'src/app/models/user-create.model';

const pollRateMs = 5000;
@Injectable()
export class UserCreateEffects {
  private statusPollOn$ = this.store.pipe(select(UserCreateSelectors.statusPollOn));
  private fileId$ = this.store.pipe(select(UserCreateSelectors.fileId));
  private pageSize$ = this.store.pipe(select(UserCreateSelectors.tempUserPageSize));
  private isStudentList$ = this.store.pipe(select(UserCreateSelectors.isStudentList));
  private isEmployeeList$ = this.store.pipe(select(UserCreateSelectors.isEmployeeList));
  private params$ = this.store.pipe(select(UserCreateSelectors.tempUserParams));
  private pagination$ = this.store.pipe(select(UserCreateSelectors.tempUserPagination));

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private userCreateService: UserCreateService
  ) { }

  getUploadStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getUserUploadStatus),
    switchMap(({ id }) => this.userCreateService.getUserCreateStatus().pipe(
      map(res => UserCreateActions.getUserUploadStatusSuccess({ status: res, id })),
      catchError((err) => {
        const msg = getErrorMessage(err);
        return of(UserCreateActions.getUserUploadStatusFailure({ error: msg, id }))
      })
    ))
  ));
  /** start/stop polling for status */
  userUploadStatusPoll$ = createEffect(() => this.actions$.pipe(
    ofType(
      UserCreateActions.startUserUploadStatusPoll,
      UserCreateActions.requestEarlyUserUploadStatus
    ),
    withLatestFrom(this.statusPollOn$),
    filter(([action, statusPollOn]) =>
      !(UserCreateActions.requestEarlyUserUploadStatus.type === action.type && !statusPollOn)),
    switchMap(() => timer(0, pollRateMs).pipe(
      map(() => UserCreateActions.getUserUploadStatus({})),
      takeUntil(this.actions$.pipe(ofType(UserCreateActions.stopUserUploadStatusPoll)))
    ))
  ));
  /** Upload Csv. requests and waits for user creation status  */
  uploadTempUserCsv$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.uploadTempUserCsv),
    switchMap(action => this.userCreateService.uploadCsv(action).pipe(
      map(() => UserCreateActions.uploadTempUserCsvSuccess()),
      mergeMap(action => this.requestUploadStatus().pipe(map(() => action))),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.uploadTempUserCsvFailure({ error: msg }));
      })
    ))
  ));
  getTempStudentList$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempStudentList),
    withLatestFrom(this.fileId$, this.pageSize$),
    switchMap(([action, fileId, pageSize]) => this.userCreateService.getTempStudentList({
      columnFilters: action.columnFilters,
      fileId: fileId,
      pageNumber: 1,
      pageSize: pageSize ? pageSize:10,
      searchQuery: action.searchQuery,
      showError: action.showError
    }).pipe(
      map(res => UserCreateActions.getTempStudentListSuccess(res)),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.getTempStudentListFailure({ error: msg }));
      })
    ))
  ));
  getTempEmployeeList$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempEmployeeList),
    withLatestFrom(this.fileId$, this.pageSize$),
    switchMap(([action, fileId, pageSize]) => this.userCreateService.getTempEmployeeList({
      columnFilters: action.columnFilters,
      fileId: fileId,
      pageNumber: 1,
      pageSize: pageSize ? pageSize:10,
      searchQuery: action.searchQuery,
      showError: action.showError
    }).pipe(
      map(res => UserCreateActions.getTempEmployeeListSuccess(res)),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.getTempEmployeeListFailure({ error: msg }));
      })
    ))
  ));
  /** determine the user upload file type and dispatch student or employee action */
  getTempUserList$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempUserList),
    withLatestFrom(this.isStudentList$, this.isEmployeeList$),
    map(([{ type, ...actionData }, student, employee]) => {
      if (student) { return UserCreateActions.getTempStudentList({ ...actionData }); }
      else if (employee) { return UserCreateActions.getTempEmployeeList({ ...actionData }); }
      else { return UserCreateActions.getTempUserListFailure({ error: 'Could not determine type of user.' }); }
    })
  ));
  setPageSize$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.setPageSize),
    withLatestFrom(this.params$),
    map(([action, params]) => UserCreateActions.getTempUserList({
      ...params
    }))
  ));
  changePage$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.changePage),
    withLatestFrom(this.params$, this.pagination$, this.isStudentList$, this.isEmployeeList$, this.fileId$),
    withLatestFrom(this.pageSize$),
    switchMap(([[action, params, pagination, isStudent, isEmployee, fileId], pageSize]) => {
      const request: GetTempUserListRequest = ({
        columnFilters: params.columnFilters,
        fileId,
        pageNumber: action.page,
        pageSize,
        searchQuery: params.searchQuery,
        showError: params.showError
      });
      const requestFn: (GetTempUserListRequest) => Observable<GetTempStudentListData | GetTempEmployeeListData> = isStudent
        ? this.userCreateService.getTempStudentList
        : this.userCreateService.getTempEmployeeList;
      return requestFn(request).pipe(
        map(res => {
          const studentData: StudentData[] = isStudent ? res.data as StudentData[] : undefined;
          const employeeData: EmployeeData[] = isEmployee ? res.data as EmployeeData[] : undefined;
          return UserCreateActions.changePageSuccess({
            newPagination: res?.pagination,
            studentList: studentData,
            employeeList: employeeData
          })
        }),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(UserCreateActions.changePageFailure({ error: msg }));
        })
      );
    })
  ));
  userActionChangePage$ = createEffect(() => this.actions$.pipe(
    ofType(
      UserCreateActions.nextPage,
      UserCreateActions.previousPage,
      UserCreateActions.firstPage,
      UserCreateActions.lastPage,
    ),
    withLatestFrom(this.pagination$),
    map(([action, pagination]) => {
      switch (action.type) {
        case UserCreateActions.nextPage.type:
          return pagination?.currentPage + 1;
        case UserCreateActions.previousPage.type:
          return pagination?.currentPage - 1;
        case UserCreateActions.firstPage.type:
          return 1;
        case UserCreateActions.lastPage.type:
          return pagination?.totalPages;
        default:
          return 1;
      }
    }),
    map((page) => UserCreateActions.changePage({ page }))
  ));
  /** cancel user creation. requests and waits for user creation status */
  cancelUserCreation$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.cancelUserCreation),
    withLatestFrom(this.fileId$),
    switchMap(([_action, fileId]) => this.userCreateService.cancelUserCreation(fileId).pipe(
      map(() => UserCreateActions.cancelUserCreationSuccess()),
      mergeMap(action => this.requestUploadStatus().pipe(map(() => action))),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.cancelUserCreationFailure({ error: msg }));
      })
    ))
  ));

  exportCurrentList$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.exportCurrentList),
    withLatestFrom(this.fileId$, this.params$, this.isStudentList$),
    switchMap(([_action, fileId, params, isStudentList]) => {
      const reqBody: GetTempUserFileRequest = {
        columnFilters: params?.columnFilters,
        fileId: fileId,
        searchQuery: params?.searchQuery,
        showError: params?.showError
      };
      const request = isStudentList
        ? this.userCreateService.getTempStudentFile(reqBody)
        : this.userCreateService.getTempEmployeeFile(reqBody)
      return request.pipe(
        map(res => UserCreateActions.exportCurrentListSuccess({ blob: res.file, name: res.fileName })),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(UserCreateActions.exportCurrentListFailure({ error: msg }));
        })
      )
    })
  ));

  processUserCreation$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.processUserCreation),
    withLatestFrom(this.fileId$),
    switchMap(([_action, fileId]) => this.userCreateService.processUserCreation(fileId).pipe(
      map((res) => UserCreateActions.processUserCreationSuccess({successful: res})),
      mergeMap(action => this.requestUploadStatus().pipe(map(() => action))),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.processUserCreationFailure({ error: msg }));
      })
    ))
  ));

  getTempUserInfo$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempUserInfo),
    withLatestFrom(this.isStudentList$, this.isEmployeeList$),
    map(([action, isStudent, isEmployee]) => {
      if (isStudent) { return UserCreateActions.getTempStudentInfo({ id: action.id }); }
      else if (isEmployee) { return UserCreateActions.getTempEmployeeInfo({ id: action.id }); }
      else { return UserCreateActions.getTempUserInfoFailure({ id: action.id, error: 'invalid state' }) }
    })
  ))
  getTempStudentInfo$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempStudentInfo),
    switchMap(action => this.userCreateService.getTempStudent(action.id).pipe(
      map(res => UserCreateActions.getTempStudentInfoSuccess({ id: action.id, data: res })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.getTempStudentInfoFailure({ id: action.id, error: msg }));
      })
    ))
  ))
  getTempEmployeeInfo$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getTempEmployeeInfo),
    switchMap(action => this.userCreateService.getTempEmployee(action.id).pipe(
      map(res => UserCreateActions.getTempEmployeeInfoSuccess({ id: action.id, data: res })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.getTempEmployeeInfoFailure({ id: action.id, error: msg }));
      })
    ))
  ))
  updateTempUserRecord$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.updateTempUserRecord),
    withLatestFrom(this.isStudentList$, this.isEmployeeList$),
    map(([action, isStudent, isEmployee]) => {
      if (isStudent) { return UserCreateActions.updateStudentRecord({ data: action.data as StudentData }); }
      else if (isEmployee) { return UserCreateActions.updateEmployeeRecord({ data: action.data as EmployeeData }); }
      else { return UserCreateActions.updateTempUserRecordFailure({ data: action.data, error: 'invalid state' }) }
    })
  ));

  updateStudentRecord$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.updateStudentRecord),
    switchMap(action => this.userCreateService.updateTempStudent(action.data.id, action.data).pipe(
      map((res) => UserCreateActions.updateStudentRecordSuccess({ data: res })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.updateStudentRecordFailure({ data: action.data, error: msg }));
      })
    ))
  ));
  updateEmployeeRecord$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.updateEmployeeRecord),
    switchMap(action => this.userCreateService.updateTempEmployee(action.data.id, action.data).pipe(
      map((res) => UserCreateActions.updateEmployeeRecordSuccess({ data: res })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.updateEmployeeRecordFailure({ data: action.data, error: msg }));
      })
    ))
  ));
  deleteTempUserRecord$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.deleteTempUserRecord),
    withLatestFrom(this.isEmployeeList$, this.isStudentList$),
    mergeMap(([action, isEmployee, isStudent]) => {
      let request: Observable<Object>;
      if (isEmployee) {
        request = this.userCreateService.deleteTempEmployee(action.id);
      } else if (isStudent) {
        request = this.userCreateService.deleteTempStudent(action.id)
      } else {
        throwError('type of user cannot be determined');
      }
      return request.pipe(
        withLatestFrom(this.params$),
        mergeMap(([_res, params]) => of(
          UserCreateActions.deleteTempUserRecordSuccess({ id: action.id }),
          UserCreateActions.getTempUserList(params)
        )),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(UserCreateActions.deleteTempUserRecordFailure({ id: action.id, error: msg }));
        })
      );
    })
  ))

  getHistoryList$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.getUserCreateHistory),
    switchMap(action => this.userCreateService.getHistoryList().pipe(
      map(res => UserCreateActions.getUserCreateHistorySuccess({
        data: res
      })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.getUserCreateHistoryFailure({ error: msg }))
      })
    ))
  ))

  getUploadFile$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.downloadUploadCsv),
    mergeMap(action => this.userCreateService.getUploadFile(action.fileId, action.fileName).pipe(
      map(res => UserCreateActions.downloadUploadCsvSuccess({
        blob: res.file,
        fileId: action.fileId,
        name: res.fileName
      })),
      catchError(error => {
        const msg = getErrorMessage(error);
        return of(UserCreateActions.downloadUploadCsvFailure({
          fileId: action.fileId,
          error: msg
        }))
      })
    ))
  ))
  getResultFile$ = createEffect(() => this.actions$.pipe(
    ofType(UserCreateActions.downloadResultCsv),
    mergeMap(action => {
      const req = cond([
        [equals(UserUploadFileType.LolliStudent),
          () => this.userCreateService.getStudentResultFile],
        [equals(UserUploadFileType.LolliEmployee),
          () => this.userCreateService.getEmployeeResultFile]
      ]);
      return req(action.fileType)(
        action.fileId,
        action.fileName
      ).pipe(
        map(res => UserCreateActions.downloadResultCsvSuccess({
          blob: res.file,
          fileId: action.fileId,
          name: res.fileName
        })),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(UserCreateActions.downloadResultCsvFailure({
            fileId: action.fileId,
            error: msg
          }))
        })
      );
    })
  ))

  downloadExport$ = createEffect(() => this.actions$.pipe(
    ofType(
      UserCreateActions.exportCurrentListSuccess,
      UserCreateActions.downloadUploadCsvSuccess,
      UserCreateActions.downloadResultCsvSuccess,
    ),
    map((action) => {
      return [
        URL.createObjectURL(action.blob),
        action.name,
        action.type
      ] as const;
    }),
    tap(([url, name]) => {
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }),
    pairwise(),
    tap(([[a]]) => {
      URL.revokeObjectURL(a);
    })
  ), { dispatch: false });


  /** dispatch upload request and wait for result */
  private requestUploadStatus() {
    return defer(() => {
      const id = "" + +new Date();
      this.store.dispatch(UserCreateActions.getUserUploadStatus({ id }));
      const userCreateStatus$ = this.actions$.pipe(
        ofType(
          UserCreateActions.getUserUploadStatusSuccess,
          UserCreateActions.getUserUploadStatusFailure
        ),
        filter(x => x.id === id),
        take(1)
      );
      return userCreateStatus$;
    })
  }
}
