import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CoreModule } from '../core.module';

@Injectable({
  providedIn: CoreModule
})

export class ProfileService {
  profile$:Observable<Profile> = of({
    givenName: 'first name',
    familyName: 'last name',
    synlabId: 'A',
    claims: []
  })
}
export type Claim = {
  claimValue: string,
  claimType: string
}

export type Profile = {
  givenName: string,
  familyName: string,
  synlabId: string,
  claims: Claim[]
}

