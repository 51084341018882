import { userTypes } from "src/app/appsettings";

export const CanAccessUserManagement = [
  userTypes.PT_ADMIN,
  userTypes.SUPERADMIN,
  userTypes.USERADMIN,
  userTypes.EE_ADMIN,
  userTypes.UK_DFP_ADMIN,
  userTypes.UK_SHFY_ADMIN
];

export const CanAccessUserManagementDK = [
  userTypes.CUSTOMER_CARE
]
