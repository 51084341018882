import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CoreModule } from '../core.module';

@Injectable({
  providedIn: CoreModule
})
export class UsertypeService {
  usertypes$: Observable<string[]> = of([])
}
