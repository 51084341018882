import { createReducer, on } from "@ngrx/store";
import { ResultActionsV2 } from "../actions/results.actionsV2";
import { ResultV2 } from "../../pages/results-v2/model/resultv2";

export const resultsV2FeatureKey = "resultsV2";

export interface State {
  results: ResultV2[] | null,
  selectedUserSynlabId?: string,
  selectedWardSynlabId?: string,
  selectedUserHasPassport?: boolean,
  scrollTop?: number,
  loading: boolean,
  detachedFilterPanel?: boolean,
  resultTransfer?: {
    enabled: boolean,
    inProgress: boolean,
    showDialog: boolean,
  }
}

export const initialState: State = {
  results: null,
  loading: false
};

export const reducer = createReducer<State>(
  initialState,
  on(ResultActionsV2.wardChanged, (state, { ward }) => ({
    ...state,
    selectedWardSynlabId: ward.synlabId
  })),
  on(ResultActionsV2.loadResultssV2, (state) => ({
    ...state,
    loading: true
  })),
  on(ResultActionsV2.loadResultsForWard, (state) => ({
    ...state,
    loading: true
  })),
  on(ResultActionsV2.loadResultssSuccessfulV2, (state, { results }) => ({
    ...state,
    results: results,
    loading: false
  })),
  on(ResultActionsV2.loadResultssFailureV2, (state) => ({
    ...state,
    results: [],
    loading: false
  })),
  on(ResultActionsV2.loadResultsV2AdminClear, (state) => ({
    ...state,
    results: []
  })),
  on(ResultActionsV2.setSupportViewSelectedSynlabId, (state, { synlabId }) => ({
    ...state,
    selectedUserSynlabId: synlabId,
    selectedUserHasPassport: null
  })),
  on(ResultActionsV2.loadResultsV2Admin, (state) => ({
    ...state,
    loading: true
  })),
  on(ResultActionsV2.setSupportViewSelectedPatientHasPassport, (state, { hasPassport }) => ({
    ...state,
    selectedUserHasPassport: hasPassport
  })),
  on(ResultActionsV2.setScrollTop, (state, { top }) => ({
    ...state,
    scrollTop: top
  })),
  on(ResultActionsV2.setFilterPanelDetached, (state, { detached }) => ({
    ...state,
    detachedFilterPanel: detached
  })),
  on(ResultActionsV2.resultsTransferShowDialog, (state, { show }) => ({
    ...state,
    resultTransfer: {
      ...state.resultTransfer,
      showDialog: show
    }
  })),
  on(ResultActionsV2.resultsTransferCancel, (state) => ({
    ...state,
    resultTransfer: {
      ...state.resultTransfer,
      showDialog: false,
      inProgress: false,
    }
  })),
  on(ResultActionsV2.resultsTransferFailure, (state) => ({
    ...state,
    resultTransfer: {
      ...state.resultTransfer,
      showDialog: false,
      inProgress: false,
    }
  })),
  on(ResultActionsV2.resultsTransferSuccessful, (state) => ({
    ...state,
    resultTransfer: {
      ...state.resultTransfer,
      showDialog: false,
      inProgress: false,
    }
  })),
  on(ResultActionsV2.resultsTransferStart, (state) => ({
    ...state,
    resultTransfer: {
      ...state.resultTransfer,
      inProgress: true
    }
  }))
);
