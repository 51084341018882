export type defaultCountryPerLangType = { [key: string]: string };

export const defaultCountryPerLang: defaultCountryPerLangType = {
  en: 'gb',
  et: 'ee',
  ru: 'ru',
  de: 'de',
  fr: 'fr',
  pt: 'pt',
  it: 'it',
  lt: 'lt',
  uk: 'ua',
  el: 'gr',
  hu: 'hu',
  es: 'es'
}

export function lookupCountry(locale:string, defaultCountryPerLang:defaultCountryPerLangType):string {
  let l = locale.split('-');
  if (l.length>1) {
    return l[1].toLowerCase();
  } else if (l[0] in defaultCountryPerLang) {
    return defaultCountryPerLang[l[0]].toLowerCase();
  }
  return '';
}
