import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { GenerateOtpRequest } from 'src/app/models/otp.model';


@Injectable({
  providedIn: 'root'
})
export class OtpService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig), take(1));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl), take(1));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  generateOtp(request: GenerateOtpRequest) {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/otp/generate`;
        return this.http.post(
          url,
          request
        );
      })
    );
  }
}
