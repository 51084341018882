import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER, inject, CSP_NONCE } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { NgSelectModule } from "@ng-select/ng-select";

/*Main*/
import { AppComponent } from "./app.component";
import { IndexComponent } from "./pages/index/index.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";

/*Module*/
import { FindPatientComponent } from "./pages/find-patient/find-patient.component";
import { CreateNewPatientComponent } from "./pages/create-new-patient/create-new-patient.component";
import { InitComponent } from "./pages/init/init.component";
import { TestResultComponent } from "./pages/test-result/test-result.component";
import { TestResultDetailComponent } from "./pages/test-result-detail/test-result-detail.component";

/*Services*/
import { AuthGuard } from "./service/authGuard/auth.guard";
import { ConfigService } from "./service/config/config.service";
import { AuthService } from "./service/auth/auth.service";
import { SiginCallbackComponent } from "./pages/signin-callback/signin-callback.component";
import { TokenInterceptor } from "./service/auth/token-interceptor/token-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PopupUnauthorizedComponent } from "./layout/popup-unauthorized/popup-unauthorized.component";
import { PaginationComponent } from "./shared/components/pagination/pagination.component";
import { LoginRedirectComponent } from "./pages/login-redirect/login-redirect.component";
import { SearchPatientComponent } from "./pages/search-patient/search-patient.component";
import { RenewtokenComponent } from "./pages/renewtoken/renewtoken.component";
import { UnauthorizeComponent } from "./pages/unauthorize/unauthorize.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { RouterModule } from "@angular/router";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF, CommonModule, PlatformLocation } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { reducers, metaReducers } from "./@store/reducers";
import { effects } from "./@store/effects";

/** */
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import toArray from "dayjs/plugin/toArray";
import localizedFormat from "dayjs/plugin/localizedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AssetLinkPipe } from "./shared/pipes/asset-link.pipe";
import { PdfModalComponent } from "./shared/components/pdf-modal/pdf-modal.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { TranslocoRootModule } from "./transloco-root.module";
import { ClickOutsideModule } from "ng-click-outside";
import { LanguagePickerComponent } from "./shared/components/language-picker/language-picker.component";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RegisterAndTestComponent } from "./pages/register-and-test/register-and-test.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MessageDialogComponent } from "./shared/components/message-dialog/message-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { LabOrderComponent } from "./pages/lab-order/lab-order.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatInputModule } from "@angular/material/input";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule, RippleGlobalOptions } from "@angular/material/core";
import { SuccessMessageComponent } from "./pages/lab-order/success-message/success-message.component";
import { InfoMessageComponent } from "./pages/lab-order/info-message/info-message.component";
import { ErrorMessageComponent } from './pages/lab-order/error-message/error-message.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { ImgPreloaderService } from "./service/img-preloader/img-preloader.service";
import { WasRedirectComponent } from "./pages/was-redirect/was-redirect.component";
import { SynlabHeaderSwitchProfileModule } from "./shared/component-modules/synlab-header-switch-profile/synlab-header.component";
import { HeaderSwitchProfileModule } from "./shared/component-modules/header-switch-profile/header-switch-profile.component";
import { ConfigService as LibConfigService, UsertypeService } from "./plasma-ui-common/synlab-access-ui-components/core";
import { LoggerService as LibLoggerService } from "./plasma-ui-common/synlab-access-ui-components/core";
import { HeaderUserTypeService } from "./service/header/header-user-type.service";
import { HeaderConfigService } from "./service/header/header-config.service";
import { ProfileService } from "./plasma-ui-common/synlab-access-ui-components/core/profile/profile.service";
import { LoggerService } from "./service/logger.service";
import { SpinnerModule } from "./plasma-ui-common/synlab-access-ui-components/spinner/spinner.component";
import { HeaderVisibilityService as LibHeaderVisibilityService } from "./plasma-ui-common/synlab-access-ui-components/core/header-visibility/header-visibility.service";
import { HeaderVisibilityService } from "./service/header/header-visibility.service";
import { GoogleTagManagerModule, GoogleTagManagerService } from "angular-google-tag-manager";
import { GTM_PLACEHOLDER_KEY } from "./appsettings";
import { RANDOM_NONCE } from "./plasma-ui-common/synlab-access-ui-components/core/constants";

const logr = new LoggerService(null);
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/prevention-wellness/', '.json');
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0
  }
};

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    FooterComponent,
    FindPatientComponent,
    CreateNewPatientComponent,
    SiginCallbackComponent,
    InitComponent,
    PopupUnauthorizedComponent,
    PaginationComponent,
    LanguagePickerComponent,
    LoginRedirectComponent,
    TestResultComponent,
    TestResultDetailComponent,
    SearchPatientComponent,
    RenewtokenComponent,
    UnauthorizeComponent,
    LoaderComponent,
    AssetLinkPipe,
    PdfModalComponent,
    MessageDialogComponent,
    AssetLinkPipe,
    RegisterAndTestComponent,
    LabOrderComponent,
    SuccessMessageComponent,
    InfoMessageComponent,
    ErrorMessageComponent,
    HeaderComponent,
    NotFoundPageComponent,
    RedirectComponent,
    WasRedirectComponent
  ],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    CommonModule,
    RouterModule,
    NgxMatTimepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    BrowserModule,
    ClickOutsideModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    NgSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: "toast-top-right",
    }),
    NgbModule,
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    TranslocoRootModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HeaderSwitchProfileModule,
    SynlabHeaderSwitchProfileModule,
    SpinnerModule,
    GoogleTagManagerModule,
  ],
  providers: [
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: globalRippleConfig
    },
    {
      provide: 'googleTagManagerId',
      useValue: GTM_PLACEHOLDER_KEY
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, GoogleTagManagerService],
      useFactory: (cfg: ConfigService = inject(ConfigService), gtms: GoogleTagManagerService) => () => {
        cfg.load().then(c => {
          if ("GoogleTagId" in c) {
            gtms.googleTagManagerId = c.GoogleTagId;
            gtms.config.id = c.GoogleTagId;
          }
        });
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => {
        return () => configService.loadEnvJson();
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ImgPreloaderService],
      useFactory: (preloader: ImgPreloaderService) => {
        return () => {
          return preloader.preloadImages();
        };
      },
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => () => {
        dayjs.extend(tz);
        dayjs.extend(utc);
        dayjs.extend(localeData);
        dayjs.extend(toArray);
        dayjs.extend(localizedFormat);
        dayjs.extend(customParseFormat);
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    AuthGuard,
    AuthService,
    AssetLinkPipe,
    LoggerService,
    {
      provide: UsertypeService,
      useClass: HeaderUserTypeService
    },
    {
      provide: LibConfigService,
      useClass: HeaderConfigService
    },
    {
      provide: LibLoggerService,
      useClass: LoggerService
    },
    {
      provide: ProfileService,
      useClass: HeaderUserTypeService
    },
    {
      provide: LibHeaderVisibilityService,
      useClass: HeaderVisibilityService
    },
    {
      provide: CSP_NONCE,
      useValue: RANDOM_NONCE
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    AssetLinkPipe
  ]
})
export class AppModule { }
