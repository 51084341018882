import { Component, OnInit, Inject, Renderer2, ViewChild, ElementRef, } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { ApiService } from "src/app/service/api/api.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { DOCUMENT } from "@angular/common";
import { Result, PagedResults } from "src/app/models/result.model";
import { mergeMap, take } from "rxjs/operators";
import debounce from "lodash/fp/debounce";

/** Component state model */
interface State {
  page: number;
  pageSize: number;
  totalCount: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: string;
  data: Result[];
  loading: boolean;

  // Derived states
  maxPage: number;
  /** Index of the first item shown from the whole list.  */
  startItemIndex: number;
  /** Index of the last item shown from the whole list.  */
  endItemIndex: number;
}

@Component({
  selector: "app-test-result",
  templateUrl: "./test-result.component.html",
  styleUrls: ["./test-result.component.scss"],
})
export class TestResultComponent implements OnInit {
  state: State = {
    page: 1,
    pageSize: 10,
    totalCount: 0,
    searchTerm: "",
    sortColumn: "asc",
    sortDirection: "",
    data: [],
    loading: true,
    maxPage: 0,
    startItemIndex: 0,
    endItemIndex: 0,
  };

  apiSubscription: Subscription;
  @ViewChild("searchFocus", { read: ElementRef }) searchInputFocus: ElementRef<
    HTMLElement
  >;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private unAuth: UnauthorizePromptService,
    private actRoute: ActivatedRoute
  ) {
    this.changeSearchTerm = debounce(250, this.changeSearchTerm);
  }

  ngOnInit() {
    this.renderer.removeClass(this.document.body, "init");

    this.auth
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });

    let priorTask = new Promise((resolve, reject) => {
      this.load();
      resolve("done");
    });

    priorTask.then((msg) => {
      if (msg === "done") {
        setTimeout(() => this.searchInputFocus.nativeElement.focus(), 800);
      }
    });
  }

  load() {
    this.updateState({ loading: true });

    const params = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      SearchText: this.state.searchTerm,
      totalCount: this.state.totalCount,
    };

    if (this.apiSubscription) this.apiSubscription.unsubscribe();
    this.apiSubscription = this.auth.patientSsn$
      .pipe(
        take(1),
        mergeMap((ssn) => this.getResults(ssn, params))
      )
      .subscribe(
        (d) => {
          this.updateState({
            data: d.resultMessages,
            loading: false,
            totalCount: d.totalCount,
          });
        },
        (error) => {
          this.updateState({ loading: false });
          console.log(error);
        }
      );
  }

  /** Called to update `searchTerm` in `state` when user types in searchBox.
   * Debounced to reduce unnecessary load to api.
   */
  changeSearchTerm(searchTerm: string) {
    console.log("changeTerm ran");
    this.updateState({ page: 1, searchTerm });
    this.load();
  }

  /** Clear `searchTerm` in `state`. Called when search box is cleared. */
  clear() {
    this.changeSearchTerm("");
  }

  onSelect(userId) {
    this.router.navigate(["/test-results-detail"]);
  }

  get listStatus() {
    if (this.state.loading) return 1;
    else if (
      !this.state.loading &&
      !this.state.data.length &&
      this.state.searchTerm
    )
      return 2;
    else if (
      !this.state.loading &&
      !this.state.data.length &&
      !this.state.searchTerm
    )
      return 3;
  }

  onPageChange(page: number) {
    this.updateState({ page: page });
    this.load();
  }

  /** get results of user */
  private getResults(ssn: string, params: any): Observable<PagedResults> {
    return this.api.resultsGet<PagedResults>(
      `/patients/${ssn}/labresults`,
      params
    );
  }

  /** Update component state */
  private updateState(newState: Partial<State>): State {
    this.state = this.computeDerivedState({
      ...this.state,
      ...newState,
    });
    return this.state;
  }

  /**
   * create updated state with correct derived data.
   * @param state object to base creation of state with updated derived data.
   */
  private computeDerivedState(state: State): State {
    const firstIndex = this.computeStartItemIndex(state.page, state.pageSize);
    return {
      ...state,
      maxPage: Math.ceil(state.totalCount / state.pageSize),
      startItemIndex: firstIndex,
      endItemIndex: this.computeEndItemIndex(firstIndex, state.data.length),
    };
  }
  /**
   * compute index of first item in current result
   * @param page current page number
   * @param pageSize page size of every request to determine size of previous pages
   */
  private computeStartItemIndex(page: number, pageSize: number) {
    return (page - 1) * pageSize + 1;
  }
  /**
   * compute index of last item in current result
   * @param firstItemIndex index of the first item in the result
   * @param lengthOfCurrentResult size of current result to determine last index from first.
   */
  private computeEndItemIndex(
    firstItemIndex: number,
    lengthOfCurrentResult: number
  ) {
    return firstItemIndex - 1 + lengthOfCurrentResult;
  }
}
