<ul data-test-id="pagination-pagination" class="pagination">

  <!-- first button -->
  <li data-test-id="pagination-page1" class="page-item disabled" *ngIf="page === 1" title="First">
    <span data-test-id="pagination-page1-link" class="page-link">
      <img data-test-id="pagination-page1-img" src="assets/images/pagination-icons/first.png" alt="first"
        class="img-fluid" />
    </span>
  </li>
  <li data-test-id="pagination-first" title="First" *ngIf="page > 1" class="page-item" (click)="changePage('first')">
    <span data-test-id="pagination-first-link" class="page-link">
      <img data-test-id="pagination-first-img" src="assets/images/pagination-icons/first.png" alt="first"
        class="img-fluid" />
    </span>
  </li>

  <!-- previous button -->
  <li data-test-id="pagination-page2" class="page-item disabled" *ngIf="page <= 1" title="Previous">
    <span data-test-id="pagination-page2-link" class="page-link">
      <img data-test-id="pagination-page2-img" src="assets/images/pagination-icons/prev.png" alt="Previous"
        class="img-fluid" />
    </span>
  </li>
  <li data-test-id="pagination-prev" class="page-item" *ngIf="page > 1" (click)="changePage('prev')" title="Previous">
    <span data-test-id="pagination-prev-link" class="page-link">
      <img data-test-id="pagination-prev-img" src="assets/images/pagination-icons/prev.png" alt="Previous"
        class="img-fluid" />
    </span>
  </li>

  <!-- result range -->
  <li data-test-id="pagination-page3" class="page-item">
    <span data-test-id="pagination-page3-link" class="range-detail f-height text-muted" *ngIf="total != 0">
      <span data-test-id="pagination-resultRange-label">
        <small data-test-id="pagination-resultRange-text">{{ resultRange }}</small>
      </span>
    </span>
  </li>

  <!-- next button -->
  <li data-test-id="pagination-page4" class="page-item disabled" *ngIf="page >= maxPage" title="Next">
    <span data-test-id="pagination-page4-link" class="page-link">
      <img data-test-id="pagination-page4-img" src="assets/images/pagination-icons/next.png" alt="Next"
        class="img-fluid" />
    </span>
  </li>
  <li data-test-id="pagination-next" class="page-item" *ngIf="page < maxPage" (click)="changePage('next')" title="Next">
    <span data-test-id="pagination-next-link" class="page-link">
      <img data-test-id="pagination-next-img" src="assets/images/pagination-icons/next.png" alt="Next"
        class="img-fluid" />
    </span>
  </li>

  <!-- last button -->
  <li data-test-id="pagination-page5" class="page-item" *ngIf="page < maxPage" (click)="changePage('last')"
    title="Last">
    <span data-test-id="pagination-page5-link" class="page-link">
      <img data-test-id="pagination-page5-img" src="assets/images/pagination-icons/last.png" alt="Last"
        class="img-fluid" />
    </span>
  </li>
  <li data-test-id="pagination-last" class="page-item disabled" *ngIf="page >= maxPage" title="Last">
    <span data-test-id="pagination-last-link" class="page-link">
      <img data-test-id="pagination-last-img" src="assets/images/pagination-icons/last.png" alt="Last"
        class="img-fluid" />
    </span>
  </li>
</ul>
