import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogTextbox } from 'src/app/models/log-textbox.model';
import { map, mergeMap, take } from 'rxjs/operators'
import { CreatePoolTestRequest, HasNegative, PoolBarcodeInformationResponse, PoolIndividualOrder, TempOrderRequest } from 'src/app/models/pool-order.model';
import { select, Store } from '@ngrx/store';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { AppState } from 'src/app/@store/reducers';
@Injectable({
  providedIn: 'root'
})
export class PoolOrderService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig), take(1));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl), take(1));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  checkIfPoolBarcodeExists(barcode: string): Observable<{ doesExist: boolean }> {
    return this.api$.pipe(
      mergeMap((api) => {
        const url = `${api}/api/abtesting/testTubeIdentifiers/${barcode}`;
        return this.http.get<{ doesExist: boolean }>(url);
      })
    )
  }

  logTextbox(log: LogTextbox): Observable<boolean> {
    return this.api$.pipe(
      mergeMap((api) => {
        const url = `${api}/api/pool-tests/logtextbox`;
        return this.http.post<boolean>(url, log);
      })
    );
  }

  getPoolBarcodeInfo(barcode: string): Observable<PoolBarcodeInformationResponse> {
    return this.api$.pipe(
      mergeMap((api) => {
        const url = `${api}/api/abtesting/poolbarcode/${barcode}`;
        return this.http.get<PoolBarcodeInformationResponse>(url)
      })
    );
  }

  getPoolIndividualOrders(poolBarcode: string): Observable<PoolIndividualOrder[]> {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/abtesting/gettemporaryorders/${poolBarcode}`;
        return this.http.get<PoolIndividualOrder[]>(url)
      })
    );
  }

  loadHasNegativeResults(poolBarcode: string): Observable<HasNegative> {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/abtesting/hasnegativeresults/${poolBarcode}`;
        return this.http.get<HasNegative>(url)
      })
    )
  }

  createPoolBarcode(request: CreatePoolTestRequest): Observable<{Id: string}> {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/abtesting/pool`;
        return this.http.post<{Id: string}>(url, request)
      })
    )
  }

  createTempOrder(request: TempOrderRequest): Observable<string> {
    request = {
      ...request,
      synlabId: request.synlabId.toUpperCase()
    }
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/abtesting/tempindividualorder`;
        return this.http.post<''>(url, request)
      })
    );
  }
}
