import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, defer, Observable, of, pipe } from "rxjs";
import { map, mergeMap, shareReplay, tap, withLatestFrom } from "rxjs/operators";
import { AssetLinkPipe } from "../../shared/pipes/asset-link.pipe";
import { ConfigService } from "../config/config.service";
import memoize from 'lodash/memoize';
import _merge from 'lodash/merge';
import { TranslocoService } from "@ngneat/transloco";


export type ReportSetting = {
  content: string;
  marginTop: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  style?: string;
  header?: string;
  footer?: string;
  height?: string;
  width?: string;
  format?: string;
  useHandleBars?: boolean;
};

export class ReportsConfiguration {
  static readonly whiteLabeledCertificate: ReportSetting = {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/report-templates/white-labeled-certificate/template.html",
    height: "29.55cm",
    width: "21cm",
    useHandleBars: true,
  };
  static readonly employerCertificate: ReportSetting = {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/report-templates/employer-certificate/template.html",
    style: "/assets/report-templates/employer-certificate/styles.css",
    height: "29.55cm",
    width: "21cm",
  };
  static readonly viewCertificate: ReportSetting = {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/report-templates/view-certificate/template.html",
    style: "/assets/report-templates/view-certificate/styles.css",
    height: "29.55cm",
    width: "21cm",
  };
  static readonly certificate: ReportSetting = {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/report-templates/result-certificate/template.html",
    style: "/assets/report-templates/result-certificate/styles.css",
    useHandleBars: true,
  };
  static readonly noCertificate: ReportSetting = {
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/report-templates/no-certificate/template.html",
    style: "/assets/report-templates/no-certificate/styles.css",
  };
  static readonly patientTestResult: ReportSetting = {
    marginTop: "30px",
    marginBottom: "40px",
    marginLeft: "0px",
    marginRight: "0px",
    content: "/assets/template/result/patient/template.html",
    footer: "/assets/template/result/patient/footer.html",
    style: "/assets/template/result/patient/styles.css",
  };
}

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  enCertTranslations$ = this.transloco.selectTranslateObject('certificate', undefined, 'en');
  certTranslations$ = this.transloco.selectTranslateObject('certificate').pipe(
    withLatestFrom(this.enCertTranslations$),
    map(([en, current]) => _merge(en, current))
  );
  readonly logo1Path =
    "/assets/report-templates/white-labeled-certificate/logo-1.png";
  readonly logo2Path =
    "/assets/report-templates/white-labeled-certificate/logo-2.png";
  readonly logo3Path =
    "/assets/report-templates/white-labeled-certificate/logo-3.png";
  readonly logo4Path =
    "/assets/report-templates/white-labeled-certificate/logo-4.png";
  readonly logo5Path =
    "/assets/report-templates/white-labeled-certificate/logo-5.png";
  readonly watermarkPath =
    "/assets/report-templates/white-labeled-certificate/watermark.png";
  logo1Image$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.logo1Path))
  );
  logo2Image$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.logo2Path))
  );
  logo3Image$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.logo3Path))
  );
  logo4Image$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.logo4Path))
  );
  logo5Image$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.logo5Path))
  );
  watermarkImage$ = defer(() =>
    this.getImageBase64(this.assetLink.transform(this.watermarkPath))
  );
  certificateAssets$ = combineLatest([
    this.logo1Image$,
    this.logo2Image$,
    this.logo3Image$,
    this.logo4Image$,
    this.logo5Image$,
    this.watermarkImage$,
  ]);
  url: string;
  getImageBase64(path: string): Observable<string | ArrayBuffer> {
    return this.http
      .get(path, {
        responseType: "blob",
        params: { version: this.config?.environment?.Version },
      })
      .pipe(this.blobToBase64Pipe);
  }
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private assetLink: AssetLinkPipe,
    private transloco: TranslocoService
  ) {
    this.getLinks = memoize(this.getLinks);
  }

  generatePdf(
    reportTemplates: ReportSetting,
    jsonData: string,
    orientation?: "portrait" | "landscape"
  ) {
    const { basePlasmatUrl } = this.config;
    return this.createBodyParam(
      reportTemplates,
      jsonData,
      orientation
    ).pipe(
      mergeMap(([req, params]) =>
        this.http.post(`${basePlasmatUrl}/api/report`, req, {
          responseType: "blob",
          params,
        })
      ),
      tap(() => {
        if (this.url) {
          URL.revokeObjectURL(this.url);
        }
      }),
      map((x) => URL.createObjectURL(x)),
      tap((x) => (this.url = x))
    );
  }
  generatePdfWithToken(
    reportTemplates: ReportSetting,
    jsonData: string,
    docToken: string,
    orientation?: "portrait" | "landscape",
  ) {
    const { basePlasmatUrl } = this.config;
    return this.createBodyParam(
      reportTemplates,
      jsonData,
      orientation
    ).pipe(
      mergeMap(([req, params]) =>
        this.http.post(
          `${basePlasmatUrl}/api/report/ward`,
          { ...req, docToken },
          { responseType: "blob", params }
        )
      ),
      tap(() => {
        if (this.url) {
          URL.revokeObjectURL(this.url);
        }
      }),
      map((x) => URL.createObjectURL(x)),
      tap((x) => (this.url = x))
    );
  }
  download(url: string, filename: string) {
    const link = document.createElement('a');
    link.setAttribute('download', filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  /** create tuple of request body and params for generating pdf */
  private createBodyParam(
    reportTemplates: ReportSetting,
    jsonData: string,
    orientation?: "portrait" | "landscape",
  ) {
    return combineLatest([
      this.getLinks(reportTemplates.content),
      this.getLinks(reportTemplates.header),
      this.getLinks(reportTemplates.footer),
      this.getLinks(reportTemplates.style),
    ]).pipe(
      map(([content, head, foot, styles]) => {
        let temp = content
          .split("[[[baseUrl]]]")
          .join(this.config.environment.basePlasmaUrl)
          .split("[[[styles]]]")
          .join(styles);
        return {
          template: {
            content: temp,
            header: head,
            footer: foot,
            engine: "jsrender",
            recipe: "chrome-pdf",
            marginTop: reportTemplates.marginTop,
            marginBottom: reportTemplates.marginBottom,
            marginLeft: reportTemplates.marginLeft,
            marginRight: reportTemplates.marginRight,
            height: reportTemplates.height,
            width: reportTemplates.width,
          },
          jsonData: jsonData,
        };
      }),
      map((req) => {
        /** get orientation */
        let params: Record<string, string> = {};
        if (orientation && orientation === "landscape") {
          params.isLandscape = "true";
        }
        if (reportTemplates.format) {
          params.format = reportTemplates.format;
        }
        if (reportTemplates.useHandleBars) {
          params.useHandleBars = "true";
        }
        return [req, params] as const;
      })
    )
  }

  private getLinks(path): Observable<string> {
    if (!path) {
      return of("");
    }
    return of(path).pipe(
      withLatestFrom(this.config.envConfig$.pipe(map((x) => x?.Version))),
      mergeMap(([contentPath, version]) =>
        contentPath
          ? this.http.get(this.assetLink.transform(contentPath), {
              responseType: "text",
              params: { version },
            })
          : of(undefined)
      ),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }
  private blobToBase64Pipe = pipe(
    mergeMap((blob: Blob) => {
      return new Observable<string | ArrayBuffer>((obs) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          obs.next(reader.result as string);
          obs.complete();
        };
      });
    })
  );
}
