import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of, throwError, zip } from "rxjs";
import { catchError, delay, map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { claimValue } from "src/app/appsettings";
import { Claim, UserAccountInfo } from "src/app/models/claims.model";
import { ApiService } from "src/app/service/api/api.service";
import { ProfileService } from "src/app/service/profile/profile.service";
import { localStorageKeys } from "src/app/shared/model/storage.model";
import { STSActions } from "../actions/sts.actions";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";

@Injectable()
export class StsEffects {
  synlabId$ = this.store.pipe(select(StsSelectors.getSynlabId));
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private profileApi: ProfileService
  ) {
    this.restoreURLRedirect();
  }

  fetchMultipleClaims$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STSActions.loadMultipleClaims),
        switchMap(({ synlabIds }) =>
          zip(
            ...synlabIds.map((synlabId) =>
              this.fetchClaimsViaSynlabId(synlabId).pipe(
                tap((res) =>
                  this.store.dispatch(
                    STSActions.loadSingleClaimSuccess({
                      synlabId: synlabId,
                      claims: res,
                    })
                  )
                ),
                catchError((error) => {
                  console.error(error);
                  return of<Claim[]>([]);
                })
              )
            )
          )
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            STSActions.loadMultipleClaimsFailure({ error: error })
          );
          return caught;
        }),
        tap((claimList) =>
          this.store.dispatch(
            STSActions.loadMultipleClaimsSuccess({ claims: claimList })
          )
        )
      ),
    { dispatch: false }
  );

  fetchViaUserId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STSActions.fetchClaimsViaUserId),
        mergeMap(({ userId }) =>
          this.fetchClaims(userId).pipe(map((res) => [userId, res] as const))
        ),
        tap(([userId, res]) =>
          this.store.dispatch(
            STSActions.fetchClaimsViaUserIdSuccess({ userId, claims: res })
          )
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            STSActions.fetchClaimsViaUserIdFailure({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadClaims$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STSActions.loadClaims),
        mergeMap(() => this.synlabId$),
        mergeMap((synlabId) => this.profileApi.getClaimsBySynlabId(synlabId)),
        tap((claims) =>
          this.store.dispatch(STSActions.loadClaimsSuccess({ claims }))
        ),
        catchError((err, caught) => {
          this.store.dispatch(
            STSActions.loadClaimsFailure({
              error: err?.message || "Error occurred",
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  loadClaimsIfEmpty$ = createEffect(
    () => this.actions$.pipe(
      ofType(STSActions.loadClaimsIfEmpty),
      withLatestFrom(this.store.pipe(select(StsSelectors.getClaims))),
      mergeMap(([_a, claims]) => {
        if (Array.isArray(claims) && claims.length > 0) {
          return of(true);
        } else {
          return this.loadClaimsAndWait$()
        }
      }),
      map(() => STSActions.loadClaimsIfEmptyDone())
    )
  )

  loadProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STSActions.loadProfile),
        switchMap(({ userId }) => this.fetchClaims(userId)),
        tap((claims) =>
          this.store.dispatch(STSActions.loadProfileSuccess({ claims }))
        ),
        catchError((err, caught) => {
          this.store.dispatch(STSActions.loadProfileFailed({ error: err }));
          return caught;
        })
      ),
    { dispatch: false }
  );

  setRedirectUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(STSActions.setRedirectUrl),
        map(({ path }) => {
          sessionStorage.setItem(localStorageKeys.redirect, path);
        })
      ),
    { dispatch: false }
  );

  fetchHasGeneplanetOrders$ = createEffect(
    () => this.actions$.pipe(
      ofType(STSActions.fetchHasGeneplanetOrders),
      delay(1000),
      withLatestFrom(
        this.store.pipe(select(StsSelectors.getSynlabId)),
        this.store.pipe(select(StsSelectors.isAppAccessUser)),
        this.store.pipe(select(StsSelectors.getApiEndpoint))
      ),
      switchMap(([actions, synlabId, isSAuser, url]) => {
        if (synlabId && isSAuser) {
          return this.api.getMethod(`${url}/api/results/v2/ee-has-gp-result/${synlabId}`).pipe(
            map(response => `${response}`.toLowerCase() == claimValue.TRUE),
            catchError(() => of(false))
          )
        }
        return of(null);
      }),
      map(result => STSActions.setHasGeneplanetOrders({ hasOrders: result }))
    )
  )

  fetchClaims(userId: string) {
    return this.api.get<Claim[]>(`/manage/accounts/${userId}`);
  }

  fetchClaimsViaSynlabId(synlabId: string) {
    return this.api
      .get<{ users: UserAccountInfo[] }>(
        `/manage/accounts/synlabid/${synlabId}`
      )
      .pipe(
        mergeMap((res) => {
          if (!res || res.users.length !== 1) {
            return throwError(`Could not find user with synlabId: ${synlabId}`);
          } else {
            return this.fetchClaims(res.users[0].id);
          }
        })
      );
  }

  restoreURLRedirect() {
    const path = sessionStorage.getItem(localStorageKeys.redirect);
    if (path) {
      sessionStorage.removeItem(localStorageKeys.redirect);
      this.store.dispatch(STSActions.restoreRedirectUrl({ path }));
    }
  }

  private loadClaimsAndWait$() {
    const done$ = this.actions$.pipe(
      ofType(
        STSActions.loadClaimsFailure,
        STSActions.loadClaimsSuccess
      ),
      take(1)
    );
    setTimeout(() => {
      this.store.dispatch(STSActions.loadClaims());
    }, 0);
    return done$;
  }
}
