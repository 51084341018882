import {  createSelector } from "@ngrx/store";
import { isAntigen, Results } from "../models/results.model";

import { AppState } from "../reducers";
import * as ResultReducer from "../reducers/results.reducer";
import uniqBy from "lodash/uniqBy";

const State = (state: AppState) => state[ResultReducer.resultsFeatureKey];
const getTestTypeList = createSelector(State, (data) =>
  uniqBy(data.testTypeList, "value"));
const getTestTypeListAll = createSelector(State, (data) => data.testTypeList);
/** get results from store
  CORE-250 - remove inconclusive antigen results */
const getResults = createSelector(State, (data) =>
  data.result.filter(
    (x) => !(isAntigen(x) && x.synlabValue != "1" && x.synlabValue != "2")
  )
);
const getResultsCount = createSelector(State, (data) => data.totalResultCount);
const getResultsLoading = createSelector(State, (data) => data.isResultLoading);
const getShowFileLookup = createSelector(
  State,
  (data) => data.loadingShowFileLookup
);
const getSelectedUserInfo = createSelector(State, (data) => data.selectedUser);
const getlabResultsLoading = createSelector(State, (data) => data.labResults);
const getInvoiceLoading = createSelector(State, (data) => data.invoice);
const showSelectedUser = createSelector(State, (data) => data.showSelectUser);

const getSchoolListLoading = createSelector(State, data => data.schoolListLoading);
const getSchoolListQuery = createSelector(State, data => data.schoolListQuery);
const getSchoolList = createSelector(State, (data) => data.schoolList);
const getSchoolSelected = createSelector(State, data => data.schoolSelected);

const getTeacherListLoading = createSelector(State, data => data.teacherListLoading);
const getTeacherList = createSelector(State, (data) => data.teacherList);
const getTeacherListQuery = createSelector(State, (data) => data.teacherListQuery);
const getTeacherSelected = createSelector(State, data => data.teacherSelected);
const getTeacherListfiltered = createSelector(
  getTeacherList,
  getTeacherListQuery,
  (list, query) => {
    const actualQ = query?.trim?.();
    if(typeof actualQ === 'string' && actualQ.length > 0){
      const re = new RegExp(actualQ, 'i');
      return list.filter(item => re.test(item.email) ||
        re.test(item.name) || re.test(item.synlabId)
      );
    }
    return list;
  }
);
const getPoolListLoading = createSelector(State, data => data.poolListLoading);
const getPoolList = createSelector(State, (data) => data.poolList);
const getPoolListQuery = createSelector(State, (data) => data.poolListQuery);
const getPoolListfiltered = createSelector(
  getPoolList,
  getPoolListQuery,
  (list, query) => {
    const actualQ = query?.trim?.();
    if(typeof actualQ === 'string' && actualQ.length > 0){
      const re = new RegExp(actualQ, 'i');
      return list.filter(item => re.test(item.email) ||
        re.test(item.name) || re.test(item.synlabId)
      );
    }
    return list;
  }
);

const getGreenpassRecord = createSelector(State, (data) => data.greepassRecord);

const getGroupedResults = createSelector(getResults, (results) => {
  const parentResults = results.filter((result) => result.memberId === 0);
  return parentResults.map(
    (parentResult): Results => ({
      ...parentResult,
      pastResults: results.filter(
        (result) =>
          result.groupId === parentResult.groupId && result.memberId !== 0
      ),
    })
  );
});

const isForceLoadCertLoading = createSelector(
  State,
  (data) => data.isForceLoadCertLoading
);
const forceLoadCertId = createSelector(State, (data) => data.forceLoadCertId);
const getCompanyCheckRecord = createSelector(State, data => data.companyCheckRecord);
const getCompanyCheckLoading = createSelector(State, data => data.companyCheckLoading);

const isPoolRecord = createSelector(State, data => data.isPoolRecord);
const labAddresses = createSelector(State, data => data.labAddresses);
const getResultAddress = createSelector(State, data => data.resultAddresses);

export const ResultSelectors = {
  isForceLoadCertLoading,
  forceLoadCertId,
  getTestTypeListAll,
  getInvoiceLoading,
  getlabResultsLoading,
  getTestTypeList,
  getResultsCount,
  getResults,
  getResultsLoading,
  getShowFileLookup,
  getSelectedUserInfo,
  showSelectedUser,

  getSchoolListLoading,
  getSchoolListQuery,
  getSchoolList,
  getSchoolSelected,
  getTeacherListLoading,
  getTeacherList,
  getTeacherListQuery,
  getTeacherListfiltered,
  getTeacherSelected,
  getPoolListLoading,
  getPoolList,
  getPoolListQuery,
  getPoolListfiltered,

  getGreenpassRecord,
  getGroupedResults,
  getCompanyCheckRecord,
  getCompanyCheckLoading,
  isPoolRecord,
  labAddresses,
  getResultAddress
};
