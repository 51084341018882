<ng-container data-test-id="header-switch-profile-container" *transloco="let t; read: 'header'">
  <nav data-test-id="header-switch-profile-lib-header-0" class="lib-header" [class.is-mobile]="isMobile$|async">
    <a data-test-id="header-switch-profile-header-brand" class="lib-header-brand" [class.--centered]="links.length==0" [href]="brandLogoLink">
      <img data-test-id="header-switch-profile-brandLogo" [src]="brandLogo" alt="logo">
    </a>

    <ul data-test-id="header-switch-profile-locale-picker-li-outer" class="lib-header-menu"
      [class.is-open]="isMobileMenuOpen$|async" *ngIf="links.length>0">
      <li data-test-id="header-switch-profile-locale-picker-cont" class="lib-header-menu-header">
        <lib-locale-picker data-test-id="header-switch-profile-locale-picker2" [useFlags]="false"></lib-locale-picker>
        <button data-test-id="header-switch-profile-lib-header-close8" mat-mini-fab class="lib-header-close"
          (click)="toggleMenu()">
          <mat-icon data-test-id="header-switch-profile-close8" *ngIf="(isMobileMenuOpen$|async)"
            color="primary">close</mat-icon>
          <mat-icon data-test-id="header-switch-profile-menu8" *ngIf="!(isMobileMenuOpen$|async)"
            color="primary">menu</mat-icon>
        </button>
      </li>
      <li [attr.data-test-id]="'header-switch-profile-header-li'+i" *ngFor="let l of links; let i = index">
        <a data-test-id="header-switch-profile-link-loop1" [attr.disabled]="disableLinks ? 'disabled' : null"
          (click)="l.route === '/prevention-wellness' && switchToPreventionWellnes()" *ngIf="l.route"
          [routerLink]="disableLinks? null: l.route" [state]="l.state" routerLinkActive="active active-link">
          <img data-test-id="header-switch-profile-icon-header7" class="icon-header" [src]="l.icon"
            alt="icon-header" /><span data-test-id="header-switch-profile-label7">{{ t(l.label) }}</span><mat-icon
            data-test-id="header-switch-profile-icon-arrow7" class="icon-arrow">arrow_forward</mat-icon>
        </a>
        <a data-test-id="header-switch-profile-link-loop2" [attr.disabled]="disableLinks ? 'disabled' : null" *ngIf="l.url" [href]="disableLinks?  null : l.url">
          <img data-test-id="header-switch-profile-icon6" class="icon-header" [src]="l.icon" alt="icon-header" /> <span
            data-test-id="header-switch-profile-label6">{{ t(l.label) }}</span> <mat-icon
            data-test-id="header-switch-profile-arrow_forward6" class="icon-arrow">arrow_forward</mat-icon>
        </a>
      </li>
    </ul>

    <div data-test-id="header-switch-profile-header-section-sm" class="lib-header-section-sm locale-picker">
      <lib-locale-picker data-test-id="header-switch-profile-picker" [useFlags]="false"></lib-locale-picker>
    </div>

    <div data-test-id="header-switch-profile-header-section" class="lib-header-section" *ngIf="user && user.synlabId">

      <button data-test-id="header-switch-profile-dropdownMenuButton" class="ee-text-button" id="dropdownMenuButton"
        (click)="onOpenWardSelection($event)">
        <div data-test-id="header-switch-profile-lib-profile" class="lib-profile">
          <img data-test-id="header-switch-profile-profile6" src="/assets/synlab-header/profile-icon.svg"
            alt="profile icon" *ngIf="!(isMobile$|async)">
          <mat-icon data-test-id="header-switch-profile-svg-icon4" *ngIf="(isMobile$|async)">
            <svg data-test-id="header-switch-profile-svg4" width="100%" height="100%" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.6663 3C9.27311 3 7.33301 4.9401 7.33301 7.33333C7.33301 9.72657 9.27311 11.6667 11.6663 11.6667C14.0596 11.6667 15.9997 9.72657 15.9997 7.33333C15.9997 4.9401 14.0596 3 11.6663 3ZM9.33301 7.33333C9.33301 6.04467 10.3777 5 11.6663 5C12.955 5 13.9997 6.04467 13.9997 7.33333C13.9997 8.622 12.955 9.66667 11.6663 9.66667C10.3777 9.66667 9.33301 8.622 9.33301 7.33333Z"
                fill="#fff"></path>
              <path
                d="M8.33333 13C7.18406 13 6.08186 13.4565 5.2692 14.2692C4.45655 15.0819 4 16.1841 4 17.3333V19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19V17.3333C6 16.7145 6.24583 16.121 6.68342 15.6834C7.121 15.2458 7.71449 15 8.33333 15H15C15.6188 15 16.2123 15.2458 16.6499 15.6834C17.0875 16.121 17.3333 16.7145 17.3333 17.3333V19C17.3333 19.5523 17.781 20 18.3333 20C18.8856 20 19.3333 19.5523 19.3333 19V17.3333C19.3333 16.1841 18.8768 15.0819 18.0641 14.2692C17.2515 13.4565 16.1493 13 15 13H8.33333Z"
                fill="#fff"></path>
            </svg>
          </mat-icon>
          <span data-test-id="header-switch-profile-loggedUserInitial" class="lib-profile-name"
            [title]="user.givenName+' '+user.familyName">{{ chosenUserInitial || loggedUserInitial }}</span>
        </div>
      </button>
      <div data-test-id="header-switch-profile-dropdown" class="dropdown" *ngIf="user && user.synlabId">
        <!-- desktop view -->

        <div data-test-id="header-switch-profile-ward-selection-wrapper" class="ward-selection-wrapper"
          [ngClass]="{'closed': !openWardSelection }" (click)="onCloseWardSelection($event)">
          <div data-test-id="header-switch-profile-ward-selection" class="ward-selection-container">
            <div data-test-id="header-switch-profile-head" class="head">
              <div data-test-id="header-switch-profile-close-btn" class="close-btn"
                (click)="onCloseWardSelection($event)"><img data-test-id="header-switch-profile-close"
                  src="/assets/images/close.svg" alt="close" /></div>
            </div>
            @if (enableSuomiWardSelector$ | async) {
              <app-suomi-ward-selector data-test-id="suomi-ward-selector" (toggleSidebar)="openWardSelection = false"></app-suomi-ward-selector>
            } @else {
              <div data-test-id="header-switch-profile-current-user" class="current-user">
                {{ chosenUserName || loggedUser }}
                <span data-test-id="header-switch-profile-email-ssn" class="email-ssn">{{ displayIdenityLabel$ | async
                  }}</span>
              </div>
              <div data-test-id="header-switch-profile-more-users" class="more-users"
                *ngIf="allowProfileSwitch && (wardsAndUser$ | async)?.length > 1">
                <div data-test-id="header-switch-profile-changeProfileTo" class="change-label">
                  {{'resultsV2.changeProfileTo' | transloco}}</div>
                <ul data-test-id="header-switch-profile-ward-wrapper-li">
                  <li [attr.data-test-id]="'header-switch-profile-ward-wrapper'+i" class="ward-wrapper" [ngClass]="{
                            'selected': ward.synlabId === selectedWardSynlabId || first && selectedWardSynlabId === '',
                            'unverified': !ward.isVerified || ward.isAdult
                            }" (click)="(ward.isVerified && !ward.isAdult) && changeSelectedWard(ward)"
                    *ngFor="let ward of (wardsAndUser$ | async); let first = first; let i = index">
                    <div data-test-id="header-switch-profile-name-container5-outer" *ngIf="first">
                      <div data-test-id="header-switch-profile-name-container5" class="name-container">
                        <img data-test-id="header-switch-profile-name5-img" src="/assets/images/skyblue-user.svg"
                          alt="skyblue-user" />
                        <span data-test-id="header-switch-profile-name5" class="name">{{ ward.firstName }} {{
                          ward.lastName }} </span>
                      </div>
                    </div>
                    <div data-test-id="header-switch-profile-name-container4-outer"
                      *ngIf="!ward.isVerified && !ward.isAdult && !first">
                      <div data-test-id="header-switch-profile-name-container4" class="name-container">
                        <img data-test-id="header-switch-profile-name4-img" src="/assets/images/grey-user.svg"
                          alt="grey-user" />
                        <span data-test-id="header-switch-profile-name4" class="name">{{ ward.firstName }} {{
                          ward.lastName }} </span>
                      </div>
                      <span data-test-id="header-switch-profile-badge-unverified4"
                        class="ward-unverified badge-unverified badge-pill">
                        <span data-test-id="header-switch-profile-unverified4-icon"
                          class="material-icons-round material-icons">close</span>
                        <span data-test-id="header-switch-profile-unverified4">{{ 'resultsV2.unverified' | transloco
                          }}</span>
                      </span>
                    </div>
                    <div data-test-id="header-switch-profile-name-container3-outer"
                      *ngIf="ward.isVerified && !ward.isAdult && !first">
                      <div data-test-id="header-switch-profile-name-container3" class="name-container">
                        <img data-test-id="header-switch-profile-name3-img" src="/assets/images/skyblue-user.svg"
                          alt="skyblue-user" />
                        <span data-test-id="header-switch-profile-name3" class="name">{{ ward.firstName }} {{
                          ward.lastName }} </span>
                      </div>
                      <span data-test-id="header-switch-profile-badge-verified3"
                        class="ward-verified badge-verified badge-pill">
                        <span data-test-id="header-switch-profile-verified3img"
                          class="material-icons-round material-icons">check</span>
                        <span data-test-id="header-switch-profile-verified3">{{ 'resultsV2.verified' | transloco }}</span>
                      </span>
                    </div>
                    <div data-test-id="header-switch-profile-name-container2-outer"
                      *ngIf="ward.isVerified && ward.isAdult && !first">
                      <div data-test-id="header-switch-profile-name-container2" class="name-container">
                        <img data-test-id="header-switch-profile-name2img" src="/assets/images/grey-user.svg"
                          alt="grey-user" />
                        <span data-test-id="header-switch-profile-name2" class="name">{{ ward.firstName }} {{
                          ward.lastName }} </span>
                      </div>
                      <span data-test-id="header-switch-profile-ward-verified"
                        class="ward-verified badge-verified badge-pill">
                        <span data-test-id="header-switch-profile-verified-icon"
                          class="material-icons-round material-icons">check</span>
                        <span data-test-id="header-switch-profile-verified">{{ 'resultsV2.verified' | transloco }}</span>
                      </span>
                    </div>
                    <div data-test-id="header-switch-profile-name-container-outer"
                      *ngIf="!ward.isVerified && ward.isAdult && !first">
                      <div data-test-id="header-switch-profile-name-container" class="name-container">
                        <img data-test-id="header-switch-profile-nameimg" src="/assets/images/grey-user.svg"
                          alt="grey-user" />
                        <span data-test-id="header-switch-profile-name" class="name">{{ ward.firstName }} {{ ward.lastName
                          }} </span>
                      </div>
                      <span data-test-id="header-switch-profile-badge-unverified"
                        class="ward-unverified badge-unverified badge-pill">
                        <span data-test-id="header-switch-profile-unverified-close"
                          class="material-icons-round material-icons">close</span>
                        <span data-test-id="header-switch-profile-unverified">{{ 'resultsV2.unverified' | transloco
                          }}</span>
                      </span>
                    </div>
                    <span data-test-id="header-switch-profile-dot" *ngIf="ward.isVerified && !ward.isAdult"
                      class="dot"></span>
                    <span data-test-id="header-switch-profile-unverified" *ngIf="!ward.isVerified || ward.isAdult"
                      class="unverified-dot"></span>
                  </li>
                </ul>
              </div>
            }

            <div data-test-id="header-switch-profile-option-menu" class="option-menu">
              <ul data-test-id="header-switch-profile-option-menuul">
                <a data-test-id="header-switch-profile-baseProfileUrl" [href]="baseProfileUrl$|async">
                  <li data-test-id="header-switch-profile-option-menu-li"><img
                      data-test-id="header-switch-profile-option-menu-img" src="/assets/images/skyblue-gear.svg"
                      alt="skyblue-gear" />{{ 'resultsV2.accountPreferences' | transloco }}</li>
                </a>
                <a data-test-id="header-switch-profile-logout" (click)="logout()" href="javascript:;">
                  <li data-test-id="header-switch-profile-option-menu-li2"><img
                      data-test-id="header-switch-profile-option-menu-img2" src="/assets/images/red-logout.svg"
                      alt="red-logout" />{{ t('logoutLabel') }}</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div data-test-id="header-switch-profile-header-toggle" class="lib-header-toggle" *ngIf="isMobile$|async">
      <button data-test-id="header-switch-profile-text-button-toggle" class="ee-text-button" (click)="toggleMenu()">
        <mat-icon data-test-id="header-switch-profile-header-toggle-icon">
          <svg data-test-id="header-switch-profile-header-togglesvg" width="100%" height="100%" viewBox="0 0 32 32"
            fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
            <path
              d="M2.66699 7.99996C2.66699 7.26358 3.26395 6.66663 4.00033 6.66663H28.0003C28.7367 6.66663 29.3337 7.26358 29.3337 7.99996C29.3337 8.73634 28.7367 9.33329 28.0003 9.33329H4.00033C3.26395 9.33329 2.66699 8.73634 2.66699 7.99996Z"
              fill="white"></path>
            <path
              d="M2.66699 16C2.66699 15.2636 3.26395 14.6666 4.00033 14.6666H28.0003C28.7367 14.6666 29.3337 15.2636 29.3337 16C29.3337 16.7363 28.7367 17.3333 28.0003 17.3333H4.00033C3.26395 17.3333 2.66699 16.7363 2.66699 16Z"
              fill="white"></path>
            <path
              d="M4.00033 22.6666C3.26395 22.6666 2.66699 23.2636 2.66699 24C2.66699 24.7363 3.26395 25.3333 4.00033 25.3333H28.0003C28.7367 25.3333 29.3337 24.7363 29.3337 24C29.3337 23.2636 28.7367 22.6666 28.0003 22.6666H4.00033Z"
              fill="white"></path>
          </svg>
        </mat-icon>
      </button>
    </div>

  </nav>
</ng-container>
