import { createSelector } from "@ngrx/store";

import { AppState } from "../reducers";
import * as RegTestReducer from "../reducers/regtest.reducer";

const State = (state: AppState) => state[RegTestReducer.regtestFeatureKey];

const getTestPools = createSelector(State, (data) => data.testPools);
const getLookupValue = createSelector(State, (data) => data.tubeIdLookupValue);
const getLookupInProgress = createSelector(
  State,
  (data) => data.tubeIdLookupInProgress
);
const getSendTestInProgress = createSelector(
  State,
  (data) => data.sendTestInProgress
);

export const RegTestSelectors = {
  getTestPools,
  getLookupValue,
  getLookupInProgress,
  getSendTestInProgress,
};
