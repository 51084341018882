import { createSelector } from '@ngrx/store'
import { AppState } from '../reducers'
import * as HeaderReducer from '../reducers/header.reducer'

const State = (state: AppState) => state[HeaderReducer.headerFeatureKey]

const loadingBarShown = createSelector(State, (state) => state.loadingBar)
const getRelatedUsers = createSelector(State, (state) => state.relatedUsers)
const getWards = createSelector(State, (state) => state.relatedUsers?.wards);
const getVisibility = createSelector(State, (state) => state.visible)

export const HeaderSelectors = {
  loadingBarShown,
  getRelatedUsers,
  getWards,
  getVisibility
}
