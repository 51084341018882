import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public selectedCountry:any = {
    twoLetterCountryCode: '',
    cultureCode: ''
  };
  public countryValueSource = new BehaviorSubject<string>("");
  currentCountryValue = this.countryValueSource.asObservable();

  changeValue(c: any){
    this.selectedCountry = c;
    this.countryValueSource.next(`${c.twoLetterCountryCode}`);
  }
}
