import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { UsertypeService } from "src/app/plasma-ui-common/synlab-access-ui-components/core";
import { Profile, ProfileService } from "src/app/plasma-ui-common/synlab-access-ui-components/core/profile/profile.service"
import { AuthService } from "../auth/auth.service";

@Injectable()
export class HeaderUserTypeService implements UsertypeService, ProfileService {
  constructor(private _store: Store, private _auth: AuthService) {}
  usertypes$: Observable<string[]> = this._auth.userInfo$.pipe(
    filter((i) => !!i),
    map((u) => (Array.isArray(u.user_type) ? u.user_type : [u.user_type]))
  );
  profile$: Observable<Profile> = this._store.pipe(
    select(StsSelectors.getUserBasicInfo)
  );
}
