import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class LoggerService {

  private consoleLog = console.log.bind(window.console);

  constructor(private errorHandler: ErrorHandler) {}

  public get log(): Function {
    if (!this.isProduction) {
      return this.consoleLog
    }
    return () => {}
  }

  error(error: Error): void {
    this.errorHandler.handleError(error);
  }

  warn(value: any, ...rest: any[]): void {
    console.warn(value, ...rest);
  }

  /**
   * @returns String. returns current line number where this function is called.
   */
  getLine(): string {
    const e = new Error();
    const regex = /\((.*):(\d+):(\d+)\)$/;
    const match = regex.exec(e.stack.split("\n")[2]);
    return `path:${match[1]}@[line:${match[2]}][column:${match[3]}]`;
  }

  private get isProduction(): boolean {
    const envs = ["//dev.", "//stage.", "//localhost"];
    const origin = window.location?.origin;
    return !envs.some((env) => origin?.includes(env));
  }
}
