<div data-test-id="lab-order-container5" class="upload-result-container-outer lab-order"
  *transloco="let t; read: 'labOrder'">

  <form data-test-id="lab-order-container4" autocomplete="off">
    <div data-test-id="lab-order-container3" class="content-wrapper selected-panel-container-outer mt-0">
      <ng-container *ngIf="orderInformation.get('synlabId') as sn">
        <ng-container *ngIf="orderInformation.get('testTubeNumber') as tn">

          <!-- synlab id -->
          <div data-test-id="lab-order-orderInformation" class="scan-container" [formGroup]="orderInformation">
            <label data-test-id="lab-order-synlabId" class="label">{{ t('synlabId') }}</label>
            <div data-test-id="lab-order-testTubeFocus-cont3" class="d-flex container-row">
              <div data-test-id="lab-order-testTubeFocus-cont2" class="flex-1 synlab-input-field">
                <div data-test-id="lab-order-testTubeFocus-cont1" class="input-group"
                  [ngClass]="{'border-danger': sn.dirty && !sn.valid && !(barcodeLoading$|async) && !(orderLoading$|async) && !sn.disabled }">
                  <input data-test-id="lab-order-testTubeFocusinput" type="text" class="form-control" id="synlabId"
                    (input)="trimInput($event, 'synlabId')" (keydown.enter)="onKeyEnter($event.target.value)"
                    formControlName="synlabId" tabindex="0" #synlabId autocomplete="off">
                  <button data-test-id="lab-order-testTubeFocusbtn" class="btn btn-primary" type="button"
                    [disabled]="(barcodeLoading$ | async) ||  (orderLoading$ | async)|| !orderInformation.get('synlabId').valid || (isSchoolLoading$|async)"
                    (click)="testTubeFocus()">

                    <mat-spinner data-test-id="lab-order-spinner" *ngIf="isSchoolLoading$|async; else showIcon"
                      diameter="20" aria-label="loading">
                    </mat-spinner>
                    <ng-template data-test-id="lab-order-showIcon2" #showIcon>
                      <span data-test-id="lab-order-search" class="material-icons">search</span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- test tube number -->
          <div data-test-id="lab-order-orderInformation" class="scan-container mt-4" [formGroup]="orderInformation">
            <label data-test-id="lab-order-barcode" class="label">{{ t('barcode') }}</label>
            <div data-test-id="lab-order-testTubeNumber-cont2" class="d-flex container-row">
              <div data-test-id="lab-order-testTubeNumber-cont1" class="flex-1 synlab-input-field">
                <div data-test-id="lab-order-testTubeNumber-cont" class="input-group"
                  [ngClass]="{'border-danger': tn.dirty && !tn.valid && !(barcodeLoading$ | async) && !tn.disabled }">
                  <input data-test-id="lab-order-testTubeNumber" type="text" class="form-control" id="testTubeNumber"
                    formControlName="testTubeNumber" (input)="trimInput($event, 'testTubeNumber')"
                    (keydown.enter)="onKeyEnter($event.target.value, 1)" #testTubeNumber autocomplete="off">
                  <button data-test-id="lab-order-checkExistingOrder" class="btn btn-primary" type="button"
                    [disabled]="(barcodeLoading$|async) || (orderLoading$|async) || (!tn.valid || !sn.valid)"
                    (click)="checkExistingOrder('click')">
                    <mat-spinner data-test-id="lab-order-spinner" *ngIf="barcodeLoading$|async; else showIcon2"
                      diameter="20" aria-label="loading">
                    </mat-spinner>
                    <ng-template data-test-id="lab-order-showIcon2" #showIcon2>
                      <span data-test-id="lab-order-search" class="material-icons">search</span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--schoolname-->
      <div data-test-id="lab-order-schoolName-cont1" class="mt-3"
        *ngIf="((isSchoolLoading$|async) || (schoolName$|async)); else noSchoolName">
        <ng-container *ngIf="(isSchoolLoading$|async); else schoolName">
          <span data-test-id="lab-order-schoolName2" class="school-name-label">{{ t('schoolName') }}: Please
            wait...</span>
        </ng-container>
        <ng-template data-test-id="lab-order-schoolName-temp" #schoolName>
          <span data-test-id="lab-order-schoolName" class="school-name-label">{{ t('schoolName') }}: {{schoolName$ |
            async}}</span>
        </ng-template>
      </div>
      <ng-template data-test-id="lab-order-noSchoolName" #noSchoolName>
        <div data-test-id="lab-order-noschoolName-cont" class="mt-3">
          <span data-test-id="lab-order-noschoolName" class="school-name-label">{{ t('schoolName') }}: --- </span>
        </div>
      </ng-template>


      <!--poolname-->
      <div data-test-id="lab-order-poolName-cont4" class="scan-container mt-4"
        *ngIf="!(orderExist$ | async) && orderInformation.get('testTubeNumber').value" [ngClass]="{'no-result': true}"
        [formGroup]="orderInformation">
        <label data-test-id="lab-order-poolName" class="label">{{ t('poolName') }}</label>
        <div data-test-id="lab-order-poolName-cont3" class="d-flex container-row">
          <div data-test-id="lab-order-poolName-cont2" class="flex-1 poolname-input-field">
            <div data-test-id="lab-order-poolName-cont" class="input-group">
              <input data-test-id="lab-order-poolName" type="text" class="form-control" id="poolName"
                formControlName="poolName" #poolName (keydown.enter)="sendOrder('enter')"
                (input)="trimInput($event, 'poolName')" autocomplete="off">
            </div>
          </div>
        </div>

        <div data-test-id="lab-order-container2" class="time-picker pt-3">
          <label data-test-id="lab-order-samplingDateAndTime" class="dandtlabel">{{ t('samplingDateAndTime') }}</label>
          <div data-test-id="lab-order-form-field-cont2" class="d-flex">
            <div data-test-id="lab-order-form-field-cont1" class="hyRLSow3 flex-1 disabled2"
              [ngClass]="{'disabled': orderLoading$|async}">
              <mat-form-field data-test-id="lab-order-form-field">
                <input data-test-id="lab-order-dateInput" matInput [matDatepicker]="picker" [value]="defaultDate"
                  [disabled]="orderLoading$|async" [max]="today" #dateInput
                  (input)="manualDateInput(dateInput.value, 'manual')"
                  (dateChange)="manualDateInput(dateInput.value, 'picker')">
                <mat-datepicker-toggle data-test-id="lab-order-datepicker-toggle" [disabled]="orderLoading$|async"
                  matSuffix [for]="picker">
                  <mat-icon data-test-id="lab-order-matDatepickerToggleIcon"
                    matDatepickerToggleIcon>calendar_today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker data-test-id="lab-order-picker" #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div data-test-id="lab-order-time" class="time flex-1">
              <ngx-mat-timepicker-field data-test-id="lab-order-timepicker-field" color="primary"
                [disabled]="orderLoading$|async" [defaultTime]="defaultTime" (timeChanged)="timeChangedEvt($event)">
              </ngx-mat-timepicker-field>
            </div>
          </div>
          <small data-test-id="lab-order-date-error" *ngIf="isInvalidDate$|async" class="text-danger date-error">Please
            enter a valid
            date</small>
        </div>
      </div>

      <div data-test-id="lab-order-container1" class="actions-container" [formGroup]="orderInformation">
        <div data-test-id="lab-order-line" class="line"></div>
        <div data-test-id="lab-order-sendOrder-cont" class="buttons d-flex flex-row-reverse align-items-center">
          <button data-test-id="lab-order-sendOrder" type="button" class="btn btn-primary"
            [disabled]="(orderExist$ | async) && ((orderInformation.get('automatic').value) || (orderInformation.get('automatic').enabled)) || !orderInformation.valid"
            (click)="sendOrder('submit')">
            <span data-test-id="lab-order-nextOrder" *ngIf="!(orderLoading$ | async)">{{ t('nextOrder') }}</span>
            <mat-spinner data-test-id="lab-order-spinner" *ngIf="orderLoading$ | async" diameter="20"
              aria-label="loading">
            </mat-spinner>
          </button>
          <span data-test-id="lab-order-automatic-cont" class="pr-3">
            <mat-checkbox data-test-id="lab-order-automatic" formControlName="automatic">
              {{ t('automatic') }}
            </mat-checkbox>
          </span>
        </div>
      </div>
    </div>
  </form>


</div>
