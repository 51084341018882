import { Results } from "src/app/@store/models/results.model";
import { findClaimAndGetBool } from "src/app/models/claims.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface SchoolIndividualData extends BaseCertData {
  isSchoolIndividual: true;
  firstName: string;
  lastName: string;
  barcode: string;
  resultComment: string;
  laboratory: string;
  laboratoryAddress: string;
}
export const isSchoolIndividualResult: ConditionChecker = (r, o) => {
  if (!(r && typeof r === 'object' && o && typeof o === 'object')) {
    return false;
  }
  const claims = o.claimsRecord[r.synlabId];
  return findClaimAndGetBool('school_child_account', claims);
}
export const resultAsSchoolIndividualCertData: ResultMapper<Results, SchoolIndividualData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
  ...baseResultAsCertData(r,o),
  isSchoolIndividual: true,
  firstName: customUppercase(r?.firstName?.trim?.()),
  lastName: customUppercase(r?.lastName?.trim?.()),
  barcode: Array.isArray(r.testTubeIdentifiers)
    ? r.testTubeIdentifiers.join(', ')
    : r.testTubeIdentifiers,
    resultComment: o.translations?.[tranKey]?.['message'],
    laboratory: r.laboratory,
    laboratoryAddress: o.labAddress[r.senderIdentifierCode]
  }
}
