import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom, } from "rxjs/operators";
import { CheckDuplicateResponseComputed, PoolIndividuals, PoolTempOrders, STSClaims, } from "src/app/pages/individual-test/orders/data-model";
import { ApiService } from "src/app/service/api/api.service";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";
import { IndividualOrderActions } from "./individual-order.actions";
import { IndividualOrderSelector } from "./individual-order.selectors";

@Injectable()
export class IndividualOrderEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService
  ) {}

  checkPool$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.checkPool),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ q }, endpoint]) => {
          if (!q) {
            return of(null);
          }

          return this.api.getMethod(
            `${endpoint}/api/order-results/orders/latest/samplingdate/${q}`,
            null
          );
        }),
        tap((rt) =>
          this.store.dispatch(
            IndividualOrderActions.checkPoolSuccess({ data: rt })
          )
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            IndividualOrderActions.checkPoolError({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  checkChild$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.checkChild),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ q, pool }, endpoint]) => {
          if (!q || !pool) {
            return of(null);
          }
          return this.api.getMethod(
            `${endpoint}/api/profiles/GetBySynlabId/${q}/${pool}`,
            null
          );
        }),
        tap((rt: STSClaims[]) => {
          let data: any = rt.reduce(function (acc, cur, i) {
            acc[cur.claimType] = cur.claimValue;
            return acc;
          }, {});

          let { school_synlab_id, is_exists, synlab_id } = data;
          let exists = is_exists == "1";

          // show error in the UI
          if (exists) {
            this.store.dispatch(
              IndividualOrderActions.childExistsError({ synlabId: synlab_id })
            );
            return;
          }

          if (school_synlab_id) {
            this.store.dispatch(
              IndividualOrderActions.checkChildSuccess({ data })
            );
          } else {
            this.store.dispatch(
              IndividualOrderActions.checkChildError({ error: null })
            );
          }
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            IndividualOrderActions.checkChildError({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  submit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.submit),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ payload }, endpoint]) => {
          return this.api
            .postMethod(`${endpoint}/api/pool-tests/abtesting`, payload)
            .pipe(
              map((n) => ({
                data: n,
                payload,
              }))
            );
        }),
        tap((data: { data: any; payload: any }) => {
          this.store.dispatch(
            IndividualOrderActions.submitSuccess({ data: data.data })
          );
        }),
        catchError((err, caught) => {
          const { error } = err;
          const { message } = error;

          this.store.dispatch(
            IndividualOrderActions.submitFailed({ error: message || error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  checkDuplicate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.checkDuplicate),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ testTube, payload }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/pool-tests/testTubeIdentifiers/${testTube}/exists`,
              payload
            )
            .pipe(
              map((n) => ({
                result: n,
                testTube,
              }))
            );
        }),
        tap((data: CheckDuplicateResponseComputed) => {
          if (data.result && data.result.doesExist) {
            this.store.dispatch(
              IndividualOrderActions.stopCreateOrder({
                message: "",
                displayError: true,
                barcode: data.testTube,
              })
            );
          } else {
            this.store.dispatch(IndividualOrderActions.proceedCreateOrder());
          }
        }),
        catchError((err, caught) => {
          let { error } = err;
          let message = error ? error.message : "";

          this.store.dispatch(
            IndividualOrderActions.stopCreateOrder({ message })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  hasNegativeResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.hasNegativeResults),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ testTubeIdentifier }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/pool-tests/hasnegativeresults/${testTubeIdentifier}`
            )
            .pipe(
              map((n) => ({
                hasNegative: n ? n.hasNegative : null,
                barcode: testTubeIdentifier,
              }))
            );
        }),
        tap(({ hasNegative, barcode }) => {
          this.store.dispatch(
            IndividualOrderActions.hasNegativeResultsSuccess({
              value: hasNegative,
              barcode,
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            IndividualOrderActions.hasNegativeResultsFailed({ error: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getPoolInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.getPoolInformation),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ barcode }, endpoint]) => {
          return this.api
            .getMethod(`${endpoint}/api/pool-tests/poolbarcode/${barcode}`)
            .pipe(
              map((n) => ({
                data: n,
                barcode,
              }))
            );
        }),
        tap(({ data, barcode }) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolInformationSuccess({
              data,
              barcode,
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolInformationFailed({ error: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getPoolInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.getPoolInformationSuccess),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.getApiEndpoint)),
          this.store.pipe(select(StsSelectors.isMassCompany))
        ),
        map(([{ barcode, data }, endpoint, isMassCompany]) => {
          let { synlabId } = data || {};
          // normal user: get count of individuals
          if (!isMassCompany) {
            this.store.dispatch(
              IndividualOrderActions.getPoolCountNormal({ synlabId, poolBarcode: barcode })
            );
          }
          // mass_company: get count of temp order
          else {
            this.store.dispatch(
              IndividualOrderActions.getPoolCountCompany({
                poolBarcode: barcode,
              })
            );
          }
        }),
        catchError((err, caught) => {
          return caught;
        })
      ),
    { dispatch: false }
  );

  getPoolCountNormal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.getPoolCountNormal),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ synlabId, poolBarcode }, endpoint]) => {
          if (!synlabId) {
            return of(null);
          }

          return this.api.getMethod(
            `${endpoint}/api/pool-tests/poolindividualorder/${synlabId}/${poolBarcode}`
          );
        }),
        tap((n: PoolIndividuals) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolCountNormalSuccess({
              data: n,
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolCountNormalFailed({
              error: err,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getPoolCountCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.getPoolCountCompany),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ poolBarcode }, endpoint]) => {
          if (!poolBarcode) {
            return of(null);
          }

          return this.api.getMethod(
            `${endpoint}/api/pool-tests/gettemporaryorders/${poolBarcode}`
          );
        }),
        tap((n: PoolTempOrders[]) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolCountCompanySuccess({
              data: n,
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            IndividualOrderActions.getPoolCountCompanyFailed({
              error: err,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  submitTempOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.submitTempOrder),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ payload }, endpoint]) => {
          return this.api.postMethod(
            `${endpoint}/api/pool-tests/tempindividualorder`,
            payload
          );
        }),
        tap((n) => {
          this.store.dispatch(
            IndividualOrderActions.submitTempOrderSuccess({
              data: n,
            })
          );
        }),
        catchError((err, caught) => {
          let { error } = err;
          let message = error ? error.message : "";
          this.store.dispatch(
            IndividualOrderActions.submitTempOrderFailed({
              message: message,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  checkDuplicateInList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.checkDuplicateInList),
        withLatestFrom(
          this.store.pipe(select(StsSelectors.isMassCompany)),
          this.store.pipe(select(IndividualOrderSelector.getTempOrdersList)),
          this.store.pipe(
            select(IndividualOrderSelector.getIndividualOrdersList)
          )
        ),
        map(([{ synlabId }, isMassCompany, temps, individuals]) => {
          let exists = false;
          if (isMassCompany) {
            exists = temps.includes(synlabId);
          } else {
            exists = individuals.includes(synlabId);
          }

          this.store.dispatch(
            IndividualOrderActions.checkDuplicateInListSuccess({
              value: exists,
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveInputValue$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IndividualOrderActions.saveInputValue),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        mergeMap(([{ id, action, value }, endpoint]) => {
          let payload = {
            textboxName: id,
            textboxValue: value,
            action: action,
            comment: "",
          };

          return this.api.postMethod(
            `${endpoint}/api/pool-tests/logtextbox`,
            payload
          );
        }),
        tap((n) => {
        }),
        catchError((err, caught) => {
          return caught;
        })
      ),
    { dispatch: false }
  );
}
