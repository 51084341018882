import { Component, Inject, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/service/auth/auth.service";
import { UnauthorizePromptService } from "src/app/service/auth/unauthorize-prompt/unauthorize-prompt.service";
import { DOCUMENT } from "@angular/common";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: string;
  pageInput: any;
}

@Component({
  selector: "app-test-result-detail",
  templateUrl: "./test-result-detail.component.html",
  styleUrls: ["./test-result-detail.component.scss"],
})
export class TestResultDetailComponent {
  state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: "",
    sortColumn: "asc",
    sortDirection: "",
    pageInput: 1,
  };

  testData = {
    pageSize: 20,
    totalCount: 21,
    test: [
      {
        testId: "1234",
        testDate: "01/10/15",
        testType: [
          {
            testTypeId: "1111",
            testTypeName: "Full Blood Count",
            testTypeResult: [
              {
                testTypeResultID: "2222",
                parameter: "Feratin",
                actualReading: "101",
                unitOfMeasure: "ng/ml",
                referenceRange: "11-305",
              },
              {
                testTypeResultID: "2223",
                parameter: "Triglycerides",
                actualReading: "0.35-1.5",
                unitOfMeasure: "g/L",
                referenceRange: "11-305",
              },
              {
                testTypeResultID: "2224",
                parameter: "Blood Glucose",
                actualReading: "0.8-14",
                unitOfMeasure: "g/L",
                referenceRange: "11-305",
              },
              {
                testTypeResultID: "2225",
                parameter: "Total Colesterol",
                actualReading: "2.11",
                unitOfMeasure: "g/L",
                referenceRange: "1-3",
              },
            ],
          },
        ],
        laboratory: "SYNLAB Manchester",
        requestingDoctor: "Dr. Angelo Nuñes",
      },
    ],
  };

  nameValue: string;
  apiSubscription: Subscription;
  usersTemp: any;
  patientClaims: any;
  countryVal: string = "HU;hu-HU";
  isPatient;
  queryCont;
  patientArr = [];
  total: number = 0;
  testDetail;
  testDate;
  loading: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private auth: AuthService,
    private unAuth: UnauthorizePromptService,
    private actRoute: ActivatedRoute
  ) {
    this.renderer.removeClass(this.document.body, "init");
    this.auth
      .checkUserAccess(this.actRoute.snapshot.data.allowedUsers || [])
      .subscribe((allowed) => {
        if (!allowed) { this.unAuth.showPopup(); }
      });

    this.load();
  }

  load() {
    this.testDetail = [];

    this.testDetail = this.testData.test[0].testType[0].testTypeResult;
    this.testDate = this.testData.test[0].testDate;
  }

  clear() {
    this.state.searchTerm = "";
    this.load();
  }

  onSelect(userId) {
    this.router.navigate(["/user", userId]);
  }

  get resultRange() {
    if (!this.total) return `${this.total} Result`;
    let start = (this.state.page - 1) * this.state.pageSize + 1;
    let end =
      (this.state.page - 1) * this.state.pageSize + this.testDetail.length;
    if (start == end) return `${start} of ${this.total}`;

    return `${start}-${end} of ${this.total}`;
  }

  get maxPage() {
    return Math.ceil(this.total / this.state.pageSize);
  }

  onInput(evt) {
    let page = 1;
    if (!evt.target.value) {
      page = parseInt(evt.target.value) || 1;
      if (page > this.maxPage) {
        page = this.maxPage;
        evt.target.value = this.maxPage;
        this.state.page = page;
        this.load();
      } else {
        evt.target.value = page;
        this.state.page = page;
        this.load();
      }
    }
  }

  onFocusOut(evt) {
    let page = 1;
    if (evt.target.value === "") {
      evt.target.value = 1;
      this.state.page = page;
      this.load();
    }
  }

  changePage(op) {
    switch (op) {
      case "first": {
        this.state.page = 1;
        this.state.pageInput = this.state.page;
        break;
      }
      case "last": {
        this.state.page = this.maxPage;
        this.state.pageInput = this.state.page;
        break;
      }
      case "prev": {
        this.state.page = this.state.page - 1;
        this.state.pageInput = this.state.page;
        break;
      }
      case "next": {
        this.state.page = this.state.page + 1;
        this.state.pageInput = this.state.page;
        break;
      }
    }
    this.load();
  }

  get listStatus() {
    if (this.loading) return 1;
    else if (!this.loading && !this.testDetail.length && this.state.searchTerm)
      return 2;
    else if (!this.loading && !this.testDetail.length && !this.state.searchTerm)
      return 3;
  }

  onPageChange(evt) {
    this.state.page = evt;
    this.load();
  }
}
