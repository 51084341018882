import { createAction, props } from '@ngrx/store';
import { PoolDetails } from 'src/app/pages/generate-order/g-order/data-model';

export const getPoolDetails = createAction(
  '[PoolIndividualInfo] Get Pool Details',
  props<{ barcode: string }>()
);

export const getPoolDetailsSuccess = createAction(
  '[PoolIndividualInfo] Get Pool Details Success',
  props<{ data: PoolDetails }>()
);

export const getPoolDetailsFailed = createAction(
  '[PoolIndividualInfo] Get Pool Details Failed',
  props<{ error: any }>()
);

export const getPoolParticipants = createAction(
  '[PoolIndividualInfo] Get Pool Participants',
  props<{ barcode: string }>()
);

export const getPoolParticipantsSuccess = createAction(
  '[PoolIndividualInfo] Get Pool Participants Success',
  props<{ data: any }>()
);

export const getPoolParticipantsFailed = createAction(
  '[PoolIndividualInfo] Get Pool Participants Failed',
  props<{ error: any }>()
);

export const clearPoolAndParticipantData = createAction(
  '[PoolIndividualInfo] Clear Pool and Participant Data'
);


export const PoolIndividualInfoActions = {
  getPoolDetails,
  getPoolDetailsSuccess,
  getPoolDetailsFailed,
  getPoolParticipants,
  getPoolParticipantsSuccess,
  getPoolParticipantsFailed,
  clearPoolAndParticipantData
};
