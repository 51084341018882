import { createReducer, on } from "@ngrx/store";
import { SchoolOption } from "src/app/models/school-option.model";
import { ResultActions } from "../actions/results.actions";
import { Results, TestType } from "../models/results.model";

export const resultsFeatureKey = "results";

export interface State {
  testTypeList: TestType[];
  result: Results[];
  totalResultCount: number;
  isResultLoading: boolean;
  loadingShowFileLookup: { [key: string]: boolean | undefined };
  loadingResultLookup: { [key: string]: boolean | undefined };
  labResults: any;
  invoice: any;
  labResultsIsLoading: boolean;
  selectedUser: any;
  showSelectUser: boolean;

  /** school filter state for lolli admin users */
  schoolListError: string;
  schoolListLoading: boolean;
  schoolListQuery: string;
  schoolList: SchoolOption[];
  schoolSelected: SchoolOption;
  /** teacher filter state for lolli admin users*/
  teacherListError: string;
  teacherListLoading: boolean;
  teacherListQuery: string;
  teacherList: SchoolOption[];
  teacherSelected: SchoolOption;

  /** pool filter state for lolli admin users*/
  poolListError: string;
  poolListLoading: boolean;
  poolListQuery: string;
  poolList: SchoolOption[];
  poolSelected: SchoolOption;

  /** record for greenpass. id is testreference, value is guid key */
  greepassRecord: Record<string, string>;
  forceLoadCertId: any;
  isForceLoadCertLoading: boolean;

  companyCheckLoading: boolean;
  companyCheckRecord: Record<string, {
    companyName: string,
    isPoolCompany: boolean
  }>;

  isPoolRecord: Record<string, boolean>;
  labAddresses: Record<string, string>;
  resultAddresses: Record<string, { address: string, passportNumber: string; }>;
}

export const initialState: State = {
  testTypeList: [],
  result: [],
  totalResultCount: 0,
  isResultLoading: false,
  loadingShowFileLookup: {},
  loadingResultLookup: {},
  labResults: {},
  invoice: {},
  labResultsIsLoading: false,
  selectedUser: {},
  showSelectUser: false,

  schoolListError: undefined,
  schoolListLoading: false,
  schoolListQuery: undefined,
  schoolList: [],
  schoolSelected: undefined,

  teacherListError: undefined,
  teacherListLoading: false,
  teacherListQuery: undefined,
  teacherList: [],
  teacherSelected: undefined,

  poolListError: undefined,
  poolListLoading: false,
  poolListQuery: undefined,
  poolList: [],
  poolSelected: undefined,

  greepassRecord: {},
  forceLoadCertId: {},
  isForceLoadCertLoading: false,

  companyCheckLoading: false,
  companyCheckRecord: {},
  isPoolRecord: {},
  labAddresses: {},
  resultAddresses: {}
};

export const reducer = createReducer<State>(
  initialState,
  on(ResultActions.loadTestTypeListSuccess, (state, { data }) => ({
    ...state,
    testTypeList: data,
  })),
  on(ResultActions.loadResultss, (state) => ({
    ...state,
    isResultLoading: true,
  })),
  on(ResultActions.loadResultssSuccess, (state, { data, total, params }) => {
    const { page } = params;
    let computed = [];
    if (page > 1) {
      computed = state.result;
      computed = computed.concat(data);
    } else {
      computed = data;
    }

    return {
      ...state,
      result: computed,
      totalResultCount: total,
      isResultLoading: false,
    };
  }),
  on(ResultActions.loadResultssFailure, (state) => ({
    ...state,
    result: [],
    isResultLoading: false,
  })),
  on(ResultActions.downloadFile, (state, { id }) => ({
    ...state,
    loadingShowFileLookup: { ...state.loadingResultLookup, [id]: true },
  })),
  on(ResultActions.downloadFileSuccess, (state, { id }) => ({
    ...state,
    loadingShowFileLookup: { ...state.loadingResultLookup, [id]: undefined },
  })),
  on(ResultActions.downloadFileFailure, (state, { id }) => ({
    ...state,
    loadingShowFileLookup: { ...state.loadingResultLookup, [id]: undefined },
  })),
  on(ResultActions.loadCheckResults, (state) => ({
    ...state,
    // result: [],
    isResultLoading: true,
    showSelectUser: false,
  })),
  on(
    ResultActions.loadCheckResultsSuccess,
    (state, { list, total, params }) => {
      const { page } = params;
      let computed = [];
      if (page > 1) {
        computed = state.result;
        computed = computed.concat(list);
      } else {
        computed = list;
      }

      return {
        ...state,
        result: computed,
        totalResultCount: total,
        isResultLoading: false,
      };
    }
  ),
  on(ResultActions.loadSelectedUser, (state, { criteria }) => ({
    ...state,
    selectedUser: {
      email: criteria,
    },
    showSelectUser: false,
    // result: [],
  })),
  on(ResultActions.loadSelectedUserSuccess, (state, { data }) => ({
    ...state,
    selectedUser: {
      ...state.selectedUser,
      ...data,
    },
    showSelectUser: data ? true : false,
    // result: [],
  })),
  on(ResultActions.loadSelectedUserFailed, (state, { error }) => ({
    ...state,
    showSelectUser: false,
    // result: [],
  })),
  on(ResultActions.loadSelectedUserSynlabId, (state, { criteria }) => ({
    ...state,
    selectedUser: {
      email: criteria,
    },
    showSelectUser: false,
    // result: [],
  })),
  on(ResultActions.loadSelectedUserSynlabIdSuccess, (state, { data }) => ({
    ...state,
    selectedUser: {
      ...state.selectedUser,
      ...data,
    },
    showSelectUser: data ? true : false,
    // result: [],
  })),
  on(ResultActions.loadSelectedUserSynlabIdFailed, (state, { error }) => ({
    ...state,
    showSelectUser: false,
    // result: [],
  })),
  // on(ResultActions.loadCheckResultsSuccess, (state, { list }) => ({
  //   ...state,
  //   result: list,
  //   isResultLoading: false,
  // })),
  on(ResultActions.loadCheckResultsFailed, (state, { error }) => ({
    ...state,
    result: [],
    isResultLoading: false,
  })),
  on(ResultActions.loadLabResults, (state, { testRef }) => ({
    ...state,
    labResults: { ...state.labResults, [testRef]: true },
  })),
  on(ResultActions.loadLabResultsSuccess, (state, { id }) => ({
    ...state,
    labResults: { ...state.labResults, [id]: undefined },
  })),
  on(ResultActions.loadLabResultsFailed, (state, { id }) => ({
    ...state,
    labResults: { ...state.labResults, [id]: undefined },
  })),

  /**
   * load invoice
   */
  on(ResultActions.loadInvoice, (state, { testRef }) => ({
    ...state,
    invoice: { ...state.invoice, [testRef]: true },
  })),
  on(
    ResultActions.loadInvoiceSuccess,
    ResultActions.loadInvoiceFailed,
    (state, { id }) => ({
      ...state,
      invoice: { ...state.invoice, [id]: undefined },
    })
  ),
  /** load school */
  on(ResultActions.clearLoadSchool, s => ({
    ...s,
    schoolError: undefined,
    schoolListQuery: '',
    schoolListLoading: false,
    schoolList: []
  })),
  on(ResultActions.loadSchool, (state, { params }) => ({
    ...state,
    schoolError: undefined,
    schoolListQuery: params,
    schoolListLoading: true,
    schoolList: []
  })),
  on(ResultActions.loadSchoolSuccess, (state, { data }) => ({
    ...state,
    schoolListLoading: false,
    schoolList: data,
  })),
  on(ResultActions.loadSchoolFailed, (state, { error }) => ({
    ...state,
    schoolListLoading: false,
    schoolListError: error
  })),
  on(ResultActions.selectSchool, (state, {school}) => ({
    ...state,
    schoolSelected: school
  })),
  /** load teacher */
  on(ResultActions.LoadTeacherCancel, (state) => ({
    ...state,
    teacherList: [],
    teacherListError: undefined,
    teacherListLoading: false,
  })),
  on(ResultActions.loadTeacher, (state) => ({
    ...state,
    teacherList: [],
    teacherListError: undefined,
    teacherListLoading: true,
  })),
  on(ResultActions.fetchTeacherSuccess, (state, {data}) => ({
    ...state,
    teacherList: [...state.teacherList, ...data]
  })),
  on(ResultActions.loadTeacherSuccess, (state) => ({
    ...state,
    teacherListError: undefined,
    teacherListLoading: false,
  })),
  on(ResultActions.loadTeacherFailed, (state, {error}) => ({
    ...state,
    teacherListError: error,
    teacherListLoading: false,
  })),
  on(ResultActions.setTeacherQuery, (state, {query}) => ({
    ...state,
    teacherListQuery: query,
  })),
  on(ResultActions.selectTeacher, (state, {teacher}) => ({
    ...state,
    teacherSelected: teacher
  })),
  /** load pool */
  on(ResultActions.LoadPoolCancel, (state) => ({
    ...state,
    poolList: [],
    poolListError: undefined,
    poolListLoading: false,
  })),
  on(ResultActions.loadPool, (state) => ({
    ...state,
    poolList: [],
    poolListError: undefined,
    poolListLoading: true,
  })),
  on(ResultActions.fetchPoolSuccess, (state, {data}) => ({
    ...state,
    poolList: [...state.poolList, ...data]
  })),
  on(ResultActions.loadPoolSuccess, (state) => ({
    ...state,
    poolListError: undefined,
    poolListLoading: false,
  })),
  on(ResultActions.loadPoolFailed, (state, {error}) => ({
    ...state,
    poolListError: error,
    poolListLoading: false,
  })),
  on(ResultActions.setPoolQuery, (state, {query}) => ({
    ...state,
    poolListQuery: query,
  })),
  /** greenpass */
  on(
    ResultActions.loadGreenpassSuccess,
    (state, { result, greenpassQrData }) => ({
      ...state,
      greepassRecord: {
        ...state.greepassRecord,
        [result.testReference]: greenpassQrData,
      },
    })
  ),
  on(ResultActions.forceLoadCertificate, (state, { refId }) => ({
    ...state,
    forceLoadCertId: {
      ...state.forceLoadCertId,
      [refId]: true,
    },
    isForceLoadCertLoading: true,
  })),
  on(ResultActions.stopForceLoading, (state) => ({
    ...state,
    forceLoadCertId: {},
    isForceLoadCertLoading: false,
  })),
  on(ResultActions.loadCompanyCheck , (state) => ({
    ...state,
    companyCheckLoading: true
  })),
  on(ResultActions.fetchCompanyCheckSuccess , (state, action) => ({
    ...state,
    companyCheckRecord: {
      ...state.companyCheckRecord,
      [action.poolSynlabId]: action.companyCheckResponse
    }
  })),
  on(ResultActions.loadCompanyCheckDone , (state) => ({
    ...state,
    companyCheckLoading: false
  })),
  on(ResultActions.checkIfPoolSuccess, (state, action) => ({
    ...state,
    isPoolRecord: {
      ...state.isPoolRecord,
      [action.synlabId]: action.isPool
    }
  })),
  on(ResultActions.getLabAddressSuccess, (state, action) => ({
    ...state,
    labAddresses: {
      ...state.labAddresses,
      [action.senderIdentifierCode]: action.laboratoryAddress
    }
  })),
  on(ResultActions.getResultAddressSuccess, (state, action) => ({
    ...state,
    resultAddresses: {
      ...state.labAddresses,
      [action.synlabId]: <any>action.data
    }
  }))
);
