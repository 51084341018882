import { createAction, props } from '@ngrx/store';
import { Club } from 'src/app/models/club.model';

export const loadClubList = createAction(
  '[HungaryFootball] Load Club list'
);

export const loadClubListSuccess = createAction(
  '[HungaryFootball] Load Club list Success',
  props<{ data: Club[] }>()
);

export const loadClubListFailure = createAction(
  '[HungaryFootball] Load Club list Failure',
  props<{ error: any }>()
);

export const hungaryFootballActions = {
  loadClubList,
  loadClubListSuccess,
  loadClubListFailure
}
