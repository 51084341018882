import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api/api.service';
import { PoolIndividualInfoActions } from '../actions/pool-individual-info.actions';
import { AppState } from '../reducers';
import { StsSelectors } from '../selectors/sts.selectors';



@Injectable()
export class PoolIndividualInfoEffects {

  constructor(private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService) {}

  getPoolDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoolIndividualInfoActions.getPoolDetails),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ barcode }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/abtesting/getbarcodedetails/${barcode}`
            )
            .pipe(
              map((n) => ({
                poolName: n ? n.poolName : "",
                poolBarcode: n.poolBarcode,
                sampleDateTime: dayjs.utc(n.sampleDatetime).toISOString(),
                organization: n.organization
              }))
            );
        }),
        tap((x) => {
          this.store.dispatch(
            PoolIndividualInfoActions.getPoolDetailsSuccess({
              data: x
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            PoolIndividualInfoActions.getPoolDetailsFailed({ error: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getParticipants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PoolIndividualInfoActions.getPoolParticipants),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ barcode }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/abtesting/getorderdetails/simple/${barcode}`
            )
        }),
        tap((x) => {
          this.store.dispatch(
            PoolIndividualInfoActions.getPoolParticipantsSuccess({
              data: x
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            PoolIndividualInfoActions.getPoolParticipantsFailed({ error: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

}
