import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import moment from "moment";

/** Key in translation json for `general` scope. */
export const generalScopeKey = "general";
/** Key in translation json for `forgotPassword` scope. */
export const forgotPasswordScopeKey = "forgotPassword";
/** Key in translation json for `myAccount` scope. */
export const myAccountScopeKey = "myAccount";
/** Key in translation json for `security` scope. */
export const securityScopeKey = "security";
/** Key in translation json for `twoFactorAuthenticator` scope. */
export const twoFactorAuthenticatorScopeKey = "twoFactorAuthenticator";
/** Key in translation json for `verifyProfile` scope. */
export const verifyProfileScopeKey = "verifyProfile";
/** Key in translation json for `completeProfile` scope. */
export const completeProfileScopeKey = "completeProfile";
/** Key in translation json for `registrationSecure` scope. */
export const registrationSecureScopeKey = "registrationSecure";

/** storage key for lang code */
export const langCodeStorageKey = "langCode";

/**
 * Service to manage i18n (active lang, detect preferred lang, stored lang).
 */
@Injectable({ providedIn: "root" })
export class I18nService {
  activeLang$ = this.translocoService.langChanges$;
  constructor(private translocoService: TranslocoService) {
    translocoService.events$.subscribe(console.log.bind(console, 'transloco event'))
  }

  getAvailableLanguages(): string[] {
    return this.translocoService.getAvailableLangs() as string[];
  }
  setStoredLanguage(langCode: string) {
    localStorage?.setItem(langCodeStorageKey, langCode);
    this.translocoService.setActiveLang(langCode);
  }

  detectBrowserLocale() {
    const locale = this.getFromLocalStorage()
      ?? this.translocoService.getDefaultLang()
      ?? this.getPreferredSupportedLanguage();
    this.setActiveLang(locale)
  }

  /** sets active language. Does not save to storage.
   * use `setStoredLanguage` to save to storage.
  */
  private setActiveLang = this.translocoService
    .setActiveLang.bind(this.translocoService);

  private getFromLocalStorage() {
    return this.getAvailableLanguages().indexOf(
      localStorage?.getItem(langCodeStorageKey)
    ) > -1
      ? localStorage?.getItem(langCodeStorageKey)
      : undefined;
  }

  /** get browser user preferred language */
  private getBrowserLanguages(): string[] {
    return (
      navigator.languages.map((x) => x) || [navigator.language] || [
        (navigator as any).userLanguage,
      ] ||
      []
    );
  }
  /** determine preferred language between supported language and
   * browser settings. uses language fallbacks  */
  private getPreferredSupportedLanguage(): string | undefined {
    const preferredLanguages: string[] = this.getBrowserLanguages();
    const supportedLanguages: string[] = this.getAvailableLanguages();
    for (const pl of preferredLanguages) {
      const lang = supportedLanguages.find((sl) => pl === sl);
      if (lang !== undefined) {
        return lang;
      }
    }
    for (const pl of preferredLanguages) {
      const lang = supportedLanguages.find(
        (sl) => this.getLangCode(sl) === this.getLangCode(pl)
      );
      if (lang !== undefined) {
        return lang;
      }
    }
  }
  /** Extract language code from locale code. (ex: 'en' in 'en-US')
   * @param locale string to extract language code from.
   * @Note Naive language code parser.
   * Use in the future: https://www.npmjs.com/package/locale-code
   */
  private getLangCode(locale: string = ""): string {
    return (locale.trim().match(/^\w+/g) || []).shift();
  }

  /** Get date format that can be used by moment */
  public getMomentDateFormatToken() {
    return moment().localeData().longDateFormat("L");
  }

  public getLocalizedMeridiem(meridiem: "AM" | "PM") {
    return moment.localeData().meridiem(meridiem === "AM" ? 11 : 13, 0, false);
  }

  public formatTime(time: string, momentFormat: string = "kk:mm") {
    const mom = moment(time, "kk:mm");
    return mom.format(momentFormat);
  }
}
