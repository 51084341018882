import { createReducer, on } from '@ngrx/store';
import { HeaderActions } from 'src/app/@store/actions/header.actions'
import { RelatedUsers } from 'src/app/models/related-users.model';

export const headerFeatureKey = 'appHeader';

export interface State {
  loadingBar: boolean,
  relatedUsers: RelatedUsers,
  visible: boolean
}

export const initialState: State = {
  loadingBar: true,
  relatedUsers: undefined,
  visible: true
};

export const reducer = createReducer(
  initialState,
  on(HeaderActions.startLoadingBar, (state) => ({
    ...state,
    loadingBar: true
  })),
  on(HeaderActions.endLoadingBar, (state) => ({
    ...state,
    loadingBar: false
  })),
  on(HeaderActions.getRelatedUsersSuccessful, (state, { results }) => ({
    ...state,
    relatedUsers: results
  }))
);

