export const userEditfields = [
  'organizationId',
  'role',
  'synlabId',
  'lastName',
  'firstName',
  'dateOfBirth',
  'gender',
  'personalId',
  'address',
  'city',
  'zipCode',
  'countryOfResidence',
  'parentEmail',
  'parentMobile',
  'mobile',
  'email',
  'companyTester'
];
export const excludeInFilter = ['role','organizationId', 'gender', 'dateOfBirth', 'countryOfResidence', 'companyTester'];
export const excludeSchool = ['mobile', 'email', 'companyTester'];
export const excludeCompany = ['parentEmail', 'parentMobile'];
export const allowedDuplicateClaims = ['user_type'];

export interface Pagination {
  TotalCount: number,
  PageSize: number,
  CurrentPage: number,
  TotalPages: number,
  HasNext: boolean,
  HasPrevious: boolean
}

export const rolesToEdit = ['Student', 'Employee'];

export interface UserRow {
  organizationId: string,
  role: string,
  synlabId: string,
  lastName: string,
  firstName: string,
  dateOfBirth: any,
  gender?: string,
  personalId?: string,
  address: string,
  city?: string,
  zipCode: string,
  countryOfResidence: string,
  parentEmail?: string,
  parentMobile?: string,
  mobile?: string,
  email: string
  companyTester: boolean
}

export const dateFormat = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface UserEditItemResponse {
  userId: string;
  organizationId: string;
  role: string;
  synlabId: string;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  gender: string;
  personalId: string;
  address: string;
  city: string;
  zipCode: string;
  countryOfResidence: string;
  parentEmail: string;
  parentMobile: string;
  mobile: string;
  email: string;
  companyTester: string;
}
