export interface GenerateOtpRequest {
  sms: boolean;
  email: boolean;
  synlabId: string;
  feature: string;
  referenceId: string;
}
/** create local identifier for otp request status */
export function getOtpRequestId(request: GenerateOtpRequest) {
  return request?.referenceId;
}

export type  OtpType = 'sms' | 'email';
export const otpSms: OtpType = 'sms';
export const otpEmail: OtpType = 'email';
