<div data-test-id="language-picker-showOptions" (clickOutside)="showOptions = false">
  <button data-test-id="language-picker-DATA_TEST_ID_Pbtn-linkACEHOLDER" type="button"
    class="btn btn-link decoration-none" (click)="showOptions = !showOptions" [title]="activeLang$ | async">
    <div data-test-id="language-picker-icon-wrap" class="d-flex align-items-center">
      <mat-icon data-test-id="language-picker-icon">language</mat-icon>
    </div>
  </button>
  <ng-container *ngIf="showOptions">
    <div data-test-id="language-picker-language-options"
      class="d-flex flex-column align-items-center position-absolute language-options">
      <button [attr.data-test-id]="'language-picker-changeLanguage-btn'+i" type="button" (click)="changeLanguage(code)"
        *ngFor="let code of langCodes; let i = index" class="btn btn-link btn-language" [title]="code.languageValue"
        [ngClass]="{'selected': (activeLang$ | async) === code.languageValue}">
        <ng-container *ngTemplateOutlet="flagOrCodeTemplate; context: {code: code}"></ng-container>
      </button>
    </div>
  </ng-container>
</div>
<ng-template data-test-id="language-picker-flagOrCodeTemplate2" #flagOrCodeTemplate let-code="code">
  <div data-test-id="language-picker-flag-cont" class="d-flex flex-column align-items-center">
    <span data-test-id="language-picker-flag" [class]="'font-size-3 fp ' + code?.flag"></span>
    <span data-test-id="language-picker-languageValue" class="language-value">{{ code?.languageValue }}</span>
  </div>
</ng-template>
