import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { SingleTestService } from "src/app/service/single-test/single-test.service";
import * as SingleTestActions from "src/app/@store/actions/single-test-actions";
import { AppState } from "src/app/@store/reducers";
import { catchError, map, mergeMap } from "rxjs/operators";
import { getErrorMessage } from "src/app/shared/utils/error.util";
import { of } from "rxjs";

@Injectable()
export class SingleTestEffect {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private singleTestService: SingleTestService
  ) { }
  sendSingleTest$ = createEffect(
    () => this.actions$.pipe(
      ofType(SingleTestActions.sendSingleTest),
      mergeMap(action => this.singleTestService.sendSingleTest(action.data).pipe(
        map(() => SingleTestActions.sendSingleTestSuccess()),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(SingleTestActions.sendSingleTestFailure({ error: msg }))
        })
      ))
    )
  )
}
