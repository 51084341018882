import { createAction, props } from '@ngrx/store';
import { RelatedUsers } from "../../models/related-users.model";

const loadHeaders = createAction(
  '[Header] Load Headers'
);

const openMenu = createAction(
  '[Header] Open Menu'
);

const closeMenu = createAction(
  '[Header] Close Menu'
);

const startLoadingBar = createAction(
  '[Header] Start Loading Bar'
);

const endLoadingBar = createAction(
  '[Header] End Loading Bar'
);

const getRelatedUsers = createAction(
  "[Header] Get Related Users By SynlabId",
  props<{ synlabId: string }>()
);

const getRelatedUsersSuccessful = createAction(
  "[Header] Get Related Users By SynlabId Successful",
  props<{results: RelatedUsers}>(),
);

export const HeaderActions = {
  loadHeaders,
  openMenu,
  closeMenu,
  startLoadingBar,
  endLoadingBar,
  getRelatedUsers,
  getRelatedUsersSuccessful
};
