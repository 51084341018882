import { createReducer, on } from "@ngrx/store";
import { Claim } from "src/app/models/claims.model";
import { EnvConfig } from "src/app/service/config/config.service";
import { isGenePlanetEnrolled } from "src/app/shared/utils/geneplanet.utils";
import { STSActions } from "../actions/sts.actions";

export const stsFeatureKey = "sts";

export interface State {
  role: string[];
  name: string;
  country: string;
  email: string;
  config: EnvConfig;
  token: any;
  given_name: string;
  family_name: string;
  sub: string;
  synlab_id: string;
  claims: Claim[];
  claimsLoading: boolean;
  claimsError: string;
  profile: Claim[];
  idp: string;

  /** store for previously fetched claims */
  fetchedClaimRecord: Record<string, Claim[]>;
  redirectUrl?: string;

  // Geneplanet
  hasGeneplanetOrders: boolean | null;
  isGenePlanetEnrolled: boolean | null;
}

export const initialState: State = {
  role: [],
  name: null,
  country: null,
  email: "",
  config: null,
  token: null,
  given_name: null,
  family_name: null,
  sub: null,
  synlab_id: null,
  claims: [],
  claimsLoading: false,
  claimsError: "",
  profile: null,
  idp: null,

  fetchedClaimRecord: {},

  hasGeneplanetOrders: null,
  isGenePlanetEnrolled: null,

};

export const reducer = createReducer<State>(
  initialState,
  on(STSActions.loadStssSuccess, (state, { data }) => ({
    ...state,
    ...data,
  })),
  on(STSActions.loadConfigSuccess, (state, { data }) => ({
    ...state,
    config: data,
  })),
  on(STSActions.loadClaims, (state) => ({
    ...state,
    claimsLoading: true,
    claimsError: "",
  })),
  on(STSActions.loadClaimsSuccess, (state, action) => ({
    ...state,
    claimsLoading: false,
    claims: action.claims,
    isGenePlanetEnrolled: isGenePlanetEnrolled(action.claims),
  })),
  on(STSActions.loadClaimsFailure, (state, action) => ({
    ...state,
    claimsLoading: false,
    claimsError: action.error,
  })),
  on(STSActions.loadProfileSuccess, (state, { claims }) => ({
    ...state,
    profile: claims,
    claimsLoading: false,
    claims: claims,
    isGenePlanetEnrolled: isGenePlanetEnrolled(claims),
  })),
  on(STSActions.loadProfileFailed, (state, { error }) => ({
    ...state,
    claimsLoading: false,
    claimsError: error,
    profile: null,
  })),
  on(STSActions.loadSingleClaimSuccess, (state, action) => ({
    ...state,
    fetchedClaimRecord: {
      ...state.fetchedClaimRecord,
      [action.synlabId]: action.claims,
    },
  })),
  on(STSActions.setRedirectUrl, (state, { path }) => ({
    ...state,
    redirectUrl: path,
  })),
  on(STSActions.restoreRedirectUrl, (state, { path }) => ({
    ...state,
    redirectUrl: path,
  })),
  on(STSActions.setHasGeneplanetOrders, (state, { hasOrders }) => ({
    ...state,
    hasGeneplanetOrders: hasOrders
  })),
);
