import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { take, tap } from "rxjs/operators";
import { UnauthorizePromptService } from "../auth/unauthorize-prompt/unauthorize-prompt.service";

@Injectable({
  providedIn: "root",
})
export class UserTypeGuard  {
  constructor(
    private authService: AuthService,
    private notAuth: UnauthorizePromptService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isPatient$.pipe(
      take(1),
      tap((isPatient) => (!isPatient ? this.notAuth.showPopup() : undefined))
    );
  }
}
