export const StsScopes = [
  "offline_access",
  "IdentityServerApi",
  "ResultsApi",
  "user_type",
  "address",
  "email",
  "profile",
  "openid",
  "api",
  "synlab_id",
  "mobileapi",
  "ResultsApiV2"
];
