import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent  {
  @Input() page: number = 1;
  @Input() pageSize: number = 20;
  @Input() total: number = 0;
  @Input() resultCount: number = 0;
  @Output() pageChange = new EventEmitter();


  get maxPage() {
    return Math.ceil(this.total / this.pageSize);
  }

  get resultRange() {
    if (!this.total) return `${this.total} Result`;
    let start = (this.page - 1) * this.pageSize + 1;
    let end = (this.page - 1) * this.pageSize + this.resultCount;
    if (start == end) return `${start} of ${this.total}`;

    return `${start}-${end} of ${this.total}`;
  }

  changePage(op) {
    switch (op) {
      case "first": {
        this.page = 1;
        break;
      }
      case "last": {
        this.page = this.maxPage;
        break;
      }
      case "prev": {
        this.page = this.page - 1;
        break;
      }
      case "next": {
        this.page = this.page + 1;
        break;
      }
    }
    this.pageChange.emit(this.page);
  }

}
