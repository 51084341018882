import {  createReducer, on } from '@ngrx/store';
import * as preventionWellnessActions from 'src/app/@store/actions/prevention-wellness.actions'
import { HeaderActions } from 'src/app/@store/actions/header.actions'

export const preventionWellnessFeatureKey = 'preventionWellness';

export interface State {
  showMenu: boolean;
  narrowView: boolean;
  hideModule: boolean;
}

export const initialState: State = {
  showMenu: true,
  narrowView: false,
  hideModule: false
};

export const reducer = createReducer(
  initialState,
  on(preventionWellnessActions.setNarrowViewTrue, (state => ({
    ...state,
    narrowView: true,
    showMenu: false
  }))),
  on(preventionWellnessActions.setNarrowViewFalse, (state => ({
    ...state,
    narrowView: false,
    showMenu: true
  }))),
  on(preventionWellnessActions.setMenuStateTrue, (state => ({
    ...state,
    showMenu: true
  }))),
  on(preventionWellnessActions.setMenuStateFalse, (state => ({
    ...state,
    showMenu: false
  }))),
  on(HeaderActions.openMenu, (state => ({
    ...state,
    hideModule: true
  }))),
  on(HeaderActions.closeMenu, (state => ({
    ...state,
    hideModule: false
  })))
);

