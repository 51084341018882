import { createAction, props } from '@ngrx/store';
import { Claim } from 'src/app/models/claims.model';

const key = `[Profile]`;

export const loadClaims = createAction(
  `${key} Load claims`,
  props<{ synlabId: string }>()
);
export const loadClaimsSuccess = createAction(
  `${key} Load claims success`,
  props<{ synlabId: string, claims: Claim[] }>()
);
export const loadClaimsFailure = createAction(
  `${key} Load claims failure`,
  props<{ synlabId: string, error: string, errorObject: unknown }>()
);
