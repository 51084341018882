import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-success-message",
  templateUrl: "./success-message.component.html",
  styleUrls: ["./success-message.component.scss"],
})
export class SuccessMessageComponent {
  constructor(private _snackBar: MatSnackBar) {}

  close() {
    this._snackBar.dismiss();
  }
}
