import dayjs from "dayjs";
import { Results } from "src/app/@store/models/results.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface CompanyPoolForEmployeeData extends BaseCertData {
  isCompanyPoolForEmployee: true;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  barcode: string;
  poolName: string;
  resultComment: string;
  laboratory: string;
  laboratoryAddress: string;
}
/** result is for pool and is negative and current user is employee */
export const isCompanyPoolForEmployeeResult: ConditionChecker = (r, o) => {
  const isCompanyPoolItem = o.companyCheckRecord[r.synlabId];
  return isCompanyPoolItem?.isPoolCompany && o.userIsCompanyEmployee && r.synlabValue === '1';
}
export const resultAsCompanyPoolForEmployeeCertData: ResultMapper<Results, CompanyPoolForEmployeeData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
  ...baseResultAsCertData(r,o),
  isCompanyPoolForEmployee: true,
  firstName: customUppercase(o.userPatientInfo?.firstName?.trim?.()),
  lastName: customUppercase(o.userPatientInfo?.lastName?.trim?.()),
  dateOfBirth: dayjs.tz(o.userPatientInfo?.dateOfBirth, "Europe/Berlin").format("DD.MM.YYYY"),
  barcode: Array.isArray(r.testTubeIdentifiers)
    ? r.testTubeIdentifiers.join(', ')
      : r.testTubeIdentifiers,
  poolName: customUppercase(r?.firstName?.trim?.()),
  resultComment: o.translations?.[tranKey]?.['companyPoolMessage'],
  laboratory: r.laboratory,
  laboratoryAddress: o.labAddress[r.senderIdentifierCode]
  }
}
