import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private config: ConfigService, private http: HttpClient) {}

  private setHttpHeaders(headers?: any) {
    return new HttpHeaders(headers);
  }

  get<T = any>(endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(`${this.config.baseAuthUrl}${endpoint}`, {
      params: params ? params : {},
    });
  }

  resultsGet<T = any>(endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(`${this.config.baseResultsUrl}${endpoint}`, {
      params: params ? params : {},
    });
  }

  post(endpoint: string, body: Object = {}, options?: Object): Observable<any> {
    return this.http.post(`${this.config.baseAuthUrl}${endpoint}`, body, options);
  }

  put(endpoint: string, body: Object = {}, options?: Object, stringify:boolean = true): Observable<any> {
    return this.http.put(
      `${this.config.baseAuthUrl}${endpoint}`,
      stringify ? JSON.stringify(body) : body,
      options
    );
  }

  delete(endpoint: string): Observable<any> {
    return this.http.delete(`${this.config.baseAuthUrl}${endpoint}`);
  }

  getFile<T = any>(
    endpoint: string,
    params?: any,
    headers?: any
  ): Observable<any> {
    return this.http.get(endpoint, {
      params: params ? params : {},
      headers: this.setHttpHeaders(headers),
      responseType: "text",
      observe: "response",
    });
  }

  getFileBlob<T = any>(
    endpoint: string,
    params?: any,
    headers?: any,
  ) {
    return this.http.get(endpoint, {
      params: params ? params : {},
      headers: this.setHttpHeaders(headers),
      responseType: "blob",
      observe: "response",
    });
  }

  getMethod<T = any>(endpoint: string, params?: any): Observable<T> {
    return this.http.get<T>(`${endpoint}`, {
      params: params ? params : {},
    });
  }

  getForResponseTypeText<T = any>(endpoint: string, params?: any): Observable<any> {
    return this.http.get(endpoint, {
      params: params ? params : {},
      responseType: "text",
    });
  }

  getMethodResponse<T = any>(endpoint: string, params?: any) {
    return this.http.get<T>(`${endpoint}`, {
      observe: 'response',
      params: params ? params : {},
    });
  }

  postMethod<T = any>(endpoint: string, body: Object = {}): Observable<any> {
    return this.http.post(`${endpoint}`, body);
  }

  putMethod(endpoint: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${endpoint}`,
      JSON.stringify(body)
    );
  }
  // needed because the other putmethod object stringifies the payload
  putMethodObject(endpoint: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${endpoint}`,
      body
    );
  }

  checkAuth() {
    return this.http.get(`/api/auth/check`);
  }
}
