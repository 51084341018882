<div data-test-id="user-info-card-info-card" class="user-info-card pt-3">
  <h3 data-test-id="user-info-card-name">{{ user.name }}</h3>
  <div data-test-id="user-info-card-card-info" class="user-info-card-info">
    <strong
      data-test-id="user-info-card-strong">{{'checkResult.selectUserInformation.synlabIdLabel'|transloco}}:</strong>
    <span data-test-id="user-info-card-synlabId" class="text-truncate" [title]="user.synlabId ">{{ user.synlabId
      }}</span>
    <strong
      data-test-id="user-info-card-emailLabel">{{'checkResult.selectUserInformation.emailLabel'|transloco}}:</strong>
    <span data-test-id="user-info-card-email" class="text-truncate" [title]="user.email">{{ user.email }}</span>
  </div>
  <button data-test-id="user-info-card-close" class="close">
    <img data-test-id="user-info-card-close-icon" (click)="close()" src="/assets/images/primary-close-icon.svg"
      alt="primary-close-icon" />
  </button>
  <button data-test-id="user-info-card-close-button" class="close-button" (click)="close()">
    {{'resultsV2.filter.close'|transloco}}
  </button>
</div>
