import { createReducer, on } from '@ngrx/store';
import { EmployeeData, employeeFields, GetTempUserListPagination, StudentData, studentFields, TempUserGetParams, UploadHistoryItem, UserStatusResponse, UserUploadFileType } from 'src/app/models/user-create.model';
import * as UserCreateActions from 'src/app/@store/actions/user-create.actions';

export const userCreateFeatureKey = 'userCreate';

export interface State {
  studentFields: string[];
  employeeFields: string[];
  waitingForStatusUpdate: boolean;
  statusPollOn: boolean;
  statusLoading: boolean;
  statusGetError: string;
  userStatus: UserStatusResponse;
  /** csv is currently being uploaded */
  uploading: boolean;
  uploadingError: string;
  uploadingSuccess: boolean;

  tempStudentList: StudentData[];
  tempEmployeeList: EmployeeData[];
  tempUserListLoading: boolean;
  tempStudentGetRecord: Record<string, StudentData>;
  tempEmployeeGetRecord: Record<string, EmployeeData>;
  tempUserGetLoadingRecord: Record<string, boolean>;
  tempUserGetErrorRecord: Record<string, string>;
  tempUserPage: number;
  tempUserPageSize: number;
  tempUserRequestParams: TempUserGetParams;
  tempUserRequestPagination: GetTempUserListPagination;
  tempUserListError: string;
  changePageLoading: boolean;
  changePageError: string;

  cancelLoading: boolean;
  cancelError: string;

  currentListExportLoading: boolean;
  currentListExportError: string;

  processAllLoading: boolean;
  processAllError: string;

  /** id is `id` of student/employee */
  tempUserUpdateLoadingRecord: Record<string, boolean>;
  /** id is `id` of student/employee */
  tempUserUpdateErrorRecord: Record<string, string>;
  /** id is `id` of student/employee */
  tempUserDeleteLoadingRecord: Record<string, boolean>;
  /** id is `id` of student/employee */
  tempUserDeleteErrorRecord: Record<string, string>;

  historyList: UploadHistoryItem[];
  historyLoading: boolean;
  historyError: string;
  /** id is `fileId` of history item  */
  historyDownloadingRecord: Record<number, boolean>;
  historyDownloadErrorRecord: Record<number, string>;
  /** record for download status of upload files.
   * key is `fileId` of history item. */
  historyUploadDownloadingRecord: Record<number, boolean>;
  /** record for download error of upload files.
   * key is `fileId` of history item. */
  historyUploadDownloadErrorRecord: Record<number, string>;
}

export const initialState: State = {
  studentFields: studentFields,
  employeeFields: employeeFields,
  statusPollOn: false,
  waitingForStatusUpdate: false,
  statusLoading: false,
  statusGetError: undefined,
  userStatus: undefined,
  uploading: false,
  uploadingError: undefined,
  uploadingSuccess: false,
  tempStudentList: [],
  tempEmployeeList: [],
  tempUserListLoading: false,
  tempStudentGetRecord: {},
  tempEmployeeGetRecord: {},
  tempUserGetLoadingRecord: {},
  tempUserGetErrorRecord: {},
  tempUserPage: undefined,
  tempUserPageSize: 10,
  tempUserRequestParams: undefined,
  tempUserRequestPagination: undefined,
  tempUserListError: undefined,
  changePageLoading: false,
  changePageError: undefined,

  cancelLoading: false,
  cancelError: undefined,

  currentListExportLoading: false,
  currentListExportError: undefined,

  processAllLoading: false,
  processAllError: undefined,

  tempUserUpdateLoadingRecord: {},
  tempUserUpdateErrorRecord: {},
  tempUserDeleteLoadingRecord: {},
  tempUserDeleteErrorRecord: {},

  historyList: [],
  historyLoading: false,
  historyError: undefined,
  historyDownloadingRecord: {},
  historyDownloadErrorRecord: undefined,
  historyUploadDownloadingRecord: {},
  historyUploadDownloadErrorRecord: undefined
}

export const reducer = createReducer<State>(
  { ...initialState },
  on(
    UserCreateActions.resetUserCreateState,
    () => ({ ...initialState })
  ),
  on(
    UserCreateActions.getUserUploadStatus,
    (state) => ({
      ...state,
      statusLoading: true,
      statusGetError: undefined
    })
  ),
  on(
    UserCreateActions.getUserUploadStatusSuccess,
    (state, { status }) => ({
      ...state,
      statusLoading: false,
      statusGetError: undefined,
      userStatus: status
    })
  ),
  on(
    UserCreateActions.getUserUploadStatusFailure,
    (state, { error }) => ({
      ...state,
      statusLoading: true,
      statusGetError: error
    })
  ),
  on(
    UserCreateActions.getUserUploadStatusFailure,
    (state, { error }) => ({
      ...state,
      statusLoading: true,
      statusGetError: error
    })
  ),
  on(
    UserCreateActions.startUserUploadStatusPoll,
    (state) => ({
      ...state,
      statusPollOn: true
    })
  ),
  on(
    UserCreateActions.stopUserUploadStatusPoll,
    (state) => ({
      ...state,
      statusPollOn: false
    })
  ),
  on(
    UserCreateActions.uploadTempUserCsv,
    (state) => ({
      ...state,
      uploading: true,
      uploadingError: undefined,
      uploadingSuccess: false,
    })
  ),
  on(
    UserCreateActions.uploadTempUserCsvSuccess,
    (state) => ({
      ...state,
      uploading: false,
      uploadingError: undefined,
      uploadingSuccess: true,
    })
  ),
  on(
    UserCreateActions.uploadTempUserCsvFailure,
    (state, { error }) => ({
      ...state,
      uploading: false,
      uploadingError: error,
      uploadingSuccess: false
    })
  ),
  on(
    UserCreateActions.getTempUserList,
    (state, { type, ...params }) => ({
      ...state,
      tempUserListLoading: true,
      tempUserListError: undefined,
      tempUserRequestParams: params,
      tempUserPage: 1,
      tempUserRequestPagination: undefined,
    })
  ),
  on(
    UserCreateActions.getTempStudentListSuccess,
    (state, { data, pagination }) => ({
      ...state,
      tempStudentList: data,
      tempUserPageSize: pagination?.pageSize
    })
  ),
  on(
    UserCreateActions.getTempEmployeeListSuccess,
    (state, { data }) => ({
      ...state,
      tempEmployeeList: data
    })
  ),
  on(
    UserCreateActions.getTempStudentListSuccess,
    UserCreateActions.getTempEmployeeListSuccess,
    (state, { data, pagination }) => ({
      ...state,
      tempUserListLoading: false,
      tempUserListError: undefined,
      tempUserRequestPagination: pagination
    })
  ),
  on(
    UserCreateActions.getTempStudentListFailure,
    UserCreateActions.getTempEmployeeListFailure,
    (state, { error }) => ({
      ...state,
      tempUserListLoading: false,
      tempUserListError: error,
    })
  ),
  on(
    UserCreateActions.setPageSize,
    (state, { size }) => ({
      ...state,
      tempUserPageSize: size
    })
  ),
  on(UserCreateActions.changePage, state => ({...state, changePageLoading: true})),
  on(
    UserCreateActions.changePageSuccess,
    (state) => ({
      ...state,
      changePageLoading: true
    })
  ),
  on(
    UserCreateActions.changePageSuccess,
    (state, {newPagination, studentList, employeeList}) => ({
      ...state,
      changePageLoading: false,
      tempUserPage: newPagination?.currentPage,
      tempUserRequestPagination: newPagination,
      tempStudentList: state?.userStatus?.fileType === UserUploadFileType.LolliStudent
        ? studentList
        : state?.tempStudentList,
      tempEmployeeList: state?.userStatus?.fileType === UserUploadFileType.LolliEmployee
        ? employeeList
        : state?.tempEmployeeList,
    })
  ),
  on(
    UserCreateActions.changePageFailure,
    (state, {error}) => ({
      ...state,
      changePageLoading: false
    })
  ),
  on(
    UserCreateActions.cancelUserCreation,
    (state) => ({
      ...state,
      cancelLoading: true,
      cancelError: undefined,
    })
  ),
  on(
    UserCreateActions.cancelUserCreationSuccess,
    (state) => ({
      ...state,
      cancelLoading: false,
      cancelError: undefined,
    })
  ),
  on(
    UserCreateActions.cancelUserCreationFailure,
    (state, { error }) => ({
      ...state,
      cancelLoading: false,
      cancelError: error,
    })
  ),
  on(
    UserCreateActions.exportCurrentList,
    (state) => ({
      ...state,
      currentListExportLoading: true,
      currentListExportError: undefined,
    })
  ),
  on(
    UserCreateActions.exportCurrentListSuccess,
    (state) => ({
      ...state,
      currentListExportLoading: false,
      currentListExportError: undefined,
    })
  ),
  on(
    UserCreateActions.exportCurrentListFailure,
    (state, { error }) => ({
      ...state,
      currentListExportLoading: false,
      currentListExportError: error,
    })
  ),
  on(
    UserCreateActions.processUserCreation,
    (state) => ({
      ...state,
      processAllLoading: true,
      processAllError: undefined,
    })
  ),
  on(
    UserCreateActions.processUserCreationSuccess,
    (state) => ({
      ...state,
      processAllLoading: false,
      processAllError: undefined,
    })
  ),
  on(
    UserCreateActions.processUserCreationFailure,
    (state, { error }) => ({
      ...state,
      processAllLoading: false,
      processAllError: error,
    })
  ),
  on(
    UserCreateActions.getTempStudentInfo,
    UserCreateActions.getTempEmployeeInfo,
    (state, action) => ({
      ...state,
      tempUserGetLoadingRecord: {
        ...state.tempUserGetLoadingRecord,
        [action.id]: true
      }
    })
  ),
  on(
    UserCreateActions.getTempStudentInfoSuccess,
    UserCreateActions.getTempEmployeeInfoSuccess,
    UserCreateActions.getTempStudentInfoFailure,
    UserCreateActions.getTempEmployeeInfoFailure,
    (state, action) => ({
      ...state,
      tempUserGetLoadingRecord: {
        ...state.tempUserGetLoadingRecord,
        [action.id]: false
      }
    })
  ),
  on(
    UserCreateActions.getTempStudentInfoSuccess,
    (state, action) => ({
      ...state,
      tempStudentGetRecord: {
        ...state.tempStudentGetRecord,
        [action.id]: action.data
      }
    })
  ),
  on(
    UserCreateActions.getTempEmployeeInfoSuccess,
    (state, action) => ({
      ...state,
      tempEmployeeGetRecord: {
        ...state.tempEmployeeGetRecord,
        [action.id]: action.data
      }
    })
  ),
  on(
    UserCreateActions.getTempStudentInfoFailure,
    UserCreateActions.getTempEmployeeInfoFailure,
    (state, action) => ({
      ...state,
      tempUserGetErrorRecord: {
        ...state.tempUserGetErrorRecord,
        [action.id]: action.error
      }
    })
  ),
  on(
    UserCreateActions.updateStudentRecord,
    UserCreateActions.updateEmployeeRecord,
    (state, { data }) => ({
      ...state,
      tempUserUpdateLoadingRecord: {
        ...state.tempUserUpdateLoadingRecord,
        [data.id]: true
      },
      tempUserUpdateErrorRecord: {
        ...state.tempUserUpdateErrorRecord,
        [data.id]: undefined
      },
    })
  ),
  on(
    UserCreateActions.updateStudentRecordSuccess,
    UserCreateActions.updateEmployeeRecordSuccess,
    (state, { data }) => ({
      ...state,
      tempUserUpdateLoadingRecord: {
        ...state.tempUserUpdateLoadingRecord,
        [data.id]: false
      },
      tempUserUpdateErrorRecord: {
        ...state.tempUserUpdateErrorRecord,
        [data.id]: undefined
      },
    })
  ),
  on(
    UserCreateActions.updateStudentRecordFailure,
    UserCreateActions.updateEmployeeRecordFailure,
    (state, { data, error }) => ({
      ...state,
      tempUserUpdateLoadingRecord: {
        ...state.tempUserUpdateLoadingRecord,
        [data.id]: false
      },
      tempUserUpdateErrorRecord: {
        ...state.tempUserUpdateErrorRecord,
        [data.id]: error
      },
    })
  ),
  on(
    UserCreateActions.updateStudentRecordSuccess,
    (state, { data }) => {
      const clone = state.tempStudentList.slice();
      const index = clone.findIndex(x => x.id === data.id);
      clone.splice(index, 1, data);
      return {
        ...state,
        tempStudentList: clone
      };
    }
  ),
  on(
    UserCreateActions.updateEmployeeRecordSuccess,
    (state, { data }) => {
      const clone = state.tempEmployeeList.slice();
      const index = clone.findIndex(x => x.id === data.id);
      clone.splice(index, 1, data);
      return {
        ...state,
        tempEmployeeList: clone
      };
    }
  ),
  on(
    UserCreateActions.deleteTempUserRecord,
    (state, { id }) => ({
      ...state,
      tempUserDeleteLoadingRecord: {
        ...state.tempUserDeleteLoadingRecord,
        [id]: true
      },
      tempUserDeleteErrorRecord: {
        ...state.tempUserDeleteErrorRecord,
        [id]: undefined
      },
    })
  ),
  on(
    UserCreateActions.deleteTempUserRecordSuccess,
    (state, { id }) => ({
      ...state,
      tempUserDeleteLoadingRecord: {
        ...state.tempUserDeleteLoadingRecord,
        [id]: false
      },
      tempUserDeleteErrorRecord: {
        ...state.tempUserDeleteErrorRecord,
        [id]: undefined
      },
    })
  ),
  on(
    UserCreateActions.deleteTempUserRecordFailure,
    (state, { id, error }) => ({
      ...state,
      tempUserDeleteLoadingRecord: {
        ...state.tempUserDeleteLoadingRecord,
        [id]: false
      },
      tempUserDeleteErrorRecord: {
        ...state.tempUserDeleteErrorRecord,
        [id]: error
      },
    })
  ),
  on(
    UserCreateActions.getUserCreateHistory,
    (state) => ({
      ...state,
      historyLoading: true,
    })
  ),
  on(
    UserCreateActions.getUserCreateHistorySuccess,
    (state, { data }) => ({
      ...state,
      historyLoading: false,
      historyList: data,
    })
  ),
  on(
    UserCreateActions.getUserCreateHistoryFailure,
    (state, { error }) => ({
      ...state,
      historyLoading: false,
    })
  ),
  on(
    UserCreateActions.downloadResultCsv,
    (state, { fileId }) => ({
      ...state,
      historyDownloadingRecord: {
        ...state.historyDownloadingRecord,
        [fileId]: true
      },
      historyDownloadErrorRecord: {
        ...state.historyDownloadErrorRecord,
        [fileId]: undefined
      }
    })
  ),
  on(
    UserCreateActions.downloadResultCsvSuccess,
    (state, { fileId }) => ({
      ...state,
      historyDownloadingRecord: {
        ...state.historyDownloadingRecord,
        [fileId]: false
      },
      historyDownloadErrorRecord: {
        ...state.historyDownloadErrorRecord,
        [fileId]: undefined
      }
    })
  ),
  on(
    UserCreateActions.downloadResultCsvFailure,
    (state, { fileId, error }) => ({
      ...state,
      historyDownloadingRecord: {
        ...state.historyDownloadingRecord,
        [fileId]: false
      },
      historyDownloadErrorRecord: {
        ...state.historyDownloadErrorRecord,
        [fileId]: error
      }
    })
  ),
  on(
    UserCreateActions.downloadUploadCsv,
    (state, { fileId }) => ({
      ...state,
      historyUploadDownloadingRecord: {
        ...state.historyUploadDownloadingRecord,
        [fileId]: true
      },
      historyUploadDownloadErrorRecord: {
        ...state.historyUploadDownloadErrorRecord,
        [fileId]: undefined
      }
    })
  ),
  on(
    UserCreateActions.downloadUploadCsvSuccess,
    (state, { fileId }) => ({
      ...state,
      historyUploadDownloadingRecord: {
        ...state.historyUploadDownloadingRecord,
        [fileId]: false
      },
      historyUploadDownloadErrorRecord: {
        ...state.historyUploadDownloadErrorRecord,
        [fileId]: undefined
      }
    })
  ),
  on(
    UserCreateActions.downloadUploadCsvFailure,
    (state, { fileId, error }) => ({
      ...state,
      historyUploadDownloadingRecord: {
        ...state.historyUploadDownloadingRecord,
        [fileId]: false
      },
      historyUploadDownloadErrorRecord: {
        ...state.historyUploadDownloadErrorRecord,
        [fileId]: error
      }
    })
  ),
  on(
    UserCreateActions.resetUploadSuccessFlag,
    (state) => ({
      ...state,
      uploadingSuccess: false,
      uploadingError: undefined
    })
  )
  )
