import { Pipe, PipeTransform } from "@angular/core";
import { ConfigService } from "src/app/service/config/config.service";

@Pipe({
  name: "assetLink",
})
export class AssetLinkPipe implements PipeTransform {
  get assetsLink() { return this.config._envConfig.basePlasmaUrl; }
  constructor(private config: ConfigService) {}
  transform(value: unknown): string {
    if (typeof value === "string") {
      const p = this.assetsLink + value;
      return p;
    }
    return "";
  }
}
