import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map, tap, filter, switchMap, debounceTime, share, catchError, withLatestFrom } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR , UntypedFormControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/@store/reducers';
import { ApiService } from 'src/app/service/api/api.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserInfoCardComponent } from '../../misc/user-info-card/user-info-card.component';
import { SetFilterAction } from '../../../model/enum/set-filter-action.enum';
import { ResultsV2FilterNotifierService } from '../../../service/results-v2-filter-notifier.service';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';

export interface User {
  name: string;
  email: string;
  synlabId: string;
}

@Component({
  selector: 'app-results-v2-people-filter',
  templateUrl: './results-v2-people-filter.component.html',
  styleUrls: ['./results-v2-people-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResultsV2PeopleFilterComponent),
      multi: true
    }
  ]
})
export class ResultsV2PeopleFilterComponent implements OnInit, ControlValueAccessor {

  @Input() label: string;
  @Input() fullUser: boolean = false;

  searchControl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  selected?: User;

  fetching = false;
  message: string;

  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private t: TranslocoService,
    private dialog: MatDialog,
    private filterNotifierService:ResultsV2FilterNotifierService,
  ) { }

  ngOnInit() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      debounceTime(200),
      filter(search => typeof search === 'string'),
      map(search => encodeURIComponent(search.trim())),
      filter(search => search.length > 2),
      tap(() => { this.fetching = true; }),
      withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
      switchMap(([searchText, endpoint]) => {
        return this.api.getMethod(endpoint + '/api/user/search', { searchText, resultCount: 20 })
          .pipe(catchError(() => {
            this.fetching = false;
            this.message = this.t.translate('error.ERROR__INTERNALSERVER');
            this.searchControl.setErrors({ errorServer: true });
            this.searchControl.markAsTouched();
            return EMPTY;
          }))
      }),
      share()
    );

    this.filteredOptions
      .subscribe(
      (options) => {
        this.fetching = false;
        if (options.length == 0) {
          this.message = this.t.translate('userEditor.noUsersFound');
          this.searchControl.setErrors({ noResults: true });
          this.searchControl.markAsTouched();
        } else {
          this.message = null;
        }
      }
    )
  }

  displayFn(user: User): string {
    if (user?.name) {
      return user.name.trim().length > 0 ? user.name : user.synlabId
    }
    return '';
  }

  writeValue(input: any): void {
    this.searchControl.setValue(input)
  }

  onChange: any = () => {}
  onTouch: any = () => {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  select(user?: User) {
    this.selected = user;
    this.onTouch();
    const value = this.fullUser ? user : user?.synlabId
    this.onChange(value);
  }

  clear() {
    this.select(null);
    this.searchControl.setValue('');
    this.message = null;
    this.filterNotifierService.setFilterOptions({ action: SetFilterAction.RESET });
  }

  showDetail() {
    this.dialog.open(UserInfoCardComponent, {
      minWidth: '365px',
      height: '232px',
      panelClass: 'user-info-card-panel',
      data: this.selected
    })
  }

}
