import { RegtestEffects } from "./regtest.effects";
import { ResultsEffects } from "./results.effects";
import { ResultsV2Effects } from "./resultsv2.effects";
import { StsEffects } from "./sts.effects";
import { LabOrderEffects } from "./lab-order.effects";
import { IndividualOrderEffects } from "../individual-orders/individual-order.effects";
import { HungaryFootballEffects } from "./hungary-football.effects";
import { AbTestingEffects } from "./ab-testing.effects";
import { PoolOrderEffects } from "./pool-order.effects"
import { PoolIndividualInfoEffects } from "./pool-individual-info.effects";
import { ParentChildViewResultEffects } from "./parent-child-view-result.effects";
import { OtpEffects } from "./otp.effect";
import { UserEditEffects } from "./user-edit.effects";
import { UserCreateEffects } from "./user-create.effect";
import { SingleTestEffect } from 'src/app/@store/effects/single-test.effects';
import { ProfileEffects } from 'src/app/@store/effects/profile.effects';
import { HeaderEffects } from "./header.effects";

export const effects: any[] = [
  ResultsEffects,
  ResultsV2Effects,
  StsEffects,
  RegtestEffects,
  LabOrderEffects,
  IndividualOrderEffects,
  HungaryFootballEffects,
  AbTestingEffects,
  PoolOrderEffects,
  PoolIndividualInfoEffects,
  ParentChildViewResultEffects,
  OtpEffects,
  UserEditEffects,
  UserCreateEffects,
  SingleTestEffect,
  ProfileEffects,
  HeaderEffects,
];
