import { claimTypes, claimValue } from "src/app/appsettings";
import { Claim } from "src/app/models/claims.model";
import { ApiService } from "src/app/service/api/api.service";
import { GpEnrollee } from "src/app/shared/components/gp-agreement-popup/gp-enrollee.model";
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store, select } from "@ngrx/store";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { STSActions } from "src/app/@store/actions/sts.actions";

export const HAS_GP_RESULT_API: string = '/api/results/v2/ee-has-gp-result';

export function isGenePlanetEnrolled(claims: Claim[]): boolean {
  return (
    claims
      .find((n) => n.claimType == claimTypes.GENEPLANET_USER)
      ?.claimValue?.toLowerCase() == claimValue.TRUE || false
  )
}

export function getEnrolleeInfo(claims: Claim[]): GpEnrollee {
  return {
    synlabId: claims.find(n => n.claimType == claimTypes.SYNLAB_ID)?.claimValue,
    dateOfBirth: claims.find(n => n.claimType == claimTypes.DATE_OF_BIRTH)?.claimValue,
    sex: claims.find(n => n.claimType == 'gender')?.claimValue,
  }
}

export function getAgreement(claims: Claim[]) {
  return {
    privacyPolicy: !!claims.find(n => n.claimType == 'ee_gp_agree_privacy_policy')?.claimValue,
    dataPrivacy: !!claims.find(n => n.claimType == 'ee_gp_agree_data_privacy')?.claimValue,
    termsAndConditions: !!claims.find(n => n.claimType == 'ee_gp_agree_terms_and_conditions')?.claimValue
  }
}

export function checkIfCanEnroll(baseUrl$: Observable<string>, api: ApiService, store: Store, synlabId: string): Observable<boolean> {
  return baseUrl$.pipe(
    switchMap(baseUrl => {
      return store.pipe(select(StsSelectors.hasGeneplanetOrders)).pipe(
        switchMap(hasOrders => {
          if (hasOrders === null) {
            return api.getMethod(`${baseUrl}${HAS_GP_RESULT_API}/${synlabId}`).pipe(
              map(response => `${response}`.toLowerCase() == claimValue.TRUE),
              catchError(() => of(false)),
              tap(hasOrders => store.dispatch(STSActions.setHasGeneplanetOrders({ hasOrders }))),
            )
          }
          return of(hasOrders);
        })
      )

    })
  )
}
