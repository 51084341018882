import { Config } from '../synlab-access-ui-components/model/config.model';

export function formatLocale(locale: string, config?: Config, allowNull?: boolean) {
    if (!locale) {

      if (allowNull) return null;

      if (config) {
        locale = config.DefaultLanguage ;
      } else {
        locale = 'en';
      }
    }
    return locale.replace(/[^a-zA-Z]/g, '')
        .substring(0, 2)
        .toLowerCase();
}
