import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { WardResultInfoResponse, WardResultValidationInfoResponse } from 'src/app/models/ward-result.model';

@Injectable({
  providedIn: 'root'
})
export class WardResultService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig), take(1));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl), take(1));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  getWardResultInfo(id: string) {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/results/ward/validate/${id}`;
        return this.http.get<WardResultValidationInfoResponse>(url).pipe(
          map(res => ({
            ...res,
            dateCreated: dayjs.utc(res.dateCreated).toISOString()
          }))
        );
      })
    );
  }

  unlockWardResultInfo(id: string, birthday: string, otp: string) {
    return this.api$.pipe(
      mergeMap(api => {
        const url = `${api}/api/results/ward`;
        return this.http.get<WardResultInfoResponse>(
          url,
          {
            params: {
              guid: id,
              birthday: dayjs.utc(birthday).format('YYYY-MM-DD'),
              otp
            }
          }
        ).pipe(
          map(res => ({
            ...res,
            samplingDate: dayjs.utc(res.samplingTime).toISOString()
          }))
        );
      })
    );
  }

  private convertDateToClientTZ(dtStr:string){
    if (dtStr == "" || dtStr == null || !dtStr) {
      //returning a dummy date to not cause a problem on the date pipe
      let dmmyDt = new Date()
      return dmmyDt.toLocaleDateString()
    }
    let dtFromBE = new Date(dtStr);
    let BEdt = dtFromBE.toLocaleDateString();
    let BEtm = dtFromBE.toLocaleTimeString();
    return new Date(`${BEdt} ${BEtm} UTC`).toISOString();
  }
}
