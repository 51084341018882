<div data-test-id="search-patient-container" class="container py-5">
  <div data-test-id="search-patient-title-wrap" class="page-title-wrap text-center">
    <div data-test-id="search-patient-title" class="page-title p-0 m-0">Search for patient</div>
    <p>You can search on social security number or name to find the patient you want to create order for.</p>
    <div data-test-id="search-patient-left" class="text-left">
      <div data-test-id="search-patient-group" class="form-group form-inline my-3">
        <div data-test-id="search-patient-search" class="form-group has-search">
          <span data-test-id="search-patient-feedback" class="form-control-feedback">
            <img data-test-id="search-patient-img" src="assets/images/search.png" alt="" class="img-fluid" />
          </span>
          <ng-select data-test-id="search-patient-elem" #elem [placeholder]="phVal" [loading]="loadapi"
            [closeOnSelect]="true" [isOpen]="isOpen" (focus)="closeOnClick()" (change)="change()"
            (search)="state.page = 1; load($event);" (clear)="closeOnClick()" (add)="closeOnClick()" [items]="pl"
            [(ngModel)]="sv">
          </ng-select>
        </div>
        <a data-test-id="search-patient-regpatient" class="ml-1 mt-2" *ngIf="showReg" [routerLink]="['/create-new-patient']">Register Patient</a>
      </div>
    </div>
  </div>
</div>
