import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OtpService } from 'src/app/service/otp/otp.service';
import { getErrorMessage } from 'src/app/shared/utils/error.util';
import { OtpActions } from '../actions/otp.actions';

@Injectable()
export class OtpEffects {
  constructor(
    private actions$: Actions,
    private otpService: OtpService
  ) { }
  generateOtp$ = createEffect(
    () => this.actions$.pipe(
      ofType(OtpActions.generateOtp),
      mergeMap(({ request }) => this.otpService.generateOtp(request).pipe(
        map(() => OtpActions.generateOtpSuccess({ request })),
        catchError(error => {
          const msg = getErrorMessage(error);
          return of(OtpActions.generateOtpFailure({
            request,
            error: msg
          }));
        })
      ))
    )
  );
}
