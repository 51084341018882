import { createReducer, on } from '@ngrx/store';
import { SchoolOption } from 'src/app/models/school-option.model';
import * as userEditActions from '../actions/user-edit.actions';
import { Pagination, userEditfields } from 'src/app/models/user-edit.model';

export const userEditFeatureKey = 'userEdit';

export interface State {
  tableLoading: boolean,
  tableFields: string[],
  tableActiveRowIndex: number,
  searchBarLoading: boolean,
  sideFilterShow: boolean,
  paging: Pagination,
  search: {
    term: string,
    fields: string
  },
  users: any[],
  userActiveId: any,
  userActive: any,
  organization: SchoolOption,
  /** synlab id / name search */
  user: string,
  prevSelectedUserData: any,
  selectedUser: any,
  exportLoading: boolean
}

export const initialState: State = {
  tableLoading: false,
  tableFields: [...userEditfields],
  tableActiveRowIndex: null,
  searchBarLoading: false,
  sideFilterShow: true,
  paging: {
    PageSize: 10,
    TotalCount: 0,
    CurrentPage: 1,
    TotalPages: 0,
    HasPrevious: false,
    HasNext: false
  },
  search: {
    term: "",
    fields: "all"
  },
  users: [],
  userActiveId: null,
  userActive: null,
  organization: null,
  user: null,
  prevSelectedUserData: null,
  selectedUser: null,
  exportLoading: false
};

export const reducer = createReducer(
  initialState,
  on(userEditActions.reset, () => initialState),
  on(userEditActions.loadUsers, (state) => ({
    ...state,
    tableLoading: true,
    tableActiveRowIndex: null
  })),
  on(userEditActions.loadUsersFailure, (state) => ({
    ...state,
    searchBarLoading: false,
    tableLoading: false,
  })),
  on(userEditActions.loadUsersSuccess, (state, { users, paging }) => ({
    ...state,
    tableLoading: false,
    tableActiveRowIndex: null,
    users: users,
    searchBarLoading: false,
    paging: {
      ...state.paging,
      HasNext: paging.HasNext,
      HasPrevious: paging.HasPrevious,
      TotalCount: paging.TotalCount,
      TotalPages: paging.TotalPages
    }
  })),
  on(userEditActions.loadUsersFailure, (state) => ({
    ...state,
    tableLoading: false,
    tableActiveRowIndex: null,
    users: []
  })),
  on(userEditActions.setActiveUserId, (state, { userId }) => ({
    ...state,
    userActiveId: userId
  })),
  on(userEditActions.loadUser, (state) => ({
    ...state,
    tableLoading: true
  })),
  on(userEditActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    tableLoading: false,
    userActive: user
  })),
  on(userEditActions.setActiveRowIndex, (state, { rowIndex }) => ({
    ...state,
    tableActiveRowIndex: state.tableActiveRowIndex == rowIndex ? null : rowIndex
  })),
  on(userEditActions.setPageSize, (state:State, { pageSize }) => ({
    ...state,
    tableLoading: true,
    paging: {
      ...state.paging,
      PageSize: pageSize,
      CurrentPage: 1
    }
  })),
  on(userEditActions.nextPage, (state) => ({
    ...state,
    tableLoading: true,
    paging: {
      ...state.paging,
      CurrentPage: state.paging.CurrentPage + (state.paging.CurrentPage == state.paging.TotalPages ? 0 : 1)
    }
  })),
  on(userEditActions.previousPage, (state) => ({
    ...state,
    tableLoading: true,
    paging: {
      ...state.paging,
      CurrentPage: state.paging.CurrentPage == 1 ? 1 : state.paging.CurrentPage - 1
    }
  })),
  on(userEditActions.firstPage, (state) => ({
    ...state,
    tableLoading: true,
    paging: {
      ...state.paging,
      CurrentPage: 1
    }
  })),
  on(userEditActions.lastPage, (state) => ({
    ...state,
    tableLoading: true,
    paging: {
      ...state.paging,
      CurrentPage: state.paging.TotalPages
    }
  })),
  on(userEditActions.setUser, (state, { user }) => ({
    ...state,
    user,
    search: {
      ...state.search,
      term: ''
    },
    paging: {
      ...state.paging,
      CurrentPage: 1
    }
  })),
  on(userEditActions.setOrganization, (state, { organization }) => ({
    ...state,
    organization: organization,
    search: {
      ...state.search,
      term: ''
    },
    paging: {
      ...state.paging,
      CurrentPage: 1
    }
  })),
  on(userEditActions.sideFilterToggle, (state) => ({
    ...state,
    sideFilterShow: !state.sideFilterShow
  })),
  on(userEditActions.UserEditActions.getSelectedUserDetails, (state) => ({
    ...state,
    selectedUser: null
  })),
  on(userEditActions.UserEditActions.getSelectedUserDetailsSuccess, (state, {userDetails}) => ({
    ...state,
    selectedUser: userDetails,
    detailsStatus: 'success'
  })),
  on(userEditActions.UserEditActions.getSelectedUserDetailsFailure, (state) => ({
    ...state,
    selectedUser: null,
    userActiveId: null,
    detailsStatus: 'failure'
  })),
  on(userEditActions.UserEditActions.storePreviousValue, (state, { old_data }) => ({
    ...state,
    prevSelectedUserData: old_data
  })),
  on(userEditActions.UserEditActions.updateUserSuccess, (state:State) => ({
    ...state,
    userActiveId: null,
    tableLoading: true
  })),
  on(userEditActions.searchBarSearch, (state, { term, fields }) => ({
    ...state,
    searchBarLoading: true,
    tableLoading: true,
    paging: {
      ...state.paging,
      CurrentPage: 1
    },
    search: { term, fields }
  })),
  on(userEditActions.exportUsers, (state) => ({
    ...state,
    exportLoading: true
  })),
  on(userEditActions.exportUsersSuccess, state => ({
    ...state,
    exportLoading: false
  })),
  on(userEditActions.exportUsersFailure, (state, error) => ({
    ...state,
    exportLoading: false
  }))
);


