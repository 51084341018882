<div data-test-id="register-and-test-container" class="pt-4 pl-4 pr-4" *transloco="let t; read: 'registerAndTest'">
  <div data-test-id="register-and-test-container2" class="card register-test shadow border-0 d-lg-block">

    <div data-test-id="register-and-test-card-header" class="card-header px-0 py-0">
      <span data-test-id="register-and-test-title" class="title">
        {{ t('registerHeader') }}
      </span>
    </div>

    <div data-test-id="register-and-test-card-body" class="card-body px-5 py-4 mt-3">
      <div data-test-id="register-and-test-container3" class="container-fluid">

        <form data-test-id="register-and-test-form" [formGroup]="registerAndTestForm" (submit)="sendTest($event)">
          <div data-test-id="register-and-test-form-group" class="form-group align-items-center flex-group row">
            <label data-test-id="register-and-test-teacherName-label" class="control-label col-sm-3 mb-0"
              for="teacherName">
              <span data-test-id="register-and-test-teacherName">{{ t('teacherNameLabel') }}:</span>
            </label>
            <div data-test-id="register-and-test-teacherName-cont" class="col-sm-9">

              <!-- show info -->
              <ng-container *ngIf="sendTestSucceeded$|async; else editname">
                <span data-test-id="register-and-test-teacherName-val">{{ registerAndTestForm.get('teacherName').value
                  }}</span>
              </ng-container>

              <!-- allow edit -->
              <ng-template data-test-id="register-and-test-editname" #editname>
                <input data-test-id="register-and-test-teacherName-input" formControlName="teacherName"
                  class="form-control input-box" type="text" />
              </ng-template>
            </div>
          </div>

          <div data-test-id="register-and-test-flex-group" class="form-group align-items-center flex-group row">
            <label data-test-id="register-and-test-control-label" class="control-label col-sm-3 mb-0"
              for="selectTestPool">
              <span data-test-id="register-and-test-selectTestPool">{{ t('selectTestPool') }}:</span>
            </label>
            <div data-test-id="register-and-test-container4" class="col-sm-9">

              <!-- show info -->
              <ng-container *ngIf="sendTestSucceeded$|async; else editPool">
                <span data-test-id="register-and-test-selectedPoolName">
                  {{ selectedPoolName$|async }}
                </span>
              </ng-container>

              <!-- allow edit -->
              <ng-template data-test-id="register-and-test-editPool" #editPool>
                <select data-test-id="register-and-test-select" class="custom-select" formControlName="testPool"
                  [ngClass]="{'touched': this.registerAndTestForm.pristine}"
                  (change)="poolNameOnChanges($event.target)">
                  <option data-test-id="register-and-test-option-null" [value]="null" selected hidden disabled>
                    {{t('selectOnePlaceHolder')}}</option>
                  <option [attr.data-test-id]="'register-and-test-option-synlabId'+i" [value]="pool.synlabId"
                    *ngFor="let pool of (pools$|async); let i = index">
                    {{ pool.name }}
                  </option>
                </select>
              </ng-template>

            </div>
          </div>

          <div data-test-id="register-and-test-fg" class="form-group align-items-center flex-group row">
            <label data-test-id="register-and-test-testNumber-lb" class="control-label col-sm-3 mb-0" for="testNumber">
              <span data-test-id="register-and-test-testNumber">{{ t('testNumber') }}:</span>
            </label>
            <div data-test-id="register-and-test-container6" class="col-sm-9">

              <ng-container >
                <!-- show info -->
                <ng-container *ngIf="sendTestSucceeded$|async; else edittestnumber">
                  <span data-test-id="register-and-test-testNumber-value">{{ registerAndTestForm.get('testNumber').value
                    }}</span>
                </ng-container>

                <!-- allow edit -->
                <ng-template data-test-id="register-and-test-edittestnumber" #edittestnumber>
                  <input data-test-id="register-and-test-edittestnumber-input" formControlName="testNumber"
                    class="form-control input-box" type="text" maxlength="12" placeholder="{{t('examplePlaceHolder')}}"
                    [ngClass]="{'touched': this.registerAndTestForm.pristine}" />
                </ng-template>
              </ng-container>

              <ng-container *ngIf="!(sendTestSucceeded$|async)">
                <!-- ui validation error -->
                <small data-test-id="register-and-test-testNumberValidationLabel" class="text-danger ui"
                  *ngIf="!registerAndTestForm.get('testNumber').pristine && registerAndTestForm.get('testNumber').errors?.pattern">
                  {{ t('testNumberValidationLabel') }}
                </small>

                <!-- lookup validation error -->
                <small data-test-id="register-and-test-testNumberLookupValidationLabel" class="text-danger lookup"
                  *ngIf="(getLookupValue$|async) == true && !(getLookupInProgress$|async) && !registerAndTestForm.get('testNumber').errors?.pattern">
                  {{ t('testNumberLookupValidationLabel') }}
                </small>

                <!-- lookup is loading -->
                <small data-test-id="register-and-test-loadingLabel" *ngIf="(getLookupInProgress$|async)">
                  {{ t('loadingLabel') }}
                </small>
              </ng-container>

            </div>
          </div>

          <div data-test-id="register-and-test-container12" class="row">
            <div data-test-id="register-and-test-container11" class="col-sm-12">

              <!-- resend or go to list of result -->
              <ng-container *ngIf="sendTestSucceeded$|async; else showSubmit">
                <span data-test-id="register-and-test-routerLink-cont" class="float-right">
                  <button data-test-id="register-and-test-routerLink" type="button" [routerLink]="['/results', 'list']"
                    routerLinkActive="router-link-active"
                    class="btn btn-outline-primary mr-2">{{t('backToMyResultButton')}}</button>
                  <button data-test-id="register-and-test-testAnotherPoolButton" type="button" (click)="reset()"
                    class="btn btn-primary">{{t('testAnotherPoolButton')}}</button>
                </span>
              </ng-container>

              <!-- submit button -->
              <ng-template data-test-id="register-and-test-showSubmit" #showSubmit>
                <ng-container *ngIf="sendTestInProgress$|async; else enableBtn">
                  <button data-test-id="register-and-test-loadingLabel" type="button" disabled
                    class="btn btn-primary float-right">
                    {{ t('loadingLabel') }}
                  </button>
                </ng-container>
                <ng-template data-test-id="register-and-test-enableBtn" #enableBtn>

                  <button data-test-id="register-and-test-submit" type="submit"
                    [disabled]="isSubmitDisabled || !registerAndTestForm.valid || (getLookupValue$|async) == true || (getLookupInProgress$|async)"
                    class="btn btn-primary float-right">{{ t('sendTestButton')
                    }}</button>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
