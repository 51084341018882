import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { formatLocale } from 'src/app/plasma-ui-common/utils/format-locale';
import { ConfigService } from 'src/app/service/config/config.service';
import { I18nService } from 'src/app/service/i18n/i18n.service';

/** language codes does not always have a country, so here is a lookup object
 * for a country code to be used */
interface CountryInfo {
  flag: string;
  languageValue: string;
  locale?: string;
}

const countryEquivalent: Record<string, CountryInfo> = {
  "en": { flag: "gb", languageValue: "English (UK)" },
  "es-CO": { flag: "co", languageValue: "Spanish (Columbia)" },
  "hu": { flag: "hu", languageValue: "Hungarian" },
  "pt": { flag: "pt", languageValue: "Portuguese (Portugal)" },
  "fr-FR": { flag: "fr", languageValue: "French" },
  "es-ES": { flag: "es", languageValue: "Spanish (Spain)" },
  "it-IT": { flag: "it", languageValue: "Italian" },
  "de": { flag: "de", languageValue: "German" },
  "nl-NL": { flag: "nl", languageValue: "Dutch (Netherlands)" },
  "it-CH": { flag: "ch", languageValue: "Italian (Switzerland)" },
  "fr-CH": { flag: "ch", languageValue: "French (Switzerland)" },
  "de-CH": { flag: "ch", languageValue: "German (Switzerland)" },
  "lt-LT": { flag: "lt", languageValue: "Lithuanian (Lithuania)" },
  "tr-TR": { flag: "tr", languageValue: "Turkish (Turkey)" },
  "el": { flag: "gr", languageValue: "Greek" },
  "et": { flag: "ee", languageValue: "Estonian" },
  "ee": { flag: "ee", languageValue: "Estonian" },
  "ru": { flag: "ru", languageValue: "Russian" },
};

const availableFlags = [
  "ac", "ad", "ae", "af", "ag", "ai", "al", "am", "ao", "ar", "as",
  "at", "au", "aw", "ax", "az", "ba", "bb", "bd", "be", "bf", "bg", "bh", "bi",
  "bj", "bl", "bm", "bn", "bo", "bq", "br", "bs", "bt", "bv", "bw", "by", "bz",
  "ca", "cc", "cd", "cf", "cg", "ch", "ci", "ck", "cl", "cm", "cn", "co", "cr",
  "cu", "cv", "cw", "cx", "cy", "cz", "de", "dj", "dk", "dm", "do", "dz", "ec",
  "ee", "eg", "eh", "er", "es", "et", "eu", "fi", "fj", "fk", "fm", "fo", "fr",
  "ga", "gb-eng", "gb-nir", "gb-sct", "gb-wls", "gb-zet", "gb", "gd", "ge", "gf",
  "gg", "gh", "gi", "gl", "gm", "gn", "gp", "gq", "gr", "gs", "gt", "gu", "gw",
  "gy", "hk", "hm", "hn", "hr", "ht", "hu", "id", "ie", "il", "im", "in", "io",
  "iq", "ir", "is", "it", "je", "jm", "jo", "jp", "ke", "kg", "kh", "ki", "km",
  "kn", "kp", "kr", "kw", "ky", "kz", "la", "lb", "lc", "lgbt", "li", "lk", "lr",
  "ls", "lt", "lu", "lv", "ly", "ma", "mc", "md", "me", "mf", "mg", "mh", "mk",
  "ml", "mm", "mn", "mo", "mp", "mq", "mr", "ms", "mt", "mu", "mv", "mw", "mx",
  "my", "mz", "na", "nc", "ne", "nf", "ng", "ni", "nl", "no", "np", "nr", "nu",
  "nz", "om", "pa", "pe", "pf", "pg", "ph", "pk", "pl", "pm", "pn", "pr", "ps",
  "pt", "pw", "py", "qa", "re", "ro", "rs", "ru", "rw", "sa", "sb", "sc", "sd",
  "se", "sg", "sh", "si", "sj", "sk", "sl", "sm", "sn", "so", "sr", "ss", "st",
  "sv", "sx", "sy", "sz", "ta", "tc", "td", "tf", "tg", "th", "tj", "tk", "tl",
  "tm", "tn", "to", "tr", "tt", "tv", "tw", "tz", "ua", "ug", "um", "us", "uy",
  "uz", "va", "vc", "ve", "vg", "vi", "vn", "vu", "wf", "ws", "xk", "ye", "yt",
  "za", "zm", "zw"
];

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePickerComponent implements OnInit {
  /** class to position relative for absolutely positioned element */
  @HostBinding('class') classes = 'position-relative';
  /** reference to country code lookup so that it is accessible to template */
  countryEquivalent = countryEquivalent;
  /** if language dropdown is open */
  showOptions: boolean = false;
  /** get available languages */
  langCodes: CountryInfo[] = [];
  /** currently selected language */
  activeLang$ = this.i18n.activeLang$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  constructor(
    private readonly i18n: I18nService,
    private readonly configService: ConfigService,
    private readonly changeDetection: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    const availableLocales = await this.configService.getAvailableLocales();
    this.langCodes = [...new Set(availableLocales)].map(locale => ({ ...countryEquivalent[formatLocale(locale)], locale: formatLocale(locale) }));
    this.changeDetection.markForCheck();
  }

  changeLanguage(langCode: CountryInfo) {
    this.i18n.setStoredLanguage(formatLocale(langCode.locale));
    this.showOptions = false;
  }
}
