import { createAction, props } from "@ngrx/store";
import { SendTest } from "../models/regtest.model";

const loadTestPools = createAction("[Regtest] Load Test Pools");

const loadTestPoolsSuccess = createAction(
  "[Regtest] Load Test Pools Success",
  props<{ data: any }>()
);

const loadTestPoolsFailure = createAction(
  "[Regtest] Load Test Pools Failure",
  props<{ error: any }>()
);

const testTubeIdLookup = createAction(
  "[Regtest] Test Tube ID Lookup",
  props<{ id: string }>()
);

const testTubeIdLookupSuccess = createAction(
  "[Regtest] Test Tube ID Lookup Success",
  props<{ value: boolean }>()
);

const testTubeIdLookupFailure = createAction(
  "[Regtest] Test Tube ID Lookup Failure",
  props<{ error: any }>()
);

const startSendTest = createAction(
  "[Regtest] Start Send Test",
  props<{ params: SendTest }>()
);

const startSendTestSuccess = createAction(
  "[Regtest] Start Send Test Success",
  props<{ data: any }>()
);

const startSendTestFailure = createAction(
  "[Regtest] Start Send Test Failure",
  props<{ error: any }>()
);


export const RegTestActions = {
  startSendTest,
  startSendTestSuccess,
  startSendTestFailure,
  testTubeIdLookup,
  testTubeIdLookupSuccess,
  testTubeIdLookupFailure,
  loadTestPools,
  loadTestPoolsSuccess,
  loadTestPoolsFailure,
};
