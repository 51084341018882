import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PoolOrderService } from 'src/app/service/pool-order/pool-order.service';
import { ProfileService } from 'src/app/service/profile/profile.service';
import { PoolOrderActions } from '../actions/pool-order.actions';
import { STSActions } from '../actions/sts.actions';
import { AppState } from '../reducers';
import { PoolOrderSelectors } from '../selectors/pool-order.selectors';
import { StsSelectors } from '../selectors/sts.selectors';

interface BadRequestError {
  error: {
    message: string;
  };
}
const isBadRequest = (error: unknown): error is BadRequestError =>
  typeof (error as any)?.error?.message === 'string'


@Injectable()
export class PoolOrderEffects {
  schoolClaims$ = this.store.pipe(select(PoolOrderSelectors.schoolClaims));
  userClaims$ = this.store.pipe(select(StsSelectors.getClaims));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private poolOrderApi: PoolOrderService,
    private profileApi: ProfileService
  ) { }

  viewPoolBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.viewPoolBarcode),
      filter(({barcode}) => Boolean(barcode)),
      map(({ barcode }) => PoolOrderActions.checkPoolBarcodeExists({ barcode }))
    )
  )

  checkPoolBarcodeExists$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.checkPoolBarcodeExists),
      map(action => action.barcode),
      mergeMap((barcode) => {
        return this.poolOrderApi.checkIfPoolBarcodeExists(barcode).pipe(
          map(res => PoolOrderActions.checkPoolBarcodeExistsSuccess({
            barcode,
            exists: res.doesExist
          })),
          tap(action => {
            if(!action.exists) {
              this.actions$.pipe(
                ofType(STSActions.loadClaimsSuccess),
                mergeMap(() => this.store.pipe(select(StsSelectors.getSchoolSynlabId))),
                take(1),
                takeUntil(this.actions$.pipe(ofType(STSActions.loadClaimsFailure)))
              ).subscribe(schoolSynlabId => {
                this.store.dispatch(PoolOrderActions.loadSchoolClaims({schoolSynlabId: schoolSynlabId}))
              })
            } else {
              this.actions$.pipe(
                ofType(PoolOrderActions.loadPoolBarcodeInfoSuccess),
                take(1),
                takeUntil(this.actions$.pipe(ofType(PoolOrderActions.loadPoolBarcodeInfoFailure)))
              ).subscribe(action => {
                this.store.dispatch(
                  PoolOrderActions.loadPoolClaims({
                    poolSynlabId: action.barcodeInfo.synlabId
                  })
                )
              })
            }
          }),
          mergeMap(action => {
            if(!action.exists) {
              return of(action, STSActions.loadClaims())
            } else {
              return of(
                action,
                PoolOrderActions.loadPoolBarcodeInfo({ barcode }),
                PoolOrderActions.loadHasNegativeResults({ barcode }),
                PoolOrderActions.loadPoolIndividualOrders({ poolBarcode: barcode })
              )
            }
          }),
          catchError((error) => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.checkPoolBarcodeExistsFailure({
              barcode,
              error: msg
            }))
          })
        );
      })
    )
  );
  loadHasNegativeResults$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadHasNegativeResults),
      mergeMap(action => {
        return this.poolOrderApi.loadHasNegativeResults(action.barcode).pipe(
          map(res => PoolOrderActions.loadHasNegativeResultsSuccess({
            barcode :action.barcode,
            hasNegative: res.hasNegative
          })),
          catchError(error => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.loadHasNegativeResultsFailure({
              barcode: action.barcode,
              error: msg
            }))
          })
        )
      })
    )
  )
  loadPoolBarcodeInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadPoolBarcodeInfo),
      map(action => action.barcode),
      mergeMap(barcode => {
        return this.poolOrderApi.getPoolBarcodeInfo(barcode).pipe(
          map(res => PoolOrderActions.loadPoolBarcodeInfoSuccess({
            barcode,
            barcodeInfo: res
          })),
          catchError((error) => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.loadPoolBarcodeInfoFailure({
              barcode,
              error: msg
            }))
          })
        )
      })
    )
  );
  loadPoolIndividualOrders$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadPoolIndividualOrders),
      map(action => action.poolBarcode),
      mergeMap(poolBarcode => {
        return this.poolOrderApi.getPoolIndividualOrders(poolBarcode).pipe(
          map(res => PoolOrderActions.loadPoolIndividualOrdersSuccess({
            poolBarcode,
            poolIndividualOrders: res
          })),
          catchError(error => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.loadPoolIndividualOrdersFailure({
              poolBarcode,
              error: msg
            }))
          })
        )
      })
    )
  )
  loadSchoolClaims$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadSchoolClaims),
      withLatestFrom(this.schoolClaims$),
      mergeMap(([_action, schoolClaims]) => {
        if (Array.isArray(schoolClaims) && schoolClaims.length > 0) {
          return of(schoolClaims).pipe(
            map(claims => PoolOrderActions.loadSchoolClaimsSuccess({ claims }))
          );
        } else {
          return this.getSchoolSynlabId().pipe(
            mergeMap(schoolSynlabId =>
              this.profileApi.getClaimsBySynlabId(schoolSynlabId)),
            map(claims => PoolOrderActions.loadSchoolClaimsSuccess({ claims })),
            catchError((error) => {
              const msg = this.getErrorMessage(error);
              return of(PoolOrderActions.loadSchoolClaimsFailure(
                { error: msg }
              ));
            })
          );
        }
      })
    )
  );
  newActiveParticipant$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.setActiveParticipantSynlabId),
      filter(({synlabId}) => Boolean(synlabId)),
      map(({synlabId}) => PoolOrderActions.loadParticipantClaims({synlabId}))
    )
  );
  loadParticipantClaims$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadParticipantClaims),
      mergeMap(action =>
        this.profileApi.getClaimsBySynlabId(action.synlabId).pipe(
          map(claims => PoolOrderActions.loadParticipantClaimsSuccess({
            claims,
            synlabId: action.synlabId
          })),
          catchError(error => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.loadParticipantClaimsFailure({
              synlabId: action.synlabId,
              error: msg
            }))
          })
        )
      )
    )
  );
  loadPoolClaims$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.loadPoolClaims),
      map(({poolSynlabId}) => poolSynlabId),
      mergeMap(synlabId => this.profileApi.getClaimsBySynlabId(synlabId).pipe(
        map(claims => PoolOrderActions.loadPoolClaimsSuccess({claims, poolSynlabId: synlabId})),
        catchError((error) => {
          const msg = this.getErrorMessage(error);
          return of(PoolOrderActions.loadPoolClaimsFailure(
            {
              error: msg,
              poolSynlabId: synlabId
            }
          ));
        })
      ))
    )
  )

  createPoolBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.createPoolBarcode),
      mergeMap(action => {
        return this.poolOrderApi.createPoolBarcode(action.request).pipe(
          tap(() => this.store.dispatch(PoolOrderActions.viewPoolBarcode({barcode: action.barcode}))),
          map(_res => PoolOrderActions.createPoolBarcodeSuccess({barcode: action.barcode})),
          catchError(error => {
            const msg = this.getErrorMessage(error);
            return of(PoolOrderActions.createPoolBarcodeFailure({
              barcode: action.barcode,
              error: msg
            }))
          })
        )
      })
    )
  );

  tempOrderRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.tempOrderRequest),
      mergeMap(action => this.poolOrderApi.createTempOrder(action.request).pipe(
        mergeMap(() => of(
          PoolOrderActions.tempOrderRequestSuccess({ participantBarcode: action.participantBarcode }),
          PoolOrderActions.loadPoolIndividualOrders({ poolBarcode: action.poolBarcode})
        )),
        catchError(error => {
          const msg = this.getErrorMessage(error);
          return of(PoolOrderActions.tempOrderRequestFailure({
            participantBarcode: action.participantBarcode,
            error: msg
          }));
        })
      ))
    )
  )

  logTextboxAction$ = createEffect(
    () => this.actions$.pipe(
      ofType(PoolOrderActions.log),
      mergeMap(({ action }) => this.poolOrderApi.logTextbox(action))
    ),
    { dispatch: false }
  );

  /** request to get user claims */
  private fetchUserClaims() {
    return of(true).pipe(
      tap(() => this.store.dispatch(STSActions.loadClaims())),
      mergeMap(() => this.actions$.pipe(
        ofType(
          STSActions.loadClaimsSuccess,
          STSActions.loadClaimsFailure
        ),
        mergeMap(action => {
          if(action.type === STSActions.loadClaimsSuccess.type) {
            return this.userClaims$.pipe(take(1))
          } else { return throwError(action.error) }
        }),
        take(1),
      ))
    );
  }
  /** get school synlab id from user claims */
  private getSchoolSynlabId() {
    return this.userClaims$.pipe(
      mergeMap(claims => {
        if(Array.isArray(claims) && claims.length > 0) {
          return of(claims);
        } else {
          return this.fetchUserClaims();
        }
      }),
      map(claims => claims.find(c => c.claimType === 'school_synlab_id')),
      map(claim => claim?.claimValue),
      take(1),
    );
  }

  private getErrorMessage(error: unknown) {
    if (isBadRequest(error)) { return error.error.message; }
    else if (typeof error === 'string') { return error; }
    else if (error instanceof Error) { return error.message }
    else { return "An unknown error occured"; }
  }
}
