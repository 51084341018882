import { createAction, props } from "@ngrx/store";
import { Claim } from "src/app/models/claims.model";

const loadStss = createAction("[Sts] Load Stss");

const loadStssSuccess = createAction(
  "[Sts] Load Stss Success",
  props<{ data: any }>()
);
const loadStssFailure = createAction(
  "[Sts] Load Stss Failure",
  props<{ error: any }>()
);
const loadConfigSuccess = createAction(
  "[Sts] Load Config Success",
  props<{ data: any }>()
);

const loadMultipleClaims = createAction(
  "[Sts] Load multiple claims",
  props<{ synlabIds: string[] }>()
);
const loadMultipleClaimsSuccess = createAction(
  "[Sts] Load multiple claims success",
  props<{ claims: Claim[][] }>()
);
const loadMultipleClaimsFailure = createAction(
  "[Sts] Load multiple claims failure",
  props<{ error: any }>()
);
const loadSingleClaimSuccess = createAction(
  "[Sts] Load single claim success",
  props<{ synlabId: string, claims: Claim[] }>()
);

const fetchClaimsViaUserId = createAction(
  "[Sts] Fetch claims via user id",
  props<{ userId: string }>()
);
const fetchClaimsViaUserIdSuccess = createAction(
  "[Sts] Fetch claims via user idsuccess",
  props<{ userId: string, claims: Claim[] }>()
);
const fetchClaimsViaUserIdFailure = createAction(
  "[Sts] Fetch claims via user idfailure",
  props<{ error: any }>()
);
const loadClaimsIfEmpty = createAction("[Sts] Load claims if empty");
const loadClaimsIfEmptyDone = createAction("[Sts] Load claims if empty done");
const loadClaims = createAction("[Sts] Load claims");
const loadClaimsSuccess = createAction(
  "[Sts] Load claims Success",
  props<{ claims: Claim[]}>()
);
const loadClaimsFailure = createAction(
  "[Sts] Load claims Failure",
  props<{ error: string | any}>()
);

const loadProfile = createAction("[STS] Load Profile",
props<{ userId: string }>());
const loadProfileSuccess = createAction("[STS] Load Profile Success",
props<{ claims: Claim[]}>());
const loadProfileFailed = createAction("[STS] Load Profile Failed",
  props<{ error: string | any }>());

const setRedirectUrl = createAction(
  "[Sts] Set Redirect Url",
  props<{ path: string }>()
);
const restoreRedirectUrl = createAction(
  "[Sts] Set Redirect Url",
  props<{ path: string }>()
);
const setHasGeneplanetOrders = createAction(
  "[Sts] Geneplanet: Set Has Orders",
  props<{ hasOrders: boolean | null }>()
);
const fetchHasGeneplanetOrders = createAction(
  "[Sts] Geneplanet: Fetch Has Orders"
);

export const STSActions = {
  setRedirectUrl,
  restoreRedirectUrl,
  loadProfileSuccess,
  loadProfileFailed,
  loadProfile,
  loadConfigSuccess,
  loadStss,
  loadStssSuccess,
  loadStssFailure,
  loadMultipleClaims,
  loadMultipleClaimsSuccess,
  loadMultipleClaimsFailure,
  loadSingleClaimSuccess,
  fetchClaimsViaUserId,
  fetchClaimsViaUserIdSuccess,
  fetchClaimsViaUserIdFailure,
  loadClaims,
  loadClaimsIfEmpty,
  loadClaimsIfEmptyDone,
  loadClaimsSuccess,
  loadClaimsFailure,
  setHasGeneplanetOrders,
  fetchHasGeneplanetOrders,
};
