interface BadRequestError {
  error: {
    message: string;
  };
}
const isBadRequest = (error: unknown): error is BadRequestError =>
  typeof (error as any)?.error?.message === 'string'

export function getErrorMessage(error: unknown) {
  if (isBadRequest(error)) { return error.error.message; }
  else if (typeof error === 'string') { return error; }
  else if (error instanceof Error) { return error.message }
  else { return "An unknown error occured"; }
}
