import { Results } from "src/app/@store/models/results.model";
import { findClaimAndGetBool } from "src/app/models/claims.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface CompanyIndividualData extends BaseCertData {
  isCompanyIndividual: true;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  barcode: string;
  resultComment: string;
  laboratory: string;
  laboratoryAddress: string;
}
export const isCompanyIndividualResult: ConditionChecker = (r, o) => {
  const claims = o?.claimsRecord?.[r?.synlabId];
  return Array.isArray(claims) && findClaimAndGetBool('employee_account', claims);
}
export const resultAsCompanyIndividualCertData: ResultMapper<Results, CompanyIndividualData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
    ...baseResultAsCertData(r, o),
    isCompanyIndividual: true,
    firstName: customUppercase(r?.firstName?.trim?.()),
    lastName: customUppercase(r?.lastName?.trim?.()),
    dateOfBirth: r.dateOfBirth?.slice?.(0, 10),
    barcode: Array.isArray(r.testTubeIdentifiers)
      ? r.testTubeIdentifiers.join(', ')
      : r.testTubeIdentifiers,
    resultComment: o.translations?.[tranKey]?.['message'],
    laboratory: r.laboratory,
    laboratoryAddress: o.labAddress[r.senderIdentifierCode]
  }
}
