import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import memoize from 'lodash/memoize';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, shareReplay, take, tap, withLatestFrom } from 'rxjs/operators';
import { GenerateOtpRequest } from 'src/app/models/otp.model';
import { BaseCertData } from 'src/app/service/reports/cert-data-models/base-cert-data.model';
import { createReportData } from 'src/app/service/reports/report.model';
import { ReportsConfiguration, ReportsService } from 'src/app/service/reports/reports.service';
import { WardResultService } from 'src/app/service/ward-result/ward-result.service';
import { getErrorMessage } from 'src/app/shared/utils/error.util';
import { OtpActions } from '../actions/otp.actions';
import { ParentChildViewResultActions } from '../actions/parent-child-view-result.actions';
import { AppState } from '../reducers';
import { ParentChildViewResultSelectors } from '../selectors/parent-child-view-result.selectors';


const otpFeature = 'getWardResult';
@Injectable()
export class ParentChildViewResultEffects {
  resultInfo$ = this.store.pipe(select(ParentChildViewResultSelectors.resultInfo), distinctUntilChanged());
  /** data to be used by jsreport to generate certificate */
  private reportData$ = combineLatest([
    this.resultInfo$,
    this.reportService.certTranslations$
  ]).pipe(
    map(([result, translations]) => ({
      patients: [createReportData(result, { isWardResult: true, translations })],
      label: {
        company: translations['companyLabel'],
        poolName: translations['poolNameLabel'],
        poolBarcode: translations['poolBarcodeLabel'],
        resultAndSampling: translations['resultAndSamplingLabel'],
        testReferenceNumber: translations['testReferenceNumberLabel'],
        testingLab: translations['testingLabLabel'],
        issuedOn: translations['issuedOnLabel'],
      }
    }))
  );
  /** name for certificate file downloaded */
  private reportName$ = this.resultInfo$.pipe(
    map(result => `${result?.givenName}_${result?.familyName}_${result?.samplingDate}.pdf`)
  );
  /** token used to be allowed to generate certificate */
  private docToken$ = this.resultInfo$.pipe(
    map(result => result?.docToken)
  );
  constructor(
    private actions$: Actions,
    private wardResultService: WardResultService,
    private store: Store<AppState>,
    private reportService: ReportsService
  ) { }

  loadSecurityInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.loadSecurityInfo),
      mergeMap(({ id }) => this.wardResultService.getWardResultInfo(id).pipe(
        map(res => ParentChildViewResultActions.loadSecurityInfoSuccess({ data: res })),
        catchError((error) => {
          const action = ParentChildViewResultActions.loadSecurityInfoFailure({ error: getErrorMessage(error) });
          return of(action);
        })
      ))
    )
  );

  loadWardResultInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.loadResultInfo),
      mergeMap(({ id, date, otp }) => {
        const refreshAction = ParentChildViewResultActions.refreshSecurityInfo({ id });
        return this.wardResultService.unlockWardResultInfo(id, date, otp).pipe(
          mergeMap(res => of(
            ParentChildViewResultActions.loadResultInfoSuccess({ data: res }),
            ParentChildViewResultActions.decrementAttemptsRemaining()
          )),
          catchError((error) => {
            const action = ParentChildViewResultActions.loadResultInfoFailure({ error: getErrorMessage(error) });
            return of(action, refreshAction);
          })
        );
      })
    )
  );

  refreshSecurityInfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.refreshSecurityInfo),
      mergeMap(({ id }) => this.wardResultService.getWardResultInfo(id).pipe(
        map(res => ParentChildViewResultActions.refreshSecurityInfoSuccess({ data: res })),
        catchError((error) => {
          const action = ParentChildViewResultActions.refreshSecurityInfoFailure({ error: getErrorMessage(error) });
          return of(action);
        })
      ))
    )
  );

  generateOtp$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.generateOtp),
      map((action): GenerateOtpRequest => ({
        ...action.request,
        feature: otpFeature
      })),
      mergeMap((otpRequest) => of(otpRequest).pipe(
        tap(request => setTimeout(() =>
          this.store.dispatch(OtpActions.generateOtp({ request }))
          , 1)),
        mergeMap(() => this.actions$.pipe(
          ofType(
            OtpActions.generateOtpSuccess,
            OtpActions.generateOtpFailure
          ),
          filter(action => action.request.referenceId === otpRequest.referenceId),
          take(1),
          map(otpAction => otpAction.type === OtpActions.generateOtpSuccess.type
            ? ParentChildViewResultActions.generateOtpSuccess()
            : ParentChildViewResultActions.generateOtpFailure({
              error: otpAction.error
            })
          )
        ))
      ))
    )
  );

  getCertificate$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.getCertificate),
      mergeMap(() => this.downloadCertificate().pipe(
        withLatestFrom(this.reportName$),
        map(([url, name]) => ParentChildViewResultActions.getCertificateSuccess({ url, name })),
        catchError((error) => {
          const action = ParentChildViewResultActions.getCertificateFailure({ error: getErrorMessage(error) });
          return of(action);
        })
      ))
    )
  )

  /** actual download of file */
  download$ = createEffect(
    () => this.actions$.pipe(
      ofType(ParentChildViewResultActions.getCertificateSuccess),
      tap(cert => this.reportService.download(cert.url, cert.name))
    ),
    { dispatch: false }
  )

  private downloadCertificate(): Observable<string> {
    return this.reportData$.pipe(
      take(1),
      withLatestFrom(this.docToken$),
      mergeMap(([certData, docToken]) => this.generateCertificate(docToken, certData))
    );
  }

  /** generate certificate
   * calls report service.
   * memoized so successful calls dont repeat network call
   */
  private generateCertificate = (() => {
    const memoizedGenerate = memoize((certData: string, docToken: string) =>
      this.reportService.generatePdfWithToken(
        ReportsConfiguration.certificate,
        certData,
        docToken
      ).pipe(shareReplay({ bufferSize: 1, refCount: false })));
    return (
      docToken: string,
      certData: {
        patients: BaseCertData[],
        label: Record<string, string>
      }) => memoizedGenerate(JSON.stringify(certData), docToken);
  })()
}
