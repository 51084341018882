import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { externalLink } from 'src/app/appsettings';

@Component({
  selector: 'app-was-redirect',
  templateUrl: './was-redirect.component.html'
})
export class WasRedirectComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private rt: Router
  ) { }

  ngOnInit(): void {
    this.wasredirection();
  }

  wasredirection() {
     this.store.pipe(select(StsSelectors.isDKTenant))
        .pipe(take(1))
        .subscribe(
          (d) => {
            if(d){
              window.location.href = externalLink.WAS_URL
            } else {
              this.rt.navigate(["/"]);
            }
          },
          (error) => {
            this.rt.navigate(["/"]);
          }
        );
  }
}
