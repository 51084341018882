import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { claimValue } from "src/app/appsettings";
import { Claim } from "src/app/models/claims.model";
import { ConfigService } from "src/app/plasma-ui-common/synlab-access-ui-components/core";
import { Config } from "src/app/plasma-ui-common/synlab-access-ui-components/model/config.model";
import { ApiService } from "../api/api.service";
import { ConfigService as AppConfigService } from "../config/config.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderConfigService implements ConfigService {

  config$: Observable<Config>;
  configSubject: BehaviorSubject<Config>;

  constructor(
    private _cfg: AppConfigService,
    private _api: ApiService,
    private _store: Store
  ) {
    const {
      baseAdminUrl,
      FullCatalogueViewEnabled,
      baseBigcommerceUrl,
      EeBaseBookingUrl,
      baseBookingUrl,
      baseBookingAdminUrl,
      baseWebreqUrl,
      baseProfileUrl,
      basePlasmaUrl,
      WhiteLabelEnabled,
      WhiteLabelName,
      EnableCheckResults
    } = this._cfg._envConfig;

    const config: Config = {
      EnablePreventionWellness: null,
      FullCatalogueViewEnabled: FullCatalogueViewEnabled.toLocaleLowerCase() == "true",
      baseAdminUrl,
      baseBigcommerceUrl,
      baseBookingUrl,
      baseBookingAdminUrl,
      baseWebreqUrl,
      baseProfileUrl,
      basePlasmaUrl,
      applicationId: 'plasma-web',
      eeBaseBookingUrl: EeBaseBookingUrl,
      WhiteLabelEnabled: WhiteLabelEnabled.toLowerCase() == "true",
      WhiteLabelName,
      EnableCheckResults: EnableCheckResults?.toLowerCase() == "true",
    }

    this.configSubject = new BehaviorSubject(config);

    combineLatest([
      this._store.pipe(select(StsSelectors.isGenePlanetEnrolled)),
      this._store.pipe(select(StsSelectors.hasGeneplanetOrders))
    ]).pipe(
      filter(([isEnrolled]) => isEnrolled !== null),
      map(([isEnrolled, canEnroll]) => isEnrolled || canEnroll),
      map((result) => {
        if (_cfg._envConfig.EnablePreventionWellness?.toLowerCase() != claimValue.TRUE) {
          return false;
        }
        return result;
      }),
      tap((result) => {
        if (result === null) {
          this._store.dispatch(STSActions.fetchHasGeneplanetOrders())
        }
        return of(result);
      }),
      map((EnablePreventionWellness) => ({
        ...config,
        EnablePreventionWellness
      })),
      distinctUntilChanged(),
      tap(config => this.configSubject.next(config))
    ).subscribe();

    this.config$ = this.configSubject.asObservable();
  }
}
