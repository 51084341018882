import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
export interface Document {
  id: string;
  type: string;
  subType: string;
  coding: string;
  data: string;
}

export interface Results {
  id: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  samplingTime: string;
  issuedOn: string;
  laboratory: string;
  testReference: string;
  synlabValue: string;
  laboratoryResult: {
    documents: Document[];
  };
  result: string;
  isNegative: boolean;
  isPositive: boolean;
  isInconclusive: boolean;
  hasExpired: boolean;
  isWhiteLabeled: boolean;
  testType: string;
  testCode: string;
  invoiceId: string;

  synlabId: string;
  passportNumber: string;
  testTubeIdentifiers?: any[];
  status?: any;
  validUntil: string;
  validFrom: string;
  uniqueIdentifier?: string;
  qrCodeUrl: string;

  /** identifier for which group the result belongs to */
  groupId: number;
  /** identifier for the order of each result in group */
  memberId: number;
  /** FE only property
   * past results that are considered child results of latest result in same group */
  pastResults?: Results[];
  /** FE only property
   * if result is lolliIndividual */
  isLolliIndividualResult?: boolean;
  /** FE only property */
  issuedOnDateOnly: string;

  poolSynlabId: string;
  /** manufacturer in greenpass request */
  manufacturerId: string;
  manufacturerName: string;
  senderIdentifierCode: string;
  uniqueResultId?: string;
}

const dateTimeComparer = (x: string, y: string): number => {
  const expectedFormat = "DD.MM.YYYY HH:mm";
  const xDate = dayjs(x, expectedFormat);
  const yDate = dayjs(y, expectedFormat);

  if (xDate.isValid() && !yDate.isValid()) {
    return -1;
  } else if (!xDate.isValid() && yDate.isValid()) {
    return 1;
  } else if (!xDate.isValid() && !yDate.isValid()) {
    return 0;
  } else if (xDate.isSame(yDate)) {
    return 0;
  } else if (xDate.isAfter(yDate)) {
    return 1;
  } else return -1;
}
const stringComparer = (x: string, y: string) =>
  (x ?? "").localeCompare(y ?? "");
type ResultSortComparer = {
  [K in keyof Results]?: (a: Results, b: Results) => number
}
type Comparer = ResultSortComparer[keyof ResultSortComparer];
export const keyCompareRecord: ResultSortComparer = {
  samplingTime: (a,b) => dateTimeComparer(a?.samplingTime, b?.samplingTime),
  issuedOn: (a,b) => dateTimeComparer(a?.issuedOn,b?.issuedOn),
  testCode: (a, b) => stringComparer(a?.testCode, b?.testCode),
  firstName: (a, b) => stringComparer(a?.firstName, b?.firstName),
  lastName: (a, b) => stringComparer(a?.lastName, b.lastName),
};
export const poolIndividualComparer: Comparer = (a, b) => {
  if (a?.testCode === '96797-61' && b?.testCode !== '96797-61') {
    return -1;
  } else if (a?.testCode !== '96797-61' && b?.testCode === '96797-61') {
    return 1;
  } else {
    return 0;
  }
}
export const appendComparer = (c1: Comparer, c2: Comparer): Comparer =>
  (r1, r2) => {
    const comp1 = c1(r1, r2);
    return comp1 !== 0 ? comp1 : c2(r1, r2);
  };
const concatComparer = (cs: Comparer[]): Comparer => cs.reduce(appendComparer);
export const negateComparer = (c: Comparer): Comparer => (...args) => c(...args) * -1;

/** testtype data. used for labels and requests. */
export interface TestType {
  name: string;
  value: string;
  testType: string;
}

/** hard coded check for antigen.
 * Used to determine what message to show. */
export function isAntigen(result: Results) {
  return result?.testCode?.trim()?.toLowerCase?.() === "94558-4";
}
/** hard coded check for white label antigen.
 * Used to determine what test type to show. */
export function isWhiteLabelAntigen(result: Results) {
  return result?.testCode?.trim()?.toLowerCase?.() === "95209-3";
}
/** determine if result is for school individual */
export function isLolliIndividual(result: Results) {
  return ["94531-1", "96797-6"].indexOf(result.testCode) > -1;
}
/** inverse of `isLolliIndividual` to get lolli pool */
export function isLolliPool(result: Results) {
  return !isLolliIndividual(result);
}

/** split the test type and manufacturer name.
 * See: https://github.com/ehn-dcc-development/ehn-dcc-schema/blob/release/1.3.0/valuesets/test-manf.json  */
export function getManufacturerTestTypeInfo(manufacturerName:string): { testType:string, manufacturer:string} {
  let m = manufacturerName.split(/,+/);
  const testType = m.pop().trim();
  const manufacturer = m.join();
  return { testType, manufacturer};
}

/**
 * value = group
 */
export const testTypes: TestType[] = [
  { value: "ekjq", testType: "94531-1", name: "SARS-CoV-2 (PCR)" },
  { value: "steo", testType: "94558-4", name: "SARS-CoV-2 (Antigen)" },
  { value: "fbre", testType: "96797-61", name: "SARS-CoV-2 (Mouthwash)" },
  { value: "ekjq", testType: "96797-6", name: "SARS-CoV-2 (PCR)" },
];

export const schoolTestTypes: TestType[] = [
  { value: "krmw", testType: "96797-6", name: "SARS-CoV-2 (PCR)" },
  { value: "krmw", testType: "94531-1", name: "SARS-CoV-2 (PCR)" },
  {
    value: "owkt",
    testType: "96797-61",
    name: "SARS-CoV-2 (PCR Lolli-Pool)",
  },
];
export const whiteLabelTestTypes: TestType[] = [
  { value: "ifnf", testType: "94531-1", name: "SARS-CoV-2 (PCR)" },
  { value: "aufg", testType: "95209-3", name: "SARS-CoV-2 (Antigen)" },
];

/**
 *
 * @param testTypes
 *
 * this function groups array of testTypes with the same name.
 * {
 *   name:     'SARS-CoV-2 (PCR)',
 *   testType: '94531-1|96797-6',
 *   value:    'ekjq|veqk'
 * }
 */

export function groupTestsByName(testTypes:TestType[]): TestType[] {
  const names = uniqBy(testTypes, "name").map(t => t.name);
  return names.map(name => ({
    name,
    testType: testTypes
      .filter(t => t.name == name)
      .map(t => t.testType)
      .join('|'),
    value: testTypes
      .filter(t => t.name == name)
      .map(t => t.value)
      .join('|')
  }))

}
