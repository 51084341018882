import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, take, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { Claim } from 'src/app/models/claims.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  getClaimsBySynlabId = (synlabId: string): Observable<Claim[]> => {
    return this.api$.pipe(
      take(1),
      mergeMap((api) => {
        const url = `${api}/api/profiles/GetBySynlabId/${synlabId.toUpperCase()}`;
        return this.http.get<Claim[]>(url)
      }),
      mergeMap(claims => Array.isArray(claims)
        ? of(claims)
        : throwError('SYNLAB ID does not exist')
      )
    )
  }
}
