import { createAction, props } from "@ngrx/store";
import {
  CheckDuplicatePayload,
  ChildInfo,
  Payload,
  Pool,
  PoolIndividuals,
  PoolTempOrders,
  TempOrderPayload,
} from "src/app/pages/individual-test/orders/data-model";

const checkPool = createAction(
  "[IndividualOrder] Check Pool",
  props<{ q: string }>()
);
const checkPoolSuccess = createAction(
  "[IndividualOrder] Check Pool Success",
  props<{ data: Pool }>()
);
const checkPoolError = createAction(
  "[IndividualOrder] Check Pool Failure",
  props<{ error: any }>()
);
const clearPool = createAction("[IndividualOrder] Clear Pool");

const checkChild = createAction(
  "[IndividualOrder] Check Child",
  props<{ q: string; pool: string }>()
);
const clearChild = createAction("[IndividualOrder] Clear Child");
const checkChildSuccess = createAction(
  "[IndividualOrder] Check Child Success",
  props<{ data: ChildInfo }>()
);
const checkChildError = createAction(
  "[IndividualOrder] Check Child Failure",
  props<{ error: any }>()
);

const submit = createAction(
  "[IndividualOrder] Submit",
  props<{ payload: Payload }>()
);
const submitSuccess = createAction(
  "[IndividualOrder] Submit Success",
  props<{ data: any }>()
);
const submitFailed = createAction(
  "[IndividualOrder] Submit Failed",
  props<{ error: any }>()
);

const checkDuplicate = createAction(
  "[IndividualOrder] Check Duplicate",
  props<{ testTube: string; payload: CheckDuplicatePayload }>()
);
const proceedCreateOrder = createAction(
  "[IndividualOrder] Proceed Create Order"
);
const stopCreateOrder = createAction(
  "[IndividualOrder] Stop Create Order",
  props<{ message?: string; displayError?: boolean; barcode?: string }>()
);

const childExistsError = createAction(
  "[IndividualOrder] Child Exists Error",
  props<{ synlabId: string }>()
);

// check if has negative result
const hasNegativeResults = createAction(
  "[IndividualOrder] Has Negative Results",
  props<{ testTubeIdentifier: string }>()
);
const hasNegativeResultsSuccess = createAction(
  "[IndividualOrder] Has Negative Results Success",
  props<{ value: boolean; barcode: string }>()
);
const hasNegativeResultsFailed = createAction(
  "[IndividualOrder] Has Negative Results Failed",
  props<{ error: any }>()
);

// get pool information
const getPoolInformation = createAction(
  "[IndividualOrder] Get Pool Information",
  props<{ barcode: string }>()
);
const getPoolInformationSuccess = createAction(
  "[IndividualOrder] Get Pool Information Success",
  props<{ data: Pool; barcode: string }>()
);
const getPoolInformationFailed = createAction(
  "[IndividualOrder] Get Pool Information Failed",
  props<{ error: string }>()
);

// get pool count - normal user
const getPoolCountNormal = createAction(
  "[IndividualOrder] Get Pool Count Normal",
  props<{ synlabId: string, poolBarcode: string }>()
);
const getPoolCountNormalSuccess = createAction(
  "[IndividualOrder] Get Pool Count Normal Success",
  props<{ data: PoolIndividuals }>()
);
const getPoolCountNormalFailed = createAction(
  "[IndividualOrder] Get Pool Count Normal Failed",
  props<{ error: any }>()
);

// get pool count - mass_company user
const getPoolCountCompany = createAction(
  "[IndividualOrder] Get Pool Count Company",
  props<{ poolBarcode: string }>()
);
const getPoolCountCompanySuccess = createAction(
  "[IndividualOrder] Get Pool Count Company Success",
  props<{ data: PoolTempOrders[] }>()
);
const getPoolCountCompanyFailed = createAction(
  "[IndividualOrder] Get Pool Count Company Failed",
  props<{ error: any }>()
);

// get pool count - mass_company user
const submitTempOrder = createAction(
  "[IndividualOrder] Submit Temp Order",
  props<{ payload: TempOrderPayload }>()
);
const submitTempOrderSuccess = createAction(
  "[IndividualOrder] Submit Temp Order Success",
  props<{ data: PoolIndividuals }>()
);
const submitTempOrderFailed = createAction(
  "[IndividualOrder] Submit Temp Order Failed",
  props<{ message: string }>()
);

// check child duplicate
const checkDuplicateInList = createAction(
  "[IndividualOrder] Check Duplicate In List",
  props<{ synlabId: string }>()
);
const checkDuplicateInListSuccess = createAction(
  "[IndividualOrder] Check Duplicate In List Success",
  props<{ value: boolean }>()
);

const saveInputValue = createAction(
  "[IndividualOrder] Save Input Value",
  props<{ id: string; value: string; action: string }>()
);

export const IndividualOrderActions = {
  saveInputValue,
  checkDuplicateInList,
  checkDuplicateInListSuccess,
  submitTempOrder,
  submitTempOrderSuccess,
  submitTempOrderFailed,
  getPoolCountCompany,
  getPoolCountCompanySuccess,
  getPoolCountCompanyFailed,
  getPoolCountNormal,
  getPoolCountNormalSuccess,
  getPoolCountNormalFailed,
  getPoolInformation,
  getPoolInformationFailed,
  getPoolInformationSuccess,
  hasNegativeResults,
  hasNegativeResultsSuccess,
  hasNegativeResultsFailed,
  childExistsError,
  checkDuplicate,
  proceedCreateOrder,
  stopCreateOrder,
  submitSuccess,
  submit,
  submitFailed,
  clearChild,
  clearPool,
  checkPoolSuccess,
  checkPoolError,
  checkPool,
  checkChildSuccess,
  checkChild,
  checkChildError,
};
