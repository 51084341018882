import dayjs from "dayjs";
import { Results } from "src/app/@store/models/results.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface NewCovidCertData extends BaseCertData {
  isNewCovidCertificate: boolean;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  barcode: string;
  resultComment: string;
  laboratory: string;
  laboratoryAddress: string;
}
/** result is for pool and is negative and current user is employee */
export const isNewCovidCertResult: ConditionChecker = (r, o) => {
  const isNewCovidCertificate = !!r.uniqueResultId
  return isNewCovidCertificate;
}
export const resultAsNewCovidCertData: ResultMapper<Results, NewCovidCertData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
    ...baseResultAsCertData(r,o),
    isNewCovidCertificate: true,
    firstName: customUppercase(o.userPatientInfo?.firstName?.trim?.()),
    lastName: customUppercase(o.userPatientInfo?.lastName?.trim?.()),
    dateOfBirth: dayjs.tz(o.userPatientInfo?.dateOfBirth, "Europe/Berlin").format("DD.MM.YYYY"),
    barcode: Array.isArray(r.testTubeIdentifiers)
      ? r.testTubeIdentifiers.join(', ')
        : r.testTubeIdentifiers,
    resultComment: o.translations?.[tranKey]?.['message'],
    laboratory: r.laboratory,
    laboratoryAddress: o.labAddress[r.senderIdentifierCode],
    address: o.resultAddress[r.synlabId]?.address,
    passportNumber: o.resultAddress[r.synlabId]?.passportNumber,
  }
}
