import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/@store/reducers';
import * as ProfileReducer from 'src/app/@store/reducers/profile.reducer';
import memoize from 'lodash/fp/memoize';

const State = (state: AppState) => state[ProfileReducer.profileFeatureKey];

export const claimsRecord = createSelector(State, s => s.claimsRecord);
export const errorRecord = createSelector(State, s => s.errorRecord);
export const loadingRecord = createSelector(State, s => s.loadingRecord);

export const claims = memoize((id: string) => createSelector(claimsRecord, s => s[id]));
export const error = memoize((id: string) => createSelector(errorRecord, s => s[id]));
export const loading = memoize((id: string) => createSelector(loadingRecord, s => s[id]));
