import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, map, mergeMap } from 'rxjs/operators';
import { AppState } from 'src/app/@store/reducers';
import { StsSelectors } from 'src/app/@store/selectors/sts.selectors';
import { SingleTest } from 'src/app/models/single-test.model';

@Injectable({
  providedIn: 'root'
})
export class SingleTestService {
  private config$ = this.store.pipe(select(StsSelectors.getConfig), take(1));
  private api$ = this.config$.pipe(map(x => x.basePlasmaUrl), take(1));

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  sendSingleTest = (data: SingleTest) => this.api$.pipe(mergeMap(api => {
    const url = `${api}/api/tests/company/individual`;
    return this.http.post(url, data);
  }))
}
