import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { catchError, map, switchMap, tap, withLatestFrom, } from "rxjs/operators";
import { ApiService } from "src/app/service/api/api.service";
import { LabOrderActions } from "../actions/lab-order.actions";
import { AppState } from "../reducers";
import { StsSelectors } from "../selectors/sts.selectors";

@Injectable()
export class LabOrderEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private transloco: TranslocoService
  ) {}

  checkExistingOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LabOrderActions.checkExistingOrder),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ params, testTubeNumber }, endpoint]) => {
          return this.api.getMethod(
            `${endpoint}/api/pool-tests/testTubeIdentifiers/${testTubeNumber}/exists`,
            params
          );
        }),
        map((rt) => {
          this.store.dispatch(
            LabOrderActions.checkExistingOrderSucess({
              orderExist: rt.doesExist,
            })
          );
        }),
        catchError((error, caught) => {
          this.store.dispatch(
            LabOrderActions.checkExistingError({
              error,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  createNewOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LabOrderActions.createNewOrder),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ payload }, endpoint]) => {
          console.log(payload);
          if (!payload.samplingTime) {
            this.toastr.error(
              "Invalid Sampling Time",
              this.transloco.translate("labOrder.errorTitle")
            );
          }
          return this.api.postMethod(`${endpoint}/api/pool-tests`, payload);
        }),
        tap((rt) => {
          this.store.dispatch(LabOrderActions.createNewOrderSuccess());
        }),
        catchError((error, caught) => {
          console.log(error);
          this.store.dispatch(
            LabOrderActions.createNewOrderError({ error: error })
          );

          let msg = error?.error?.message || error.message;
          this.toastr.error(
            msg,
            this.transloco.translate("labOrder.errorTitle"),
            { positionClass: "toast-bottom-right" }
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getSchool$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LabOrderActions.getSchool),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ synlabId }, endpoint]) => {
          return this.api.getMethod(`${endpoint}/api/schools/${synlabId}`);
        }),
        tap((rt) => {
          this.store.dispatch(
            LabOrderActions.getSchoolSuccess({ data: rt ? rt : null })
          );
        }),
        catchError((error, caught) => {
          console.log(error);
          this.store.dispatch(
            LabOrderActions.createNewOrderError({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  submitScannedSynlabId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LabOrderActions.submitScannedSynlabId),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ synlabId }, endpoint]) =>
          this.api.postMethod(`${endpoint}/api/pool-tests/${synlabId}`, {})
        ),
        tap((rt) => console.log({ data: rt })),
        catchError((error, caught) => caught)
      ),
    { dispatch: false }
  );
}
