import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { combineLatest, merge, Observable, ReplaySubject } from "rxjs";
import { auditTime, startWith, take, withLatestFrom } from "rxjs/operators";
import { LabOrderActions } from "src/app/@store/actions/lab-order.actions";
import { AppState } from "src/app/@store/reducers";
import { LabOrderSelector } from "src/app/@store/selectors/lab-order.selectors";
import { InfoMessageComponent } from "./info-message/info-message.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccessMessageComponent } from "./success-message/success-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { IndividualOrderActions } from "src/app/@store/individual-orders/individual-order.actions";
import { ActionTypes, InputIds, } from "src/app/service/data/submit.input.value.model";
import { SubSink } from "subsink";

@Component({
  selector: "app-lab-order",
  templateUrl: "./lab-order.component.html",
  styleUrls: ["./lab-order.component.scss"],
})
export class LabOrderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("synlabId", { static: false }) synlabIdElement: ElementRef;
  @ViewChild("testTubeNumber", { static: false })
  testTubeNumberElement: ElementRef;
  @ViewChild("poolName", { static: false }) poolNameElement: ElementRef;

  orderExist$: Observable<boolean> = this.store.pipe(
    select(LabOrderSelector.orderExist)
  );
  schoolName$: Observable<any> = this.store.pipe(
    select(LabOrderSelector.getSchool)
  );
  accountHolder$: Observable<any> = this.store.pipe(
    select(LabOrderSelector.getAccountHolder)
  );
  barcodeLoading$: Observable<boolean> = this.store.pipe(
    select(LabOrderSelector.getBarcodeLoading)
  );
  orderLoading$: Observable<boolean> = this.store.pipe(
    select(LabOrderSelector.getOrderLoading)
  );

  private barcodeRegex = /^97\d{8}(01|00)$/; // 97 + 8integer + 00 || 01
  private synlabIdRegex = /^[a-zA-Z0-9]{7}$/; // alphanumeric 7 chars
  private dateRegex =
    /^([1-9]|[0-1][0-2])\/([1-9]|[0-3][0-1]|0[1-9]|1[1-9]|2\d)\/\d{4}$/;

  automaticDefault: boolean = true;

  orderInformation = new UntypedFormGroup({
    synlabId: new UntypedFormControl(null, {
      validators: [Validators.pattern(this.synlabIdRegex), Validators.required],
    }),
    testTubeNumber: new UntypedFormControl(
      { value: null, disabled: true },
      {
        validators: [
          Validators.pattern(this.barcodeRegex),
          Validators.required,
        ],
      }
    ),
    poolName: new UntypedFormControl(null, {
      validators: [Validators.required],
    }),
    automatic: new UntypedFormControl(this.automaticDefault),
    samplingTime: new UntypedFormControl(
      { value: "" },
      {
        validators: [Validators.required],
      }
    ),
  });

  prev = 0;

  defaultDate: any = moment().format("YYYY-MM-DD");
  defaultTime: any = "08:00 AM";
  timeObs$ = new ReplaySubject(1);
  dateObs$ = new ReplaySubject(1);
  dateTime$ = new ReplaySubject(1);
  public date: any = moment().format("YYYY-MM-DD");
  dateFormControlFrom = new UntypedFormControl({ value: this.defaultDate }, [
    Validators.required,
  ]);
  today = new Date();

  isSchoolLoading$ = this.store.pipe(select(LabOrderSelector.getSchoolLoading));
  isInvalidDate$ = new ReplaySubject(0);

  private subs = new SubSink();

  constructor(
    private store: Store<AppState>,
    private action$: Actions,
    private toastr: ToastrService,
    private transloco: TranslocoService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initWrap();

    this.checkExistingOrderSucess();
    this.checkExistingOrderAction();
    this.checkExistingError();
    this.createNewOrder();
    this.createNewOrderSuccess();
    this.createNewOrderError();
    this.inputChanges();

    // clear school info when exist
    this.store.dispatch(LabOrderActions.resetSchool());
  }

  initWrap() {
    let form = this.orderInformation;

    this.subs.sink = merge(
      form.get("synlabId").valueChanges,
      form.get("automatic").valueChanges
    )
      .pipe(auditTime(50))
      .subscribe((n) => {
        let { automatic, synlabId } = form.getRawValue();
        let synlabIdValid = form.get("synlabId").valid;

        // get school information if synlabId is valid
        if (synlabIdValid) {
          this.store.dispatch(
            LabOrderActions.getSchool({
              synlabId: synlabId.toUpperCase().trim(),
            })
          );
          form.get("testTubeNumber").enable();

          // if automatic enabled, focus on testtubenumber
          if (automatic) {
            this.testTubeNumberElement.nativeElement.focus();

            this.logInputValue(
              InputIds.spoSynlabId,
              synlabId,
              ActionTypes.auto
            );
          }
        } else {
          // reset school info if exist
          this.store.dispatch(LabOrderActions.resetSchool());
          this.store.dispatch(LabOrderActions.inputChanges());
        }
      });

    this.subs.sink = merge(
      form.get("testTubeNumber").valueChanges,
      form.get("automatic").valueChanges
    )
      .pipe(auditTime(50))
      .subscribe((n) => {
        let { automatic } = form.getRawValue();
        let isTestTubeNumberValid = form.get("testTubeNumber").valid;
        let synlabIdValid = form.get("synlabId").valid;

        this.store.dispatch(LabOrderActions.inputChanges());

        if (isTestTubeNumberValid && synlabIdValid) {
          if (automatic) {
            this.logInputValue(
              InputIds.spoBarcode,
              form.get("testTubeNumber").value,
              ActionTypes.auto
            );

            this.checkExistingOrder();
          }
        }
      });

    this.dateTimeChanges();

    // clear form
    this.subs.sink = merge(
      this.action$.pipe(ofType(LabOrderActions.checkExistingOrderSucess)),
      this.action$.pipe(ofType(LabOrderActions.checkExistingError)),
      this.action$.pipe(ofType(LabOrderActions.createNewOrderError)),
      this.action$.pipe(ofType(LabOrderActions.createNewOrderSuccess))
    )
      .pipe(auditTime(100))
      .subscribe((n) => {
        this.dateFormControlFrom.patchValue(this.defaultDate);
      });
  }
  /**
   *
   * @param value Value that trigger by the event.
   * @param type check if triggers by SynlabId or Barcode 0 = synlabId, 1 = Barcode
   */
  onKeyEnter(value, type?: number) {
    let f = this.orderInformation;

    let isSynlabFormat = this.synlabIdRegex.test(value);
    let isBarcodeFormat = this.barcodeRegex.test(value);

    // barcode on enter keypress
    if (type) {
      // assign value as synlabId and clear barcode field
      if (isSynlabFormat) {
        f.get("testTubeNumber").reset();

        f.get("synlabId").enable({ emitEvent: false });
        f.get("synlabId").patchValue(value);
        this.testTubeNumberElement.nativeElement.focus();

        this.logInputValue(InputIds.spoBarcode, value, ActionTypes.enter);

        return;
      }

      let a = f.get("synlabId").valid;
      let b = f.get("testTubeNumber").valid;

      // validation is passed, proceed to api call
      if (a && b) {
        this.logInputValue(InputIds.spoBarcode, value, ActionTypes.enter);
        this.checkExistingOrder();
      }
    }

    // synlabId on enter keypress
    else {
      // if valid synlabId and press "Enter", focus on barcode field
      if (isSynlabFormat) {
        // if barcode is invalid, reset the barcode value
        let b = f.get("testTubeNumber").valid;
        if (!b) {
          f.get("testTubeNumber").reset();
          f.get("testTubeNumber").patchValue("", { emitEvent: false });

          this.testTubeNumberElement.nativeElement.focus();

          this.logInputValue(
            InputIds.spoSynlabId,
            f.get("synlabId").value,
            ActionTypes.enter
          );

          return;
        } else {
          this.checkExistingOrder();
        }
      } else if (isBarcodeFormat) {
        f.get("synlabId").reset();

        f.get("testTubeNumber").enable({ emitEvent: false });
        f.get("testTubeNumber").patchValue(value, { emitEvent: false });

        this.logInputValue(InputIds.spoSynlabId, value, ActionTypes.enter);
      }
    }
  }

  inputChanges() {
    this.subs.sink = this.action$
      .pipe(ofType(LabOrderActions.inputChanges))
      .subscribe((n) => {
        const f = this.orderInformation;
        f.get("automatic").enable({ emitEvent: false });
        f.get("poolName").reset();
      });
  }

  ngOnDestroy() {
    this._snackBar.dismiss();

    this.subs.unsubscribe();
  }

  createNewOrderError() {
    this.subs.sink = this.action$
      .pipe(ofType(LabOrderActions.createNewOrderError))
      .subscribe((n) => {
        let f = this.orderInformation;
        f.get("synlabId").enable({ emitEvent: false });
        f.get("testTubeNumber").enable({ emitEvent: false });
        f.get("automatic").enable({ emitEvent: false });
        f.get("poolName").enable({ emitEvent: false });

        this.setDefaulDateAndTime();

        f.get("automatic").patchValue(this.automaticDefault, {
          emitEvent: false,
        });
      });
  }

  createNewOrderSuccess() {
    this.subs.sink = this.action$
      .pipe(ofType(LabOrderActions.createNewOrderSuccess))
      .subscribe((n) => {
        const f = this.orderInformation;

        this._snackBar.openFromComponent(SuccessMessageComponent, {
          duration: 300000,
          verticalPosition: "bottom",
          horizontalPosition: "left",
          panelClass: "lab-order",
        });

        f.reset();
        f.get("synlabId").enable({ emitEvent: false });
        f.get("poolName").enable({ emitEvent: false });

        f.get("automatic").patchValue(this.automaticDefault, {
          emitEvent: false,
        });

        this.setDefaulDateAndTime();

        setTimeout(() => {
          this.synlabIdElement.nativeElement.focus();
        }, 100);
      });
  }

  createNewOrder() {
    this.subs.sink = this.action$
      .pipe(ofType(LabOrderActions.createNewOrder))
      .subscribe((n) => {
        const f = this.orderInformation;
        f.get("synlabId").disable({ emitEvent: false });
        f.get("testTubeNumber").disable({ emitEvent: false });
        f.get("automatic").disable({ emitEvent: false });
        f.get("poolName").disable({ emitEvent: false });
      });
  }

  checkExistingOrderSucess() {
    this.subs.sink = this.action$
      .pipe(
        ofType(LabOrderActions.checkExistingOrderSucess),
        withLatestFrom(this.orderExist$, this.barcodeLoading$)
      )
      .pipe(auditTime(100))
      .subscribe(([n, exist, loading]) => {
        let f = this.orderInformation;
        let a = f.get("automatic");

        if (exist) {
          f.get("synlabId").reset();
          f.get("testTubeNumber").reset();

          if (n.orderExist) {
            this._snackBar.openFromComponent(InfoMessageComponent, {
              duration: 300000,
              verticalPosition: "bottom",
              horizontalPosition: "left",
              panelClass: "lab-order",
            });

            if (a.value) {
              f.get("automatic").patchValue(true, { emitEvent: false });
            }
          }

          f.get("synlabId").enable({ emitEvent: false });
          f.get("testTubeNumber").disable({ emitEvent: false });
          this.synlabIdElement.nativeElement.focus();
        } else {
          f.get("automatic").patchValue(false, { emitEvent: false });
          f.get("automatic").disable({ emitEvent: false });

          f.get("synlabId").enable({ emitEvent: false });
          f.get("testTubeNumber").enable({ emitEvent: false });
          setTimeout((d) => this.poolNameElement.nativeElement.focus(), 200);
          this.prev = 0;
        }
      });
  }

  checkExistingOrderAction() {
    this.subs.sink = this.action$
      .pipe(
        ofType(LabOrderActions.checkExistingOrder),
        withLatestFrom(this.barcodeLoading$)
      )
      .subscribe(([n, loading]) => {
        let f = this.orderInformation;
        if (loading) {
          f.get("synlabId").disable({ emitEvent: false });
          f.get("testTubeNumber").disable({ emitEvent: false });
          f.get("automatic").disable({ emitEvent: false });
        }
      });
  }

  checkExistingError() {
    this.subs.sink = this.action$
      .pipe(
        ofType(LabOrderActions.checkExistingError),
        withLatestFrom(this.barcodeLoading$)
      )
      .pipe(auditTime(100))
      .subscribe(([{ error }, isLoading]) => {
        const f = this.orderInformation;

        let x = error ? error.error : null;
        let message = x
          ? x.message
          : this.transloco.translate("labOrder.invalidSynlabMessage");
        console.log({ message });

        this._snackBar.openFromComponent(ErrorMessageComponent, {
          duration: 300000,
          verticalPosition: "bottom",
          horizontalPosition: "left",
          panelClass: "lab-order",
          data: {
            title: this.transloco.translate("labOrder.invalidSynlabTitle"),
            message,
          },
        });

        f.reset();
        f.get("synlabId").enable({ emitEvent: false });
        f.get("testTubeNumber").disable({ emitEvent: false });
        f.get("automatic").enable({ emitEvent: false });

        f.get("automatic").patchValue(this.automaticDefault, {
          emitEvent: false,
        });
        this.setDefaulDateAndTime();
        this.synlabIdElement.nativeElement.focus();
      });
  }

  ngAfterViewInit(): void {
    this.initfocus();
  }

  dateTimeChanges() {
    this.subs.sink = combineLatest([
      this.timeObs$.pipe(startWith(<string>null)),
      this.dateObs$.pipe(startWith(<string>null))
    ]).pipe(auditTime(50))
      .subscribe(([n, o]) => {
        // set initial load value for datetime
        if (n == null && o == null) {
          this.setDefaulDateAndTime();
        } else {
          if (!n || !o) {
            this.dateTime$.next("invalid");
          } else {
            this.subs.sink = this.dateObs$
              .pipe(take(1), withLatestFrom(this.timeObs$))
              .subscribe(([d, t]) => {
                let date = moment(
                  `${moment(d).format("YYYY-MM-DD")} ${t}`,
                  "YYYY-MM-DDTHH:mm:ss[Z]"
                )
                  .subtract(2, "h")
                  .format();
                const utcCET = `${moment(date).format("YYYY-MM-DD")}T${moment(
                  date
                ).format("HH:mm:ss")}Z`;

                this.dateTime$.next(utcCET);
              });
          }
        }
      });

    // store datetime value on formgroup
    this.subs.sink = this.dateTime$.pipe(auditTime(100)).subscribe((n) => {
      if (n != "invalid") {
        this.orderInformation.get("samplingTime").patchValue(n);
      } else {
        this.orderInformation.get("samplingTime").patchValue("");
      }
    });
  }

  setDefaulDateAndTime() {
    let m = `${this.defaultDate}  ${this.defaultTime}`;
    let init = moment(m).format();

    // set values
    this.dateObs$.next(this.defaultDate);
    this.timeObs$.next(this.defaultTime);
    this.dateTime$.next(init);
  }

  testTubeFocus() {
    this.subs.sink = this.isSchoolLoading$.pipe(take(1)).subscribe((t) => {
      if (!t) {
        let form = this.orderInformation;
        let synlabIdValid = form.get("synlabId").valid;
        let tube = form.get("testTubeNumber").value;

        // if barcode is null or empty, set as pristine
        if (!tube) {
          form.get("testTubeNumber").markAsPristine();
        }

        // if synlabId valid, focus cursor to test tube
        if (synlabIdValid) {
          form.get("testTubeNumber").enable();
          this.testTubeNumberElement.nativeElement.focus();

          // save value for logging purposes
          this.logInputValue(
            InputIds.spoSynlabId,
            form.get("synlabId").value,
            ActionTypes.click
          );
        }
      }
    });
  }

  checkExistingOrder(action?: "auto" | "click") {
    const { synlabId, testTubeNumber } = this.orderInformation.getRawValue();
    const voucherCode = synlabId.toUpperCase().trim();

    if (action == "click") {
      this.logInputValue(
        InputIds.spoBarcode,
        testTubeNumber,
        ActionTypes.click
      );
    }

    this.store.dispatch(
      LabOrderActions.checkExistingOrder({
        params: { voucherCode },
        testTubeNumber: testTubeNumber,
      })
    );
  }

  sendOrder(action?: "enter" | "submit") {
    this.subs.sink = this.accountHolder$
      .pipe(take(1))
      .subscribe((accountHolder) => {
        let f = this.orderInformation.getRawValue();
        let { synlabId, poolName, testTubeNumber, samplingTime } = f;

        this.store.dispatch(
          LabOrderActions.createNewOrder({
            payload: {
              poolName: poolName.trim(),
              schoolId: synlabId.toUpperCase().trim(),
              schoolName: accountHolder,
              samplingTime,
              testTubeIdentifier: testTubeNumber,
            },
          })
        );

        // save input value for logs
        this.logInputValue(
          InputIds.spoPoolName,
          poolName,
          action == "enter" ? ActionTypes.enter : ActionTypes.click
        );
      });
  }

  initfocus() {
    setTimeout(() => {
      if (this.synlabIdElement) {
        this.synlabIdElement.nativeElement.focus();
      }
    }, 100);
  }

  selectedDate(event) {
    console.log({ event });
  }

  timeChangedEvt(evt) {
    this.timeObs$.next(evt);
  }

  trimInput(event, n) {
    let trimed;
    let f = this.orderInformation.get(n);

    if (n !== "poolName") {
      trimed = f.value.trim();
    } else {
      trimed = f.value.trimStart();
    }

    this.orderInformation.get(n).patchValue(trimed);
  }

  manualDateInput(value: string, source?: string) {
    let valid = this.dateRegex.test(value);

    console.log({ valid, value });

    if (!valid) {
      this.dateObs$.next("");
      this.isInvalidDate$.next(true);
    } else {
      let isFuture = moment().diff(value) < 0;
      let isInvalidInMoment = !moment(value).isValid();
      if (isFuture || isInvalidInMoment) {
        this.dateObs$.next("");
        this.isInvalidDate$.next(true);
      } else {
        this.dateObs$.next(value);
        this.isInvalidDate$.next(false);
      }
    }
  }

  isSchoolInfoLoading() {
    this.subs.sink = merge(
      this.isSchoolLoading$,
      this.orderInformation.get("synlabId").valueChanges
    )
      .pipe(auditTime(10))
      .pipe(withLatestFrom(this.isSchoolLoading$))
      .subscribe(([t, loading]) => {
        let x = this.orderInformation.get("synlabId");
        let y = x.valid && x.value;

        let z = this.orderInformation.get("testTubeNumber");
        if (y && !loading) {
          // clear validation error if value is empty
          z.enable();
          setTimeout(() => {
            if (!z.value) {
              z.markAsPristine();
            }

            let x = this.testTubeNumberElement;
            if (x) {
              x.nativeElement.focus();
            }
          }, 100);
        } else {
          z.disable();
        }
      });
  }

  logInputValue(id: string, value: string, action: string) {
    this.store.dispatch(
      IndividualOrderActions.saveInputValue({
        id,
        value,
        action,
      })
    );
  }
}
