import { Results } from "src/app/@store/models/results.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface SchoolPoolData extends BaseCertData {
  isSchoolPool: true;
  poolName: string;
  teacherName: string;
  barcode: string;
  resultComment: string;
  laboratory: string;
  issuedOnDate: string;
  laboratoryAddress: string;
}
export const isSchoolPoolResult: ConditionChecker = (r, o) => {
  const isCompanyPool = o.companyCheckRecord[r.synlabId]?.isPoolCompany;
  const isPool = o.isPoolRecord[r.synlabId];
  return isPool && !isCompanyPool;
}
export const resultAsSchoolPoolCertData: ResultMapper<Results, SchoolPoolData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
    ...baseResultAsCertData(r, o),
    isSchoolPool: true,
    poolName: customUppercase(r?.firstName?.trim?.()),
    teacherName: customUppercase(r?.lastName?.trim?.()),
    barcode: Array.isArray(r.testTubeIdentifiers)
      ? r.testTubeIdentifiers.join(', ')
      : r.testTubeIdentifiers,
    resultComment: o.translations?.[tranKey]?.['lolliMessage'],
    laboratory: r.laboratory,
    issuedOnDate: r.issuedOnDateOnly,
    laboratoryAddress: o.labAddress[r.senderIdentifierCode]
  }
}
