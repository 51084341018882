import { HttpClient } from "@angular/common/http";
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
} from "@ngneat/transloco";
import { APP_INITIALIZER, Injectable, NgModule } from "@angular/core";
import { environment } from "src/environments/environment";
import { ConfigService } from "./service/config/config.service";
import { I18nService } from "./service/i18n/i18n.service";
import { of, from } from "rxjs";
import { filter, mergeMap, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { memoize } from 'lodash/fp';
import { uniq } from 'lodash';
import { localStorageKeys } from "./shared/model/storage.model";
import { formatLocale } from "./plasma-ui-common/utils/format-locale";
import { getLangCodeFromLocalStorage, getLangCodeFromQueryString } from "./shared/component-modules/core/util/common.utils";
import { ACCOUNTS_LOCALE_STORAGE_KEY, PATIENT_WEB_LOCALE_STORAGE_KEY } from "./plasma-ui-common/synlab-access-ui-components/core/constants";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private config: ConfigService) { }

  getTranslation = memoize((lang: string) => {
    return of(lang).pipe(
      mergeMap(l => this.http.get<Translation>(`/assets/i18n/${l}.json`, {
        params: { version: this.config._envConfig.Version },
      })),
      shareReplay({ refCount: false, bufferSize: 1 })
    )
  })
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          "en" /** english */,
          "es-CO" /** english */,
          "hu" /** hungarian */,
          "pt" /** portuguese */,
          "fr-FR" /** french/france */,
          "es-ES" /** spain/spanish */,
          "it-IT" /** italian/italy */,
          "de" /** german/germanian */,
          "nl-NL" /** Netherlands/Dutch */,
          "de-CH" /** German-Switzerland */,
          "fr-CH" /** French-Switzerland */,
          "it-CH" /** Italian-Switzerland */,
          "lt-LT" /** Lithuanian */,
          "tr-TR" /** Turkish (Turkey) */,
          "el" /** Greek */,
          "et", /**  Estonian */
        ],
        defaultLang: "en",
        missingHandler: {
          logMissingKey: true,
          useFallbackTranslation: true,
        },
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    /** post-transloco-import setup because config data cannot be fetched
     * before transloco config is setup as provider
     * * Loads available language from config
     * * sets current language after available languages are set
     */
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService, TranslocoService, I18nService],
      useFactory: (configService: ConfigService, transloco: TranslocoService, i18n: I18nService) => {
        return () => {
          return configService.getEnvironmentConfig().pipe(
            filter(x => Boolean(x)),
            take(1),
            switchMap(config => {
              const getAvailableLocals = configService.getAvailableLocales().then(locales => {
                return {
                  ...config,
                  AvailableLanguage: locales.join(',')
                };
              });
              return from(getAvailableLocals);
            }),
            tap(config => {
              const configLanguages = config.AvailableLanguage?.split(',')
              let availableLanguages = [...(Array.isArray(configLanguages) ? configLanguages : [])].map(locale => formatLocale(locale));
              availableLanguages = uniq(availableLanguages)
              transloco.setDefaultLang(config.DefaultLanguage)
              transloco.setAvailableLangs(availableLanguages)
              i18n.detectBrowserLocale();


              const getLocale: Function = isInAvailableLocale;
              const locale: string = getLangCodeFromQueryString();
              const savedLocale: string = getLangCodeFromLocalStorage();

              const t = formatLocale(getLocale(locale, availableLanguages), <any>config);
              if (locale && t != "") {
                // set activeLanguage from query string
                transloco.setActiveLang(t);
                sessionStorage?.setItem(ACCOUNTS_LOCALE_STORAGE_KEY, t);
                localStorage?.setItem(PATIENT_WEB_LOCALE_STORAGE_KEY, t);
              } else if (savedLocale) {
                // set activeLanguage from localStorage
                transloco.setActiveLang(savedLocale?.toLowerCase());
              } else {
                // set activeLanguage from <config.DefaultLanguage>
                const initLang: string = formatLocale(getLocale(locale, availableLanguages), <any>config);
                transloco.setActiveLang(initLang);
                sessionStorage?.setItem(ACCOUNTS_LOCALE_STORAGE_KEY, initLang);
                localStorage?.setItem(PATIENT_WEB_LOCALE_STORAGE_KEY, initLang);
              }

              /** hide text initially -- css styles, to prevent on displaying translation keys on first load */
              setTimeout(() => document.body.removeAttribute('ngCloak'), 100);
            })
          );
        };
      },
    },
  ],
})
export class TranslocoRootModule { }

export function isInAvailableLocale(locale:string, available:string[]):string {
  if(!locale) { return ""; }
  if ( available.map((l) => l.toLowerCase()).indexOf(locale?.toLowerCase()) > -1 ) {
    return locale.length == 2 ? locale.toLowerCase() : locale;
  }
  if (locale.split("-").length > 1) {
    return isInAvailableLocale(locale.split("-")[0], available);
  }
  return "";
}
