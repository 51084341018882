<div data-test-id="test-result-detail-user-container" class="container py-4 user-container mb-150">
  <div data-test-id="test-result-detail-user-container-row" class="row">
    <div data-test-id="test-result-detail-user-container-inner" class="col-12">
      <div data-test-id="test-result-detail-mb-2" class="d-flex mb-2">
        <div data-test-id="test-result-detail-fullBloodCount-count" class="flx-1">
          <div data-test-id="test-result-detail-fullBloodCount" id="fullBloodCount" class="page-title p-0 m-0"><em
              data-test-id="test-result-detail-tint" class="fas fa-tint"></em> Full Blood Count</div>
        </div>
        <div data-test-id="test-result-detail-testDate-cont" class="flx-1 mrg-auto">
          <h6 data-test-id="test-result-detail-testDate" class="text-right mr-4">Date Performed: {{testDate}}</h6>
        </div>
      </div>
      <form data-test-id="test-result-detail-form">
        <table data-test-id="test-result-detail-table" class="table" aria-describedby="fullBloodCount">
          <thead data-test-id="test-result-detail-table-thead">
            <tr data-test-id="test-result-detail-table-tr">
              <th data-test-id="test-result-detail-param" scope="col" sortable="clientName">
                Parameter
              </th>
              <th data-test-id="test-result-detail-actual-reading" scope="col" sortable="name">
                Actual Reading
              </th>
              <th data-test-id="test-result-detail-unit-measure" scope="col" sortable="clientName">
                Unit of Measure
              </th>
              <th data-test-id="test-result-detail-ref-range" scope="col" sortable="clientName">
                Reference Range
              </th>
            </tr>
          </thead>
          <tbody data-test-id="test-result-detail-testDetail-tbody">
            <tr [attr.data-test-id]="'test-result-detail-testDetail-tr'+i" *ngFor="let t of testDetail; let i = index">
              <td data-test-id="test-result-detail-parametertd">
                <ngb-highlight data-test-id="test-result-detail-parameter" [result]="t.parameter"
                  [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td data-test-id="test-result-detail-actualReadingtd">
                <ngb-highlight data-test-id="test-result-detail-actualReading" [result]="t.actualReading"
                  [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td data-test-id="test-result-detail-unitOfMeasuretd">
                <ngb-highlight data-test-id="test-result-detail-unitOfMeasure" [result]="t.unitOfMeasure"
                  [term]="state.searchTerm"></ngb-highlight>
              </td>
              <td data-test-id="test-result-detail-referenceRangetd">
                <ngb-highlight data-test-id="test-result-detail-referenceRange" [result]="t.referenceRange"
                  [term]="state.searchTerm"></ngb-highlight>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div data-test-id="test-result-detail-home-cont" class="text-right">
        <button data-test-id="test-result-detail-home" class="btn btn-outline-primary mr-3" [routerLink]="['/home']">
          Back to My Test Results
        </button>
      </div>
    </div>
  </div>
</div>
