import { createReducer, on } from '@ngrx/store';
import { Claim } from 'src/app/models/claims.model';
import { CreatePoolBarcodeStateRecord, HasNegativeStateRecord, ParticipantClaimStateRecord, PoolBarcodeExistRecord, PoolBarcodeInformationRecord, PoolClaimsRecord, PoolIndividualOrdersStateRecord, TempOrderRequestRecord, newPoolBarcodeInformationState } from 'src/app/models/pool-order.model';
import { PoolOrderActions } from '../actions/pool-order.actions';

export const poolOrderFeatureKey = 'poolOrder';

export interface State {
  poolBarcodeExists: PoolBarcodeExistRecord;
  poolBarcodeInfo: PoolBarcodeInformationRecord;
  poolClaims: PoolClaimsRecord;
  schoolClaims: Claim[];
  schoolClaimsLoading: boolean;
  schoolClaimsError: string;
  activePoolBarcode: string;
  poolIndividualOrders: PoolIndividualOrdersStateRecord;
  poolBarcodeHasNegative: HasNegativeStateRecord;
  createPoolBarcodeState: CreatePoolBarcodeStateRecord;

  /** current participant synlab id values */
  activeParticipantSynlabId: string;
  participantClaimRecord: ParticipantClaimStateRecord;
  /** current participant barcode value */
  activeParticipantBarcode: string;
  /** temp order request state */
  tempOrderRequestRecord: TempOrderRequestRecord;
}

export const initialState: State = {
  poolBarcodeExists: {},
  poolBarcodeInfo: {},
  poolClaims: {},
  schoolClaims: [],
  schoolClaimsLoading: false,
  schoolClaimsError: undefined,
  activePoolBarcode: undefined,
  poolIndividualOrders: {},
  poolBarcodeHasNegative: {},
  createPoolBarcodeState: {},
  activeParticipantSynlabId: undefined,
  participantClaimRecord: {},
  activeParticipantBarcode: undefined,
  tempOrderRequestRecord: {}
};


export const reducer = createReducer<State>(
  initialState,
  /** pool barcode exists state */
  on(PoolOrderActions.checkPoolBarcodeExists, (s,a) => ({
    ...s,
    poolBarcodeExists: {
      ...s.poolBarcodeExists,
      [a.barcode]: {
        error: undefined,
        exists: false,
        requestAttempted: true,
        loading: true
      }
    }
  })),
  on(PoolOrderActions.checkPoolBarcodeExistsSuccess, (s,a) => ({
    ...s,
    poolBarcodeExists: {
      ...s.poolBarcodeExists,
      [a.barcode]: {
        error: undefined,
        exists: a.exists,
        requestAttempted: true,
        loading: false
      }
    }
  })),
  on(PoolOrderActions.checkPoolBarcodeExistsFailure, (s,a) => ({
    ...s,
    poolBarcodeExists: {
      ...s.poolBarcodeExists,
      [a.barcode]: {
        error: a.error,
        exists: false,
        requestAttempted: true,
        loading: false
      }
    }
  })),
  /** pool barcode information state */
  on(PoolOrderActions.loadPoolBarcodeInfo, (s,a) => ({
    ...s,
    poolBarcodeInfo: {
      ...s.poolBarcodeInfo,
      [a.barcode]: {
        ...newPoolBarcodeInformationState(),
        loading: true
      }
    }
  })),
  on(PoolOrderActions.loadPoolBarcodeInfoSuccess, (s,a) => ({
    ...s,
    poolBarcodeInfo: {
      ...s.poolBarcodeInfo,
      [a.barcode]: {
        ...s.poolBarcodeInfo[a.barcode],
        loading: false,
        orderId: a.barcodeInfo.orderId,
        organizationSynlabId: a.barcodeInfo.organizationSynlabId,
        samplingDate: a.barcodeInfo.samplingDate,
        synlabId: a.barcodeInfo.synlabId,
        error: undefined
      }
    }
  })),
  on(PoolOrderActions.loadPoolBarcodeInfoFailure, (s,a) => ({
    ...s,
    poolBarcodeInfo: {
      ...s.poolBarcodeInfo,
      [a.barcode]: {
        ...newPoolBarcodeInformationState(),
        loading: false,
        error: a.error,
      }
    }
  })),
  /** pool individual orders */
  on(PoolOrderActions.loadPoolIndividualOrders, (s,a) => ({
    ...s,
    poolIndividualOrders: {
      ...s.poolIndividualOrders,
      [a.poolBarcode]: {
        error: undefined,
        loading: true,
        poolIndividualOrders: undefined,
      }
    }
  })),
  on(PoolOrderActions.loadPoolIndividualOrdersSuccess, (s,a) => ({
    ...s,
    poolIndividualOrders: {
      ...s.poolIndividualOrders,
      [a.poolBarcode]: {
        error: undefined,
        loading: false,
        poolIndividualOrders: a.poolIndividualOrders,
      }
    }
  })),
  on(PoolOrderActions.loadPoolIndividualOrdersFailure, (s,a) => ({
    ...s,
    poolIndividualOrders: {
      ...s.poolIndividualOrders,
      [a.poolBarcode]: {
        error: a.error,
        loading: false,
        poolIndividualOrders: undefined,
      }
    }
  })),
  /** has negative results */
  on(PoolOrderActions.loadHasNegativeResults, (s,a) => ({
    ...s,
    poolBarcodeHasNegative: {
      ...s.poolBarcodeHasNegative,
      [a.barcode]: {
        error: undefined,
        hasNegative: false,
        loading: true
      }
    }
  })),
  on(PoolOrderActions.loadHasNegativeResultsSuccess, (s,a) => ({
    ...s,
    poolBarcodeHasNegative: {
      ...s.poolBarcodeHasNegative,
      [a.barcode]: {
        error: undefined,
        hasNegative: a.hasNegative,
        loading: false
      }
    }
  })),
  on(PoolOrderActions.loadHasNegativeResultsFailure, (s,a) => ({
    ...s,
    poolBarcodeHasNegative: {
      ...s.poolBarcodeHasNegative,
      [a.barcode]: {
        error: a.error,
        hasNegative: false,
        loading: false
      }
    }
  })),
  /** create pool state */
  on(PoolOrderActions.createPoolBarcode, (s, a) => ({
    ...s,
    createPoolBarcodeState: {
      ...s.createPoolBarcodeState,
      [a.barcode]: {
        error: undefined,
        loading: true,
        success: false
      }
    }
  })),
  on(PoolOrderActions.createPoolBarcodeSuccess, (s, a) => ({
    ...s,
    createPoolBarcodeState: {
      ...s.createPoolBarcodeState,
      [a.barcode]: {
        error: undefined,
        loading: false,
        success: true
      }
    }
  })),
  on(PoolOrderActions.createPoolBarcodeFailure, (s, a) => ({
    ...s,
    createPoolBarcodeState: {
      ...s.createPoolBarcodeState,
      [a.barcode]: {
        error: a.error,
        loading: false,
        success: false
      }
    }
  })),
  /** pool claims */
  on(PoolOrderActions.loadPoolClaims, (s,a) => ({
    ...s,
    poolClaims: {
      ...s.poolClaims,
      [a.poolSynlabId]: {
        ...s.poolClaims[a.poolSynlabId],
        loading: true,
        claims: [],
        error: undefined
      }
    }
  })),
  on(PoolOrderActions.loadPoolClaimsSuccess, (s,a) => ({
    ...s,
    poolClaims: {
      ...s.poolClaims,
      [a.poolSynlabId]: {
        ...s.poolClaims[a.poolSynlabId],
        loading: false,
        claims: a.claims,
        error: undefined
      }
    }
  })),
  on(PoolOrderActions.loadPoolClaimsFailure, (s,a) => ({
    ...s,
    poolClaims: {
      ...s.poolClaims,
      [a.poolSynlabId]: {
        ...s.poolClaims[a.poolSynlabId],
        loading: false,
        claims: [],
        error: a.error
      }
    }
  })),
  /** participant synlab id */
  on(PoolOrderActions.setActiveParticipantSynlabId, (s, a) => ({
    ...s,
    activeParticipantSynlabId: a.synlabId
  })),
  /** participant claims state */
  on(PoolOrderActions.loadParticipantClaims, (s, a) => ({
    ...s,
    participantClaimRecord: {
      ...s.participantClaimRecord,
      [a.synlabId]: {
        claims: undefined,
        error: undefined,
        exists: false,
        loading: true
      }
    }
  })),
  on(PoolOrderActions.loadParticipantClaimsSuccess, (s, a) => ({
    ...s,
    participantClaimRecord: {
      ...s.participantClaimRecord,
      [a.synlabId]: {
        claims: a.claims,
        error: undefined,
        exists: true,
        loading: false
      }
    }
  })),
  on(PoolOrderActions.loadParticipantClaimsFailure, (s, a) => ({
    ...s,
    participantClaimRecord: {
      ...s.participantClaimRecord,
      [a.synlabId]: {
        claims: undefined,
        error: a.error,
        exists: false,
        loading: false
      }
    }
  })),
  /** temp order requests */
  on(PoolOrderActions.tempOrderRequest, (s,a) => ({
    ...s,
    tempOrderRequestRecord: {
      ...s.tempOrderRequestRecord,
      [a.participantBarcode]: {
        error: undefined,
        loading: true,
        success: false
      }
    }
  })),
  on(PoolOrderActions.tempOrderRequestSuccess, (s,a) => ({
    ...s,
    tempOrderRequestRecord: {
      ...s.tempOrderRequestRecord,
      [a.participantBarcode]: {
        error: undefined,
        loading: false,
        success: true
      }
    }
  })),
  on(PoolOrderActions.tempOrderRequestFailure, (s,a) => ({
    ...s,
    tempOrderRequestRecord: {
      ...s.tempOrderRequestRecord,
      [a.participantBarcode]: {
        error: a.error,
        loading: false,
        success: false
      }
    }
  })),
  /** active participant barcode */
  on(PoolOrderActions.setActiveParticipantBarcode, (s, a) => ({
    ...s,
    activeParticipantBarcode: a.barcode
  })),
  /** school claims */
  on(PoolOrderActions.loadSchoolClaims, s => ({
    ...s,
    schoolClaimsLoading: true
  })),
  on(PoolOrderActions.loadSchoolClaimsSuccess, (s, a) => ({
    ...s,
    schoolClaimsLoading: false,
    schoolClaims: a.claims
  })),
  on(PoolOrderActions.loadSchoolClaimsFailure, (s, a) => ({
    ...s,
    schoolClaimsLoading: false,
    schoolClaimsError: a.error
  })),
  on(PoolOrderActions.viewPoolBarcode, (s, a) => ({
    ...s,
    activePoolBarcode: a.barcode
  })),
  on(PoolOrderActions.reset, () => initialState)
);


