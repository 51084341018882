import { Results } from "src/app/@store/models/results.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { BaseCertData, baseResultAsCertData, ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";

export interface CompanyPoolData extends BaseCertData {
  isCompanyPool: true;
  companyName: string;
  poolName: string;
  barcode: string;
  resultComment: string;
  laboratory: string;
  issuedOnDate: string;
  laboratoryAddress: string;
}
export const isCompanyPoolResult: ConditionChecker = (r, o) => {
  const isCompanyPoolItem = o.companyCheckRecord[r.synlabId];
  return isCompanyPoolItem?.isPoolCompany;
}
export const resultAsCompanyPoolCertData: ResultMapper<Results, CompanyPoolData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
    ...baseResultAsCertData(r, o),
    isCompanyPool: true,
    companyName: customUppercase(o.companyCheckRecord[r?.poolSynlabId]?.companyName),
    poolName: customUppercase(r?.firstName?.trim?.()),
    barcode: Array.isArray(r.testTubeIdentifiers)
      ? r.testTubeIdentifiers.join(', ')
      : r.testTubeIdentifiers,
    resultComment: o.translations?.[tranKey]?.['companyPoolMessage'],
    laboratory: r.laboratory,
    issuedOnDate: r.issuedOnDateOnly,
    laboratoryAddress: o.labAddress[r.senderIdentifierCode]
  }
}
