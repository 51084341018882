import { createReducer, on } from '@ngrx/store';
import { Claim } from 'src/app/models/claims.model';
import * as ProfileActions from 'src/app/@store/actions/profile.actions';

type SynlabId = string;

export const profileFeatureKey = 'profile';
export interface State {
  claimsRecord: Record<SynlabId, Claim[]>;
  loadingRecord: Record<SynlabId, boolean>;
  errorRecord: Record<SynlabId, string>;
}
export const initialState: State = {
  claimsRecord: {},
  loadingRecord: {},
  errorRecord: {},
}
export const reducer = createReducer<State>(
  initialState,
  on(ProfileActions.loadClaims, (s, a) => ({
    ...s,
    loadingRecord: {
      ...s.loadingRecord,
      [a.synlabId]: true
    },
    errorRecord: {
      ...s.errorRecord,
      [a.synlabId]: undefined
    },
  })),
  on(
    ProfileActions.loadClaimsSuccess,
    ProfileActions.loadClaimsFailure,
    (s, a) => ({
    ...s,
    loadingRecord: {
      ...s.loadingRecord,
      [a.synlabId]: false
    },
  })),
  on(
    ProfileActions.loadClaimsSuccess,
    (s, a) => ({
    ...s,
    claimsRecord: {
      ...s.claimsRecord,
      [a.synlabId]: a.claims
    },
  })),
  on(
    ProfileActions.loadClaimsFailure,
    (s, a) => ({
    ...s,
    errorRecord: {
      ...s.errorRecord,
      [a.synlabId]: a.error
    },
  })),
)
