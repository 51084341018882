import { createAction, props } from '@ngrx/store';
import { GenerateOtpRequest } from 'src/app/models/otp.model';

const generateOtp = createAction(
  '[OTP] Generate Otp',
  props<{ request: GenerateOtpRequest }>()
);
const generateOtpSuccess = createAction(
  '[OTP] Generate Otp Success',
  props<{ request: GenerateOtpRequest }>()
);
const generateOtpFailure = createAction(
  '[OTP] Generate Otp Failure',
  props<{ request: GenerateOtpRequest, error }>()
);
export const OtpActions = {
  generateOtp,
  generateOtpSuccess,
  generateOtpFailure
};
