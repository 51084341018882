import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api/api.service';
import { AbTestingActions } from '../actions/ab-testing.actions';
import { AppState } from '../reducers';
import { StsSelectors } from '../selectors/sts.selectors';
import orderBy from "lodash/orderBy";
import dayjs from 'dayjs';


@Injectable()
export class AbTestingEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService
    ) {}


  checkBarcode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AbTestingActions.checkBarcode),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ q }, endpoint]) => {
          if (!q) {
            return of(null);
          }

          return this.api.getMethod(
            `${endpoint}/api/pool-tests/poolbarcode/${q}`,
            null
          );
        }),
        tap((rt) =>
          {
            this.store.dispatch(
            AbTestingActions.checkBarcodeSuccess({data: rt})
          )}
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            AbTestingActions.checkBarcodeFailed({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  checkPool$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AbTestingActions.getDetails),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ q }, endpoint]) => {
          if (!q) {
            return of(null);
          }

          return this.api.getMethod(
            `${endpoint}/api/pool-tests/gettemporaryorderdetails/${q}`,
            null
          );
        }),
        tap((rt) =>
          {
            const t = orderBy(rt, [(n) => n.status === false, 'familyName'], ["desc","asc"]);
            const o = rt.every((n)=> n.status === true);
            this.store.dispatch(
            AbTestingActions.getDetailsSuccess({ data: t, allOrderSent: o})
          )}
        ),
        catchError((error, caught) => {
          this.store.dispatch(
            AbTestingActions.getDetailsError({ error: error })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  hasNegativeResults$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AbTestingActions.hasNegativeResults),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ testTubeIdentifier }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/pool-tests/hasnegativeresults/${testTubeIdentifier}`
            )
            .pipe(
              map((n) => ({
                hasNegative: n ? n.hasNegative : null,
                barcode: testTubeIdentifier,
              }))
            );
        }),
        tap(({ hasNegative, barcode }) => {

          this.store.dispatch(
            AbTestingActions.hasNegativeResultsSuccess({
              value: hasNegative,
              barcode,
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            AbTestingActions.hasNegativeResultsFailed({ error: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  sendOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AbTestingActions.sendOrder),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ PoolBarcode }, endpoint]) => {
          return this.api.postMethod(
            `${endpoint}/api/pool-tests/sendtemporaryorders/${PoolBarcode}`
          );
        }),
        tap((n) => {
          console.log({ sendOrder: n });
          const t = orderBy(n, [(d) => d.status === false, 'familyName'], ["desc","asc"]);
          const o = n.every((d)=> d.status === true);
          this.store.dispatch(
            AbTestingActions.sendOrderSuccess({
              data: t , allOrderSent: o
            })
          );
        }),
        catchError((err, caught) => {
          let { error } = err;
          console.log(err, error);
          let message = error ? error.message : "";
          this.store.dispatch(
            AbTestingActions.sendOrderFailed({
              message: message,
            })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  getPoolDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AbTestingActions.getPoolDetails),
        withLatestFrom(this.store.pipe(select(StsSelectors.getApiEndpoint))),
        switchMap(([{ barcode }, endpoint]) => {
          return this.api
            .getMethod(
              `${endpoint}/api/pool-tests/getbarcodedetails/${barcode}`
            )
            .pipe(
              map((n) => ({
                poolName: n ? n.poolName : "",
                poolBarcode: barcode,
                sampleDateTime: dayjs.utc(n.sampleDatetime).toISOString(),
                organization: n.organization
              }))
            );
        }),
        tap((x) => {
          this.store.dispatch(
            AbTestingActions.getPoolDetailsSuccess({
              data: x
            })
          );
        }),
        catchError((err, caught) => {
          this.store.dispatch(
            AbTestingActions.getPoolDetailsFailed({ message: err })
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

}
