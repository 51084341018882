import { Results } from "src/app/@store/models/results.model";
import { ConditionChecker, OtherData, ResultMapper } from "./cert-data-models/base-cert-data.model";
import { isCompanyIndividualResult, resultAsCompanyIndividualCertData } from "./cert-data-models/company-individual-cert-data.model";
import stubTrue from 'lodash/stubTrue';
import { resultAsOthersCertData } from "./cert-data-models/others-cert-data.model";
import { isSchoolIndividualResult, resultAsSchoolIndividualCertData } from "./cert-data-models/school-individual-cert-data.model";
import { isCompanyPoolResult, resultAsCompanyPoolCertData } from "./cert-data-models/company-pool-cert-data.model";
import { isSchoolPoolResult, resultAsSchoolPoolCertData } from "./cert-data-models/school-pool-cert-data.model";
import { isCompanyPoolForEmployeeResult, resultAsCompanyPoolForEmployeeCertData } from "./cert-data-models/company-pool-for-employee-cert-data.model";
import { isSchoolIndividualForWardResult, resultAsSchoolIndividualForWardResultCertData } from "./cert-data-models/school-individual-for-ward-result-cert-data.model";
import { WardResult } from "src/app/models/ward-result.model";
import { isNewCovidCertResult, resultAsNewCovidCertData } from "./cert-data-models/new-covid-cert-data";
type RawCertData = Results | WardResult;
export function createReportData(result: RawCertData, otherData: Partial<OtherData>) {
  const data = reportMapper.find(([checker]) => {
    return checker(result, otherData)
  })[1](result, otherData);
  return data;
}
/** tuple list of checker and mapper */
export const reportMapper: [ConditionChecker<RawCertData>, ResultMapper<RawCertData>][] = [
  [isNewCovidCertResult, resultAsNewCovidCertData],
  [isSchoolIndividualForWardResult, resultAsSchoolIndividualForWardResultCertData],
  [isCompanyPoolForEmployeeResult, resultAsCompanyPoolForEmployeeCertData],
  [isCompanyPoolResult, resultAsCompanyPoolCertData],
  [isSchoolPoolResult, resultAsSchoolPoolCertData],
  [isCompanyIndividualResult, resultAsCompanyIndividualCertData],
  [isSchoolIndividualResult, resultAsSchoolIndividualCertData],
  [stubTrue, resultAsOthersCertData]
];
