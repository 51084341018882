const skippedChars = ["ß"];
export function customUppercase(str: string) {
  if (typeof str === "string") {
    return skippedChars.reduce(
      (acc, curr) =>
        acc.indexOf(curr) > -1
          ? str
              .split(curr)
              .map((x) => x.toUpperCase())
              .join(curr)
          : acc.toUpperCase(),
      str
    );
  } else {
    return str;
  }
}
