import { Action, createReducer, on } from '@ngrx/store';
import { OtpActions } from 'src/app/@store/actions/otp.actions';
import { getOtpRequestId } from 'src/app/models/otp.model';

export const otpFeatureKey = 'otp';

export interface State {
  loadingRecord: Record<string, boolean>;
  errorRecord: Record<string, string>;
  successRecord: Record<string, boolean>;
}

export const initialState: State = {
  loadingRecord: {},
  errorRecord: {},
  successRecord: {},
};

export const reducer = createReducer<State, Action>(
  initialState,
  on(OtpActions.generateOtp, (state, action) => ({
    ...state,
    loadingRecord: {
      ...state.loadingRecord,
      [getOtpRequestId(action.request)]: true
    },
    errorRecord: {
      ...state.errorRecord,
      [getOtpRequestId(action.request)]: undefined
    },
    successRecord: {
      ...state.successRecord,
      [getOtpRequestId(action.request)]: false
    }
  })),
  on(OtpActions.generateOtpSuccess, (state, action) => ({
    ...state,
    loadingRecord: {
      ...state.loadingRecord,
      [getOtpRequestId(action.request)]: false
    },
    errorRecord: {
      ...state.errorRecord,
      [getOtpRequestId(action.request)]: undefined
    },
    successRecord: {
      ...state.successRecord,
      [getOtpRequestId(action.request)]: true
    }
  })),
  on(OtpActions.generateOtpFailure, (state, action) => ({
    ...state,
    loadingRecord: {
      ...state.loadingRecord,
      [getOtpRequestId(action.request)]: false
    },
    errorRecord: {
      ...state.errorRecord,
      [getOtpRequestId(action.request)]: action.error
    },
    successRecord: {
      ...state.successRecord,
      [getOtpRequestId(action.request)]: false
    }
  }))
);
