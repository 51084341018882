import { Results } from "src/app/@store/models/results.model";
import { Claim } from "src/app/models/claims.model";

export interface TranslationObject extends Record<string, string | TranslationObject> { }

// other data required to complete cert data and to determine template to use
export interface OtherData {
  isWardResult?: boolean;
  userIsSchooAdmin: boolean;
  userIsLolliUser: boolean;
  userIsCompanyEmployee: boolean;
  userIsCompanyAdmin: boolean;
  userPatientInfo: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
  };
  companyCheckRecord: Record<string, {
    companyName: string;
    isPoolCompany: boolean;
  }>;
  isPoolRecord: Record<string, boolean>;
  claimsRecord: Record<string, Claim[]>;
  translations: TranslationObject;
  enTranslations: TranslationObject;
  currentLanguageIsEn: boolean;
  forceCertificate: number;
  labAddress: Record<string, string>;
  resultAddress?: Record<string, { address: string, passportNumber: string; }>;
}
/** base fn type to follow when creating checker fn to know if partner
 * `ResultMapper` is the correct mapper */
export type ConditionChecker<T = Results> = (r: T, o: Partial<OtherData>) => boolean;
/** base fn type to follow when creating mapper to specific cert data (`BaseCertData`) */
export type ResultMapper<T = Results, R extends BaseCertData = BaseCertData> = (r: T, o: Partial<OtherData>) => R;

export interface BaseCertData {
  isPositive: boolean;
  isNegative: boolean;
  isInconclusive: boolean;
  result: string;
  samplingDate: string;
  samplingTime: string;
  qrCodeUrl: string;
  testReference: string;
}

export const synlabValueTranslationKeyRecord: Record<string, string> = {
  "0": "inconclusive",
  "1": "negative",
  "2": "positive",
};
export const baseResultAsCertData: ResultMapper<Results, BaseCertData> = (r, o) => {
  const resultValue = r.synlabValue == "1" || r.synlabValue == "2" ? r.synlabValue : "0";
  const translateKey = synlabValueTranslationKeyRecord[resultValue];
  return {
    isNegative: r.synlabValue == "1",
    isPositive: r.synlabValue == "2",
    isInconclusive: r.synlabValue != "1" && r.synlabValue != "2",
    result: o.translations?.[translateKey]?.["label"],
    samplingDate: r.samplingTime?.slice?.(0, 10),
    samplingTime: r.samplingTime?.slice?.(11),
    qrCodeUrl: r.qrCodeUrl,
    testReference: r.testReference,
    uniqueResultId: r.uniqueResultId,
    issuedOn: r.issuedOn
  };
}
