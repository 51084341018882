<div data-test-id="header-header"  *transloco="let t; read: 'header'"
  class="plasma-header web-header border-bottom box-shadow navbar navbar-expand-xl sticky-top pl-bg-2 pl-box-shadow-2"
  hidden>
  <div data-test-id="header-container-fluid"  class="container-fluid px-0">
    <div data-test-id="header-av-container"  class="d-flex flex-column flex-md-row nav-container">
      <div data-test-id="header-navbar-header"  class="navbar-header"
        [ngClass]="{'white-labeled-icon-dk': isDKTenant$|async}">
        <a [routerLink]="redirectRoute$ | async" class="navbar-brand">
          <img data-test-id="header-synlab-logo"  [src]="logo$|async" alt="SYNLAB's logo"
            class="img-fluid pl-logo-1" />
        </a>
      </div>
      <div data-test-id="header-navbar-nav"  class="navbar-nav ml-2">
        <ul data-test-id="header-navbar-nav-mr"  class="navbar-nav mr-auto">
          <li [attr.data-test-id]="'header-li-nav-item'+i"  class="nav-item" *ngFor="let nav of nav; let first = first; let i = index">
            <a [attr.data-test-id]="'header-link'+i" class="nav-link" [routerLink]="nav.route"
              *ngIf="auth.checkUserAccess(nav.access)|async">
              <span [attr.data-test-id]="'header-linktext'+i"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{nav.name}}">{{nav.name}}</span>
            </a>
          </li>
          <li data-test-id="header-li-my-results"  class="nav-item">

            <a data-test-id="header-my-results-href" class="nav-link my-results"
              *ngIf="!(isDFPAdmin$ | async) && !(isLabUser$ | async) && !((canAccessCheckResult$ | async) && (!(isCustomerCare$ | async) || ((isCustomerCare$ | async) && !(isDKTenant$ | async)))) || (isMassTester$ | async) || (isEETenant$|async)"
              [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/list'">
              <span data-test-id="header-my-results-hreftext"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('myResultTabLabel')}}">{{
                t('myResultTabLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-li-dk-foreignIdLabel"  class="nav-item">
            <a data-test-id="header-href-dk-foreignIdLabel-href" class="nav-link" *ngIf="isDKTenant$ | async"
              [href]="cnfg._envConfig.baseProfileUrl">
              <span data-test-id="header-href-dk-foreignIdLabel"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('foreignIdLabel')}}">{{ t('foreignIdLabel')
                }}</span>
            </a>
          </li>
          <li data-test-id="header-bookingLinkLabel-li"  class="nav-item"
            *ngIf="!(isLolliLabAdmin$ | async) && (bookingLinkEnabled$ | async) && !(canAccessCheckResult$ | async) && !(isSchool$ |async)">
            <a data-test-id="header-bookingLinkLabel-href" class="nav-link" [href]="bookingUrl$|async">
              <span data-test-id="header-bookingLinkLabel-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('bookingLinkLabel')}}">{{
                t('bookingLinkLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-checkResultLabel-li"  class="nav-item"
            *ngIf="(isAdmin$|async) || (canAccessCheckResult$ | async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() != 'true'">
            <a data-test-id="header-checkResultLabel-href" class="nav-link" [routerLink]="'/results/check'">
              <span data-test-id="header-checkResultLabel-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('checkResultLabel')}}">{{
                t('checkResultLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-userManagementLabel"  class="nav-item"
            *ngIf="(isAdmin$|async) || (canAccessUserManagement$ | async)">
            <a data-test-id="header-userManagementLabel-text-href" class="nav-link" [href]="baseAdminUrl$|async">
              <span data-test-id="header-userManagementLabel-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('userManagementLabel')}}">{{
                t('userManagementLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-registerAndTestLabel-li"  class="nav-item" *ngIf="isSchoolTeacher$ | async">
            <a data-test-id="header-registerAndTestLabel-href" class="nav-link" [routerLink]="'register-and-test'">
              <span data-test-id="header-registerAndTestLabel-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('registerAndTestLabel')}}">{{
                t('registerAndTestLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-scanPaperOrderLabel-li"  class="nav-item" *ngIf="isLabUser$ | async">
            <a data-test-id="header-scanPaperOrderLabel-href" class="nav-link" [routerLink]="'lab-order'">
              <span data-test-id="header-scanPaperOrderLabel-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('scanPaperOrderLabel')}}">{{
                t('scanPaperOrderLabel') }}</span>
            </a>
          </li>
          <li data-test-id="header-preventionWellness-li"  class="nav-item geneplanet" *ngIf="cnfg._envConfig.EnablePreventionWellness?.trim()?.toLowerCase() === 'true' && (isAppAccessUser$|async) && (isPreventionWellnessUser$|async)">
            <a data-test-id="header-preventionWellness-href" class="nav-link" [routerLink]="'prevention-wellness'">
              <span data-test-id="header-preventionWellness-text"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('preventionWellness')}}">{{
                t('preventionWellness') }}</span>
            </a>
          </li>
          <li data-test-id="header-faqLinkLabel-li"  class="nav-item" *ngIf="isDKTenant$ | async">
            <a data-test-id="header-faqLinkLabel-a" class="nav-link" href="https://faq.covidresults.dk"
              target="_blank">
              <span data-test-id="header-faqLinkLabel-txt"
                class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
                attr.data-text="{{t('faqLinkLabel')}}" target="_blank">{{
                t('faqLinkLabel') }}</span>
            </a>
          </li>
        </ul>
      </div>
      <nav data-test-id="header-initial-nav"  class="my-2 my-md-0 nav-bar-right" aria-label="header-dropdown">
        <div data-test-id="header-initial-dropdown"  class="dropdown">
          <span data-test-id="header-initial-txt"
            class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle"
            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            {{initial}}
          </span>
          <div data-test-id="header-dropdown-menu"  class="dropdown-menu pl-box-shadow-1"
            aria-labelledby="dropdownMenuButton">
            <div data-test-id="header-arrow-up"  class="arrow-up"></div>
            <div data-test-id="header-txt-wrapper"  *ngIf="country.length > 1">
              <span data-test-id="header-country-txt"  class="h-title">Country: </span>
              <a [attr.data-test-id]="'header-country-txt'+i" *ngFor="let item of country; let i = index;" (click)="setCountry(item)"
                [ngClass]="{'dropdown-item':true, 'active': activeCountryClass(item)}">{{item.countryName}}-{{item.twoLetterCountryCode}}</a>
              <hr data-test-id="header-txt-wrapper-hr" >
            </div>
            <a data-test-id="header-myAccountLabelhref" class="dropdown-item" [href]="cnfg._envConfig.baseProfileUrl"
              target="_blank">
              <em data-test-id="header-myAccountLabel"  class="fas fa-user"></em>
              {{ t('myAccountLabel') }}
            </a>
            <a data-test-id="header-bookingLinkLabelhref" class="dropdown-item" [href]="bookingUrl$|async" _target="_black"
              [routerLinkActive]="['nav-active']">
              <span data-test-id="header-bookingLinkLabel" >{{ t('bookingLinkLabel') }}</span>
            </a>
            <a data-test-id="header-logoutLabelhref" class="dropdown-item" (click)="startSignoutMainWindow()"
              href="javascript:;">
              <em data-test-id="header-logoutLabel"  class="fas fa-sign-out-alt"></em>
              {{ t('logoutLabel') }}
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<ng-container *transloco="let t; read: 'header'">
  <nav data-test-id="header-header-5"  aria-label="plasma-header"
    class="new-header plasma-header web-header navbar navbar-expand-lg navbar-light bg-light new-header border-bottom box-shadow sticky-top  pl-bg-2 "
    [ngClass]="{'white-labeled-icon-dk': isDKTenant$|async}">
    <button data-test-id="header-toggler-5"  class="navbar-toggler pl-0" type="button" data-toggle="collapse" (click)="onOpenHeaderMenu()"
      data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
      aria-expanded="false" aria-label="Toggle navigation">
      <span data-test-id="header-material-5"  class="material-icons">menu</span>
    </button>
    <a data-test-id="header-href-img-5" [routerLink]="redirectRoute$ | async" class="navbar-brand ml-2">
      <img data-test-id="header-img-5"  [src]="logo$|async" alt="SYNLAB's logo"
        class="img-fluid pl-logo-1" />
    </a>

    <div data-test-id="header-collapse-9"  class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul data-test-id="header-navbar-nav-9"  class="navbar-nav">
        <li data-test-id="header-nav-item-9"  class="nav-item">
          <!-- toggle close -->
          <button data-test-id="header-collapsed"  class="navbar-toggler collapsed d-block d-lg-none  pl-0" (click)="onCloseHeaderMenu()"
            type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation">
            <span data-test-id="header-close-2"  class="material-icons">close</span>
          </button>
        </li>

        <li [attr.data-test-id]="'header-nav-name-li'+i"  class="nav-item" *ngFor="let nav of nav; let first = first; let i = index">
          <a [attr.data-test-id]="'header-nav-name-href'+i" class="nav-link" [routerLink]="nav.route"
            *ngIf="auth.checkUserAccess(nav.access)|async" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false">
            <span data-test-id="header-nav-name"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{nav.name}}">{{nav.name}}</span>
          </a>
        </li>

        <li data-test-id="header-myResultTabLabelli-2"  class="nav-item">
          <a data-test-id="header-myResultTabLabel-href2" class="nav-link my-results"
            *ngIf="!(isDFPAdmin$ | async) && !(isLabUser$ | async) && !((canAccessCheckResult$ | async) && (!(isCustomerCare$ | async) || ((isCustomerCare$ | async) && !(isDKTenant$ | async)))) || (isMassTester$ | async) || ((isEETenant$|async))"
            [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/list'" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false">
            <span data-test-id="header-myResultTabLabel-5"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{ fullCatalogueViewEnabled && (isSuperSupport$|async) ? t('checkResultLabel') : t('myResultTabLabel') }}">
              {{ fullCatalogueViewEnabled && (isSuperSupport$|async) ? t('checkResultLabel') : t('myResultTabLabel') }}
           </span>
          </a>
        </li>
        <li data-test-id="header-foreignIdLabel-li3"  class="nav-item foreign-id">
          <a data-test-id="header-foreignIdLabel3href" class="nav-link" *ngIf="isDKTenant$ | async"
            [href]="cnfg._envConfig.baseProfileUrl">
            <span data-test-id="header-foreignIdLabel-3"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('foreignIdLabel')}}">{{ t('foreignIdLabel')
              }}</span>
          </a>
        </li>

        <!-- SA-356: 'My Results' and 'Booking' are removed from the view of the Lab User -->
        <li data-test-id="header-bookingLinkLabel-li6"  class="nav-item booking"
          *ngIf="!(isLolliLabAdmin$ | async) && !(isAppAccessUser$ | async) && !(isDFPAdmin$ | async) && (bookingLinkEnabled$|async) && !(canAccessCheckResult$|async) && !(isSchool$|async)
            || ((bookingLinkEnabled$|async) && (isNotWhiteLabled$|async) && (isAppAccessUser$|async))
          ">
          <a data-test-id="header-bookingLinkLabel6href" class="nav-link" [href]="bookingUrl$|async">
            <span data-test-id="header-bookingLinkLabel-6"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('bookingLinkLabel')}}">{{
              t('bookingLinkLabel') }}</span>
          </a>
        </li>
        <li data-test-id="header-preventionWellness-li4"  class="nav-item geneplanet" *ngIf="!(isAppAccessUser$ | async) && cnfg._envConfig.EnablePreventionWellness?.trim()?.toLowerCase() === 'true' && (isAppAccessUser$|async) && (isPreventionWellnessUser$|async)">
          <a data-test-id="header-preventionWellness5href" class="nav-link" [routerLink]="'prevention-wellness'"
            data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown">
            <span data-test-id="header-preventionWellness-5"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('preventionWellness')}}">{{
              t('preventionWellness') }}</span>
          </a>
        </li>
        <li data-test-id="header-checkResultLabel-li5"  class="nav-item"
          *ngIf="(isAdmin$|async) || (canAccessCheckResult$ |async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() != 'true'">
          <a data-test-id="header-checkResultLabel-5href" class="nav-link" [routerLink]="fullCatalogueViewEnabled ? '/results/category' : '/results/check'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-checkResultLabel-5"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('checkResultLabel')}}">{{
              t('checkResultLabel') }}</span>
          </a>
        </li>
        <li data-test-id="header-checkResultLabel-li4"  class="nav-item"
          *ngIf="(isSuperSupport$ |async) && cnfg._envConfig.FullCatalogueViewEnabled?.toLowerCase() == 'true'">
          <a data-test-id="header-checkResultLabel-href" class="nav-link" [routerLink]="'/results/category'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-checkResultLabel-4"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('checkResultLabel')}}">{{
              t('checkResultLabel') }}</span>
          </a>
        </li>
        <li data-test-id="header-userManagementLabel-li"  class="nav-item"
          *ngIf="(isAdmin$|async) || (canAccessUserManagement$|async)">
          <a data-test-id="header-userManagementLabel-href" class="nav-link" [href]="baseAdminUrl$|async">
            <span data-test-id="header-userManagementLabel"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('userManagementLabel')}}">{{
              t('userManagementLabel') }}</span>
          </a>
        </li>
        <li data-test-id="header-registerAndTestLabel-li"  class="nav-item" *ngIf="isSchoolTeacher$ | async">
          <a data-test-id="header-registerAndTestLabel-href" class="nav-link" [routerLink]="'register-and-test'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-registerAndTestLabel"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('registerAndTestLabel')}}">{{
              t('registerAndTestLabel') }}</span>
          </a>
        </li>
        <li data-test-id="header-scanPaperOrderLabel-href" data-test-id="header-scanPaperOrderLabel-li2"  class="nav-item" *ngIf="isLabUser$|async">
          <a class="nav-link" [routerLink]="'lab-order'" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-scanPaperOrderLabel-2"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('scanPaperOrderLabel')}}">{{
              t('scanPaperOrderLabel') }}</span>
          </a>
        </li>

        <!-- create accounts -->
        <li data-test-id="header-createAccountLabel-li"  class="nav-item" *ngIf="(isLolliAdmin$ | async) || (isLolliLabAdmin$ | async)">
          <a data-test-id="header-createAccountLabel-href" class="nav-link" [routerLink]="['/createaccount']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-createAccountLabel-2"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('createAccountLabel') }}
            </span>
          </a>
        </li>

        <li data-test-id="header-userEditor-li"  class="nav-item" *ngIf="(isLolliAdmin$| async)">
        <li data-test-id="header-userEditor-li"  class="nav-item" *ngIf="hasUserEditorLink$ | async">
          <a data-test-id="header-userEditor-href" class="nav-link" [routerLink]="'/results/user-editor'">
            <span data-test-id="header-userEditor"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('userEditor')}}">{{
              t('userEditor') }}</span>
          </a>
        </li>

        <!-- Pool Individual Orders link -->
        <li data-test-id="header-poolIndividualOrders-li"  class="nav-item" *ngIf="capio$|async">
          <a data-test-id="header-poolIndividualOrders-2" class="nav-link" [routerLink]="['/individual', 'orders']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-poolIndividualOrders"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('poolIndividualOrders') }}</span>
          </a>
        </li>

        <!-- Generate Individual Orders -->
        <li data-test-id="header-sendCompanyEmployeeOrders-li"  class="nav-item" *ngIf="cagop$|async">
          <a data-test-id="header-sendCompanyEmployeeOrders-2" class="nav-link" [routerLink]="['/generate', 'orders']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-sendCompanyEmployeeOrders"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('sendCompanyEmployeeOrders') }}</span>
          </a>
        </li>

        <!-- query barcode information -->
        <li data-test-id="header-createPoolOrderLabel-li"  class="nav-item" *ngIf="hasCreatePoolOrderLink$|async">
          <a data-test-id="header-createPoolOrderLabel-1" class="nav-link" [routerLink]="['/createpoolorder']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-createPoolOrderLabel"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('createPoolOrderLabel') }}
            </span>
          </a>
        </li>
        <li data-test-id="header-individualTesting-li"  class="nav-item" *ngIf="hasCreatePoolOrderLink$|async">
          <a data-test-id="header-individualTesting-2" class="nav-link" [routerLink]="['/individualtesting']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-individualTesting"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('individualTesting') }}
            </span>
          </a>
        </li>
        <!-- query barcode information -->
        <li data-test-id="header-nav-item-5"  class="nav-item" *ngIf="hasQueryBarcodeLink$|async">
          <a data-test-id="header-queryBarcodeInfoLabel-2" class="nav-link" [routerLink]="['/generate', 'querybarcodeinfo']" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
          aria-expanded="false">
            <span data-test-id="header-queryBarcodeInfoLabel"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center">{{
              t('queryBarcodeInfoLabel') }}
            </span>
          </a>
        </li>

        <li data-test-id="header-nav-link-5"  class="nav-item" *ngIf="(isDKTenant$ | async)">
          <a data-test-id="header-faqLinkLabel-5" class="nav-link" href="https://faq.covidresults.dk"
            target="_blank">
            <span data-test-id="header-faqLinkLabel-5"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('faqLinkLabel')}}" target="_blank">{{
              t('faqLinkLabel') }}</span>
          </a>
        </li>
        <!-- show only in mobile view -->
        <li data-test-id="header-nav-item-4"  class="nav-item d-block d-lg-none">
          <a data-test-id="header-startSignoutMainWindow" href="javascript: void 0;" class="nav-link px-3"
            (click)="startSignoutMainWindow()">
            <span data-test-id="header-logoutLabel-2"
              class="pl-text-color-3 font-size-4 d-flex flex-column align-items-center"
              attr.data-text="{{t('logoutLabel')}}">{{ t('logoutLabel')
              }}</span>
          </a>
        </li>
      </ul>
    </div>
    <nav data-test-id="header-lang-picker"  class="my-2 my-md-0 nav-bar-right" aria-label="lang-picker">
      <app-language-picker data-test-id="header-language-picker" ></app-language-picker>
      <div data-test-id="header-dropdown-2"  class="dropdown">

        <!-- small devices -->
        <a data-test-id="header-header-profile" class="d-block d-lg-none dropdown-toggle"
          [href]="baseProfileUrl$|async">
          <img data-test-id="header-profile-img"  src="/assets/images/new/personal-information.png" alt="">
        </a>

        <!-- desktop view -->
        <span data-test-id="header-initial-rounded"
          class="initial-rounded pl-bg-3 pl-text-color-5 pl-border-1 dropdown-toggle mr-2 d-none d-lg-block d-xl-block"
          id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          {{ initial }}
        </span>

        <div data-test-id="header-dropdown-menu"  class="dropdown-menu pl-box-shadow-1"
          aria-labelledby="dropdownMenuButton">


          <div data-test-id="header-arrow-up"  class="arrow-up"></div>
          <div data-test-id="header-country"  *ngIf="country.length > 1">
            <span data-test-id="header-title"  class="h-title">Country: </span>
            <a [attr.data-test-id]="'header-setCountry'+i" *ngFor="let item of country; let i = index" (click)="setCountry(item)"
              [ngClass]="{'dropdown-item':true, 'active': activeCountryClass(item)}">{{item.countryName}}-{{item.twoLetterCountryCode}}</a>
            <hr data-test-id="header-hr-2" >
          </div>
          <a data-test-id="header-dropdown-item-myaccount" class="dropdown-item" [href]="cnfg._envConfig.baseProfileUrl"
            target="_blank">
            <em data-test-id="header-myAccountLabel-fa-user"  class="fas fa-user"></em>
            {{ t('myAccountLabel') }}
          </a>
          <a data-test-id="header-dropdown-item-logout" class="dropdown-item" (click)="startSignoutMainWindow()"
            href="javascript:;">
            <em data-test-id="header-sign-out"  class="fas fa-sign-out-alt"></em>
            {{ t('logoutLabel') }}
          </a>

        </div>
      </div>
    </nav>
  </nav>
</ng-container>
