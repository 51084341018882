import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../@store/reducers';
import { StsSelectors } from '../@store/selectors/sts.selectors';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsLabUserGuard  {
  constructor(
        private st: Store<AppState>,
        private auth: AuthService,
        private rt: Router,
        private actRout: ActivatedRoute
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLabUser$ = this.st.pipe(select(StsSelectors.isLabUser));
    isLabUser$.subscribe(
      d => {
        if(!d){
          this.rt.navigate(["./results"]);
        }
      }
    );
    return isLabUser$;
  }
}
