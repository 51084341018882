<div data-test-id="error-message-message-container" class="info message-container" *transloco="let t; read: 'labOrder'">
  <div data-test-id="error-message-full" class="full">
    <div data-test-id="error-message-box" class="box d-flex">
      <div data-test-id="error-message-icon" class="box-icon">
        <span data-test-id="error-message-icons" class="material-icons">
          cancel
        </span>
      </div>
      <div data-test-id="error-message-message" class="message">
        <div data-test-id="error-message-title" class="title">
          {{ data.title }}
        </div>
        <p data-test-id="error-message-description"class="description">
          {{ data.message }}
        </p>
      </div>
      <div data-test-id="error-message-close" class="close-btn" (click)="close()">
        <span data-test-id="error-message-closeicon" class="material-icons">close</span>
      </div>
    </div>
  </div>
</div>
