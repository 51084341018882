import { createAction, props } from '@ngrx/store';
import { GenerateOtpRequest } from 'src/app/models/otp.model';
import { WardResultInfoResponse, WardResultValidationInfoResponse } from 'src/app/models/ward-result.model';

const loadSecurityInfo = createAction(
  '[ParentChildViewResult] Load Security info',
  props<{ id: string }>()
);

const loadSecurityInfoSuccess = createAction(
  '[ParentChildViewResult] Load Security info Success',
  props<{ data: WardResultValidationInfoResponse }>()
);

const loadSecurityInfoFailure = createAction(
  '[ParentChildViewResult] Load Security info Failure',
  props<{ error: string }>()
);

const loadResultInfo = createAction(
  '[ParentChildViewResult] Load Result Info',
  props<{ id: string, date: string, otp: string }>()
);
const loadResultInfoSuccess = createAction(
  '[ParentChildViewResult] Load Result Info Success',
  props<{ data: WardResultInfoResponse }>()
);
const loadResultInfoFailure = createAction(
  '[ParentChildViewResult] Load Result Info Failure',
  props<{ error: string }>()
);

const refreshSecurityInfo = createAction(
  '[ParentChildViewResult] Refresh Security Info',
  props<{ id: string }>()
);
const refreshSecurityInfoSuccess = createAction(
  '[ParentChildViewResult] Refresh Security Info Success',
  props<{ data: WardResultValidationInfoResponse }>()
);
const refreshSecurityInfoFailure = createAction(
  '[ParentChildViewResult] Refresh Security Info Failure',
  props<{ error: string }>()
);
const decrementAttemptsRemaining = createAction(
  '[ParentChildViewResult] Decrement Attempts Remaining'
);
const reset = createAction(
  '[ParentChildViewResult] Reset'
);

const generateOtp = createAction(
  '[ParentChildViewResult] Send Otp',
  props<{
    request: Omit<GenerateOtpRequest, 'feature'>
  }>()
);
const generateOtpSuccess = createAction(
  '[ParentChildViewResult] Send Otp Success',
);
const generateOtpFailure = createAction(
  '[ParentChildViewResult] Send Otp Failure',
  props<{
    error: string
  }>()
);

const getCertificate = createAction(
  '[ParentChildViewResult] Get Certificate'
);
const getCertificateSuccess = createAction(
  '[ParentChildViewResult] Get Certificate Success',
  props<{url: string, name: string}>()
);
const getCertificateFailure = createAction(
  '[ParentChildViewResult] Get Certificate Failure',
  props<{error: string}>()
);
export const ParentChildViewResultActions = {
  loadSecurityInfo,
  loadSecurityInfoSuccess,
  loadSecurityInfoFailure,
  loadResultInfo,
  loadResultInfoSuccess,
  loadResultInfoFailure,
  // loadResultInfoInvalidDate,
  refreshSecurityInfo,
  refreshSecurityInfoSuccess,
  refreshSecurityInfoFailure,
  decrementAttemptsRemaining,
  reset,
  generateOtp,
  generateOtpSuccess,
  generateOtpFailure,
  getCertificate,
  getCertificateSuccess,
  getCertificateFailure,
};
