import { Component, Renderer2, Injector } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { combineLatest, fromEvent } from 'rxjs';
import camelCase from "lodash/camelCase";
import { AppState } from "./@store/reducers";
import { StsSelectors } from "./@store/selectors/sts.selectors";
import { claimValue, EBodyClass, GTM_PLACEHOLDER_KEY, pageTitle } from "./appsettings";
import { AuthService } from "./service/auth/auth.service";
import { Idle, DEFAULT_INTERRUPTSOURCES, AutoResume } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { HeaderActions } from 'src/app/@store/actions/header.actions';
import { HeaderSelectors } from 'src/app/@store/selectors/header.selectors';
import { filter,take, tap, withLatestFrom, map, mergeAll } from "rxjs/operators";
import { getRedirectUrl, isPrivateRoute, trimUrl } from "./shared/component-modules/core/util/common.utils";
import { LoggerService } from "./service/logger.service";
import { EStorageKeys } from "./shared/model/storage.model";
import { ConfigService } from "./service/config/config.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { TranslocoService } from "@ngneat/transloco";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [],
})
export class AppComponent {

  private prevurl: string;
  isDKTenant$ = this.store.pipe(select(StsSelectors.isDKTenant));
  isEETenant$ = this.store.pipe(select(StsSelectors.isEETenant));
  loadingBar$ = this.store.pipe(select(HeaderSelectors.loadingBarShown));
  useNewNav$ = this.store.pipe(select(StsSelectors.useNewNav));

  private transloco: TranslocoService;
  private renderer: Renderer2;
  private title: Title;
  private idle: Idle;
  private keepalive: Keepalive;
  private logr: LoggerService;
  private gtmService: GoogleTagManagerService;

  constructor(
    private config: ConfigService,
    private store: Store<AppState>,
    private router: Router,
    private auth: AuthService,
    private injector: Injector,

  ) {
    this.transloco = this.injector.get<TranslocoService>(TranslocoService);
    this.renderer = this.injector.get<Renderer2>(Renderer2);
    this.title = this.injector.get<Title>(Title);
    this.idle = this.injector.get<Idle>(Idle);
    this.keepalive = this.injector.get<Keepalive>(Keepalive);
    this.logr = this.injector.get<LoggerService>(LoggerService);
    this.gtmService = this.injector.get<GoogleTagManagerService>(GoogleTagManagerService);

    this._fetchFreshToken();
    this.seTitle();

    this.router.events.pipe(
      withLatestFrom(this.store.select(StsSelectors.getWhiteLabelName))).subscribe(([event, whiteLabelName]) => {
      if (event instanceof NavigationStart) {
        if (this.prevurl) { this.renderer.removeClass(document.body, this.prevurl); }
        const page = camelCase(event.url.slice(1) || "");
        if (page) this.renderer.addClass(document.body, page);
        this.prevurl = page;
        this.store.dispatch(HeaderActions.closeMenu());
        this.store.dispatch(HeaderActions.startLoadingBar());
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        if (whiteLabelName) { this.renderer.addClass(document.body, `tenant-${whiteLabelName}`); }
        setTimeout(() => this.store.dispatch(HeaderActions.endLoadingBar()));
      }
    });

    this.config.envConfig$.pipe(
      filter(v=>!!v), take(1),
      withLatestFrom(
        this.store.pipe(select(StsSelectors.isDKTenant)),
        this.store.pipe(select(StsSelectors.isEETenant)),
      ),
      tap(([config, isDK, isEE]) => {
        if (isDK || isEE) {
          const idleTimeTemp = config.eeIdleTimeOutInSeconds || 900;
          const idleTime = Number(idleTimeTemp);

          this.logr.log({ idleTime });
          this._enableIdleTimer(idleTime);
        }
        if (isEE) {
          this.subscribeToLangChanges();
        }
      })).subscribe();

    this.config.envConfig$
      .pipe(filter((v) => !!v && v.EnableGoogleTag.toLowerCase() == claimValue.TRUE), take(1))
      .subscribe((v) => {
        this.router.events.forEach((item) => {
          if (item instanceof NavigationEnd && this.gtmService.googleTagManagerId != GTM_PLACEHOLDER_KEY) {
            const gtmTag = {
              event: "page",
              pageName: item.url,
            };
            this.logr.log('pushing to GTMS', { gtmTag })
            this.gtmService.pushTag(gtmTag);
          }
        });
      });
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
  }

  logout() {
    this.auth.startSignoutMainWindow()
  }

  seTitle() {
    combineLatest([
      this.store.pipe(select(StsSelectors.isDKTenant)),
      this.store.pipe(select(StsSelectors.isEETenant)),
    ]).pipe(
      take(1),
      tap(([isDKTenant, isEETenant]) => {
        let bodyClass = EBodyClass.NORMAL;
        let title = pageTitle.SYNLAB;
        if (isDKTenant) {
          title = pageTitle.COVID_DK;
          bodyClass = EBodyClass.COVID_DK;
        } else if (isEETenant) {
          title = pageTitle.ESTONIA;
        }

        this.title.setTitle(title);
        this.renderer.addClass(document.body, bodyClass);
      })
    ).subscribe()
  }

  private _fetchFreshToken() {
    const referrer = trimUrl(window.document?.referrer);
    const origin = window.location?.host;

    this.logr.log({ routes: this.router.config });
    this.logr.log({ referrer, origin });
    if (referrer != origin) {
      const redirect = getRedirectUrl();
      const routes = this.router.config;
      const privateRoute = isPrivateRoute(redirect, routes);
      this.logr.log(
        "Check if redirectURL is private route before calling startSigninMainWindow():\n",
        { privateRoute, redirect }
      );

      if (privateRoute) {
        document.querySelector('body').classList.add('signin-redirecting');
        this.auth.signinRedirectTrigger$.next(Date.now());
        if (redirect) {
          localStorage.setItem(EStorageKeys.REDIRECT_URL, redirect);
        }
      }
    }
  }

  private _enableIdleTimer(idleTimerInSeconds: number) {
    this.idle.setIdle(30); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(idleTimerInSeconds); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setAutoResume(AutoResume.idle);

    this.idle.onTimeout.subscribe(() => {
      this.logr.log({ onTimeout: true });
      this.auth.isLoggedInObs().pipe(
        take(1), tap(isLoggedIn => {
          this.logr.log({ isLoggedIn });
          if (isLoggedIn) {
            this.auth.startSignoutMainWindow();
          }
        })
      ).subscribe();
    });
    this.idle.onIdleStart.subscribe(() => this.logr.log("You've gone idle!"));
    this.idle.onTimeoutWarning.subscribe((countdown) =>
      this.logr.log("You will time out in " + countdown + " seconds!")
    );

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => this.logr.log("User is idle!"));

    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  cookieBotDetailToggled = false;
  cookieBotFrame: HTMLIFrameElement;

  private createIframes(locale:string) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `/assets/iframe/cookiebot-${locale}.html`);
    iframe.setAttribute('data-consent', 'marketing');
    iframe.setAttribute('data-locale', locale)
    return iframe;
  }

  private subscribeToLangChanges() {
    this.transloco.langChanges$.pipe(
      // test if consent has already been given then no need to show again.
      filter(() => !document.cookie.includes('CookieConsent')),
    ).subscribe(locale => {
      if (this.cookieBotFrame?.getAttribute('data-locale') == locale) {
        return false;
      }
      if (!document.querySelector('.cookiebot-iframes')) {
        // creates the iframe group for each supported locale
        const iFrameContainer:HTMLDivElement = document.createElement('div');
        iFrameContainer.classList.add('cookiebot-iframes');
        const availableLocales = this.transloco.getAvailableLangs() as string[];
        availableLocales.forEach((locale:string) => iFrameContainer.appendChild(this.createIframes(locale)));
        document.querySelector('body').appendChild(iFrameContainer);
      }
      // hide all visible iframes
      document.querySelectorAll('.cookiebot-iframes iframe').forEach((el:Element) => el.classList.contains('visible') && el.classList.remove('visible'));

      this.cookieBotFrame = document.querySelector(`.cookiebot-iframes [data-locale='${locale}']`);

      if (this.cookieBotFrame) {
        this.cookieBotFrame.contentWindow.location.reload();
        const expandElementSelector = '#CybotCookiebotDialogBodyEdgeMoreDetailsLink, #CybotCookiebotDialogBodyLevelButtonCustomize';
        // waits for iframe to be ready, then waits for the document object to load, then wait for cookiebot to be displayed
        const onIframeReady = fromEvent(this.cookieBotFrame, 'load').pipe(
          map(() => fromEvent(this.cookieBotFrame.contentWindow, 'load').pipe(
            map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnDialogDisplay'))
          ))
        );
        const onMoreDetails = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow.document.querySelectorAll(expandElementSelector), 'click')));
        const onAcceptClick = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnAccept')));
        const onDeclineClick = onIframeReady.pipe(mergeAll(), map(() => fromEvent(this.cookieBotFrame.contentWindow, 'CookiebotOnDecline')));
        // hide the active iframe once the user accepts.
        onAcceptClick.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.classList.remove('visible') });
        // hide the active iframe once the user declines.
        onDeclineClick.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.classList.remove('visible') });
        // expand theh container height when more info is toggled
        onMoreDetails.pipe(mergeAll()).subscribe(() => { this.cookieBotFrame.style.height = '70vh'; this.cookieBotDetailToggled = true });
        //show only relevant cookiebot popup
        onIframeReady.pipe(mergeAll()).subscribe(() => {
          if (this.cookieBotDetailToggled)  this.cookieBotFrame.contentWindow.document.getElementById('CybotCookiebotDialogBodyEdgeMoreDetailsLink').click();
          this.cookieBotFrame.classList.add('visible');
        });
      }// end of: if (this.cookieBotFrame)
    })
  }
}
