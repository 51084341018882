import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ProfileService } from 'src/app/service/profile/profile.service';
import * as ProfileActions from 'src/app/@store/actions/profile.actions';
import * as ProfileSelectors from 'src/app/@store/selectors/profile.selectors';
import { AppState } from 'src/app/@store/reducers';
import { getErrorMessage } from 'src/app/shared/utils/error.util';

@Injectable()
export class ProfileEffects {
  profileRecord$ = this.store.pipe(select(ProfileSelectors.claimsRecord));
  constructor(
    private store: Store<AppState>,
    private action$: Actions,
    private profileApi: ProfileService
  ) { }

  loadProfile$ = createEffect(
    () => this.action$.pipe(
      ofType(ProfileActions.loadClaims),
      map(a => a.synlabId),
      withLatestFrom(this.profileRecord$),
      mergeMap(([synlabId, record]) => {
        const claims = record[synlabId];
        if (Array.isArray(claims) && claims.length > 1) {
          return of(ProfileActions.loadClaimsSuccess({
            synlabId,
            claims
          }));
        } else {
          return this.profileApi.getClaimsBySynlabId(synlabId).pipe(
            map(res => ProfileActions.loadClaimsSuccess({ synlabId: synlabId, claims: res })),
            catchError(err => {
              const error = getErrorMessage(err);
              return of(ProfileActions.loadClaimsFailure({ error, synlabId, errorObject: err }))
            })
          )
        }
      })
    )
  )
}
