import { WardResult } from "src/app/models/ward-result.model";
import { customUppercase } from "src/app/shared/utils/string.util";
import { ConditionChecker, ResultMapper, synlabValueTranslationKeyRecord } from "./base-cert-data.model";
import { SchoolIndividualData } from "./school-individual-cert-data.model";

export const isSchoolIndividualForWardResult: ConditionChecker<unknown> = (r, o) => {
  return o?.isWardResult;
}
export const resultAsSchoolIndividualForWardResultCertData: ResultMapper<WardResult, SchoolIndividualData> = (r, o) => {
  const tranKey = synlabValueTranslationKeyRecord[r.synlabValue] ?? synlabValueTranslationKeyRecord[0];
  return {
    isSchoolIndividual: true,
    isNegative: r.synlabValue == '1',
    isPositive: r.synlabValue == '2',
    isInconclusive: r.synlabValue != '1' && r.synlabValue != '2',
    result: o.translations?.[tranKey]?.['label'],
    samplingDate: r.samplingTime?.slice?.(0, 10),
    samplingTime: r.samplingTime?.slice?.(11),
    qrCodeUrl: r.qrCodeUrl,
    testReference: r.testReference,
    firstName: customUppercase(r?.givenName?.trim?.()),
    lastName: customUppercase(r?.familyName?.trim?.()),
    barcode: r.testTubeIdentifier,
    resultComment: o.translations?.[tranKey]?.['message'],
    laboratory: r.laboratory,
    laboratoryAddress: r.laboratoryAddress
  }
}
