import { createAction } from '@ngrx/store';

export const loadPreventionWellnesss = createAction(
  '[PreventionWellness] Load PreventionWellnesss'
);

export const setMenuStateTrue = createAction(
  '[PreventionWellness] Set Menu State True'
);

export const setMenuStateFalse = createAction(
  '[PreventionWellness] Set Menu State False'
);

export const setNarrowViewTrue = createAction(
  '[PreventionWellness] Set Narrow View True'
);

export const setNarrowViewFalse = createAction(
  '[PreventionWellness] Set Narrow View False'
);
