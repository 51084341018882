import { createAction, props } from '@ngrx/store';
import { Individual, PoolBarcode, PoolDetails } from 'src/app/pages/generate-order/g-order/data-model';

export const getDetails = createAction(
  "[AbTesting] Get Temporary Details",
  props<{ q: string }>()
);

export const getDetailsSuccess = createAction(
  "[AbTesting] Get Temporary Details Success",
  props<{ data: Individual[], allOrderSent: boolean }>()
);

export const getDetailsError = createAction(
  "[AbTesting] Get Temporary Details Failed",
  props<{ error: any }>()
);

export const checkBarcode = createAction(
  "[AbTesting] Check Barcode",
  props<{ q: string }>()
);

export const checkBarcodeSuccess = createAction(
  "[AbTesting] Check Barcode Success",
  props<{ data: PoolBarcode }>()
);

export const checkBarcodeFailed = createAction(
  "[AbTesting] Get Barcode Failed",
  props<{ error: any }>()
);

export const hasNegativeResults = createAction(
  "[AbTesting] Has Negative Results",
  props<{ testTubeIdentifier: string }>()
);

export const hasNegativeResultsSuccess = createAction(
  "[AbTesting] Has Negative Results Success",
  props<{ value: boolean, barcode: string }>()
);

export const hasNegativeResultsFailed = createAction(
  "[AbTesting] Has Negative Results Failed",
  props<{ error: any }>()
);

export const sendOrder = createAction(
  "[AbTesting] Send Order",
  props<{ PoolBarcode: string }>()
);

export const sendOrderSuccess = createAction(
  "[AbTesting] Send Order Success",
  props<{ data: Individual[], allOrderSent: boolean }>()
);

export const sendOrderFailed = createAction(
  "[AbTesting] Send Order Failed",
  props<{ message: string }>()
);

export const getPoolDetails = createAction(
  "[AbTesting] Get Pool Details",
  props<{barcode: string}>()
);

export const getPoolDetailsSuccess = createAction(
  "[AbTesting] Get Pool Details Success",
  props<{ data?: PoolDetails }>()
);

export const getPoolDetailsFailed = createAction(
  "[AbTesting] Get Pool Details Failed",
  props<{message: string}>()
);

const clearPool = createAction("[IndividualOrder] Clear Pool");

export const AbTestingActions = {
  getDetails,
  getDetailsSuccess,
  getDetailsError,
  checkBarcode,
  checkBarcodeSuccess,
  checkBarcodeFailed,
  hasNegativeResults,
  hasNegativeResultsSuccess,
  hasNegativeResultsFailed,
  clearPool,
  sendOrder,
  sendOrderSuccess,
  sendOrderFailed,
  getPoolDetails,
  getPoolDetailsSuccess,
  getPoolDetailsFailed
};
