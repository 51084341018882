<div data-test-id="index-container" class="container pt-5 pb-5">
  <div data-test-id="index-container2" class="row">
    <div data-test-id="index-container3" class="col-12 col-sm-12">
      <div data-test-id="index-card" class="card p-0 pl-box-shadow-1">
        <div data-test-id="index-card-body" class="card-body verify-form">
          <h3 data-test-id="index-title" class="title-mb py-3 px-4"><em data-test-id="index-em"
              class="fas fa-user icon-color"></em> Register Patient</h3>

          <form data-test-id="index-form" class="mt-4 py-3 px-4">
            <div data-test-id="index-firstName-row" class="form-group row">
              <label data-test-id="index-firstName-label" class="control-label col-sm-4" for="email">First Name:</label>
              <div data-test-id="index-firstName-cont" class="col-sm-8">
                <input data-test-id="index-firstName" [(ngModel)]="patientData.given_name" name="firstName"
                  class="form-control" type="text" />
              </div>
            </div>
            <div data-test-id="index-surname-row" class="form-group row">
              <label data-test-id="index-surname-label" class="control-label col-sm-4" for="email">Surname:</label>
              <div data-test-id="index-surname-cont" class="col-sm-8">
                <input data-test-id="index-surname" [(ngModel)]="patientData.family_name" name="surname"
                  class="form-control" type="text" />
              </div>
            </div>
            <div data-test-id="index-email-row" class="form-group row">
              <label data-test-id="index-emaillabel" class="control-label col-sm-4" for="email">Email:</label>
              <div data-test-id="index-email-cont" class="col-sm-8">
                <input data-test-id="index-email" [(ngModel)]="patientData.email" name="email" class="form-control"
                  type="text" />
              </div>
            </div>
            <div data-test-id="index-country-row" class="form-group row">
              <label data-test-id="index-countrylabel" class="control-label col-sm-4" for="country">Country:</label>
              <div data-test-id="index-country-cont" class="col-sm-8">
                <input data-test-id="index-country" [disabled]="true" [(ngModel)]="data.selectedCountry.countryName"
                  name="country" class="form-control" type="text" />
              </div>
            </div>
            <div data-test-id="index-ssn-row" class="form-group row">
              <label data-test-id="index-ssnlabel" class="control-label col-sm-4" for="ssn">Social Security:</label>
              <div data-test-id="index-ssn-cont" class="col-sm-8">
                <input data-test-id="index-ssn" [(ngModel)]="patientData.ssn" name="ssn" class="form-control"
                  type="text" />
              </div>
            </div>
            <div data-test-id="index-back-btn-cont" class="text-right">
              <button data-test-id="index-back-btn" (click)="back()" class="btn btn-margin btn-outline-primary">
                Cancel
              </button>
              <button data-test-id="index-createPatient-btn" class="btn btn-primary pull-right"
                (click)="createPatient()" [routerLink]="['./']">
                Send Invitation
              </button>
            </div>
          </form>


        </div>
      </div>
    </div>
  </div>
</div>
