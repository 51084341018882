/** upload status (free to upload, validating, etc.) */
export enum UserUploadStatus {
  FreeToUpload = 0,
  Validating = 1,
  Verifying = 2,
  Processing = 3
}
/** upload type (employee, children, etc.) */
export enum UserUploadFileType {
  LolliStudent = 0,
  LolliEmployee = 1
}
/** temp user upload status response */
export interface UserStatusResponse {
  progressDescription: string;
  status: UserUploadStatus;
  fileType: UserUploadFileType;
  fileId: number;
  fileName: string;
}
/** Upload csv request */
export interface UploadCsvRequest {
  uploadFileType: number;
  file: File;
}
export enum TempStudentColumnFilter {
  "schoolSynlabId" = "schoolSynlabId",
  "lastName" = "lastName",
  "firstName" = "firstName",
  "dateOfBirth" = "dateOfBirth",
  "personalId" = "personalId",
  "address" = "address",
  "city" = "city",
  "zipCode" = "zipCode",
  "parentEmail" = "parentEmail",
  "parentMobile" = "parentMobile",
}
export enum TempEmployeeColumnFilter {
  "companySynlabId" = "companySynlabId",
  "email" = "email",
  "lastName" = "lastName",
  "firstName" = "firstName",
  "dateOfBirth" = "dateOfBirth",
  "employeeId" = "employeeId",
  "address" = "address",
  "city" = "city",
  "zipCode" = "zipCode",
}
export const studentFields = Object.keys(TempStudentColumnFilter);
export const employeeFields = Object.keys(TempEmployeeColumnFilter);
export interface StudentData {
  id: string;
  schoolSynlabId: string;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  gender: string;
  studentId: string;
  address: string;
  city: string;
  zipCode: string;
  countryOfResidence: string;
  parentEmail: string;
  parentMobile: string;
  validationMessage: string;
}
export interface EmployeeData {
  id: string;
  companySynlabId: string;
  email: string;
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  gender: string;
  companyId: string;
  address: string;
  city: string;
  zipCode: string;
  countryOfResidence: string;
  isTester: string;
  validationMessage: string;
}
/** model of state for getting user list */
export interface TempUserGetParams {
  searchQuery: string;
  columnFilters: string;
  showError: boolean;
}
export interface GetTempUserListRequest {
  fileId: number;
  searchQuery: string;
  pageSize: number;
  pageNumber: number;
  /** | (pipe) delimited */
  columnFilters: string;
  showError: boolean;
}
export interface GetTempUserFileRequest {
  fileId: number;
  searchQuery: string;
  /** | (pipe) delimited */
  columnFilters: string;
  showError: boolean;
}
export interface GetTempStudentListData {
  data: StudentData[];
  pagination: GetTempUserListPagination;
}
export interface GetTempEmployeeListData {
  data: EmployeeData[];
  pagination: GetTempUserListPagination;
}
export interface GetTempUserListPagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
}

/** model of item in list. response from upload history GET  */
export interface UploadHistoryItem {
  dateUploaded: string;
  fileType: UserUploadFileType;
  uploadedCsvFileName: string;
  fileId: number;
  uploadedResultCsvFileName: string;
  summary: string;
}
