import { Router, ActivatedRoute } from "@angular/router";
import { ConfigService } from "src/app/service/config/config.service";
import { Component, Inject, Renderer2 } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";
import { DOCUMENT } from "@angular/common";
import { select, Store } from "@ngrx/store";
import { AppState } from "src/app/@store/reducers";
import { StsSelectors } from "src/app/@store/selectors/sts.selectors";
import { take, withLatestFrom, filter } from "rxjs/operators";
import { STSActions } from "src/app/@store/actions/sts.actions";
import { LoggerService } from "src/app/service/logger.service";

@Component({
  selector: "app-init",
  templateUrl: "./init.component.html",
})
export class InitComponent   {
  private readonly webreqUrl = "plasma.synlab.com";
  config$ = this.store.pipe(select(StsSelectors.getConfig));
  country$ = this.store.pipe(select(StsSelectors.getCountry)).pipe(filter(country=>!!country));

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private logr: LoggerService
  ) {
    this.renderer.addClass(this.document.body, this.route.snapshot.data['name'] ?? "nindex");
    this.country$.pipe(withLatestFrom(this.config$), take(1)).subscribe(([country, config]) => this.redirect(config, country));
  }


  private redirect(config, country) {
    const redirect$ = this.store.pipe(select(StsSelectors.getRedirectURLFromQueryParam));
    if (config && country) {
      const { basePlasmaUrl, Type } = config;
      const isInitInstance = Type?.toLowerCase() === "init";

      if (isInitInstance) {
        redirect$.pipe(take(1)).subscribe((n) => {
          const redirectUrl:string = n && "/redirect?url=" + n;
          const url = `${this.getWebreqUrl(config, country, basePlasmaUrl)}${redirectUrl ?? ""}`;
          window.location.href = url;
        });
      } else {
        this.store.pipe(select(StsSelectors.getRedirectPath))
          .pipe(withLatestFrom(redirect$), take(1))
          .subscribe(([path, redirectUrlFromQueryParam]) => {
            this.logr.log({ path, redirectUrlFromQueryParam });
            if (redirectUrlFromQueryParam) {
              this.router.navigate([redirectUrlFromQueryParam]);
              this.store.dispatch(STSActions.setRedirectUrl({ path: null }));
            } else { this.router.navigate(path); }
          });
      }
    }
  }

  private getWebreqUrl(env: any, country: string, baseurl: string): string {
    const { Environment, IsSubFolderDeployment, ExcludedList } = env;
    if (ExcludedList.includes(country)) { return `${baseurl}/${country}/results`; }
    const url: string = (country ? country + "." : "")+this.webreqUrl;
    switch (Environment.toLowerCase()) {
      case "development":
        return `https://${url}`;
      case "azuredevelopment":
        return `https://dev.${url}`;
      case "staging":
        return `https://stage.${url}`;
      case "qa":
        return `https://acc.${url}`;
      case "production":
      default:
        return `https://${url}`;
    }
  }
}
