import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, catchError, withLatestFrom, switchMap, tap, delay } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import * as userEditActions from '../actions/user-edit.actions';
import * as userEditSelectors from '../selectors/user-edit.selectors';
import { StsSelectors } from '../selectors/sts.selectors';
import { ApiService } from 'src/app/service/api/api.service';
import { allowedDuplicateClaims, UserEditItemResponse, Pagination} from 'src/app/models/user-edit.model';
import { Claim } from 'src/app/models/claims.model';
import { HttpResponse } from '@angular/common/http';
import { getErrorMessage } from 'src/app/shared/utils/error.util';

@Injectable()
export class UserEditEffects {

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(
      userEditActions.loadUsers,
      userEditActions.setPageSize,
      userEditActions.nextPage,
      userEditActions.previousPage,
      userEditActions.searchBarSearch,
      userEditActions.firstPage,
      userEditActions.lastPage,
    ),
    withLatestFrom(
      this.store.pipe(select(userEditSelectors.paging)),
      this.store.pipe(select(userEditSelectors.organization)),
      this.store.pipe(select(userEditSelectors.user)),
      this.store.pipe(select(userEditSelectors.search)),
      this.store.pipe(select(StsSelectors.getApiEndpoint)),
    ),
    // api/lolliusermanagement/search
    switchMap(([_actions, { PageSize, CurrentPage }, organization, user, { term, fields }, endpoint]) => {
      let apicall: Observable<HttpResponse<UserEditItemResponse[]>>;
      if (organization) {
        apicall = this.api.getMethodResponse(`${endpoint}/api/lolliusermanagement/search`, {
          PageSize,
          Page: CurrentPage,
          OrganizationSynlabId: organization?.synlabId,
          SearchQuery: term ? encodeURIComponent(`${term}`?.trim()) : '',
          SearchParameters: fields == '' ? 'all' : fields
        });
      } else if (user) {
        apicall = this.api.getMethodResponse(`${endpoint}/api/lolliusermanagement/search/individual`, {
          PageSize,
          Page: CurrentPage,
          SearchQuery: user ? encodeURIComponent(`${user}`?.trim()) : ''
        });
      } else {
        apicall = throwError('No proper filter applied');
      }
      return apicall.pipe(
        map(response => {
          let pagination: Pagination = JSON.parse(response.headers.get('X-Pagination'));
          // for local json-server development with no X-Pagination
          if (!pagination) {
            pagination = { TotalCount: response.body?.length, TotalPages: 1, CurrentPage: 1, PageSize: 10, HasNext: false, HasPrevious: false }
          }
          return userEditActions.loadUsersSuccess({ users: response.body, paging: pagination })
        }),
        catchError((response) => {
          let message = response?.error?.message;
          message ??= getErrorMessage(response);
          this.store.dispatch(userEditActions.loadUsersFailure({ error: message }));
          return EMPTY;
        })
      )
    })
  ));

  exportUsers$ = createEffect(() => this.actions$.pipe(
    ofType(userEditActions.exportUsers),
    withLatestFrom(
      this.store.pipe(select(userEditSelectors.organization)),
      this.store.pipe(select(userEditSelectors.user)),
      this.store.pipe(select(userEditSelectors.search)),
      this.store.pipe(select(StsSelectors.getApiEndpoint))
    ),
    // api/lolliusermanagement/export
    switchMap(([_actions, organization, user, { term, fields }, endpoint]) => {
      let request: Observable<HttpResponse<Blob>>;
      if (organization) {
        request = this.api.getFileBlob(`${endpoint}/api/lolliusermanagement/export`, {
          OrganizationSynlabId: organization.synlabId,
          SearchQuery: encodeURIComponent(term),
          SearchParameters: fields
        })
      } else if (user) {
        request = this.api.getFileBlob(`${endpoint}/api/lolliusermanagement/individual/export`, {
          SearchQuery: encodeURIComponent(user),
        })
      } else {
        request = throwError('No query available');
      }
      return request.pipe(
        tap((file) => {
          let fileName: string = file.headers
            .get("content-disposition")
            .split("; ")[1];
          fileName = fileName.replace('filename=', "").replace(/"/ig, "");
          const url = URL.createObjectURL(file.body);
          this.startDownload(url, fileName);
          URL.revokeObjectURL(url);
        }),
        map(() => userEditActions.exportUsersSuccess()),
        catchError((response) => {
          let message = typeof response?.error === "string"
            ? JSON.parse(response.error)
            : getErrorMessage(response?.error);
          this.store.dispatch(userEditActions.exportUsersFailure({ error: message }));
          return EMPTY;
        })
      )
    })
  ));

  loadSelectedUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userEditActions.UserEditActions.getSelectedUserDetails),
        withLatestFrom(
          this.store.pipe(select(userEditSelectors.userActiveId)),
          this.store.pipe(select(StsSelectors.getApiEndpoint))
          ),
        switchMap(([d, userId, endpoint]) => {
          return this.api.getMethod(`${endpoint}/api/lolliusermanagement/${userId}`);
        }),
        map((res) => {
          this.store.dispatch(
            userEditActions.UserEditActions.storePreviousValue({ old_data: this.tidyData(res) })
          );
          this.store.dispatch(
            userEditActions.UserEditActions.getSelectedUserDetailsSuccess({ userDetails: this.userDetails(res) })
          );
        }),
        catchError((err, caught) => {
          let message = err?.message;
          message ??= getErrorMessage(err);
          this.store.dispatch(
            userEditActions.UserEditActions.getSelectedUserDetailsFailure({ error: message})
          );
          return caught;
        })
      ),
    { dispatch: false }
  );

  updateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userEditActions.UserEditActions.updateUser),
        withLatestFrom(
          this.store.pipe(select(userEditSelectors.UserEditSelectors.prevSelectedUserData)),
          this.store.pipe(select(userEditSelectors.userActiveId)),
          this.store.pipe(select(StsSelectors.getApiEndpoint))
        ),
        switchMap(([n, old,  userId, endpoint ]) => {
          let x = this.updateClaims(old, n.data);
          let payload = {
            claims: x,
            comments: n.comments
          }
          // /api/lolliusermanagement/
          return this.api.putMethodObject(`${endpoint}/api/lolliusermanagement/${n.userId}`, payload).pipe(
            map(() => this.store.dispatch(userEditActions.UserEditActions.updateUserSuccess())),
            catchError((response) => {
              let message = response?.error?.message;
              message ??= getErrorMessage(response?.error);
              this.store.dispatch(userEditActions.UserEditActions.updateUserFailure({ error: message }));
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: false }
  );

  updateSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userEditActions.UserEditActions.updateUserSuccess),
        withLatestFrom(this.store.pipe(select(userEditSelectors.organization))),
        delay(2000),
        map(() => this.store.dispatch(userEditActions.loadUsers()))
      ),
      { dispatch: false }
  )


  userDetails(d) {
    let tidy_data = this.tidyData([...d]);
    let formattedDetails = {};
    tidy_data.forEach((d) => {
      // duplicate claims will turn into an array
      if (formattedDetails.hasOwnProperty(d.claimType)) {
        formattedDetails[d.claimType] = Array.isArray(
          formattedDetails[d.claimType]
        )
          ? [...formattedDetails[d.claimType], d.claimValue]
          : [formattedDetails[d.claimType], d.claimValue];
      } else {
        formattedDetails[d.claimType] = d.claimValue;
      }
    });
    return formattedDetails;
  }

  tidyData(d) {
    const claimsMap = new Map();
      d.filter(
        c => allowedDuplicateClaims.indexOf(c.claimType) === -1
      )
      .forEach((c) => claimsMap.set(c.claimType, c.claimValue));
      let distinctClaims: Claim[] = [];
      claimsMap.forEach((v, k) =>
        distinctClaims.push({ claimType: k, claimValue: v })
      );
    const duplicableClaims = d.filter(
      (c) => allowedDuplicateClaims.indexOf(c.claimType) > -1
    );
    distinctClaims.push(...duplicableClaims);
    return distinctClaims;
  }

  updateClaims(old_data: Claim[], new_data: any): Claim[] {
    let resultingClaims = old_data.filter(claim => claim.claimValue != null && allowedDuplicateClaims.indexOf(claim.claimType) == -1);
    let tidy_new_data = this.removeEmpty(new_data);
    let updated_claim = this.claimFormat(tidy_new_data);
    const indexlist = resultingClaims.map((x) => x.claimType);
    updated_claim.forEach((x) => {
      let i = indexlist.indexOf(x.claimType);
      if (i > -1) {
        resultingClaims[i] = x;
      } else {
        resultingClaims.push(x);
      }
    });
    return resultingClaims;
  }

  removeEmpty(d) {
    let o = Object.keys(d)
      .filter((k) => d[k] != null)
      .reduce((a, k) => ({ ...a, [k]: d[k] }), {});

    return o
  }

  claimFormat(d): Claim[] {
    let claimBody = Object.keys(d).map((e) => {
      return {
        claimType: e,
        claimValue: d[e]
      }
    });
    let claimsMultiValue = claimBody.filter(o => Array.isArray(o.claimValue));
    claimsMultiValue.forEach(claim => {
      claim.claimValue.forEach(value => {
        claimBody.push({ claimType: claim.claimType, claimValue: value})
      })
    })
    return claimBody.filter(o => !Array.isArray(o.claimValue));
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService
  ) { }

  private startDownload(url: string, filename: string) {
    const link = document.createElement('a');
    link.setAttribute('download', filename);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
