import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImgPreloaderService {
  private readonly preloadPaths = [
    "/assets/images/sort_asc.png",
    "/assets/images/sort_desc.png",
    "/assets/images/sort_none.png",
  ];
  preloadImages() {
    this.preloadPaths.forEach(path => {
      const img = new Image()
      img.src = path;
      img.hidden = true;
      document?.body?.appendChild?.(img);
    })
  }
}
